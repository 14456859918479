import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fieldListKpiInputdata: [],
  addMoreKpiInputdata: [],
  matrixKpiInputdata: [],
};

const kpiSlice = createSlice({
  name: "kpi",
  initialState,
  reducers: {
    setKPIinputdata: (state, action) => {
      state.fieldListKpiInputdata = action.payload;
    },
    setAddMoreKpiInputdata: (state, action) => {
      state.addMoreKpiInputdata = action.payload;
    },
    setMatrixKpiInputdata: (state, action) => {
      state.matrixKpiInputdata = action.payload;
    },
    resetFieldKPIState: (state) => {
      state.fieldListKpiInputdata = [];
    },
    resetAddMoreKPIState: (state) => {
      state.addMoreKpiInputdata = [];
    },
    resetMatrixKPIState: (state) => {
      state.matrixKpiInputdata = [];
    },
  },
});

export const {
  setKPIinputdata,
  setAddMoreKpiInputdata,
  setMatrixKpiInputdata,
  resetFieldKPIState,
  resetAddMoreKPIState,
  resetMatrixKPIState,
} = kpiSlice.actions;
export default kpiSlice.reducer;
