import React, {useEffect, useState} from "react";
import {
   Box,
   Button,
   Checkbox,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow, Typography,
} from "@mui/material";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";

const ENVINT_BLUE = "#3079bd";

const OperationalUnitsTableHeader = ({isTransposed, handleTranspose, departments, data}) => {
   const [rotate, setRotate] = useState(false);

   const handleClick = () => {
      setRotate(!rotate);
      handleTranspose();
   };

   return (
      <TableHead>
         <TableRow
            sx={{
               fontWeight: "600",
               backgroundColor: "white",
               "&:hover": {
                  backgroundColor: "white",
               },
            }}
         >
            <TableCell
               sx={{
                  textAlign: "center",
                  padding: 0,
                  position: "sticky",
                  left: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                  opacity: 0.8,
               }}
            >
               <Button
                  onClick={handleClick}
                  sx={{
                     textTransform: "none",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     width: "100%",
                     height: "100%",
                     padding: "0.5rem",
                  }}
               >
                  <Typography sx={{fontWeight: 600}}>
                     {isTransposed ? "Dept" : "OU"}
                  </Typography>
                  <SwapHorizontalCircleIcon
                     sx={{
                        fontSize: "2rem",
                        marginLeft: ".5rem",
                        marginRight: ".5rem",
                        transition: "transform 0.5s",
                        transform: rotate ? "rotate(180deg)" : "rotate(0deg)",
                     }}
                  />
                  <Typography sx={{fontWeight: 600}}>
                     {!isTransposed ? "Dept" : "OU"}
                  </Typography>
               </Button>
            </TableCell>
            {!isTransposed
               ? departments.map((department, index) => (
                  <TableCell
                     key={index}
                     sx={{
                        fontWeight: "600",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                     }}
                  >
                     {department}
                  </TableCell>
               ))
               : data.map((ou, index) => (
                  <TableCell
                     key={index}
                     sx={{
                        fontWeight: "600",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                     }}
                  >
                     {Object.keys(ou)[0]}
                  </TableCell>
               ))}
         </TableRow>
      </TableHead>
   );
};

const OperationalUnitsTableBody = ({isTransposed, data, departments}) => (
   <TableBody>
      {!isTransposed
         ? data.map((ou, ouIndex) => (
            <TableRow key={ouIndex}>
               <TableCell
                  sx={{
                     fontWeight: "600",
                     position: "sticky",
                     left: 0,
                     zIndex: 1,
                     backgroundColor: "white",
                     opacity: 0.8,
                  }}
               >
                  {Object.keys(ou)[0]}
               </TableCell>
               {departments.map((department, depIndex) => (
                  <TableCell key={depIndex} sx={{textAlign: "center", width: `${100 / departments.length}%`}}>
                     <Checkbox
                        checked={Object.values(ou)[0].includes(department)}
                        sx={{
                           "&.Mui-checked": {
                              color: ENVINT_BLUE,
                           },
                        }}
                        disabled
                     />
                  </TableCell>
               ))}
            </TableRow>
         ))
         : departments.map((department, depIndex) => (
            <TableRow key={depIndex}>
               <TableCell
                  sx={{
                     fontWeight: "600",
                     position: "sticky",
                     left: 0,
                     zIndex: 1,
                     backgroundColor: "white",
                     opacity: 0.8,
                  }}
               >
                  {department}
               </TableCell>
               {data.map((ou, ouIndex) => (
                  <TableCell key={ouIndex} sx={{textAlign: "center", width: `${100 / data.length}%`}}>
                     <Checkbox
                        checked={Object.values(ou)[0].includes(department)}
                        sx={{
                           "&.Mui-checked": {
                              color: ENVINT_BLUE,
                           },
                        }}
                        disabled
                     />
                  </TableCell>
               ))}
            </TableRow>
         ))}
   </TableBody>
);

const OperationalUnitsTable = ({operationalUnits, department = []}) => {
   const [data, setData] = useState([]);
   const [departments, setDepartments] = useState(department);
   const [isTransposed, setIsTransposed] = useState(false);

   const handleTranspose = () => {
      setIsTransposed(!isTransposed);
   };

   useEffect(() => {
      setData(operationalUnits);
      const allDepartments = departments.length > 0 ? departments : [
         ...new Set(
            operationalUnits.flatMap((ou) =>
               Object.values(ou)[0].map((department) => department)
            )
         ),
      ];
      setDepartments(allDepartments);
   }, [operationalUnits]);

   return (
      <Box
         sx={{
            marginY: "2rem",
            overflowX: "auto",
            borderRadius: "1rem",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            width: "85%",
            minWidth: "90%",
         }}
      >
         <TableContainer
            component={Paper}
            elevation={3}
            sx={{
               borderRadius: "1rem",
               overflowX: "auto",
               maxWidth: "100%",
         }}
         >
            <Table>
               <OperationalUnitsTableHeader
                  isTransposed={isTransposed}
                  handleTranspose={handleTranspose}
                  departments={departments}
                  data={data}
               />
               <OperationalUnitsTableBody
                  isTransposed={isTransposed}
                  data={data}
                  departments={departments}
               />
            </Table>
         </TableContainer>
      </Box>
   );
};

export default OperationalUnitsTable;
