import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  Checkbox,
  TableCell,
  TableContainer,
  Button,
  Box,
  Tooltip,
} from "@mui/material";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ViewDoutable.css";
import "../AddDept/AddDept.css";
import Hamburger from "../../Navbars/Hamburger";
import {
  backBtnfixed,
  buttoncontainded,
  buttoncontaindedfixed,
} from "../../../styles";
import { FramerScroll } from "../../FramerScroll/FramerScroll";

const PlantTable = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ou = location.state.operationalUnits;
  const dataString = JSON.parse(ou);

  const [data, setData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isTransposed, setIsTransposed] = useState(false);

  const handleTranspose = () => {
    setIsTransposed(!isTransposed);
  };

  useEffect(() => {
    const parsedData = JSON.parse(dataString.replace(/\\/g, ""));
    setData(parsedData);

    // Get all unique departments
    const allDepartments = [
      ...new Set(
        parsedData.flatMap((item) =>
          Object.values(item)[0]
            .split(", ")
            .map((dep) => dep.trim())
        )
      ),
    ];
    setDepartments(allDepartments);
  }, []);
  // console.log(data)

  return (
    <div className="add-dept-table-body">
      <Hamburger />
      {/* <FramerScroll /> */}
      <div className="add-dept-table-container">
        <br />
        <Box style={{ display: "flex", margin: "10px 0 20px 0" }}>
          <h3 className="frame_heading">
            Departments and Operational Units Association Matrix (View Only)
          </h3>
          {/* <Button onClick={handleTranspose} sx={{textTransform:'none'}} style={{color:'white',background:'#45b653',borderRadius:'15px',margin:'15px 0',padding:'5px 15px'}}> 
          {isTransposed ? "Show original view" :"Transpose Table"}
          </Button> */}
        </Box>
        <br />
        <TableContainer sx={{ maxHeight: "440px" }}>
          <Table className="add-dept-table">
            <TableBody>
              <TableRow
                sx={{ position: "sticky", top: "0px", zIndex: 1 }}
                // className="add-dept-table-head"
                style={{ background: "white" }}
              >
                <TableCell
                  style={{
                    color: "3079bd",
                    fontWeight: "600",
                    fontSize: "1.2rem",
                  }}
                >
                  <Tooltip title="Transpose Table">
                    <Button
                      onClick={handleTranspose}
                      sx={{ textTransform: "none", marginLeft: "10px" }}
                      style={{ color: "#3079bd" }}
                    >
                      {!isTransposed ? "OU" : "Depts"}{" "}
                      <SwapHorizontalCircleIcon
                        style={{ margin: "0 5px", fontSize: "2rem" }}
                      />{" "}
                      {!isTransposed ? "Depts" : "OU"}
                    </Button>
                  </Tooltip>
                </TableCell>
                {!isTransposed
                  ? // Render headers based on departments when transposed
                    departments.map((department, index) => (
                      <Tooltip title={department}>
                        <TableCell
                          style={{
                            color: "#3079bd",
                            fontWeight: "600",
                            fontSize: "1.2rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100px",
                          }}
                          key={index}
                        >
                          {department}
                        </TableCell>
                      </Tooltip>
                    ))
                  : // Render headers based on plants when not transposed
                    data.map((plant, index) => (
                      <Tooltip title={Object.keys(plant)[0]}>
                        <TableCell
                          style={{
                            color: "#3079bd",
                            fontWeight: "600",
                            fontSize: "1.2rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100px",
                          }}
                          key={index}
                        >
                          {Object.keys(plant)[0]}
                        </TableCell>
                      </Tooltip>
                    ))}
              </TableRow>
              {!isTransposed
                ? // Render rows based on plants when transposed
                  data.map((plant, plantIndex) => (
                    <TableRow key={plantIndex}>
                      <TableCell>{Object.keys(plant)[0]}</TableCell>
                      {departments.map((department, depIndex) => (
                        <TableCell key={depIndex}>
                          <Checkbox
                            checked={Object.values(plant)[0]
                              .split(", ")
                              .map((dep) => dep.trim())
                              .includes(department)}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : // Render rows based on departments when not transposed
                  departments.map((department, index) => (
                    <TableRow key={index}>
                      <TableCell>{department}</TableCell>
                      {data.map((plant, plantIndex) => (
                        <TableCell key={plantIndex}>
                          <Checkbox
                            checked={Object.values(plant)[0]
                              .split(", ")
                              .map((dep) => dep.trim())
                              .includes(department)}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          type="submit"
          style={backBtnfixed}
          onClick={() => navigate("/organization/management")}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default PlantTable;
