import React from "react";
import {FaLinkedin} from "react-icons/fa";
import {FaEarthAsia} from "react-icons/fa6";
import "./About.css";

function About() {
  return (
    <div className="form-container sign-up-container">
      <div className="form-l">
        <br />
        <h3 className="heading">Who Are We ?</h3>
        <span className="text">
          Envint was founded with a purpose to shape a more liveable planet for
          the coming generations. Our mission is to drive sustainability into
          mainstream thought and action, with the belief that ‘green makes sense
          beyond conscience’.
        </span>
        <br />
        <h4 className="connect"> Connect with us</h4>
        <div className="social-container">
          <a
            href="https://envintglobal.com/"
            target="_blank"
            className="social"
          >
            <FaEarthAsia />
          </a>
          <a
            href="https://www.linkedin.com/company/envintglobal/"
            target="_blank"
            className="social"
          >
            <FaLinkedin />
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;
