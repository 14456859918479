import encrypt, {decryptData} from "./crypto";

const setLoginData = (response) => {
      localStorage.setItem("otpSentFlag", encrypt("false"));
      localStorage.setItem("org", response.data.organizationName);
      localStorage.setItem("userRole", response.data.userRole);
      localStorage.setItem("orgEmail", response.data.organizationEmail);
      localStorage.setItem("countryCurrency", response.data.countryCurrency);
      localStorage.setItem("loginAttempts", response.data.loginAttempts);
      localStorage.setItem("userName", response.data.name);
      localStorage.setItem("email", response.data.userEmail);
      if (response) localStorage.setItem("access", encrypt("true"));
      else localStorage.setItem("access", encrypt("false"));
}

const getOrg = () => decryptData(localStorage.getItem("org")).replace(/['"]+/g, '');
const getUserRole = () => decryptData(localStorage.getItem("userRole")).replace(/['"]+/g, '');
const getOrgEmail = () => decryptData(localStorage.getItem("orgEmail")).replace(/['"]+/g, '');
const getCountryCurrency = () => decryptData(localStorage.getItem("countryCurrency")).replace(/['"]+/g, '');
const getLoginAttempts = () => decryptData(localStorage.getItem("loginAttempts")).replace(/['"]+/g, '');
const getUserName = () => decryptData(localStorage.getItem("userName")).replace(/['"]+/g, '');
const getUserEmail = () => decryptData(localStorage.getItem("email")).replace(/['"]+/g, '');

export {getOrg, getUserRole, getOrgEmail, getCountryCurrency, getLoginAttempts, getUserName, getUserEmail, setLoginData};
