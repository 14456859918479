import React, { useCallback, useEffect, useMemo, useState } from "react";
import { VariableSizeList as List } from "react-window";
import { useLocation, useNavigate } from "react-router-dom";
import Hamburger from "../Navbars/Hamburger";
import b from "./Img/1000020928.png";
import a from "./Img/1000020929.png";
import "./Home.css";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  backBtnfixed,
  buttonfixed,
  frameworksimgs,
  frameworktable,
} from "../../styles";
import { SelectionDetails } from "../KPI Table/SelectionDetails";
import { UserDetail } from "../KPI Table/UserDetail";
import { ArrowFilterDropdown } from "../KPI Table/ArrowFilterDropdown";
import { FramerScroll } from "../FramerScroll/FramerScroll";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import MultiStepAssignManager from "../../pages/MultiStepAssignManager/MultiStepAssignManager";

let data = "";

const SelectKpis = () => {
  const location = useLocation();
  const responseData = location.state.responseData;
  const selectedData = location.state?.selectedData;
  const selectedCategories = location.state?.selectedCategories;
  const selectedSubcategories = location.state?.selectedSubcategories;
  const requestData = location.state?.selectedOption;
  const json = {
    priority: requestData,
  };
  const prioritiesJson = {
    priority: json.priority,
  };
  // // console.log("state", location.state);
  const org_email = location.state?.org?.organizationEmail;
  const org = location.state?.org;
  const navigate = useNavigate();
  const uniqueFrameworks = ["GRI", "Multiple", "BRSR"];

  const totalKpiCount = responseData && responseData.length;
  const [showModal, setshowModal] = useState(false);
  const [search, setSearch] = useState("");
  const [changedFrequencies, setChangedFrequencies] = useState(
    location.state?.changedFrequencies || []
  );
  // // console.log("changedfreq", changedFrequencies);
  const [checkedRows, setCheckedRows] = React.useState(
    location.state?.checkedRows ||
      responseData.reduce((acc, item) => {
        acc[item.KPICode] = false;
        return acc;
      }, {})
  );

  // // console.log("checkedRows", checkedRows);
  const [selectedFrequencies, setSelectedFrequencies] = useState(
    location.state?.selectedFrequencies ||
      responseData.reduce((acc, item) => {
        acc[item.KPICode] = item.Frequency;
        return acc;
      }, {})
  );
  // // console.log("selectedFre", selectedFrequencies);
  const [checkedAll, setCheckedAll] = React.useState(
    location.state?.checkedAll || false
  );
  // // console.log("checkAll", checkedAll);
  const [frameworkFilter, setFrameworkFilter] = useState(
    location.state?.frameworkFilter || ""
  );
  // // console.log("frameworkFilter", frameworkFilter);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // const handleCheckRow = (e, kpiCode) => {
  //   const checked = e && e.target && e.target.checked;

  //   if (checked) {
  //     const existingData = changedFrequencies.find(
  //       (data) => Object.keys(data)[0] === kpiCode
  //     );

  //     if (existingData) {
  //       // Check if the frequency has changed
  //       const selectedFrequency =
  //         selectedFrequencies[kpiCode] || "DefaultFrequency";
  //       const existingFrequency =
  //         existingData[kpiCode][0] || "DefaultFrequency";

  //       if (selectedFrequency !== existingFrequency) {
  //         // If frequency changed, update the entry
  //         const updatedData = {
  //           [kpiCode]: [
  //             selectedFrequency,
  //             "Department", // Update with the actual department from your data
  //           ],
  //         };

  //         setChangedFrequencies((prev) => {
  //           const index = prev.findIndex(
  //             (data) => Object.keys(data)[0] === kpiCode
  //           );
  //           return [
  //             ...prev.slice(0, index),
  //             updatedData,
  //             ...prev.slice(index + 1),
  //           ];
  //         });
  //       }
  //     } else {
  //       // If there's no entry, add a new one
  //       const newData = {
  //         [kpiCode]: [
  //           selectedFrequencies[kpiCode] || "DefaultFrequency",
  //           "DefaultDepartment", // Use the default department or update with the actual department
  //         ],
  //       };

  //       setChangedFrequencies((prev) => [...prev, newData]);
  //     }
  //   } else {
  //     // If the row is unchecked, remove it from the changedFrequencies list
  //     setChangedFrequencies((prev) =>
  //       prev.filter((data) => Object.keys(data)[0] !== kpiCode)
  //     );
  //   }

  //   setCheckedRows((prev) => ({
  //     ...prev,
  //     [kpiCode]: checked,
  //   }));

  //   const allChecked = Object.values(checkedRows).every((item) => item);
  //   setCheckedAll(allChecked);
  // };

  const searchData = useMemo(() => {
    const filteredData = responseData.filter((item) => {
      const frameworkFilterMatch =
        !frameworkFilter ||
        (frameworkFilter === "Multiple" &&
          item.GRI_BRSR === true &&
          item.GRI !== "nan" &&
          item.BRSR !== "nan") ||
        (frameworkFilter === "GRI" &&
          item.GRI_BRSR === false &&
          item.GRI !== "nan" &&
          item.BRSR === "nan") ||
        (frameworkFilter === "BRSR" &&
          item.GRI_BRSR === false &&
          item.GRI === "nan" &&
          item.BRSR !== "nan");

      const searchMatch = search
        ? item.KPICode.toLowerCase().includes(search.toLowerCase()) ||
          item.KPIdata.toLowerCase().includes(search.toLowerCase())
        : true;

      return frameworkFilterMatch && searchMatch;
    });

    return filteredData.sort((a, b) => a.KPICode.localeCompare(b.KPICode));
  }, [responseData, frameworkFilter, search]);

  const handleCheckRow = useCallback((e, kpiCode) => {
    const checked = e.target.checked;
    setCheckedRows((prev) => ({
      ...prev,
      [kpiCode]: checked,
    }));
  }, []);

  const handleCheckAll = useCallback(
    (e) => {
      const checked = e.target.checked;
      setCheckedAll(checked);
      setCheckedRows(
        searchData.reduce((acc, item) => {
          acc[item.KPICode] = checked;
          return acc;
        }, {})
      );
    },
    [searchData]
  );

  // const handleCheckAll = (e) => {
  //   const checked = e.target.checked;
  //   const newCheckedRows = {};
  //   searchData.forEach((item) => {
  //     newCheckedRows[item.KPICode] = checked;
  //   });
  //   setCheckedAll(checked);
  //   setCheckedRows(newCheckedRows);
  // };

  // const selectedCount = Object.values(checkedRows).filter(Boolean).length;

  const selectedCount = useMemo(
    () => Object.values(checkedRows).filter(Boolean).length,
    [checkedRows]
  );
  // // console.log("selectedCount", selectedCount);

  const handleAssign = () => {
    const selectedData = responseData.filter(
      (item) => checkedRows[item.KPICode]
    );

    const updatedKPIs = {};

    selectedData.forEach((data) => {
      const kpiCode = data.KPICode;

      const selectedFrequency =
        selectedFrequencies[kpiCode] || "DefaultFrequency";
      const existingFrequency =
        changedFrequencies.find(
          (changedData) => Object.keys(changedData)[0] === kpiCode
        )?.[kpiCode]?.[0] || "DefaultFrequency";

      if (selectedFrequency !== existingFrequency) {
        // Include only the data with changed frequency
        if (!updatedKPIs[kpiCode]) {
          updatedKPIs[kpiCode] = [];
        }

        updatedKPIs[kpiCode].push(
          selectedFrequency,
          data.Department || "ChangedDepartment"
        );
      } else {
        // Include all checked instances with no frequency change
        if (!updatedKPIs[kpiCode]) {
          updatedKPIs[kpiCode] = [];
        }

        updatedKPIs[kpiCode].push(
          selectedFrequency,
          data.Department || "ChangedDepartment"
        );
      }
    });

    const finalData = Object.entries(updatedKPIs).map(([kpiCode, values]) => ({
      [kpiCode]: values,
    }));

    if (!finalData.length) {
      return toast.error("Select at least one KPI to Assign");
    }

    navigate("/assignment/review-dept-ou", {
      state: {
        data0: finalData,
        data: org_email,
        codes: Object.keys(updatedKPIs),
        org: org,
        prioritiesJson: prioritiesJson,
        selectedData: location.state?.selectedData,
        selectedCategories: selectedCategories,
        selectedSubcategories: selectedSubcategories,
        selectedOption: location.state?.selectedOption,
        responseData: responseData,
        scorecard: location.state?.scorecard,
        changedFrequencies: changedFrequencies,
        checkedRows: checkedRows,
        selectedFrequencies: selectedFrequencies,
        checkedAll: checkedAll,
        frameworkFilter: frameworkFilter,
        selectedCount: selectedCount,
        selectedUsers: location.state?.selectedUsers,
      },
    });
  };

  const handleFrameworkFilter = (filter) => {
    setFrameworkFilter(filter);
  };

  // const filteredData = responseData.filter((item) => {
  //   const frameworkFilterMatch =
  //     !frameworkFilter ||
  //     (frameworkFilter === "Multiple" &&
  //       item.GRI_BRSR === true &&
  //       item.GRI !== "nan" &&
  //       item.BRSR !== "nan") ||
  //     (frameworkFilter === "GRI" &&
  //       item.GRI_BRSR === false &&
  //       item.GRI !== "nan" &&
  //       item.BRSR === "nan") ||
  //     (frameworkFilter === "BRSR" &&
  //       item.GRI_BRSR === false &&
  //       item.GRI === "nan" &&
  //       item.BRSR !== "nan");

  //   return frameworkFilterMatch;
  // });

  // const sortedData = filteredData.slice().sort((a, b) => {
  //   if (a.KPICode < b.KPICode) {
  //     return -1;
  //   }
  //   if (a.KPICode > b.KPICode) {
  //     return 1;
  //   }
  //   return 0;
  // });

  // const searchData = sortedData.filter((item) => {
  //   const kpiCodeMatch = item.KPICode.toLowerCase().includes(
  //     search.toLowerCase()
  //   );
  //   const kpiDataMatch = item.KPIdata.toLowerCase().includes(
  //     search.toLowerCase()
  //   );

  //   return kpiCodeMatch || kpiDataMatch;
  // });

  // Memoize the filtered and sorted data

  const handleSearch = debounce((value) => {
    setSearch(value.trim());
  }, 400);

  const handleClose = () => {
    setshowModal(false);
  };

  const handleFrequencyChange = useCallback((event, kpiCode) => {
    const selectedFrequency = event.target.value;

    setSelectedFrequencies((prev) => ({
      ...prev,
      [kpiCode]: selectedFrequency,
    }));

    const existingData = changedFrequencies.find(
      (data) => Object.keys(data)[0] === kpiCode
    );

    if (existingData) {
      // if the KPI code already exists in the list, update its frequency
      const updatedData = {
        [kpiCode]: [
          selectedFrequencies[kpiCode] || "DefaultFrequency",
          "Department", // Change to the desired department value
        ],
      };

      setChangedFrequencies((prev) => {
        const index = prev.findIndex(
          (data) => Object.keys(data)[0] === kpiCode
        );
        return [...prev.slice(0, index), updatedData, ...prev.slice(index + 1)];
      });
    } else {
      // if the KPI code doesn't exist in the list, add it
      const newData = {
        [kpiCode]: [
          selectedFrequencies[kpiCode] || "DefaultFrequency",
          "DefaultDepartment", // Change to the desired department value
        ],
      };

      setChangedFrequencies((prev) => [...prev, newData]);
    }
  }, []);

  const handleBack = () => {
    navigate("/assignment/select-framework", {
      state: {
        org: org,
        prioritiesJson: prioritiesJson,
        selectedData: selectedData,
        selectedCategories: selectedCategories,
        selectedSubcategories: selectedSubcategories,
        selectedOption: location.state?.selectedOption,
        responseData: responseData,
        scorecard: location.state?.scorecard,
        changedFrequencies: changedFrequencies,
        // checkedRows: checkedRows,
        selectedFrequencies: selectedFrequencies,
        // checkedAll: checkedAll,
        frameworkFilter: frameworkFilter,
        // selectedCount: selectedCount,
        selectedUsers: location.state?.selectedUsers,
      },
    });
  };

  const step = 3;

  const Row = useCallback(
    ({ index, style }) => {
      const item = searchData[index];
      const { height, width } = style;

      return (
        <div style={style}>
          <TableRow style={{ height: height }}>
            <TableCell style={{ width: "50px" }}>
              <input
                type="checkbox"
                checked={checkedRows[item.KPICode] || false}
                onChange={(e) => handleCheckRow(e, item.KPICode)}
              />
            </TableCell>
            <Tooltip
              arrow
              placement="top"
              TransitionProps={{ timeout: 500 }}
              title={`${item.CategoryTags.split(":").slice(0).join(" : ")}`}
            >
              <TableCell
                style={{ width: "50px" }}
                className={
                  item.CategoryTags.split(":")[0] === "Environment"
                    ? "env-bg"
                    : item.CategoryTags.split(":")[0] === "Social"
                    ? "blue1"
                    : item.CategoryTags.split(":")[0] === "Governance"
                    ? "pink"
                    : "esg"
                }
              >
                {item.KPICode}
              </TableCell>
            </Tooltip>
            <TableCell style={{ width: "100px" }}>
              {item.GRI_BRSR === false &&
              item.GRI !== "nan" &&
              item.BRSR === "nan" ? (
                <img src={a} alt="gri" style={frameworksimgs} />
              ) : item.BRSR !== "nan" && item.GRI !== "nan" ? (
                <>
                  <img src={b} alt="brsr" style={frameworksimgs} />
                  <img src={a} alt="gri" style={frameworksimgs} />
                </>
              ) : (
                <img src={b} alt="brsr" style={frameworksimgs} />
              )}
            </TableCell>
            <Tooltip title={`Pillar : ${item.CategoryTags.split(":")[0]}`}>
              <TableCell sx={{ width: "500px" }}>{item.KPIdata}</TableCell>
            </Tooltip>

            <TableCell sx={{ width: "100px" }}>
              <Select
                value={selectedFrequencies[item.KPICode]}
                onChange={(e) => handleFrequencyChange(e, item.KPICode)}
                style={{ width: "100px" }}
              >
                <MenuItem value="Annual">Annual</MenuItem>
                <MenuItem value="Month">Monthly</MenuItem>
              </Select>
            </TableCell>

            <TableCell sx={{ width: "260px", textAlign: "center" }}>
              {item.Department}
            </TableCell>
          </TableRow>
        </div>
      );
    },
    [
      searchData,
      checkedRows,
      handleCheckRow,
      selectedFrequencies,
      handleFrequencyChange,
    ]
  );

  const getItemSize = (index) => {
    const item = searchData[index];
    const baseHeight = 50;
    const extraHeight = Math.ceil(item.KPIdata.length / 70) * 20;
    return baseHeight + extraHeight;
  };

  return (
    <>
      <Hamburger />
      <FramerScroll />
      <div style={{ padding: "1rem" }}>
        <TableContainer
          style={{
            backgroundColor: "#f9f8f9",
            padding: "0 0.5rem",
            borderRadius: "20px",
            width: "100%",
          }}
          className="table-container-kpi"
        >
          <Box sx={{ marginTop: "20px" }}>
            <MultiStepAssignManager step={step} />
          </Box>

          <div className="header-box">
            <h3 className="frame_heading">Select KPI's</h3>
            <TextField
              id="outlined-basic"
              label="Search KPI"
              variant="outlined"
              className="search-kpi"
              style={{ marginRight: "50px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <Table
            className="kpi-table"
            stickyHeader
            aria-label="sticky table"
            style={{
              borderRadius: "30px",
              width: "95%",
              margin: "auto",
              tableLayout: "fixed",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "50px", padding: "6px" }}>
                  <input
                    type="checkbox"
                    checked={Object.values(checkedRows).every(Boolean)}
                    onChange={handleCheckAll}
                  />
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Poppins", width: "60px" }}
                  style={frameworktable}
                >
                  KPI Code
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Poppins", width: "100px" }}
                  style={frameworktable}
                >
                  Framework
                  <ArrowFilterDropdown
                    options={uniqueFrameworks}
                    onSelect={handleFrameworkFilter}
                  />
                </TableCell>
                <TableCell
                  style={{
                    width: "500px",
                    padding: "6px",
                    fontFamily: "Poppins",
                    backgroundColor: "white",
                    color: "#3079bd",
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  KPI
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Poppins" }}
                  style={frameworktable}
                >
                  Frequency
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Poppins" }}
                  style={frameworktable}
                >
                  Department
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <List
                height={400}
                itemCount={searchData.length}
                itemSize={getItemSize}
                width={1180}
              >
                {Row}
              </List>
            </TableBody>
          </Table>
          <br />
          <SelectionDetails
            selectedCount={selectedCount}
            totalKpiCount={totalKpiCount}
          />
          <Button onClick={handleBack} style={backBtnfixed}>
            Back
          </Button>
          <Button style={buttonfixed} onClick={handleAssign}>
            Assign KPIs
          </Button>
        </TableContainer>
        {showModal && (
          <div>
            <UserDetail handleClose={handleClose} data={data} />
          </div>
        )}
      </div>
    </>
  );
};

export default SelectKpis;
