import React, {useEffect, useState} from "react";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import {backBtnfixed, buttoncontaindedfixed, inputfieldstyle,} from "../../styles";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import {baseUrl} from "../../baseurl";
import MultiStep from "./MultiStep";
import {BsInfoCircle} from "react-icons/bs";
import {decryptData} from "../../services/crypto";
import {getOrg} from "../../services/loggedIn";
import {useTheme} from "@mui/material/styles";

export const OrgDetails = ({onAdd}) => {
  const location = useLocation();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const OrgData = location.state?.orgData;
  const skills = location.state?.skills;
  const operationalUnits = location.state?.operationalUnits;
  const skillsWithDepartments = location.state?.skillsWithDepartments;
  const selectedItems = location.state?.selectedItems;

  // // console.log("state", location.state);

  const [image, setImage] = useState("");

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    // // console.log(">>>>>>>>", image);
  };

  let [orgname, setOrgname] = useState(location.state?.orgData?.name || "");
  let [orgEmail, setOrgEmail] = useState(
    location.state?.orgData?.organizationEmail || ""
  );
  const [orgtype, setOrgType] = useState(location.state?.orgData?.type || "");
  const [address, setAddress] = useState(
    location.state?.orgData?.address || ""
  );
  const [mobile, setMobile] = useState(location.state?.orgData?.mobile || "");
  let [spocName, setSpocName] = useState(
    location.state?.orgData?.spocName || ""
  );
  let [spocEmail, setSpocEmail] = useState(
    location.state?.orgData?.spocEmail || ""
  );
  const [spocMobile, setSpocMobile] = useState(
    location.state?.orgData?.spocMobile || ""
  );
  const [mobileError, setMobileError] = useState(false);
  const [spocmobileError, setSpocMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [spocemailError, setSpocEmailError] = useState(false);
  const [code, setCode] = useState(location.state?.orgData?.code || "");

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidSpocEmail, setIsValidSpocEmail] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [country, setCountry] = useState(
    location.state?.orgData?.country || ""
  );
  const [countryData, setCountryData] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState(
    location.state?.selectedManagers || []
  );
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    // const token = window.localStorage.getItem("token");
    // const headers = {
    //   Authorization: `${token}`,
    //   "Content-Type": "application/json",
    // };
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/countryCodes`, {
          withCredentials: true,
        });
        // Assuming the response data is an object with country names as keys and country codes as values
        setCountryData(response.data);

        // After setting country data, find and set the selected country
        if (country && code) {
          const countryObject = Object.entries(response.data)
            .map(([label, value]) => ({label, value}))
            .find((c) => c.label === country && c.value === code);

          if (countryObject) {
            setSelectedCountry(countryObject);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getAllEnvintUsers = async () => {
      try {
        const organizationName = getOrg();
        const res = await axios.post(
          `${baseUrl}/getAllManagersAndConsultant`,
          {organizationName},
          {withCredentials: true}
        );
        console.log("res", res);
        const decryptedData = decryptData(res.data.encryptedData);
        const parsedData = JSON.parse(decryptedData);
        console.log("parsedData", parsedData);
        // Filter managers and set them to state, storing both name and email
        // const managersList = parsedData.users
        //   .filter((user) => user.adminRights === "Manager")
        //   .map((user) => ({ name: user.userName, email: user.email }));
        setManagers(parsedData.users.Manager);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
    getAllEnvintUsers();
  }, []);

  const handleManagerChange = (event) => {
    const selectedManager = event.target.value;
    setSelectedManagers(selectedManager);
  };

  const countries = Object.entries(countryData).map(([label, value]) => ({
    label,
    value,
  }));

  const handleChange = (event, newValue) => {
    if (newValue) {
      setCountry(newValue.label);
      setCode(newValue.value);
      setSelectedCountry(newValue);
    } else {
      setCountry(null);
      setCode(null);
    }
  };
  const handleTypeChange = (event) => {
    setOrgType(event.target.value);
  };

  const validateMobileNumber = (value) => {
    const isValid = /^\d+$/.test(value) && value.length === 10;
    setMobileError(!isValid);
    return isValid;
  };
  const validateSpocMobileNumber = (value) => {
    const isValid = /^\d+$/.test(value);
    setSpocMobileError(!isValid);
    return isValid;
  };

  const validateEmail = (value) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setEmailError(!isValid);
    return isValid;
  };
  const validateSpocEmail = (value) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setSpocEmailError(!isValid);
    return isValid;
  };
  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);

    setMobile(limitedValue);
    // validateMobileNumber(value);
  };
  const handleSpocMobileNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);

    setSpocMobile(limitedValue);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setOrgEmail(value);
    validateEmail(value);
  };
  const handleSpocEmailChange = (e) => {
    const value = e.target.value;
    setSpocEmail(value);
    validateSpocEmail(value);
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    orgEmail = orgEmail.toLowerCase();
    spocEmail = spocEmail.toLowerCase();
    orgname = orgname.charAt(0).toUpperCase() + orgname.slice(1);
    spocName = spocName.charAt(0).toUpperCase() + spocName.slice(1);

    if (mobile.length === 10 && spocMobile.length === 10) {
      const userData = {
        organizationEmail: orgEmail,
        name: orgname,
        type: orgtype,
        address,
        mobile,
        spocName,
        spocEmail,
        spocMobile,
        code,
        country,
        image,
      };
      // // console.log(userData)

      navigate("/organization/add-dept", {
        state: {
          orgData: userData,
          skills: skills,
          operationalUnits: operationalUnits,
          skillsWithDepartments: skillsWithDepartments,
          selectedItems: selectedItems,
          selectedManagers: selectedManagers,
          // organizationEmail:orgEmail,
          // image:image ? image: ""
        },
      });
    } else {
      toast.error("Moible Number should be of 10 digits");
    }
  };

  useEffect(() => {
    // const token = window.localStorage.getItem("token");
    // const headers = {
    //   Authorization: `${token}`,
    // };
    const validateEmail = async () => {
      const isCompleteEmail = /\S+@\S+\.\S+/.test(orgEmail);

      if (isCompleteEmail) {
        const data = {
          organizationEmail: orgEmail,
        };

        await axios
          .post(`${baseUrl}/check-organization`, data, {
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.organizationExists !== null) {
              setIsValidEmail(false);
            } else {
              setIsValidEmail(true);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setIsValidEmail(true);
      }
    };

    validateEmail();
  }, [orgEmail]);

  useEffect(() => {
    // const token = window.localStorage.getItem("token");
    // const headers = {
    //   Authorization: `${token}`,
    // };
    const validateEmail = async () => {
      const isCompleteEmail = /\S+@\S+\.\S+/.test(spocEmail);

      if (isCompleteEmail) {
        const data = {
          spocEmail: spocEmail,
          spocMobile: spocMobile,
        };
        await axios
          .post(`${baseUrl}/check-organization`, data, {
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.spocExists !== false) {
              setIsValidSpocEmail(false);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setIsValidSpocEmail(true);
      }
    };

    validateEmail();
  }, [spocEmail, spocMobile]);

  const step = 0;

  // // console.log("managers", managers);
  // // console.log("selectedMnagers", selectedManagers);

  return (
    <>
      <div className="add-container">
        <Box sx={{marginBottom: "20px"}}>
          <MultiStep step={step}/>
        </Box>
        <h3 className="frame_heading">Organization Details</h3>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            color: "red",
            fontWeight: "600",
          }}
        >
          <BsInfoCircle/>
          &nbsp; All the fields are required.
        </p>
        <Box
          sx={{
            width: "50%",
            margin: "0px auto",
            padding: "20px",
            borderRadius: "20px",
          }}
          boxShadow={3}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  type="email"
                  value={orgEmail}
                  onChange={handleEmailChange}
                  required
                  error={!isValidEmail}
                  helperText={!isValidEmail ? "Email already exists." : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  type="text"
                  value={orgname}
                  onChange={(e) => setOrgname(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={orgtype}
                    label="Age"
                    onChange={handleTypeChange}
                  >
                    <MenuItem value={"Client"}>Client</MenuItem>
                    <MenuItem value={"Envint"}>Envint</MenuItem>
                    <MenuItem value={"Partner"}>Partner</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Address"
                  variant="outlined"
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  variant="outlined"
                  type="tel"
                  value={mobile}
                  onChange={handleMobileNumberChange}
                  required
                  error={mobileError}
                  helperText={mobileError ? "Enter only numbers" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  options={countries}
                  getOptionLabel={(option) =>
                    `${option.label || location.state?.orgData?.country} (${
                      option.value || location.state?.orgData?.code
                    })`
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Select a country"/>
                  )}
                  onChange={handleChange}
                  value={selectedCountry}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="SPOC Email"
                  variant="outlined"
                  type="tel"
                  value={spocEmail}
                  onChange={handleSpocEmailChange}
                  required
                  error={!isValidSpocEmail}
                  helperText={!isValidSpocEmail ? "Email already exists." : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="SPOC Name"
                  variant="outlined"
                  type="tel"
                  value={spocName}
                  onChange={(e) => setSpocName(e.target.value)}
                  className="capitalize"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="SPOC Mobile"
                  variant="outlined"
                  type="tel"
                  value={spocMobile}
                  onChange={handleSpocMobileNumberChange}
                  required
                  error={spocmobileError}
                  helperText={spocmobileError ? "Enter only numbers" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="manager-label">Select Manager</InputLabel>
                  {/*<Select*/}
                  {/*  labelId="manager-label"*/}
                  {/*  id="manager-label"*/}
                  {/*  label="Select Manager"*/}
                  {/*  // multiple*/}
                  {/*  value={selectedManagers}*/}
                  {/*  onChange={handleManagerChange}*/}
                  {/*  renderValue={(selected) => {*/}
                  {/*    // Find the manager objects for the selected emails*/}
                  {/*    const selectedManagerObjects = managers.filter(*/}
                  {/*      (manager) => selected.includes(manager.email)*/}
                  {/*    );*/}
                  {/*    // Return a string of selected manager names*/}
                  {/*    return selectedManagerObjects.map(*/}
                  {/*      (manager) => manager.userName*/}
                  {/*    );*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  /!* {getUserRole() === "Manager" ? (*/}
                  {/*    <MenuItem value={getUserEmail()}>*/}
                  {/*      <ListItemText*/}
                  {/*        primary={getUserName()}*/}
                  {/*        secondary={getUserEmail()}*/}
                  {/*      />*/}
                  {/*    </MenuItem>*/}
                  {/*  ) : ( *!/*/}
                  {/*  {managers.map((manager, index) => (*/}
                  {/*    <MenuItem key={index} value={manager.email}>*/}
                  {/*      /!* <Checkbox*/}
                  {/*        checked={selectedManagers.indexOf(manager.email) > -1}*/}
                  {/*      /> *!/*/}
                  {/*      <ListItemText*/}
                  {/*        primary={manager.userName}*/}
                  {/*        secondary={manager.email}*/}
                  {/*      />*/}
                  {/*    </MenuItem>*/}
                  {/*  ))}*/}
                  {/*  /!* )} *!/*/}
                  {/*</Select>*/}
                  <Select
                    labelId="manager-label"
                    id="manager-label"
                    label="Select Manager"
                    value={selectedManagers}
                    onChange={handleManagerChange}
                    renderValue={(selected) => {
                      // Find the manager object for the selected email
                      const selectedManager = managers.find((manager) => manager.email === selected);
                      // Return the selected manager name
                      return selectedManager ? selectedManager.userName : "";
                    }}
                  >
                    {managers.map((manager, index) => (
                      isMediumScreen
                        ? (
                          <MenuItem key={index} value={manager.email}>
                            <ListItemText
                              primary={manager.userName}
                              secondary={manager.email}
                            />
                          </MenuItem>
                        ) : (
                          <MenuItem key={index} value={manager.email}>
                            <Box width="100%">
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <ListItemText primary={manager.userName}/>
                                </Grid>
                                <Grid item xs={6}>
                                  <ListItemText secondary={manager.email}/>
                                </Grid>
                              </Grid>
                            </Box>
                          </MenuItem>
                        )
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Add Logo"
                  type="file"
                  onChange={handleImageChange}
                  style={inputfieldstyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <br/>
            <br/>
            <Button
              type="submit"
              style={backBtnfixed}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button type="submit" style={buttoncontaindedfixed}>
              Continue
            </Button>
          </form>
        </Box>
      </div>
    </>
  );
};
