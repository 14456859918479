import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {FaUsers, FaWpforms} from "react-icons/fa6";
import {BsBuildings} from "react-icons/bs";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardLayout from "../../components/DashboradLayout/DashboardLayout";
import {MdCloudDownload} from "react-icons/md";
import {Loader} from "../../../components/Loader/Loader";

const DashboardLandingPage = () => {
  const [loading,setLoading]=useState(true)

  useEffect(()=>{
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); 

    return () => clearTimeout(timer);
  },[])

  if (loading) {
    return <Loader loading={loading}/>
  }

  return (
    <div>
      {/* <DashHamburger /> */}
      <DashboardLayout>
        <div className="dash-landing-page-container">
          <div className="link-box-container">
            <Link
              to="/dashboard/tracker/kpi-monitoring"
              className="link-box blue"
            >
              <div className="circle">
                <DashboardIcon
                  sx={{ fontSize: "5rem", color: "white" }}
                  className="circle-icon"
                />

                {/* <h3>Organization Management</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                ESG & KPI <br /> Monitoring
              </h4>
            </Link>

            <Link to="/download_report" className="link-box green2">
              <div className="circle">
                <MdCloudDownload
                  sx={{ fontSize: "5rem", color: "white" }}
                  className="circle-icon"
                />

                {/* <h3>Organization Management</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                Download & Preview
                <br /> Report
              </h4>
            </Link>
            <Link to="/dashboard/tracker/department" className="link-box green">
              <div className="circle">
                <FaWpforms className="circle-icon" />

                {/* <h3>User Management</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                Department <br /> Tracker
              </h4>
            </Link>

            <Link
              to="/dashboard/tracker/operation-unit"
              className="link-box orange"
            >
              <div className="circle">
                <BsBuildings className="circle-icon" />

                {/* <h3>Form Manager</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                Operation Unit <br /> Tracker
              </h4>
            </Link>

            <Link
              to="/dashboard/tracker/user-adoption"
              className="link-box purple"
            >
              <div className="circle">
                <FaUsers className="circle-icon" />
                {/* <h3>Form Manager</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                User <br /> Adoption
              </h4>
            </Link>
            <Link
              to="/dashboard/spoc-review"
              className="link-box disable"
            >
            {/* <div className="link-box disable"> */}
              <div className="circle">
                <FaUsers className="circle-icon" />
                {/* <h3>Form Manager</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                Data <br /> Review
              </h4>
              {/* <p style={{color:'#fff'}}>Coming Soon...</p> */}
              {/* </div> */}
            </Link>
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default DashboardLandingPage;
