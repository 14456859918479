import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";
import React from "react";

const ReviewUserTable = ({
  ous,
  tableData,
  selectedUsers,
  handleUserChange,
}) => {
  return (
    <TableContainer sx={{ maxHeight: "440px",marginTop:'20px' }}>
      <Table style={{ backgroundColor: "white" }}>
        <TableBody>
          <TableRow
            sx={{ position: "sticky", top: "0px", zIndex: 1 }}
            style={{ backgroundColor: "#fff" }}
          >
            <TableCell
              style={{
                color: "#3079bd",fontWeight:'600',fontSize:"1.2rem",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',maxWidth:'100px',
                fontFamily: "Poppins",
              }}
            >
              Department
            </TableCell>
            {ous.map((ou) => (
              <TableCell
                style={{
                  color: "#3079bd",fontWeight:'600',fontSize:"1.2rem",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',maxWidth:'100px',
                  fontFamily: "Poppins",
                }}
                key={ou}
              >
                {ou}
              </TableCell>
            ))}
          </TableRow>
          {Object.entries(tableData).map(([department, ouData]) => (
            <TableRow key={department}>
              <TableCell>{department}</TableCell>
              {ous.map((ou) => (
                <TableCell key={ou}>
                  {Array.isArray(ouData[ou]) && ouData[ou].length !== 0 ? (
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">Select User</InputLabel>
                    <Select
                      value={selectedUsers[`${department}-${ou}`] || ""}
                      onChange={(event) =>
                        handleUserChange(department, ou, event)
                      }
                   
                      label="Select user"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                    >
                      <MenuItem value="">Select User</MenuItem>
                      {ouData[ou].map((user) => (
                        <MenuItem key={user.userName} value={user.userName}>
                          {user.userName}
                        </MenuItem>
                      ))}
                      {/* <option>N/A</option> */}
                    </Select>
                    </FormControl>
                  ) : (
                    "Plant N/A"
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReviewUserTable;
