import React, {useEffect, useState} from "react";

const LinearProgressBar = ({ KpiData }) => {
  const [progress, setProgress] = useState(0);

  const arrayLength = KpiData?.length || 0;
  const savedCount =
    KpiData?.filter((item) => item.KPIStatus === "saved").length || 0;
  const percentageFilled =
    arrayLength > 0 ? (savedCount / arrayLength) * 100 : 0;

  let barColor = "transparent"; // Default color: Transparent

  if (arrayLength > 0) {
    if (percentageFilled < 50) {
      barColor = "red";
    } else if (percentageFilled >= 50 && percentageFilled < 100) {
      barColor = "#ff9c00"; // Orange
    } else {
      barColor = "#45b653"; // Green
    }
  }

  useEffect(() => {
    let start = 0;
    const end = percentageFilled;

    if (start === end) return;

    let totalAnimationDuration = 700; // Total duration of animation in ms
    let incrementTime = 20; // Increment every 20ms
    let step = (end - start) / (totalAnimationDuration / incrementTime);

    let timer = setInterval(() => {
      start += step;
      setProgress(start.toFixed(0));
      if (start >= end) clearInterval(timer);
    }, incrementTime);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [percentageFilled, savedCount, arrayLength, KpiData]);

  return (
    <div
      style={{
        width: "100%",
        height: "10px",
        backgroundColor: "#dadada",
        borderRadius: "6px",
        position: "relative",
        cursor: "pointer",
        zIndex: 1,
      }}
    >
      <div
        style={{
          width: `${savedCount > 0 ? progress : 0}%`,
          height: "100%",
          backgroundColor: barColor,
          transition: "width .2s ease-out",
          position: "relative",
          borderRadius: "6px",
          zIndex: 50,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "-20px",
            left: `${savedCount > 0 ? "100%" : "0%"}`,
            transform: `${
              savedCount > 0 ? "translateX(-50%)" : "translateX(0)"
            }`,
            color: barColor,
            fontSize: "12px",
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          {savedCount > 0 ? `${progress}%` : "0%"}
        </div>
      </div>
    </div>
  );
};

export default LinearProgressBar;
