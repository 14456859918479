import React, { useEffect, useState } from "react";
import "./OrgManagement.css";
import Hamburger from "../../components/Navbars/Hamburger.js";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import {
  buttoncontainded,
  buttoncontaindedDisabled,
  inputfieldstyle1,
  tablehead,
} from "../../styles.js";
import { tableheaddata } from "./tableheaddata.js";
import { OrgTableRow } from "../../components/Organization/OrgTableRow.jsx";
import { OrgDetails } from "../../components/Organization/OrgDetails.jsx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaFileArrowDown } from "react-icons/fa6";
import * as XLSX from "xlsx";
import { decryptData } from "../../services/crypto.js";
import {
  deleteOrganization,
  fetchAllOrganizations,
} from "../../services/OrganizationManagemnetApi.js";
import { Loader } from "../../components/Loader/Loader.jsx";
import { baseUrl } from "../../baseurl.js";
import axios from "axios";

import {
  getLoginAttempts,
  getOrg,
  getUserEmail,
  getUserRole,
} from "../../services/loggedIn";

import ConfirmModal from "../../components/PopUpDialog/ConfirmModal.jsx";
import { getAllManagersAndConsultant } from "../../services/CommonApis.js";
import { filterOrganizationsForUser } from "../../services/helpingFunctions.js";

// The main component to display the user management page
export const OrgManagement = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  let [modalMode, setModalMode] = useState(null);
  const [search, setSearch] = useState("");
  // const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [runTour, setRunTour] = useState(false);
  const loginAttempts = parseInt(getLoginAttempts());
  const [tourCompleted, setTourCompleted] = useState(false);
  const [filteredOrgData, setFilteredOrgData] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [orgToDelete, setOrgToDelete] = useState(null);

  // useEffect(() => {
  //   // Check if it's the user's first login
  //   if (loginAttempts <= 1 && tourCompleted !== true) {
  //     setRunTour(true);
  //   }
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Execute both API calls simultaneously
        const organizationsResponse = await fetchAllOrganizations();
        // Process organizations data
        const decryptedOrgsData = decryptData(organizationsResponse.data);
        const parsedOrgsData = JSON.parse(decryptedOrgsData);
        setFilteredOrgData(parsedOrgsData.unmarshalledData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  // console.log("projects", users);
  console.log("filteredOrg", filteredOrgData);

  const handleView = (user) => {
    setSelectedUser(user);
    navigate("/view-org", {
      state: { user: user },
    })
  };
  const handleClose = () => {
    setModalMode(null);
  };
  const handleAddForm = () => {
    // setShowModal(true);
    navigate("/organization/add-org");
  };
  const handleAddUser = (user) => {
    //add user
    setUsers([...users, user]);
    setModalMode(null);
  };
  const handleDelete = async (user) => {
    setOrgToDelete(user);
    setIsConfirmModalOpen(true);
  };

  const handleConfirmDelete = () => {
    const token = window.localStorage.getItem("token");

    if (!token) {
      console.error("Token is missing or invalid.");
      return;
    }
    if (orgToDelete) {
      const params = {
        organizationEmail: orgToDelete.organizationEmail,
      };
      deleteOrganization(params)
        .then((response) => {
          setUsers((prevUsers) =>
            prevUsers.filter((u) => u.email !== orgToDelete.email)
          );
          toast.success("User Deleted Successfully!");
        })
        .catch((error) => {
          console.error(error);
        });
      setIsConfirmModalOpen(false);
      setOrgToDelete(null);
    }
  };

  const manageUser = (user) => {
    navigate("/organization_list", {
      state: {
        organizationName: user.organizationName,
        orgEmail: user.organizationEmail,
      },
    });
  };

  const handleUpdate = (user) => {
    navigate("/organization/update-org", {
      state: {
        user: user,
      },
    });
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleCheckboxTable = (user) => {
    navigate("/organization/view-dept-ou-table", {
      state: {
        operationalUnits: user.operationalUnits,
      },
    });
  };
  const deletedUsersCount = filteredOrgData.filter(
    (user) => user.deleteStatus === true
  ).length;

  const excelReportingName = "organization_data_";

  const handleDownloadExcel = () => {
    let finalExcelReportingName = excelReportingName;

    const timestampInSecondsRounded = new Date()
      .toISOString()
      .split("T")[0]
      .replace(/-/g, "");

    const currentTime = new Date();

    // Get the current time as a string in the format HH:MM:SS
    const currentTimeString = currentTime
      .toLocaleTimeString()
      .split(":")
      .join("")
      .split(" ")[0];

    // Append the current time to the final excel reporting name
    finalExcelReportingName += `${timestampInSecondsRounded}${currentTimeString}.xlsx`;

    const ws = XLSX.utils.json_to_sheet(users);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, finalExcelReportingName);
  };

  const steps = [
    {
      target: ".enter button",
      content: "You can add a new organization.",
      disableBeacon: true,
    },
    {
      target: "table",
      content:
        "This table shows all the organizations. You can view, edit, delete, or manage users for each organization.",
    },
    {
      target: "#view0",
      content: "Click here to view details of an organization.",
    },
    {
      target: ".viewOrg",
      content: "You can view the organization's details.",
    },
    {
      target: ".editOrg",
      content: "Click here to edit an organization's details.",
    },
    {
      target: ".deleteOrg",
      content: "Click here to delete an organization.",
    },
    {
      target: ".manageUsers",
      content: "Click here to manage users for an organization.",
    },
  ];

  // const handleJoyrideCallback = (data) => {
  //   const { action, index, type, status } = data;
  //   if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
  //     setRunTour(false);
  //     setTourCompleted(true);
  //   }
  //   if (type === "step:before") {
  //     // Scroll the table horizontally if needed
  //     const tableContainer = document.querySelector(".MuiTableContainer-root");
  //     const targetElement = document.querySelector(steps[index].target);
  //     if (tableContainer && targetElement) {
  //       const containerRect = tableContainer.getBoundingClientRect();
  //       const targetRect = targetElement.getBoundingClientRect();
  //       if (targetRect.right > containerRect.right) {
  //         tableContainer.scrollLeft +=
  //           targetRect.right - containerRect.right + 20; // 20px extra for padding
  //       }
  //     }
  //   }
  // };

  const userRole = getUserRole();

  const filteredUsers = filteredOrgData?.filter((user) => {
    return (
      user.organizationName?.toLowerCase().includes(search.toLowerCase()) ||
      user.spocEmail?.toLowerCase().includes(search.toLowerCase()) ||
      user.organizationEmail?.toLowerCase().includes(search.toLowerCase()) ||
      user.spocMobile?.includes(search)
    );
  });

  // Handle case where page might be out of bounds after filtering
  const maxPage = Math.max(
    0,
    Math.ceil(filteredUsers.length / rowsPerPage) - 1
  );
  const currentPage = page > maxPage ? maxPage : page;

  const paginatedUsers = filteredUsers
    .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
    .map((user, index) => (
      <OrgTableRow
        key={user.organizationEmail}
        user={user}
        id={index + 1 + currentPage * rowsPerPage}
        onView={handleView}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
        onManage={manageUser}
        handleCheckboxTable={handleCheckboxTable}
      />
    ));

  return (
    <>
      <Hamburger />
      {/* <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showProgress
        showSkipButton={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: "#e3ffeb",
            backgroundColor: "#e3ffeb",
            primaryColor: "#000",
            textColor: "#004a14",
            width: 300,
            zIndex: 1000,
          },
        }}
        disableScrolling={true}
      /> */}
      <div className="container-user">
        <h1 className="frame_heading">Organization Management</h1>
        <div className="add-user-btn-search">
          <Button
            variant="contained"
            style={
              userRole === "Consultant"
                ? buttoncontaindedDisabled
                : buttoncontainded
            }
            onClick={handleAddForm}
            disabled={userRole === "Consultant"}
          >
            Add Organization
          </Button>

          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              id="outlined-basic"
              label="Search Organization"
              variant="outlined"
              style={inputfieldstyle1}
              onChange={(e) => setSearch(e.target.value)}
            />
            <IconButton
              variant="outlined"
              sx={{ marginRight: "30px" }}
              onClick={handleDownloadExcel}
            >
              <FaFileArrowDown />
            </IconButton>
          </div>
        </div>
        <TableContainer sx={{ maxHeight: 440 }} style={{ overflow: "scroll" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{
              backgroundColor: "#fff",
              borderRadius: "25px",
              padding: "10px",
            }}
          >
            <TableBody>
              <TableRow style={tablehead}>
                {tableheaddata.map((item) => {
                  return (
                    <TableCell
                      style={{
                        fontSize: "1.1rem",
                        color: "#3079bd",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                      key={item.id}
                    >
                      {item.value}
                    </TableCell>
                  );
                })}
              </TableRow>
              {/* {filteredOrgData
                ?.filter(
                  (user) =>
                    user.organizationName
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    user.spocEmail
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    user.organizationEmail?.includes(search.toLowerCase()) ||
                    user.spocMobile?.includes(search)
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user, index) => (
                  <OrgTableRow
                    key={user.organizationEmail}
                    user={user}
                    id={index + 1 + page * rowsPerPage}
                    onView={handleView}
                    onDelete={handleDelete}
                    onUpdate={handleUpdate}
                    onManage={manageUser}
                    handleCheckboxTable={handleCheckboxTable}
                  />
                ))} */}
              {paginatedUsers}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[4, 12, 20]}
          component="div"
          count={filteredUsers.length - deletedUsersCount} // Set count to the length of filteredUsers
          rowsPerPage={rowsPerPage}
          page={currentPage} // Use updated currentPage based on filtered results
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <Loader loading={loading} />

      <ConfirmModal
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        title="Confirm Delete"
        message={`Are you sure you want to delete ${orgToDelete?.organizationName}?`}
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};
