import axios from "axios";
import { baseUrl } from "../baseurl";

export const login = (userData) => {
  console.log("Login API Data: ", userData);
  const config = {
    withCredentials: true,
  };
  return axios.post(`${baseUrl}/login`, userData, config);
};
