import React, { useState, useEffect } from "react";
import "./pages/OrganizationManagement/OrgManagement.css";
import axios from "axios";

import {
  TextField,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { buttoncontainded, inputfieldstyle1, tablehead } from "./styles.js";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { tableheaddata } from "./pages/OrganizationManagement/tableheaddata.js";
import { baseUrl } from "./baseurl.js";
import { OrgDetails } from "./components/Organization/OrgDetails.jsx";
import { AddOrgForm } from "./components/Organization/AddOrgForm.jsx";
import { UpdateOrg } from "./components/Organization/UpdateOrg.jsx";
import { OrgTableRow } from "./components/Organization/OrgTableRow.jsx";
import Hamburger from "./components/Navbars/Hamburger.js";
import { useTable, useSortBy } from "react-table";
import {getToken} from "./services/loggedIn";

// The main component to display the user management page
export const DemoOrgTable = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = () => {
      // const token = window.localStorage.getItem("token");
      // const headers = {
      //   Authorization: `${token}`,
      //   "Content-Type": "application/json",
      // };
      setLoading(true);

      axios
        .get(`${baseUrl}/getallorganizations`)
        .then((response) => {
          
          const filteredUsers = response.data.filter(
            (user) => !user.deleteStatus
          ); // Filter out users with deleteStatus false initially
          setUsers(filteredUsers);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false); // Hide the backdrop when the response is received
        });
    };

    fetchUserData();
  }, []);


  const handleView = (user) => {
    setSelectedUser(user);
    setShowModal(true);
    setModalMode("view");
  };
  const handleClose = () => {
    setShowModal(false);
    setModalMode(null);
  };
  const handleAddForm = () => {
    setShowModal(true);
    setModalMode("add");
  };
  const handleAddUser = (user) => {
    //add user
    setUsers([...users, user]);
    setShowModal(false);
    setModalMode(null);
  };
  const handleDelete = async (user) => {
    // const token = window.localStorage.getItem("token");
    // const token = getToken();
    // if (!token) {
    //   console.error("Token is missing or invalid.");
    //   return;
    // }
    //
    // const headers = {
    //   Authorization: `${token}`,
    //   // "Content-type": "application/json",
    // };
  
    const params = {
      organizationEmail: user.organizationEmail,
    };
    if (
      window.confirm(
        `Are you sure you want to delete the organization ${user.organizationName}`
      )
    ) {
      await axios
        .delete(`${baseUrl}/deleteorg`, {
          withCredentials: true,
          params,
        })
        .then((response) => {
          // alert("deleted Org");
          setUsers((prevUsers) =>
            prevUsers.filter(
              (u) => u.organizationEmail !== user.organizationEmail
            )
          );
         
          toast.success("Deleted Organization");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const manageUser = (user) => {
    navigate("/organization_list", {
      state: { organizationName: user.organizationName },
    });
  };
 
  const handleUpdate = (user) => {
  
    setSelectedUser(user);
    setShowModal(true);
    setModalMode("update");
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);

 

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleCheckboxTable = (user) => {
    navigate("/view-dept-ou-table", {
      state: {
        operationalUnits: user.operationalUnits,
      },
    });
  };
  const deletedUsersCount = users.filter(
    (user) => user.deleteStatus === true
  ).length;

  const columns = React.useMemo(
    () => [
      { Header: "Sr. No", accessor: "id" },
      { Header: "Logo", accessor: "image" },
      { Header: "Name", accessor: "organizationName" },
      { Header: "SPOC Email", accessor: "spocEmail" },
      { Header: "Mobile No", accessor: "spocMobile" },
      { Header: "No. of Depts.", accessor: "departments" },
      // Add more columns as needed
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: users }, useSortBy);
  return (
    <>
      <Hamburger />
      <div className="container-user">
        <h1 className="frame_heading">Organization Management</h1>
        <div className="enter">
          <Button
            variant="contained"
            style={buttoncontainded}
            onClick={handleAddForm}
          >
            Add
          </Button>

          <TextField
            id="outlined-basic"
            label="Search Organization"
            variant="outlined"
            style={inputfieldstyle1}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <TableContainer sx={{ maxHeight: 440 }} style={{ overflow: "scroll" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{
              backgroundColor: "#fff",
              borderRadius: "25px",
              padding: "10px",
            }}
          >
            <TableBody>
              <TableRow style={tablehead}>
                {tableheaddata.map((item) => {
                  return (
                    <TableCell
                      style={{
                        fontSize: "1.1rem",
                        color: "#3079bd",
                        fontWeight: "bold",
                      }}
                      key={item.id}
                    >
                      {item.value}
                    </TableCell>
                  );
                })}
              </TableRow>
              {users
                .filter(
                  (user) =>
                    user.organizationName
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    user.spocEmail
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    user.organizationEmail?.includes(search.toLowerCase()) ||
                    user.spocMobile?.includes(search)
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user, index) => (
                  <OrgTableRow
                    key={user.organizationEmail}
                    user={user}
                    id={index + 1 + page * rowsPerPage}
                    onView={handleView}
                    onDelete={handleDelete}
                    onUpdate={handleUpdate}
                    onManage={manageUser}
                    handleCheckboxTable={handleCheckboxTable}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[4, 12, 20]}
          component="div"
          count={users.length - deletedUsersCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {showModal && (
        <>
          {modalMode === "view" ? (
            selectedUser && (
              <OrgDetails user={selectedUser} handleClose={handleClose} />
            )
          ) : modalMode === "add" ? (
            <AddOrgForm onAdd={handleAddUser} handleClose={handleClose} />
          ) : modalMode === "update" ? (
            selectedUser && (
              <UpdateOrg user={selectedUser} handleClose={handleClose} />
            )
          ) : (
            (modalMode = "none")
          )}
        </>
      )}
    </>
  );
};
