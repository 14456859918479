import axios from "axios";
import {baseUrl} from "../baseurl";


export const getUserAssignments = (encryptData) => {
  // const token = window.localStorage.getItem("token");
  // const headers = {
  //   Authorization: `${token}`,
  //   "Content-Type": "application/json",
  // };
  return axios.post(
    `${baseUrl}/getUserAssignments`,
    { encryptedData: encryptData },
    { withCredentials: true }
  );
};

export const getAssignmentKPIData = (encryptData) => {
  // const token = window.localStorage.getItem("token");
  // const headers = {
  //   Authorization: `${token}`,
  //   "Content-Type": "application/json",
  // };
  return axios.post(
    `${baseUrl}/getAssignmentKPIData`,
    { encryptedData: encryptData },
    { withCredentials: true }
  );
};

export const captureData = (encryptedKPIData) => {
  // const token = window.localStorage.getItem("token");
  // const headers = {
  //   Authorization: `${token}`,
  //   "Content-Type": "application/json",
  // };
  return axios.post(
    `${baseUrl}/captureData`,
    { encryptedKPIData: encryptedKPIData },
    { withCredentials: true }
  );
};

export const countryCodes = () => {
  // const token = window.localStorage.getItem("token");
  // const headers = {
  //   Authorization: `${token}`,
  //   "Content-Type": "application/json",
  // };
  return axios.get(`${baseUrl}/countryCodes`, {
    withCredentials: true
  });
};

export const getOrganizationImage = (organizationName) => {
  // const token = window.localStorage.getItem("token");
  // const headers = {
  //   Authorization: `${token}`,
  //   "Content-Type": "application/json",
  // };
  return axios.post(
    `${baseUrl}/getOrganizationImage`,
    { organizationName },
    { withCredentials: true }
  );
};
