//Client Navbar.js
import React, {useState} from "react";
import "./Hstyle.css";
import {useNavigate} from "react-router-dom";
import {
    Tooltip,
    Button,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Divider,
    ListItemText,
    useMediaQuery,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CloseIcon from "@mui/icons-material/Close";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import {FaLinkedin} from "react-icons/fa";
import {FaEarthAsia, FaXTwitter} from "react-icons/fa6";
import {useTheme} from "@mui/material/styles";
import CustomNavLink from "./CustomNavLink";
import {clientnavlist} from "./clientnavbardata";
import {ProfileIcon} from "./ProfileIcon";
import axios from "axios";
import {baseUrl} from "../../baseurl";
import {handleSaveDraft} from "../../pages/ClientSide/ExtraFiles/formHandler";

export const ClientNavbar = ({orgImg}) => {
    const activeStyle = {
        color: "#2f7abd",
        fontWeight: "bold",
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const navigate = useNavigate();
    const [isSavingDraft, setIsSavingDraft] = useState(false);
    const [loading, setLoading] = useState(true);
    const submitDraftData = async () => {
        const storedData = localStorage.getItem("draftData");

        if (storedData) {
            const parsedData = JSON.parse(storedData);
            await handleSaveDraft(
              parsedData.formData,
              parsedData.fdata,
              parsedData.currentYears,
              parsedData.currentMonths,
              parsedData.data,
              setLoading,
              baseUrl,
              setIsSavingDraft,
              parsedData.editedFields,
              parsedData.code,
              false
            );

            // Clear the stored data after submission
            localStorage.removeItem("draftData");
        }
    };

    const hadellogout = async (event) => {
        event.preventDefault();

        // Submit draft data first
        await submitDraftData();

        localStorage.clear()
        try {
            const res = await axios.get(`${baseUrl}/logout`, {withCredentials: true,});
            if (res.status === 200) {
                navigate("/");
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    return (
        <nav id="navbar" className="navbar">
            <img src={orgImg} alt="Logo" id="client-logo"/>
            {isMobile ? (
                <>
                    <Tooltip title="Menu">
                        <Button id="hamburger-menu" onClick={toggleMenu}>
                            <MoreVertIcon className="menu-icon"/>
                        </Button>
                    </Tooltip>
                    <Drawer anchor="right" open={isOpen} onClose={toggleMenu}>
                        <IconButton
                            onClick={toggleMenu}
                            style={{
                                fontSize: "2rem",
                            }}
                        >
                            <CloseIcon className="close-btn"/>
                        </IconButton>
                        <List style={{margin: "40px 0"}}>
                            <ListItem>
                                <a href="/reporting/history/overview" className="nav-list-item">
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <AssignmentOutlinedIcon className="naviconstyle"/>
                                        </ListItemIcon>
                                        <ListItemText primary="History"></ListItemText>
                                    </ListItemButton>
                                </a>
                            </ListItem>
                            <ListItem>
                                <a href="/reporting/dashboard" className="nav-list-item">
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <DashboardIcon className="naviconstyle"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Reporting Dashboard"></ListItemText>
                                    </ListItemButton>
                                </a>
                            </ListItem>
                            <ListItem>
                                <a
                                    href="https://envintglobal.com/contact/"
                                    target="blank"
                                    className="nav-list-item"
                                >
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <ContactMailOutlinedIcon className="naviconstyle"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Contact"></ListItemText>
                                    </ListItemButton>
                                </a>
                            </ListItem>
                            <Divider/>
                            <ListItem>
                                <button
                                    className="nav-list-item"
                                    onClick={(event) => hadellogout(event)}
                                >
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <LogoutIcon className="naviconstyle"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Logout"></ListItemText>
                                    </ListItemButton>
                                </button>
                            </ListItem>
                        </List>
                        <div
                            style={{
                                width: "80%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "16px",
                                margin: "10px 0",
                                bottom: "0",
                            }}
                        >
                            {/* <p>Connect to us via </p> */}
                            <div className="social-container">
                                <a
                                    href="https://www.linkedin.com/company/envintglobal/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaLinkedin/>
                                </a>
                                <a
                                    href="https://envintglobal.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaEarthAsia/>
                                </a>
                                <a
                                    href="https://twitter.com/EnvintGlobal"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaXTwitter/>
                                </a>
                            </div>
                            <img
                                src="https://th.bing.com/th/id/R.a5e9f01afee8913f5a8453f007c184f4?rik=cldADQb%2fWtTlIA&riu=http%3a%2f%2fenvintglobal.com%2fwp-content%2fuploads%2f2019%2f11%2flogo-Final.png&ehk=yuheQLOvPvjbtAgWd4f1XxyZFe0aL%2f9cEdvpwyUcttM%3d&risl=&pid=ImgRaw&r=0"
                                alt="logo"
                                className="nav-envint-logo"
                            />
                        </div>
                    </Drawer>
                </>
            ) : (
                <>
                    <ul
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            textDecoration: "none",
                            backgroundColor: "#f9f8f9",
                            padding: "12px 16px",
                            borderRadius: "20px",
                            width: "70%",
                        }}
                    >
                        {clientnavlist.map((item) => {
                            return (
                                <li
                                    style={{
                                        display: "flex",
                                        padding: "0px",
                                        textDecoration: "none",
                                        listStyle: "none",
                                    }}
                                    key={item.id}
                                >
                                    <CustomNavLink to={item.navigateTo}>
                                        <h3>{item.name}</h3>
                                    </CustomNavLink>
                                </li>
                            )
                        })}
                    </ul>
                    <li className="profile-icon">
                        <ProfileIcon handleLogout={hadellogout}/>
                    </li>
                </>
            )}
        </nav>
    );
};
