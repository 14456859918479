export const clientnavlist=[
    {
        id:1,
        name:'Reporting Dashboard',
        navigateTo:'/reporting/dashboard'
    },
    {
        id:2,
        name:'Download Report',
        navigateTo:'/download_report'
    },
    {
        id:3,
        name:'History',
        navigateTo:'/reporting/history/overview'
    },
    {
        id:4,
        name:'Contact',
        navigateTo:"https://envintglobal.com/connect/"
    },

]
export const envintnavlist=[
    {
        id:1,
        name:'Home',
        navigateTo:'/home'
    },
    {
        id:2,
        name:'Organization Management',
        navigateTo:'/organization/management'
    },
    {
        id:3,
        name:'User Management',
        navigateTo:'/user/management'
    },
    {
        id:4,
        name:'Assignment Manager',
        navigateTo:'/assignment/select-organization'
    },
]