import axios from "axios";
import { baseUrl } from "../../baseurl";

export const getSunBurstFilter = (spocEmail) => {
  return axios.post(
    `${baseUrl}/getSunBurstFilter`,
    { spocEmail },
    {
      withCredentials: true,
    }
  );
};

export const getComplianceData = (encryptedData0) => {
  return axios.post(
    `${baseUrl}/getComplianceData`,
    { encryptedData: encryptedData0 },
    {
      withCredentials: true,
    }
  );
};

export const getFilterData = async (encryptedData) => {
  return axios.post(
    `${baseUrl}/getFilterData`,
    { encryptedData: encryptedData },
    {
      withCredentials: true,
    }
  );
};
