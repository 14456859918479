import axios from "axios";
import {baseUrl} from "../baseurl";


export const getDynamicSelection = (encryptData) => {
  // const token = window.localStorage.getItem("token");
  // const headers = {
  //   Authorization: `${token}`,
  //   "Content-Type": "application/json",
  // };
  return axios.post(
    `${baseUrl}/getDynamicSelection`,
    { encryptedData: encryptData },
    { withCredentials: true }
  );
};

export const assignKpisToUsers = (encryptedAssignData) => {
  // const token = window.localStorage.getItem("token");
  // const headers = {
  //   Authorization: `${token}`,
  //   "Content-Type": "application/json",
  // };
  return axios.post(
    `${baseUrl}/assignUsers`,
    {
      encryptedAssignmentData: encryptedAssignData,
    },
    { withCredentials: true }
  );
};
