import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import EditNoteIcon from "@mui/icons-material/EditNote";
import "../clientdashboard.css";
import { usereditbutton, userviewbutton } from "../../../styles";
import ClientLayout from "../../../components/ClientLayout/ClientLayout";
import encrypt, { decryptData } from "../../../services/crypto";
import {
  getOrganizationImage,
  getUserAssignments,
} from "../../../services/ClientSideApis";
import PreviewIcon from "@mui/icons-material/Preview";

import ProgressBar from "./ProgressBar/ProgressBar";
import { Loader } from "../../../components/Loader/Loader";
import { getOrg, getUserEmail } from "../../../services/loggedIn";
import LinearProgressBar from "../../../pages/ClientSide/ReportingDashboard/ProgressBar/LinearProgressBar";

export const ReportingDashboard = () => {
  const [userAssignmentList, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [savedKpiCount, setSavedKpiCount] = useState(0);
  const [transposed, setTransposed] = useState(false); // State to track transposition
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrgImg = async () => {
      try {
        const organizationName = getOrg();
        const res = await getOrganizationImage(organizationName);
        const orgImgUrl = await res.data.imageUrl;
        setImgUrl(orgImgUrl);
      } catch (error) {
        console.error("Error", error);
      }
    };

    fetchOrgImg();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // const token = window.localStorage.getItem("token");
      // const headers = {
      //   Authorization: `${token}`,
      // };
      const json = { email: getUserEmail() };

      const encryptData = encrypt({ json });
      try {
        const response = await getUserAssignments(encryptData);
        const decryptedData = decryptData(response.data.userAssignmentList);
        const parsedData = JSON.parse(decryptedData);
        console.log("---------", parsedData);
        setUserData(parsedData.groupData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEntryClick = (plant, department) => {
    const plantDepartments = userAssignmentList[plant] || [];
    const departmentStats = plantDepartments.find((obj) =>
      obj.hasOwnProperty(department)
    );

    let savedCount = 0;
    let totalCount = 0;
    let approvedCount;
    let rejectedCount;

    if (departmentStats) {
      savedCount = departmentStats[department].saved;
      totalCount = departmentStats[department].total;
      approvedCount = departmentStats[department].approved;
      rejectedCount = departmentStats[department].rejected;
    }
    navigate("/reporting/fill-kpis", {
      state: {
        orgplt: plant,
        orgdept: department,
        savedCount: savedCount,
        totalCount: totalCount,
        approvedCount: approvedCount,
        rejectedCount: rejectedCount,
      },
    });
  };

  const toggleTransposed = () => {
    setTransposed(!transposed);
  };

  const plants = Object.keys(userAssignmentList).sort();

  const allDepartments = Array.from(
    new Set(
      plants.flatMap((plant) =>
        userAssignmentList[plant].flatMap((obj) => Object.keys(obj))
      )
    )
  ).sort();

  const plantsToDisplay = !transposed ? allDepartments : plants;
  const departmentsToDisplay = !transposed ? plants : allDepartments;

  return (
    <div style={{ padding: "20px" }}>
      <ClientLayout>
        <div className="client-dashboard-container">
          <Box sx={{ display: "flex", margin: "10px 0 20px 0" }}>
            <h3 className="frame_heading">Reporting Dashboard</h3>
            {/* <Button onClick={toggleTransposed} sx={{textTransform:"none"}} style={{color:'white',background:'#45b653',borderRadius:'15px',margin:'15px 0',padding:'5px 15px'}}>
            {transposed ?"Show original view":"Transpose table"}
          </Button> */}
          </Box>
          <div className="client-dashboard-table-container">
            <TableContainer sx={{ maxHeight: "440px" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                  padding: "10px",
                }}
              >
                <TableBody>
                  <TableRow
                    sx={{ position: "sticky", zIndex: 1, background: "#fff" }}
                    // style={{ backgroundColor: "#3079bd" }}
                  >
                    <TableCell style={{ color: "#3079bd" }}>
                      <Tooltip title="Transpose Table">
                        <Button
                          onClick={toggleTransposed}
                          sx={{ textTransform: "none", marginLeft: "10px" }}
                          style={{ color: "#3079bd", fontSize: "1.1rem" }}
                        >
                          {!transposed ? "OU" : "Depts"}{" "}
                          <SwapHorizontalCircleIcon
                            style={{ margin: "0 5px", fontSize: "2rem" }}
                          />{" "}
                          {!transposed ? "Depts" : "OU"}
                        </Button>
                      </Tooltip>
                    </TableCell>
                    {plantsToDisplay.map((plant) => (
                      <TableCell
                        style={{
                          color: "#3079bd",
                          fontWeight: "600",
                          fontSize: "1.2rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                        }}
                        key={plant}
                      >
                        {plant}
                      </TableCell>
                    ))}
                  </TableRow>

                  {departmentsToDisplay.map((department) => (
                    <TableRow key={department}>
                      <TableCell>{department}</TableCell>
                      {plantsToDisplay.map((plant, idx) => {
                        const plantDepartments = !transposed
                          ? userAssignmentList[department] || []
                          : userAssignmentList[plant] || [];
                        const departmentStats = plantDepartments.find((obj) =>
                          obj.hasOwnProperty(!transposed ? plant : department)
                        );
                        const hasDepartment = !!departmentStats;

                        return (
                          <TableCell key={idx}>
                            {hasDepartment ? (
                              <>
                                <ProgressBar
                                  currentScore={
                                    departmentStats[
                                      !transposed ? plant : department
                                    ].saved
                                  }
                                  totalScore={
                                    departmentStats[
                                      !transposed ? plant : department
                                    ].total
                                  }
                                />
                                {departmentStats[
                                  !transposed ? plant : department
                                ].saved !==
                                  departmentStats[
                                    !transposed ? plant : department
                                  ].total && (
                                  <Tooltip
                                    sx={{ marginTop: "20px" }}
                                    title="Fill data"
                                  >
                                    <Button
                                      onClick={() =>
                                        handleEntryClick(
                                          !transposed ? department : plant,
                                          !transposed ? plant : department
                                        )
                                      }
                                      style={userviewbutton}
                                    >
                                      <EditNoteIcon />
                                      Fill Data
                                    </Button>
                                  </Tooltip>
                                )}
                                {departmentStats[
                                  !transposed ? plant : department
                                ].saved ===
                                  departmentStats[
                                    !transposed ? plant : department
                                  ].total && (
                                  <Tooltip
                                    sx={{ marginTop: "20px" }}
                                    title="View data"
                                  >
                                    <Button
                                      onClick={() =>
                                        handleEntryClick(
                                          !transposed ? department : plant,
                                          !transposed ? plant : department
                                        )
                                      }
                                      style={usereditbutton}
                                    >
                                      <PreviewIcon /> View Data
                                    </Button>
                                  </Tooltip>
                                )}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <Loader loading={loading} />
      </ClientLayout>
    </div>
  );
};
