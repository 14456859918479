import React, {useEffect} from "react";
import Hamburger from "../../components/Navbars/Hamburger";
import EditUserDetails from "./EditUserDetails";

const MultiStepEditUser = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Hamburger />
      <div>
        <EditUserDetails />
      </div>
    </div>
  );
};

export default MultiStepEditUser;
