// Navbar.js
import React, { useState } from "react";
import "./Hstyle.css";
import { useNavigate } from "react-router-dom";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import { FaEarthAsia, FaXTwitter } from "react-icons/fa6";
import GroupIcon from "@mui/icons-material/Group";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Navbar from "./Navbar";
import axios from "axios";
import {baseUrl} from "../../baseurl";

const Hamburger = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // Use state to manage the menu open or close
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  async function handleLog(event) {
    event.preventDefault();

    await axios.get(`${baseUrl}/logout`, {withCredentials: true})

    localStorage.removeItem("email");
    localStorage.removeItem("org");
    localStorage.removeItem("otpSentFlag");
    localStorage.removeItem("orgEmail");
    localStorage.removeItem("userRole");
    localStorage.clear();

    navigate("/");
  }

  return (
    <nav id="navbar" className="navbar">
      <Link to="/home">
        <img
          src="https://envintglobal.com/wp-content/uploads/2024/03/envint-circle-logo.png"
          alt="Logo"
          className="nav-envint-logo"
        />
      </Link>
      {isMobile ? (
        <>
          <Tooltip title="Menu">
            <Button id="hamburger-menu" onClick={toggleMenu}>
              <MoreVertIcon className="menu-icon" />
            </Button>
          </Tooltip>
          <Drawer anchor="right" open={isOpen} onClose={toggleMenu}>
            <IconButton
              style={{
                fontSize: "2rem",
              }}
              onClick={toggleMenu}
            >
              <CloseIcon className="close-btn" />
            </IconButton>
            <List style={{ margin: "40px 0" }}>
              <ListItem>
                <a href="/home" className="nav-list-item">
                  <ListItemButton>
                    <ListItemIcon>
                      <HomeIcon className="naviconstyle" />
                    </ListItemIcon>
                    <ListItemText primary="Home"></ListItemText>
                  </ListItemButton>
                </a>
              </ListItem>
              <ListItem>
                <a href="/organization/management" className="nav-list-item">
                  <ListItemButton>
                    <ListItemIcon>
                      <CorporateFareIcon className="naviconstyle" />
                    </ListItemIcon>
                    <ListItemText primary="Organization Management"></ListItemText>
                  </ListItemButton>
                </a>
              </ListItem>
              <ListItem>
                <a href="/user/management" className="nav-list-item">
                  <ListItemButton>
                    <ListItemIcon>
                      <GroupIcon className="naviconstyle" />
                    </ListItemIcon>
                    <ListItemText primary="User Management"></ListItemText>
                  </ListItemButton>
                </a>
              </ListItem>
              <ListItem>
                <a
                  href="/assignment/select-organization"
                  className="nav-list-item"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <AssignmentOutlinedIcon className="naviconstyle" />
                    </ListItemIcon>
                    <ListItemText primary="Assignment Manager"></ListItemText>
                  </ListItemButton>
                </a>
              </ListItem>
              <Divider />
              <ListItem>
                <button onClick={(e) => handleLog(e)} className="nav-list-item">
                  <ListItemButton>
                    <ListItemIcon>
                      <LogoutIcon className="naviconstyle" />
                    </ListItemIcon>
                    <ListItemText primary="Logout">
                      <button onClick={(event) => handleLog(event)}></button>
                    </ListItemText>
                  </ListItemButton>
                </button>
              </ListItem>
            </List>
            <div
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "16px",
                margin: "10px 0",
                // position: "absolute",
                bottom: "0",
              }}
            >
              {/* <p>Connect to us via </p> */}
              <div className="social-container">
                <a
                  href="https://www.linkedin.com/company/envintglobal/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin />
                </a>
                <a
                  href="https://envintglobal.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaEarthAsia />
                </a>
                <a
                  href="https://twitter.com/EnvintGlobal"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaXTwitter />
                </a>
              </div>
              <img
                src="https://th.bing.com/th/id/R.a5e9f01afee8913f5a8453f007c184f4?rik=cldADQb%2fWtTlIA&riu=http%3a%2f%2fenvintglobal.com%2fwp-content%2fuploads%2f2019%2f11%2flogo-Final.png&ehk=yuheQLOvPvjbtAgWd4f1XxyZFe0aL%2f9cEdvpwyUcttM%3d&risl=&pid=ImgRaw&r=0"
                alt="logo"
                className="nav-envint-logo"
              />
            </div>
          </Drawer>
        </>
      ) : (
        <Navbar handleLogout={handleLog} />
      )}
    </nav>
  );
};

export default Hamburger;
