import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Button,
  Divider,
  Backdrop,
  CircularProgress,
  TableContainer,
  Box,
  Tooltip,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import "./EditDept.css";
import { backBtnfixed, buttoncontaindedfixed } from "../../../styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../baseurl";
import { toast } from "react-toastify";
import Hamburger from "../../Navbars/Hamburger";
import encrypt, { decryptData } from "../../../services/crypto";
import { updateOrganization } from "../../../services/OrganizationManagemnetApi";
import EditOrgMultiStep from "../../../pages/MultiStepEditOrg/EditOrgMultiStep";
import { Loader } from "../../Loader/Loader";

export const EditDeptMatrix = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    skills: initialSkills,
    operationalUnits: initialOperationalUnits,
    organizationData: initialOrganizationData,
    departments: initialDepartments,
  } = location.state;
  // // console.log("state editDeptMatrix", location.state);
  const defaultSelectedItems = JSON.parse(
    JSON.parse(initialOrganizationData.operationalUnits)
  );
  const filteredDefaultSelectedItems = defaultSelectedItems.filter(
    (item) =>
      initialOperationalUnits &&
      initialOperationalUnits.some((unit) => item[unit])
  );

  const [selectedItems, setSelectedItems] = useState(() => {
    if (location.state.selectedItems) {
      return location.state.selectedItems;
    }
    // Otherwise, use the filtered default selected items
    return filteredDefaultSelectedItems.map((item) => {
      const unit = Object.keys(item)[0];
      const departments = item[unit].split(",").map((dept) => {
        // Check if the department name was changed
        const newName = Object.keys(location.state.selectedDept).find((key) =>
          location.state.selectedDept[key].includes(dept.trim())
        );
        return newName || dept.trim();
      });
      return { [unit]: departments.join(", ") };
    });
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay
    return () => clearTimeout(timer);
  }, [location.state]);

  if (loading) {
    return <Loader loading={loading} />;
  }

  const handleCheckboxChange = (skill, unit) => {
    // setSelectedItems((prevItems) => {
    //   const updatedItems = prevItems.map((item) => ({ ...item })); // Create a copy of selectedItems

    //   const existingItemIndex = updatedItems.findIndex((item) => item[unit]);

    //   if (existingItemIndex !== -1) {
    //     const skillsArray = updatedItems[existingItemIndex][unit].split(", ");
    //     const skillIndex = skillsArray.indexOf(skill);

    //     if (skillIndex !== -1) {
    //       // Remove the skill from the array
    //       skillsArray.splice(skillIndex, 1);

    //       // If no skills left, remove the entire unit
    //       if (skillsArray.length === 0) {
    //         updatedItems.splice(existingItemIndex, 1);
    //       } else {
    //         // Otherwise, update the skills for the unit
    //         updatedItems[existingItemIndex][unit] = skillsArray.join(", ");
    //       }
    //     } else {
    //       // Add the skill to the array only if it exists in the skills array
    //       if (skill.includes(skill)) {
    //         updatedItems[existingItemIndex][unit] += `, ${skill}`;
    //       }
    //     }
    //   } else {
    //     // Add a new entry for the unit with the skill only if it exists in the skills array
    //     if (skill.includes(skill)) {
    //       updatedItems.push({ [unit]: skill });
    //     }
    //   }

    //   return updatedItems; // Return the updated selectedItems
    // });
    setSelectedItems((prevItems) => {
      const updatedItems = prevItems.map((item) => ({ ...item }));
      const existingItemIndex = updatedItems.findIndex((item) => item[unit]);

      if (existingItemIndex !== -1) {
        const skillsArray = updatedItems[existingItemIndex][unit].split(", ");
        const skillIndex = skillsArray.findIndex(
          (s) =>
            s === skill ||
            (location.state.selectedDept &&
              Object.keys(location.state.selectedDept).find((key) =>
                location.state.selectedDept[key].includes(s)
              ) === skill)
        );

        if (skillIndex !== -1) {
          skillsArray.splice(skillIndex, 1);
          if (skillsArray.length === 0) {
            updatedItems.splice(existingItemIndex, 1);
          } else {
            updatedItems[existingItemIndex][unit] = skillsArray.join(", ");
          }
        } else {
          updatedItems[existingItemIndex][unit] += `, ${skill}`;
        }
      } else {
        updatedItems.push({ [unit]: skill });
      }

      return updatedItems;
    });
  };

  const operationalUnits = selectedItems;
  const uniqueValuesSet = new Set();
  operationalUnits.forEach((unitObject) => {
    const unitValues = Object.values(unitObject)[0].split(",");
    unitValues.forEach((value) => uniqueValuesSet.add(value.trim()));
  });

  const handleClearAll = () => {
    setSelectedItems([]);
  };

  const isItemSelected = (skill, unit) => {
    // const selectedItem = selectedItems.find((item) => item[unit]);
    // return selectedItem && selectedItem[unit].includes(skill);
    // const selectedItem = selectedItems.find((item) => item[unit]);
    // if (selectedItem) {
    //   const skills = selectedItem[unit].split(", ").map((s) => s.trim());
    //   return skills.includes(skill);
    // }
    // return false;
    const selectedItem = selectedItems.find((item) => item[unit]);
    if (selectedItem) {
      const skills = selectedItem[unit].split(", ").map((s) => s.trim());
      // Check for both the current skill name and the original name (if it was changed)
      return (
        skills.includes(skill) ||
        (location.state.selectedDept &&
          skills.includes(
            Object.keys(location.state.selectedDept).find((key) =>
              location.state.selectedDept[key].includes(skill)
            )
          ))
      );
    }
    return false;
  };

  const handleSelectAll = () => {
    const updatedItems = initialOperationalUnits.map((unit) => {
      const newItem = {};
      const initialDeptKeys = initialDepartments.map(
        (department) => Object.keys(department)[0]
      );
      initialDeptKeys.forEach((skill) => {
        newItem[unit] = newItem[unit] ? `${newItem[unit]}, ${skill}` : skill;
      });
      return newItem;
    });
    setSelectedItems(updatedItems);
  };

  const finalJSON = {
    organizationEmail: initialOrganizationData.organizationEmail,
    name: initialOrganizationData.organizationName,
    type: initialOrganizationData.type,
    address: initialOrganizationData.address,
    mobile: initialOrganizationData.mobile,
    departments: initialDepartments,
    spocName: initialOrganizationData.spocName,
    spocEmail: initialOrganizationData.spocEmail,
    spocMobile: initialOrganizationData.spocMobile,
    operationalUnits: JSON.stringify(selectedItems),
    countryCode: initialOrganizationData.countryCode,
    country: initialOrganizationData.country,
    selectedManagers: initialOrganizationData.selectedManagers,
  };

  console.log("finalJson", finalJSON);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const finalJSON = {
        organizationEmail: initialOrganizationData.organizationEmail,
        name: initialOrganizationData.organizationName,
        type: initialOrganizationData.type,
        address: initialOrganizationData.address,
        mobile: initialOrganizationData.mobile,
        departments: initialDepartments,
        spocName: initialOrganizationData.spocName,
        spocEmail: initialOrganizationData.spocEmail,
        spocMobile: initialOrganizationData.spocMobile,
        operationalUnits: JSON.stringify(selectedItems),
        countryCode: initialOrganizationData.countryCode,
        country: initialOrganizationData.country,
        selectedManagers: initialOrganizationData.selectedManagers,
        projects: initialOrganizationData.projects,
      };

      const encryptedOrgEditedData = encrypt({ finalJSON });
      // console.log("Encrypted Data: ", encryptedOrgEditedData);

      const response = await updateOrganization(encryptedOrgEditedData);

      if (response) {
        navigate("/organization_list", {
          state: { organizationName: finalJSON.name, ouAndDeptEdited: true },
        });
        if (response.data.success === true)
          toast.success(response.data.message);
      }
    } catch (error) {
      console.error("Error adding organization:", error);
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/organization/edit-dept", {
      state: {
        orginitialData: location.state.organizationData,
        skills: location.state.skills,
        parsedDepts: location.state.parsedDepts,
        selectedDept: location.state.selectedDept,
        operationalUnits: location.state.operationalUnits,
        selectedItems: selectedItems,
      },
    });
  };

  // console.log("selected", selectedItems);
  const step = 2;

  return (
    <div className="add-dept-table-body">
      <Hamburger />
      <div className="add-dept-table-container">
        <Box sx={{ marginBottom: "20px" }}>
          <EditOrgMultiStep step={step} />
        </Box>
        <h3 className="frame_heading">
          Mark departments for each operational unit
        </h3>
        <br />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              color: "gray",
              padding: "5px 15px",
              borderRadius: "20px",
              border: "1px solid gray",
              margin: "0 10px",
            }}
            onClick={handleClearAll}
          >
            Clear All
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              color: "gray",
              padding: "5px 15px",
              borderRadius: "20px",
              border: "1px solid gray",
            }}
            onClick={handleSelectAll}
          >
            Select All
          </Button>
        </div>
        <br />
        <TableContainer sx={{ maxHeight: "440px" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="add-dept-table"
          >
            <TableBody>
              <TableRow
                sx={{ position: "sticky", top: "0px", zIndex: 1 }}
                style={{ background: "#fff" }}
              >
                <TableCell
                  style={{
                    color: "#3079bd",
                    fontWeight: "600",
                    fontSize: "1.2rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100px",
                  }}
                >
                  Dept/OU
                </TableCell>
                {initialOperationalUnits.map((unit, index) => (
                  <Tooltip title={unit}>
                    <TableCell
                      key={index}
                      style={{
                        color: "#3079bd",
                        fontWeight: "600",
                        fontSize: "1.2rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100px",
                      }}
                    >
                      {unit}
                    </TableCell>
                  </Tooltip>
                ))}
              </TableRow>
              {initialDepartments.map((skill, skillIndex) => {
                const deptKey = Object.keys(skill)[0];
                return (
                  <TableRow key={skillIndex}>
                    <TableCell sx={{ position: "sticky" }}>{deptKey}</TableCell>
                    {initialOperationalUnits.map((unit, unitIndex) => (
                      <TableCell key={unitIndex}>
                        <Checkbox
                          checked={isItemSelected(Object.keys(skill)[0], unit)}
                          onChange={() => handleCheckboxChange(deptKey, unit)}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Button
          onClick={handleBack}
          // disabled={!hasAtLeastOneDepartmentSelected()}
          style={backBtnfixed}
        >
          Back
        </Button>
        <Button
          className="add-dept-clrbtn"
          style={buttoncontaindedfixed}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Loader loading={loading} />
      </div>
    </div>
  );
};
