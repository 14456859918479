import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { getCountryCurrency } from "../../services/loggedIn";

import { BsInfoCircle } from "react-icons/bs";
import {
  backBtnfixed,
  buttoncontaindedfixed,
  buttoncontaindedfixeddisabled,
} from "../../styles";
import { useNavigate } from "react-router-dom";
import BasicModal from "../PopUpDialog/BasicModal";
import ConfirmModal from "../PopUpDialog/ConfirmModal";
import SimpleModal from "../PopUpDialog/SimpleModal";
import {
  handleSaveDraft,
  handleSubmit,
} from "../../pages/ClientSide/ExtraFiles/formHandler";
import { getUserEmail } from "../../services/loggedIn";
import {
  resetMatrixKPIState,
  setMatrixKpiInputdata,
} from "../../redux/slice/KpiSlice";
import { useDispatch, useSelector } from "react-redux";

export const MatrixTable = ({
  orgdept,
  orgplt,
  setTableFromat,
  tableData,
  setKpiinputdata = () => {}, // Default empty function,
  tableRendering,
  tableDraftRendering,
  status,
  frequency,
  kpicode,
  reviewedBy
}) => {
  // // console.log("tableDraftRendering", tableDraftRendering);
  console.log("tableRendering", tableRendering);
  // // console.log("tableData Matrix", tableData);
  console.log("-----status",status)

  const { matrixKpiInputdata } = useSelector((state) => state.kpi);

  // console.log("matrixKpiInputdata", matrixKpiInputdata);

  const [showModal, setShowModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalInView, setModalInView] = useState(false);
  const [loading, setLoading] = useState(false);

  const [updatedTableData, setUpdatedTableData] = useState(tableData);
  const [editableInput, setEditiableInput] = useState();
  const [filledKpis, setFilledKpis] = useState(0);
  const [draftedKpis, setDraftedKpis] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [fieldsSaved, setFieldsSaved] = useState(false);
  const formData = "";
  const fdata = "";
  const currentYears = "";
  const currentMonths = "";
  const data = "";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // // console.log("setTableFromat", setTableFromat);
  // // console.log("tableData", tableData);
  // // console.log("updatedTableData", updatedTableData);
  // // console.log("tableRendering--------", tableRendering);
  // // console.log("tableDraftRendering--------", tableDraftRendering);
  // // console.log("status-----", status);
  // // console.log("kpicode-----", kpicode);
  // // console.log("frequency-----", frequency);

  useEffect(() => {
    if (matrixKpiInputdata.length === 0) {
      // console.log("1");
      setEditiableInput(tableDraftRendering?.map((item) => ({ ...item })));
    } else {
      // console.log("2");
      setEditiableInput(matrixKpiInputdata);
    }
  }, [tableDraftRendering, matrixKpiInputdata]);

  const openPopup = () => {
    setShowModal(true);
  };

  const handleNavigate = async () => {
    if (!confirm) {
      const returnedValue = await handleClick();
      if (returnedValue !== "0") {
        setShowModal(false);
        setFilledKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
      dispatch(resetMatrixKPIState());
    } else {
      setShowModal(false);
    }
  };

  const handleNavigateSaveDraft = async () => {
    if (!confirm) {
      const returnedValue = await handleClickDraft();
      if (returnedValue !== "0") {
        setShowModal(false);
        setDraftedKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
    } else {
      setShowModal(false);
    }
  };

  const handleClick = () => {
    // console.log("setLoading type:", typeof setLoading);
    setLoading(true);
    // console.log("Loading set to true");

    try {
      // console.log("About to call handleSubmit");
      const returnValue = handleSubmit(
        updateDrafTableData("saved"),
        formData,
        fdata,
        currentYears,
        currentMonths,
        data,
        setLoading,
        setShowConfirmModal,
        setModalInView,
        modalInView,
        [], // editedFields
        "" // countryCode
      );
      // console.log("handleSubmit called successfully");
      return returnValue;
    } catch (error) {
      console.error("Error in handleClick:", error);
      setLoading(false);
      return "0";
    }
  };

  const showToast = true;
  const handleClickDraft = () => {
    const returnValue = handleSaveDraft(
      updateDrafTableData("drafted"),
      formData,
      fdata,
      currentYears,
      currentMonths,
      data,
      setLoading,
      showToast
    );
    return returnValue;
  };

  const closePopup = () => {
    setShowModal(false);
    setSecondModal(false);
    setShowConfirmModal(false);
    setModalInView(false);
    // fetchData();
  };

  // useEffect(() => {
  //   // Check if there's draft data in local storage
  //   const storedDraft = localStorage.getItem("draftTableData");
  //   if (storedDraft) {
  //     setUpdatedTableData(JSON.parse(storedDraft));
  //   } else {
  //     setUpdatedTableData(tableData);
  //   }
  // }, [tableData]);

  const handleInputChange = (index, key, value) => {
    // console.log("handleInputChange", index, key, value);

    // Create a deep copy of updatedTableData
    let updatedData = JSON.parse(JSON.stringify(updatedTableData));

    // Update the value property of the corresponding object
    if (updatedData[index] && updatedData[index][key]) {
      // console.log("updatedData[index][key]", updatedData[index][key]);

      updatedData[index][key].value = value;
    }

    // Update the state with the modified data
    setUpdatedTableData(updatedData);
    // let updatedData = updatedTableData[index][key];
    // updatedData.value = value;
    // setUpdatedTableData(updatedData);
    // console.log("updatedTableData", updatedTableData);
  };

  const handleEditInputChange = (index, key, value) => {
    // console.log("Editing input:", { index, key, value });
    setEditiableInput((prevInput) => {
      if (!prevInput || !Array.isArray(prevInput)) {
        console.error("prevInput is not an array:", prevInput);
        return prevInput; // Return unchanged if it's not an array
      }
      const newInput = [...prevInput];
      if (!newInput[index]) {
        console.error("No item at index:", index);
        return prevInput; // Return unchanged if index doesn't exist
      }
      newInput[index] = {
        ...newInput[index],
        [key]: { ...newInput[index][key], value: value },
      };
      // console.log("New input state:", newInput);
      return newInput;
    });
    // editableInput[index][key] = value;
    // setEditiableInput(editableInput);
    // // console.log("editableInput", editableInput);
  };

  const handleChange = (index, key, value, flag) => {
    // // console.log("updatedTableData[index][key]", updatedTableData[index][key]);

    if (flag) {
      // Create a deep copy of updatedTableData
      let updatedData = JSON.parse(JSON.stringify(updatedTableData));

      // Update the value property of the corresponding object
      if (updatedData[index] && updatedData[index][key]) {
        updatedData[index][key].value = value;
      }

      // Update the state with the modified data
      setUpdatedTableData(updatedData);
      // console.log("updatedTableData", updatedTableData);
    }
  };

  const handleDraftChange = (index, key, value, flag) => {
    if (flag) {
      // Create a deep copy of updatedTableData
      let updatedData = JSON.parse(JSON.stringify(editableInput));

      // Update the value property of the corresponding object
      if (updatedData[index] && updatedData[index][key]) {
        updatedData[index][key].value = value;
      }

      // Update the state with the modified data
      setEditiableInput(updatedData);
      // console.log("editableInput", editableInput);
    }
  };

  const submit = () => {
    //   if (!isDirty) {
    //     toast.error("No changes to save");
    //     return;
    // }
    const kpi_data = {
      [frequency + "~" + kpicode]: updatedTableData,
    };
    setFieldsSaved(true);

    // setIsDirty(false);
    toast.success("Fields saved successfully");
    setKpiinputdata(kpi_data);
    // // console.log("=====",isDirty)
  };

  const submitForDraft = () => {
    if (typeof setKpiinputdata === "function") {
      // // console.log("Saving draft data:", updatedTableData);
      const kpi_data = {
        [frequency + "~" + kpicode]: editableInput,
      };

      toast.success("Fields saved successfully");
      // Call setKpiinputdata to update state or context

      dispatch(setMatrixKpiInputdata(editableInput));

      setKpiinputdata(kpi_data);
    } else {
      console.error("setKpiinputdata is not a function");
    }
  };

  function valnum(value) {
    const regex = /^(^$)|(^(0(\.\d{0,4})?|([1-9]\d{0,12})(\.\d{0,4})?))$/;
    return regex.test(value);
  }
  const validateNumber = (value, handleInputChange, key, index) => {
    // Validate number: maximum 13 places and two decimal places
    if (valnum(value)) {
      if (status === "drafted") {
        handleDraftChange(index, key, value, valnum(value));
      } else {
        handleChange(index, key, value, valnum(value));
      }
    } else {
      toast.error("Invalid number format!");
    }
  };
  const validatePerc = (value) => {
    const regex = /^(100(\.00?)?|\d{0,2}(\.\d{0,2})?)$/;
    return regex.test(value);
  };
  const validatePercent = (value, handleInputChange, index, key) => {
    if (validatePerc(value)) {
      handleInputChange(index, key, value);
    } else {
      toast.error("Invalid percent format!");
    }
  };

  const validateURL = (value) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValid = regex.test(value);
    if (!isValid) {
      toast.error("Invalid URL format!");
    }
    return isValid;
  };
  const updateDrafTableData = (draftStatus) => {
    const determineFrequency = () => {
      if (!frequency) {
        console.warn(`No frequency provided for KPI code: ${kpicode}`);
        return {};
      }

      // Check if the frequency is in the format 'MMM\'YY' (e.g., 'Aug\'24')
      if (/^\w{3}'\d{2}$/.test(frequency)) {
        return { Month: frequency };
      }

      // Check if the frequency is in the format 'YYYY-YYYY' (e.g., '2024-2025')
      if (/^\d{4}-\d{4}$/.test(frequency)) {
        return { Year: frequency };
      }

      // If it's neither a month, a year, nor a year range format we recognize, log a warning and return as-is
      console.warn(`Unexpected frequency format: ${frequency}`);
      return { Frequency: frequency };
    };

    // console.log("determineFrequency", determineFrequency());

    return [
      {
        KPICode: kpicode,
        KPIInput: editableInput,
        format: setTableFromat,
        draftStatus: draftStatus,
        frequency: determineFrequency(),
        department: orgdept,
        operationalUnit: orgplt,
        userEmail: getUserEmail(),
      },
    ];
  };

  // // console.log("updateDrafTableData", updateDrafTableData);

  const handleBack = () => {
    navigate(-1);
  };

  const renderCellValue = (item, value, index, key) => {
    // // console.log("item", item);
    // // console.log("index", index);
    // // console.log("key", key);
    // const fieldValue = value.format.replace(/"/g, "");
    if (value?.format?.includes("input")) {
      const fieldType = value.format.split("-")[1];

      if (value.format.includes("dropDown")) {
        const options = item[key]?.format.match(/\(([^)]+)\)/)[1].split("|");
        return (
          <select
            onChange={(e) => handleInputChange(index, key, e.target.value)}
          >
            <option value="">Select Option</option>
            {options.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      } else if (fieldType === "Binary") {
        return (
          <select
            onChange={(e) => handleInputChange(index, key, e.target.value)}
          >
            <option value="">Select Option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      } else if (
        typeof item[key]?.format === "string" &&
        item[key].format.includes("input")
      ) {
        switch (fieldType) {
          case "number":
          case "decimal":
          case "wholeNumber":
            return (
              <TextField
                type="number"
                required
                placeholder="Enter Number"
                onChange={(e) => {
                  validateNumber(e.target.value, handleInputChange, key, index);
                }}
              />
            );
          case "currency":
            return (
              <>
                <TextField
                  type="number"
                  placeholder="Enter Number"
                  required
                  onChange={(e) => {
                    validateNumber(
                      e.target.value,
                      handleInputChange,
                      key,
                      index
                    );
                  }}
                />
                <span>{getCountryCurrency()} </span>
              </>
            );
          case "percent":
            return (
              <TextField
                type="percent"
                required
                placeholder="Enter Percent"
                onChange={(e) => {
                  const { value } = e.target;
                  validatePercent(value, handleInputChange, index, key);
                }}
              />
            );
          case "openText":
            return (
              <TextField
                type="text"
                required
                placeholder="Enter Text"
                onChange={(e) => handleInputChange(index, key, e.target.value)}
              />
            );
          case "url":
            return (
              <TextField
                type="text"
                required
                placeholder="Enter URL"
                onChange={(e) => {
                  const { value } = e.target;
                  if (validateURL(value)) {
                    handleInputChange(index, key, value);
                  }
                }}
              />
            );

          default:
            return (
              <TextField
                type="text"
                required
                placeholder="Enter Text"
                onChange={(e) => handleInputChange(index, key, e.target.value)}
              />
            );
        }
      }
    }
    if (
      value?.format?.match(/".*"/) &&
      !value?.format?.includes("*") &&
      !value?.format?.includes("/") &&
      !value?.format?.includes("+") &&
      !value?.format?.includes("-") &&
      !value?.format?.includes("input")
    ) {
      // replaced all back slash
      const fieldValue = value?.format?.replace(/"/g, "");
      return (
        <TextField
          type="text"
          required
          value={item[fieldValue]}
          onChange={(e) => {
            validateNumber(e.target.value, handleInputChange, key, index);
          }}
          placeholder="Enter Number"
        />
      );
    }
    if (
      value?.format?.includes("(") &&
      value?.format?.includes(")") &&
      (value?.format?.includes("*") ||
        value?.format?.includes("/") ||
        value?.format?.includes("+") ||
        value?.format?.includes("-"))
    ) {
      return null;
    }

    if (
      (value?.format?.includes("*") ||
        value?.format?.includes("/") ||
        value?.format?.includes("+") ||
        value?.format?.includes("-")) &&
      item.autoCompute === "False"
    ) {
      return null;
    }

    if (value?.format === "Header" || value?.format === "Headers") {
      return item.Table_name;
    } else if (value?.format === "Add Details") {
      return (
        <Button style={{ border: "1px solid #3079bd" }}>Add details</Button>
      );
    } else if (
      value?.format === "False" ||
      value?.format === "False " ||
      value?.format === "Total" ||
      value?.format === "auto"
    ) {
      return null;
    } else if (item.Field_button === "True") {
      return (
        <>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              border: "1px solid white",
              background: "#45b653",
              borderRadius: "20px",
              color: "white",
              padding: "5px 30px",
            }}
            onClick={submit}
          >
            Save
          </Button>
        </>
      );
    } else {
      if (typeof value?.format === "string") {
        value = value?.format?.replaceAll("_", " ") || value?.format;
        return value;
      } else {
        return value?.format;
      }
    }
  };

  const renderCellValue1 = (value, item) => {
    if (value?.format === "Header" || value?.format === "Headers") {
      // console.log("1");

      return item.Table_name.replaceAll("_", " ");
    } else if (item.Field_button === "True") {
      return null;
    } else {
      if (typeof value?.format !== "string") {
        // console.log("value", value);

        // console.log("2", typeof value?.format);

        value = value?.format.replaceAll("_", " ");
        return value;
      } else {
        // console.log("3");
        // console.log("value", value);

        return typeof value?.value === "string"
          ? value.value.replaceAll("_", " ")
          : value?.value;
      }
    }
  };

  const renderEditableCellValue = (item, value, index, key) => {
    // // console.log("item", item[key]);
    // // console.log("value", value);
    if (!item || !value) {
      console.error("Invalid item or value:", { item, value, index, key });
      return null;
    }
    if (value?.format?.includes("input")) {
      const fieldType = value.format.split("-")[1];
      const existingValue = item[key]?.value || "";

      if (value.format.includes("MCQ")) {
        const options = item[key]?.format.match(/\(([^)]+)\)/)[1].split("|");
        return (
          <select
            value={existingValue}
            onChange={(e) => handleEditInputChange(index, key, e.target.value)}
          >
            <option value="">Select Option</option>
            {options.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      } else if (fieldType === "Binary") {
        return (
          <select
            value={existingValue}
            onChange={(e) => handleEditInputChange(index, key, e.target.value)}
          >
            <option value="">Select Option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      } else if (
        typeof item[key]?.format === "string" &&
        item[key].format.includes("input")
      ) {
        switch (fieldType) {
          case "number":
          case "decimal":
          case "wholeNumber":
          case "currency":
            return (
              <TextField
                type="text"
                value={existingValue}
                placeholder="Enter Number"
                required
                onChange={(e) => {
                  validateNumber(
                    e.target.value,
                    handleEditInputChange,
                    key,
                    index
                  );
                }}
              />
            );
          case "percent":
            return (
              <TextField
                type="text"
                value={existingValue}
                placeholder="Enter Percent"
                required
                onChange={(e) => {
                  validatePercent(
                    e.target.value,
                    handleEditInputChange,
                    index,
                    key
                  );
                }}
              />
            );
          case "url":
            return (
              <TextField
                type="text"
                value={existingValue}
                placeholder="Enter URL"
                required
                onChange={(e) => {
                  const isValid = validateURL(e.target.value);
                  if (isValid) {
                    handleEditInputChange(index, key, e.target.value);
                  }
                }}
              />
            );
          case "openText":
          default:
            return (
              <TextField
                type="text"
                value={existingValue}
                placeholder="Enter Text"
                required
                onChange={(e) =>
                  handleEditInputChange(index, key, e.target.value)
                }
              />
            );
        }
      }
    }
    if (
      value?.format?.match(/".*"/) &&
      !value?.format?.includes("*") &&
      !value?.format?.includes("/") &&
      !value?.format?.includes("+") &&
      !value?.format?.includes("-") &&
      !value?.format?.includes("input")
    ) {
      // replaced all back slash
      const fieldValue = value?.format?.replace(/"/g, "");
      const existingValue = item[key]?.value || "";

      return (
        <TextField
          type="text"
          value={existingValue}
          onChange={(e) => {
            validateNumber(e.target.value, handleInputChange, key, index);
          }}
          placeholder="Enter Number"
          required
        />
      );
    }
    if (
      value?.format?.includes("(") &&
      value?.format?.includes(")") &&
      (value?.format?.includes("*") ||
        value?.format?.includes("/") ||
        value?.format?.includes("+") ||
        value?.format?.includes("-"))
    ) {
      return null;
    }

    if (
      (value?.format?.includes("*") ||
        value?.format?.includes("/") ||
        value?.format?.includes("+") ||
        value?.format?.includes("-")) &&
      item.autoCompute === "False"
    ) {
      return null;
    }
    if (value?.format === "Header" || value?.format === "Headers") {
      return item.Table_name;
    } else if (value?.format === "Add Details") {
      return (
        <Button style={{ border: "1px solid #3079bd" }}>Add details</Button>
      );
    } else if (
      value?.format === "False" ||
      value?.format === "False " ||
      value?.format === "Total" ||
      value?.format === "auto"
    ) {
      return null;
    } else if (item[key]?.format?.includes("Please save")) {
      return null;
    } else if (item.Field_button === "True") {
      return (
        <>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              border: "1px solid white",
              background: "#45b653",
              borderRadius: "20px",
              color: "white",
              padding: "5px 30px",
            }}
            onClick={submitForDraft}
          >
            Save
          </Button>
        </>
      );
    } else {
      if (typeof value?.format === "string") {
        value = value?.format?.replaceAll("_", " ") || value?.format;
        return value;
      } else {
        return value?.format;
      }
    }
  };

  const closeSimplePopup = () => {
    setSecondModal(false);
    navigate("/reporting/dashboard");
  };

  return (
    <>
      <TableContainer>
        {(status === "unfilled" || status === "drafted") && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "10px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <BsInfoCircle
                style={{ color: "red", fontWeight: "600", marginRight: "5px" }}
              />
              <Typography
                variant="body1"
                sx={{
                  color: "red",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                Please Save the Table Details Before Submitting
              </Typography>
            </div>
            <Button
              sx={{ textTransform: "none" }}
              //   style={!isDirty?{
              //   border: "1px solid gray",
              //   background: "gray",
              //   borderRadius: "20px",
              //   color: "white",
              //   padding: "5px 30px",
              // }:{
              //   border: "1px solid white",
              //   background: "#45b653",
              //   borderRadius: "20px",
              //   color: "white",
              //   padding: "5px 30px",
              // }}
              style={{
                border: "1px solid white",
                background: "#45b653",
                borderRadius: "20px",
                color: "white",
                padding: "5px 30px",
              }}
              onClick={status === "drafted" ? submitForDraft : submit}
            >
              Save
            </Button>
          </Box>
        )}
        <Table>
          <TableBody
            style={{
              padding: "2rem",
            }}
          >
            {status === "unfilled" &&
              tableData.map((item, index) => {
                const header = item.Field_1;
                if (item.autoCompute !== "Y") {
                  return (
                    <TableRow
                      key={index}
                      style={
                        header === "Header" || header === "Headers"
                          ? // ? { background: "#3079bd", color: "white" }
                            { background: "#E3F4F4" }
                          : {}
                      }
                    >
                      {Object.keys(item)
                        .filter(
                          (keyFilter) =>
                            keyFilter.startsWith("Field_") ||
                            keyFilter.includes("autoCompute")
                        )
                        .sort()
                        .map((keyMap, keyIndex) => {
                          return (
                            <>
                              <TableCell
                                key={keyIndex}
                                style={{
                                  wordBreak: "break-word",
                                  // backgroundColor:
                                  //   keyIndex === 0 ? "#3079bd" : "",
                                  backgroundColor:
                                    keyIndex === 0 ? "#E3F4F4" : "",
                                  // color:
                                  //   keyIndex === 0 || header === "Header"
                                  //     ? "white"
                                  //     : "",
                                  visibility:
                                    (item[keyMap].format &&
                                      item[keyMap].format.includes("Please")) ||
                                    (item[keyMap].format &&
                                      item[keyMap].format.includes("save"))
                                      ? "hidden"
                                      : "visible",
                                }}
                              >
                                {/* {item[keyMap] === "True" && (
                                <Button
                                  style={{
                                    border: "1px solid white",
                                    background: "#3079bd",
                                    borderRadius: "20px",
                                    color: "white",
                                    padding: "10px 65px",
                                    fontWeight: "600",
                                  }}
                                  onClick={submit}
                                >
                                  Save
                                </Button>
                              )} */}

                                {renderCellValue(
                                  item,
                                  item[keyMap],
                                  index,
                                  keyMap
                                )}
                              </TableCell>
                            </>
                          );
                        })}
                    </TableRow>
                  );
                } else {
                  return null;
                }
              })}
            {(status === "saved" || status==="approved" ||  (reviewedBy!==null && status==="drafted")) &&
              tableRendering?.map((item, index) => {
                const header = item.Field_1;
                if (item.autoCompute !== "Y") {
                  return (
                    <TableRow
                      key={index}
                      style={
                        header === "Header" || header === "Headers"
                          ? { background: "#E3F4F4" }
                          : {}
                      }
                    >
                      {Object.keys(item)
                        .filter((keyFilter) => keyFilter.startsWith("Field_"))
                        .sort()
                        .map((keyMap, keyIndex) => {
                          return (
                            <TableCell
                              key={keyIndex}
                              style={{
                                wordBreak: "break-word",
                                backgroundColor:
                                  keyIndex === 0 ? "#E3F4F4" : "",
                                // color:
                                //   keyIndex === 0 || header === "Header"
                                //     ? "white"
                                //     : "",
                              }}
                            >
                              <div>
                                {renderCellValue1(item[keyMap], item, keyIndex)}
                              </div>{" "}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                } else {
                  return null;
                }
              })}
            {status === "drafted" &&
              editableInput?.map((item, index) => {
                const header = item.Field_1;
                if (item.autoCompute !== "Y") {
                  return (
                    <TableRow
                      key={index}
                      style={
                        header === "Header" || header === "Headers"
                          ? { background: "#E3F4F4" }
                          : {}
                      }
                    >
                      {Object.keys(item)
                        .filter((keyFilter) => keyFilter.startsWith("Field_"))
                        .sort()
                        .map((keyMap, keyIndex) => {
                          return (
                            <TableCell
                              key={keyIndex}
                              style={{
                                wordBreak: "break-word",
                                backgroundColor:
                                  keyIndex === 0 ? "#E3F4F4" : "",
                              }}
                            >
                              {renderEditableCellValue(
                                item,
                                item[keyMap],
                                index,
                                keyMap
                              )}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                } else {
                  return null;
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        type="button"
        onClick={openPopup}
        style={
          status === "saved"
            ? buttoncontaindedfixeddisabled
            : buttoncontaindedfixed
        }
        disabled={status === "saved"}
      >
        Next
      </Button>

      {showModal && (
        <BasicModal
          message="Please select the action to be performed."
          closePopup={closePopup}
          openPopup={openPopup}
          handleNavigate={handleNavigate}
          confirm={confirm}
          setConfirm={setConfirm}
          handleClickDraft={handleClickDraft}
          handleNavigateSaveDraft={handleNavigateSaveDraft}
        />
      )}

      {secondModal && (
        <SimpleModal
          openPopup={secondModal}
          message={`Good Job! You have filled ${filledKpis} response`}
          draftMessage={`Good Job! You have Drafted ${draftedKpis} response`}
          closeSimplePopup={closeSimplePopup}
          closePopup={closePopup}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          open={showConfirmModal}
          title={"Confirm Submitting"}
          onClose={closePopup}
          message={"Drafted Data will also we saved after confirming"}
          confirmText={"confirm"}
          cancelText={"cancel"}
          onConfirm={handleNavigate}
        />
      )}
    </>
  );
};
