import axios from "axios";

import { toast } from "react-toastify";
import encrypt from "../../../services/crypto";
import { captureData } from "../../../services/ClientSideApis";
import { getUserEmail } from "../../../services/loggedIn";

export const handleSubmit = async (
  updateDrafTableData,
  formData,
  fdata,
  currentYears,
  currentMonths,
  data,
  setLoading,
  setShowConfirmModal,
  setModalInView,
  modalInView,
  editedFields,
  countryCode
) => {
  // console.log("currentYears save draft", currentYears);
  // console.log("currentMonths save draft", currentMonths);
  // console.log("updateDrafTableData:", updateDrafTableData);
  console.log("formData:", formData);
  // console.log("fdata:", fdata);
  console.log("currentYears:", currentYears);
  console.log("currentMonths:", currentMonths);
  // console.log("data:", data);

  try {
    setLoading(true);

    const formDataToSend = [];
    let containsDraftedData = false;

    Object.keys(formData).forEach((kpiCode) => {
      const kpiData = Object.values(fdata)
        .flat()
        .find((item) => item.KPICode === kpiCode);

      console.log("kpiData", kpiData);

      if (
        formData[kpiCode] !== undefined &&
        formData[kpiCode] !== "undefined"
      ) {
        console.log("currentYears[kpiCode]", currentYears[kpiCode]);

        const inputYearMonth = currentYears[kpiCode] || currentMonths[kpiCode];
        console.log("inputYearMonth", inputYearMonth);

        const frequency =
          inputYearMonth?.length === 9
            ? { Year: inputYearMonth }
            : { Month: inputYearMonth };

        const isSavedOrDraftedForYearOrMonth = Object.values(fdata)
          .flat()
          .some(
            (item) =>
              item.KPICode === kpiCode &&
              (item.KPIStatus === "saved" || item.KPIStatus === "drafted") &&
              ((frequency.Year && item.Year === frequency.Year) ||
                (frequency.Month && item.Month === frequency.Month))
          );

        let saveDrafted = false;

        if (kpiData?.KPIStatus === "drafted") {
          containsDraftedData = true;
          saveDrafted = true; // Save drafted data
        } else if (
          (!isSavedOrDraftedForYearOrMonth || editedFields.includes(kpiCode)) &&
          formData[kpiCode] !== ""
        ) {
          saveDrafted = true; // Save new or edited data
        }

        if (saveDrafted) {
          let kpiInput;
          if (kpiData?.Format === "Telephone") {
            const phoneNumber = formData[kpiCode];
            kpiInput = {
              countryCode: countryCode,
              number: phoneNumber,
            };
          } else if (kpiData?.Format === "Number") {
            kpiInput = formData[kpiCode].toString(); // Ensure number fields are handled correctly
          } else {
            kpiInput = formData[kpiCode];
          }

          formDataToSend.push({
            KPICode: kpiCode,
            KPIInput: kpiInput,
            format: kpiData?.Format,
            draftStatus: "saved", // Ensure the draftStatus is updated to 'saved'
            frequency: frequency,
            department: data.orgdept,
            operationalUnit: data.orgplt,
            userEmail: getUserEmail(),
          });

          console.log("formDataToSend", formDataToSend);
        }
      }
    });

    let encryptedKPIData;

    if (updateDrafTableData?.length > 0) {
      encryptedKPIData = encrypt({ updateDrafTableData });
    } else if (formDataToSend?.length > 0) {
      encryptedKPIData = encrypt({ formDataToSend });
    }

    console.log("encryptedKPIData", encryptedKPIData);

    // if (formDataToSend.length === 0) {
    //   setLoading(false);
    //   toast.warn("No new data to submit.");
    //   return "0";
    // }

    if (modalInView) {
      setShowConfirmModal(false);
      return await continueSubmission(formDataToSend, setLoading);
    }

    if (containsDraftedData && typeof setShowConfirmModal === "function") {
      // console.log("modalinview1");
      const userConfirmed = await setShowConfirmModal(true);
      // console.log("user", userConfirmed);
      // console.log("1");
      setModalInView(true);
      // console.log("2");
      if (!userConfirmed) {
        setLoading(false);
        return "0";
      }
    }
    // console.log("modalinview", modalInView);

    //  else if (containsDraftedData) {
    //   // Fallback to window.confirm if showConfirmModal is not provided
    //   const userConfirmed = window.confirm(
    //     "It will save the drafted data also. Are you sure you want to continue?"
    //   );
    //   if (!userConfirmed) {
    //     setLoading(false);
    //     return "0";
    //   }
    // }

    if (formDataToSend?.length > 0) {
      return await continueSubmission(formDataToSend, setLoading);
    } else if (updateDrafTableData?.length > 0) {
      return await continueSubmission(updateDrafTableData, setLoading);
    }
    setLoading(false);

    // return await continueSubmission(formDataToSend || updateDrafTableData, setLoading);
  } catch (error) {
    setLoading(false);
    console.error("Error submitting form:", error);
    toast.error("Error submitting form. Please try again later.");
  }
};

const continueSubmission = async (formDataToSend, setLoading) => {
  console.log("Form dAta to send======", formDataToSend);
  const encryptedKPIData = encrypt({ formDataToSend });

  await captureData(encryptedKPIData);

  setLoading(false);
  toast.success("Form submitted successfully!");
  localStorage.removeItem("draftData");
  return formDataToSend.length;
};

export const handleSaveDraft = async (
  updateDrafTableData,
  formData,
  fdata,
  currentYears,
  currentMonths,
  data,
  setLoading = () => {},
  baseUrl,
  setIsSavingDraft = () => {},
  editedFields,
  countryCode,
  showToast = true
) => {
  try {
    if (showToast) {
      setLoading(true);
    }

    console.log("currentYears save draft", currentYears);
    console.log("currentMonths save draft", currentMonths);
    console.log("updateDrafTableData save draft", updateDrafTableData);
    console.log("formData save draft", formData);
    console.log("fdata save draft", fdata);

    // Filter out form data with KPIStatus "drafted" or "saved" for the same year or month
    const formDataToSend = [];
    Object.keys(formData).forEach((kpiCode) => {
      const kpiData = Object.values(fdata)
        .flat()
        .find((item) => item.KPICode === kpiCode);
      if (
        formData[kpiCode] !== undefined &&
        formData[kpiCode] !== "undefined"
      ) {
        const inputYearMonth = currentYears[kpiCode] || currentMonths[kpiCode];
        const frequency =
          inputYearMonth.length === 9
            ? { Year: inputYearMonth }
            : { Month: inputYearMonth };

        const isSavedOrDraftedForYearOrMonth = Object.values(fdata)
          .flat()
          .some(
            (item) =>
              item.KPICode === kpiCode &&
              (item.KPIStatus === "saved" || item.KPIStatus === "drafted") &&
              ((frequency.Year && item.Year === frequency.Year) ||
                (frequency.Month && item.Month === frequency.Month))
          );

        if (
          (!isSavedOrDraftedForYearOrMonth || editedFields.includes(kpiCode)) &&
          formData[kpiCode] !== ""
        ) {
          let kpiInput;
          if (kpiData?.Format === "Telephone") {
            const phoneNumber = formData[kpiCode];
            kpiInput = {
              countryCode: countryCode,
              number: phoneNumber,
            };
          } else {
            kpiInput = formData[kpiCode];
          }

          formDataToSend.push({
            KPICode: kpiCode,
            KPIInput: kpiInput,
            format: kpiData?.Format,
            draftStatus: "drafted",
            frequency: frequency,
            department: data.orgdept,
            operationalUnit: data.orgplt,
            userEmail: getUserEmail(),
          });
        }
      }
    });
    console.log("formDataToSend draft:", formDataToSend);

    // Make sure there's data to submit
    if (formData) {
      if (formDataToSend.length === 0) {
        setLoading(false);
        if (showToast) {
          toast.warn("No new data to draft.");
        }
        return "0";
      }
    }

    let encryptedKPIData;

    if (updateDrafTableData.length > 0) {
      encryptedKPIData = encrypt({ updateDrafTableData });
    } else if (formDataToSend.length > 0) {
      encryptedKPIData = encrypt({ formDataToSend });
    }
    //     const decryptKPIData = decryptData(encryptedKPIData);
    //     console.log("decryptKPIData", JSON.parse(decryptKPIData));

    await captureData(encryptedKPIData);
    //  axios.post(
    //   `${baseUrl}/captureData`, // Update this with your actual backend endpoint
    //   { encryptedKPIData: encryptedKPIData },
    //   { headers }
    // );

    setLoading(false);
    if (showToast) {
      toast.success("Draft saved successfully!");
    }
    setIsSavingDraft(true); // Set the flag to indicate that a draft is being saved
    localStorage.removeItem("draftData");
    return formDataToSend.length > 0
      ? formDataToSend.length
      : updateDrafTableData.length;
  } catch (error) {
    // setLoading(false);
    console.error("Error saving draft:", error);
    toast.error("Error saving draft. Please try again later.");
  }
};
