import React, {useEffect, useMemo, useRef, useState} from "react";
import {isEqual} from "lodash/lang";
import * as d3 from "d3";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@mui/material";
import {tablehead} from "../../../styles";

const partition = (data) => {
  const root = d3
    .hierarchy(data)
    .sum((d) => d.size)
    .sort((a, b) => b.size - a.size);
  const partition = d3.partition().size([2 * Math.PI, root.height + 1])(root);
  return partition;
};

function usePrevious(size) {
  const ref = useRef();
  useEffect(() => {
    ref.current = size;
  }, [size]);
  return ref.current;
}

function processNodes(data) {
  const dataCopy = JSON.parse(JSON.stringify(data));

  function combineNodes(nodes) {
    const combined = {};

    nodes.forEach((node) => {
      const [key] = node.name.split("|");
      if (!combined[key]) {
        combined[key] = {
          children: [],
          hasFilled: true,
          email: [],
          number: [],
          DU_OU: [],
        };
      }
      combined[key].children.push(node);

      const userData = node.name.split("|")[1] || "";
      const emailPattern = /\d+_([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})_/;
      const emailMatch = userData.match(emailPattern);
      const email = emailMatch ? emailMatch[1] : "";

      const numberPattern = /\+(\d{12})/;
      const numberMatch = userData.match(numberPattern);
      const number = numberMatch ? numberMatch[0] : "";

      const emailEndIndex = email ? userData.indexOf(email) + email.length : -1;
      const numberStartIndex = userData.indexOf(number);
      const DU_OU =
        numberStartIndex > emailEndIndex
          ? userData
              .slice(emailEndIndex, numberStartIndex)
              .trim()
              .replace(/_/g, " ")
          : "";

      combined[key].email.push(email);
      combined[key].number.push(number);
      combined[key].DU_OU.push(DU_OU);

      if (
        !node.children ||
        !node.children.some((child) => child.name === "Filled")
      ) {
        combined[key].hasFilled = false;
      }
    });

    return Object.keys(combined).map((key) => {
      if (combined[key].hasFilled) {
        return {
          name: key,
          children: [{ name: "Filled", size: 100 }],
        };
      } else {
        return {
          name: key,
          children: combined[key].children.map((node) => ({
            name: node.name,
            size: node.size || 100,
          })),
          email: combined[key].email,
          number: combined[key].number,
          DU_OU: combined[key].DU_OU,
        };
      }
    });
  }

  function traverse(node, level = 1) {
    if (node.children) {
      if (level === 4) {
        node.children = combineNodes(node.children);
      } else {
        node.children.forEach((child) => traverse(child, level + 1));
      }
    }
  }

  traverse(dataCopy);
  return dataCopy;
}

const Sunburst = (props) => {
  console.log(props.isMobile);
  const svgRef = useRef();
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isMouseOverTooltip, setIsMouseOverTooltip] = useState(false);

  // const renderTooltip = () => {
  //   if (tooltipVisible) {
  //     return (
  //       <div
  //         className="tooltip"
  //         style={{
  //           display: "block",
  //           position: "absolute",
  //           top: tooltipData?.y + "px",
  //           left: tooltipData?.x + "px",
  //           zIndex: "999",
  //           height: "auto",
  //           width: "auto",
  //           backgroundColor: "white",
  //           pointerEvents: "none",
  //           padding: "15px",
  //           borderRadius: "10px",
  //           boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.4)",
  //           margin: "0",
  //           fontFamily: "Arial",
  //           fontSize: "10px",
  //         }}
  //       >
  //         <div className="label">{tooltipData.name}</div>
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  const renderTooltip = () => {
    if (tooltipVisible && tooltipData && tooltipData.emails) {
      return (
        <div
          className="tooltip"
          style={{
            display: "block",
            position: "absolute",
            top: tooltipData.y + "px",
            left: tooltipData.x + "px",
            zIndex: "999",
            backgroundColor: "white",
            pointerEvents: "none",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            fontFamily: "Arial",
            fontSize: "12px",
            maxWidth: "600px",
          }}
          onMouseEnter={() => setIsMouseOverTooltip(true)}
          onMouseLeave={() => {
            setIsMouseOverTooltip(false);
            setTooltipVisible(false);
          }}
        >
          <h3 style={{ margin: "0 0 10px 0" }}>{tooltipData.name}</h3>
          <TableContainer
            sx={{ maxHeight: 440 }}
            style={{ overflow: "scroll" }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                backgroundColor: "#fff",
                borderRadius: "25px",
                // padding: "7px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      // padding: "8px",
                      textAlign: "left",
                      color: "#3079bd",
                    }}
                  >
                    Emails
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      // padding: "8px",
                      textAlign: "left",
                      color: "#3079bd",
                    }}
                  >
                    Numbers
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      // padding: "8px",
                      textAlign: "left",
                      color: "#3079bd",
                    }}
                  >
                    Department & OU
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tooltipData.emails.map((email, index) => (
                  <TableRow style={tablehead} key={index}>
                    <TableCell
                      style={{
                        // fontSize: "1.1rem",
                        fontFamily: "Poppins",
                        padding: "0px",
                      }}
                    >
                      {email}
                    </TableCell>
                    <TableCell
                      style={{
                        // fontSize: "1.1rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      {tooltipData.numbers[index] || ""}
                    </TableCell>
                    <TableCell
                      style={{
                        // fontSize: "1.1rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      {tooltipData.du[index] || ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    } else if (tooltipVisible && tooltipData) {
      return (
        <div
          className="tooltip"
          style={{
            display: "block",
            position: "absolute",
            top: tooltipData.y + "px",
            left: tooltipData.x + "px",
            zIndex: "999",
            backgroundColor: "white",
            pointerEvents: "none",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            fontFamily: "Arial",
            fontSize: "12px",
          }}
          onMouseEnter={() => setIsMouseOverTooltip(true)}
          onMouseLeave={() => {
            setIsMouseOverTooltip(false);
            setTooltipVisible(false);
          }}
        >
          <div className="label">{tooltipData.name}</div>
        </div>
      );
    }
    return null;
  };

  const arcVisible = (d) => {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
  };

  const handleMouseOver = (event, d) => {
    if (arcVisible(d.current)) {
      if (d.depth === 4) {
        const isFilled =
          d.children &&
          d.children.some((child) => child.data.name === "Filled");
        if (!isFilled) {
          setTooltipData({
            name: d.data.name,
            emails: d.data.email,
            numbers: d.data.number,
            du: d.data.DU_OU, // Assuming DU_OU only contains DU values
            x: event.pageX - 20,
            y: event.pageY - 170,
          });
          setTooltipVisible(true);
        } else {
          setTooltipData({
            name: isFilled && "Filled",
            x: event.pageX - 20,
            y: event.pageY - 170,
          });
          setTooltipVisible(true);
        }
      } else if (d.depth <= 3) {
        setTooltipData({
          name: d.data.full_form,
          x: event.pageX - 20,
          y: event.pageY - 170,
        });
        setTooltipVisible(true);
      }
    }
  };

  const handleMouseMove = (event, d) => {
    if (isMouseOverTooltip) return;

    if (arcVisible(d.current)) {
      if (d.depth === 4) {
        const isFilled =
          d.children &&
          d.children.some((child) => child.data.name === "Filled");
        if (!isFilled) {
          setTooltipData({
            name: d.data.name,
            emails: d.data.email,
            numbers: d.data.number,
            du: d.data.DU_OU, // Assuming DU_OU only contains DU values
            x: event.pageX - 20,
            y: event.pageY - 170,
          });
          setTooltipVisible(true);
        } else {
          setTooltipData({
            name: isFilled && "Filled",
            x: event.pageX - 20,
            y: event.pageY - 170,
          });
          setTooltipVisible(true);
        }
      } else if (d.depth <= 3) {
        setTooltipData({
          name: d.data.full_form,
          x: event.pageX - 20,
          y: event.pageY - 170,
        });
        setTooltipVisible(true);
      }
    }
  };

  // const handleMouseOver = (event, d) => {
  //   if (arcVisible(d.current)) {
  //     if (d.depth === 4) {
  //       const isFilled =
  //         d.children &&
  //         d.children.some((child) => child.data.name === "Filled");
  //       setTooltipData({
  //         name: isFilled
  //           ? "Filled"
  //           : `User Emails: ${d.data.email.join(
  //               ", "
  //             )}\nNumber: ${d.data.number.join(
  //               ", "
  //             )}\nDU_OU: ${d.data.DU_OU.join(", ")}`,
  //         value: d.data.size,
  //         x: event.pageX - 90,
  //         y: event.pageY - 70,
  //       });
  //     } else if (d.depth <= 3) {
  //       setTooltipData({
  //         name: d.data.full_form,
  //         value: d.data.size,
  //         x: event.pageX - 90,
  //         y: event.pageY - 70,
  //       });
  //     }
  //     setTooltipVisible(true);
  //   }
  // };

  // const handleMouseMove = (event, d) => {
  //   if (arcVisible(d.current)) {
  //     if (d.depth === 4) {
  //       const isFilled =
  //         d.children &&
  //         d.children.some((child) => child.data.name === "Filled");
  //       setTooltipData({
  //         name: isFilled
  //           ? "Filled"
  //           : `User Emails: ${d.data.email.join(
  //               ", "
  //             )}\nNumber: ${d.data.number.join(
  //               ", "
  //             )}\nDU_OU: ${d.data.DU_OU.join(", ")}`,
  //         x: event.pageX - 100,
  //         y: event.pageY - 90,
  //       });
  //     } else if (d.depth <= 3) {
  //       setTooltipData({
  //         name: d.data.full_form,
  //         value: d.data.size,
  //         x: event.pageX - 100,
  //         y: event.pageY - 90,
  //       });
  //     }
  //     setTooltipVisible(true);
  //   }
  // };

  const handleMouseOut = () => {
    // setTooltipVisible(false);
    if (!isMouseOverTooltip) {
      setTooltipVisible(false);
    }
  };

  useEffect(() => {
    renderTooltip();
  }, [tooltipVisible, tooltipData]);

  const prevProps = usePrevious(props);

  const processedData = useMemo(() => {
    if (props.data) {
      return processNodes(props.data);
    }
    return null;
  }, [props.data]);

  useEffect(() => {
    if (!isEqual(prevProps, props) && props.data && props.width) {
      renderSunburst();
    }
  }, [props.data, props.width]);

  const renderSunburst = () => {
    const { data, width, height, offsetX, offsetY } = props;
    if (!data || !data.children || data.children.length === 0) {
      return; // Don't render if data is not available
    }

    const color = d3
      .scaleOrdinal()
      .domain(["E", "S", "G", "Multiple", "MPD", "GD"])
      .range([
        "#c1f4d3",
        "#acbbf2",
        "#f4dbc1",
        "#cafbfe",
        "#C0F9F4",
        "#FBDBF9",
      ]);
    document.querySelectorAll("g").forEach((node) => {
      node.remove();
    });

    const radius = 260 / 6;
    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", [0, 0, width, height])
      .style("font", "10px sans-serif");
    const g = svg
      .append("g")
      .attr(
        "transform",
        props.isMobile
          ? `translate(210,130)`
          : `translate(${width / 2 + offsetX},${height / 2 + offsetY})`
      );
    const root = partition(processedData);
    root.each((d) => (d.current = d));

    const path = g
      .append("g")
      .selectAll("path")
      .data(
        root
          .descendants()
          .slice(1)
          .filter((d) => d.depth <= 4)
      )
      .join("path")
      .attr("fill", (d) => {
        if (d.depth === 3) {
          const hasGrandchildWithoutFilledName = d.children.some(
            (child) =>
              child.children &&
              child.children.some(
                (grandchild) => grandchild.data.name !== "Filled"
              )
          );
          if (hasGrandchildWithoutFilledName) {
            return "grey";
          }
        } else if (d.depth === 4) {
          const hasChildWithoutFilledName =
            d.children &&
            d.children.some((child) => child.data.name !== "Filled");
          if (hasChildWithoutFilledName) {
            return "grey";
          }
        }
        if (d.depth === 2) {
          const hasChildWithGrey = d.children.some(
            (child) =>
              child.depth === 3 &&
              child.children.some(
                (grandchild) =>
                  grandchild.children &&
                  grandchild.children.some(
                    (greatGrandchild) => greatGrandchild.data.name !== "Filled"
                  )
              )
          );
          if (hasChildWithGrey) {
            return "grey";
          }
        }
        if (d.depth === 1) {
          const hasChildWithGrey = d.children.some(
            (child) =>
              child.depth === 2 &&
              child.children.some(
                (grandchild) =>
                  grandchild.children &&
                  grandchild.children.some(
                    (greatGrandchild) => greatGrandchild.data.name !== "Filled"
                  )
              )
          );
          if (hasChildWithGrey) {
            return "grey";
          }
        }

        while (d.depth > 1) d = d.parent;
        return color(d.data.name);
      })
      .attr("fill-opacity", (d) =>
        arcVisible(d.current) ? (d.children ? 1 : 1) : 0
      )
      .attr("d", (d) => {
        return d3
          .arc()
          .startAngle((d) => d.x0)
          .endAngle((d) => d.x1)
          .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
          .padRadius(radius * 1.5)
          .innerRadius((d) => d.y0 * radius)
          .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1))(
          d.current
        );
      });

    path
      .filter((d) => d.children)
      .style("cursor", "pointer")
      .on("click", clicked)
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut)
      .on("mousemove", handleMouseMove);

    const label = g
      .append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(
        root
          .descendants()
          .slice(1)
          .filter((d) => d.depth <= 4)
      )
      .join("text")
      .attr("dy", "0.35em")
      .attr("fill-opacity", (d) => +labelVisible(d.current))
      .attr("transform", (d) => labelTransform(d.current))
      .text((d) => d.data.name)
      .style("font-size", "8px");

    const parent = g
      .append("circle")
      .datum(root)
      .attr("r", radius)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("click", clicked);

    function clicked(event, p) {
      if (p.depth === 4 && p.children) {
        // console.log(p.children[0].data.name);
        return;
      }

      if (p.depth === 3) {
        console.log("Children:");
        p.children.forEach((child) => console.log(child.data.name));
        console.log("Grandchildren:");
        p.children.forEach((child) => {
          if (child.children) {
            child.children.forEach((grandchild) =>
              console.log(grandchild.data.name)
            );
          }
        });
        return;
      }

      if (p.depth > 2) {
        return;
      }

      parent.datum(p.parent || root);

      root.each(
        (d) =>
          (d.target = {
            x0:
              Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            x1:
              Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            y0: Math.max(0, d.y0 - p.depth),
            y1: Math.max(0, d.y1 - p.depth),
          })
      );
      const t = g.transition().duration(750);

      path
        .transition(t)
        .tween("data", (d) => {
          const i = d3.interpolate(d.current, d.target);
          return (t) => (d.current = i(t));
        })
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
        .attr("fill-opacity", (d) =>
          arcVisible(d.target) ? (d.children ? 1 : 1) : 0
        )
        .attrTween("d", (d) => () => {
          return d3
            .arc()
            .startAngle((d) => d.x0)
            .endAngle((d) => d.x1)
            .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
            .padRadius(radius * 1.5)
            .innerRadius((d) => d.y0 * radius)
            .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1))(
            d.current
          );
        });

      label
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || labelVisible(d.target);
        })
        .transition(t)
        .attr("fill-opacity", (d) => +labelVisible(d.target))
        .attrTween("transform", (d) => () => labelTransform(d.current));
    }

    function arcVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.05;
    }

    function labelTransform(d) {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
      const y = ((d.y0 + d.y1) / 2) * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }
  };

  return (
    <div id={props.keyId} className="text-center">
      {renderTooltip()}
      <svg ref={svgRef} id={`${props.keyId}-svg`} />
    </div>
  );
};

export default Sunburst;
