import axios from "axios";
import {baseUrl, config} from "../baseurl";
import encrypt from "./crypto";

export const fetchAllOrganizations = () => {
    return axios.get(`${baseUrl}/getallorganizations`, config);
};

export const addOrganization = (encryptedOrgData) => {
    return axios.post(
        `${baseUrl}/addorg`,
        {orgData: encryptedOrgData},
        config
    );
};

export const updateOrganization = (encryptedOrgEditedData) => {
    return axios.put(
        `${baseUrl}/editorg`,
        {orgEditedData: encryptedOrgEditedData},
        config
    );
};

export const getAllDepartments = () => {
    return axios.get(`${baseUrl}/getAllDepartments`, config);
};

export const deleteOrganization = (params) => {
    return axios.delete(`${baseUrl}/deleteorg`, {...config, params});
};

export const getOrganizationUsers = (organizationName) =>
    axios.get(`${baseUrl}/getOrganizationUsers`, {
        ...config,
        params: { organizationName: encrypt(organizationName.replace(/"/g, "")) },
    });

// export const getOrganizationUsers = (organizationName) => axios.post(
//   `${baseUrl}/getOrganizationUsers`,
//   {organizationName},
//   config
// );

export const getAllOrganizations = () =>
    axios.get(`${baseUrl}/getallorganizations`, config);
