import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import DataTableTables from "../../pages/ClientSide/ExtraFiles/DataTableTables";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { backBtnfixed } from "../../styles";

const PopUpDialog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("state", location.state);
  const popupProps = location.state;

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {popupProps.responseSaved &&
            popupProps?.responseSaved[0]?.CategoryTags &&
            popupProps?.responseSaved[0]?.CategoryTags.split(":")[1]}
          {popupProps.responseDrafted &&
            popupProps?.responseDrafted[0]?.CategoryTags &&
            popupProps?.responseDrafted[0]?.CategoryTags.split(":")[1]}
          (Previous Data)
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ my: 2 }}>
          {popupProps.responseSaved && (
            <Typography>
              1. {popupProps?.responseSaved[0]?.KPIdata}{" "}
              {popupProps?.responseSaved[0]?.KPICode}
            </Typography>
          )}
          {popupProps.responseDrafted && (
            <Typography>
              1. {popupProps?.responseDrafted[0]?.KPIdata}{" "}
              {popupProps?.responseDrafted[0]?.KPICode}
            </Typography>
          )}
        </Box>
        <DataTableTables
          orgdept={popupProps.orgdept}
          orgplt={popupProps.orgplt}
          renderd={
            popupProps?.responseSaved
              ? popupProps.responseSaved
              : popupProps.responseDrafted
          }
          formate={popupProps.tdata}
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button style={backBtnfixed} onClick={handleBack}>
          Back
        </Button>
      </Box>
    </Container>
  );
  // return (
  //   <Dialog
  //     fullScreen
  //     TransitionComponent={Transition}
  //     onClose={closePopup}
  //     aria-labelledby="customized-dialog-title"
  //     open={true}
  //     style={{ zIndex: 9999 }}
  //   >
  //     <DialogTitle
  //       style={{
  //         display: "flex",
  //         justifyContent: "space-between",
  //         alignItems: "center",
  //       }}
  //     >
  //       <span style={{ fontSize: "24px" }}>
  //         {popupProps?.response[0]?.CategoryTags &&
  //           popupProps?.response[0]?.CategoryTags.split(":")[1]}{" "}
  //         (Previous Data)
  //       </span>

  //       {/* <Button> */}
  //       <IconButton
  //         edge="start"
  //         backgroundColor="inherit"
  //         onClick={closePopup}
  //         aria-label="close"
  //       >
  //         <CloseIcon />
  //       </IconButton>
  //       {/* </Button> */}
  //     </DialogTitle>
  //     <Divider />
  //     <DialogContent>
  //       <div>
  //         {popupProps.response[0] !== undefined ? (
  //           <>
  //             {i + 1 + ". "}
  //             {popupProps?.response[0]?.KPIdata + " "}
  //             {popupProps?.response[0]?.KPICode}
  //           </>
  //         ) : (
  //           ""
  //         )}
  //       </div>
  //       <DataTableTables
  //         renderd={popupProps.response}
  //         formate={popupProps.tdata}
  //       />
  //     </DialogContent>
  //     <DialogActions>
  //       <Button onClick={closePopup}>Close</Button>
  //     </DialogActions>
  //   </Dialog>
  // );
};

export default PopUpDialog;
