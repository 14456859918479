export const tableheaddata = [
  {
    id: 1,
    value: "Sr. No",
  },
  {
    id: 2,
    value: "Name",
  },
  {
    id: 3,
    value: "Department",
  },
  {
    id: 4,
    value: "Email",
  },
  {
    id: 5,
    value: "Mobile No",
  },
  {
    id: 6,
    value: "Status",
  },
  {
    id: 7,
    value: "Last Login",
  },
  {
    id: 8,
    value: "",
  },
  {
    id: 9,
    value: "Actions",
  },
  {
    id: 10,
    value: "",
  },
];
