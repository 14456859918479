import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import { cancelBtn, frameworksimgs3, okBtn } from "../../styles";
import CloseIcon from "@mui/icons-material/Close";
import b from "../Org/Img/1000020928.png";
import a from "../Org/Img/1000020929.png";
import { frameworksimgs } from "../../styles";

const ThankyouModal = ({
  closePopup,
  openPopup,
  handleNavigate,
  message,
  title,
  confirmText,
  singalKpiData,
  kpiIndex,
}) => {
  return (
    <Dialog
      //   TransitionComponent={Transition}
      onClose={closePopup}
      aria-labelledby="customized-dialog-title"
      open={openPopup}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h5 style={{ fontFamily: "Poppins, sans-serif", color: "grey" }}>
          {title}
        </h5>
        {singalKpiData && singalKpiData?.GRI === "nan" ? (
          <div style={{ width: "100%" }}>
            <img src={a} alt="GRI" style={frameworksimgs3} />
          </div>
        ) : singalKpiData && singalKpiData?.BRSR === "nan" ? (
          <div style={{ maxWidth: "100px" }}>
            <img src={b} alt="BRSR" style={frameworksimgs3} />
          </div>
        ) : singalKpiData ? (
          <div style={{ maxWidth: "100px" }}>
            <img src={a} alt="GRI" style={frameworksimgs3} />
            <img src={b} alt="BRSR" style={frameworksimgs3} />
          </div>
        ) : (
          ""
        )}
        <IconButton
          edge="end"
          backgroundColor="inherit"
          onClick={handleNavigate}
          aria-label="close"
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ width: "450px" }}>
        {message?.length > 0 && (
          <h2 style={{ textAlign: "center" }}>{message}</h2>
        )}
        {singalKpiData && (
          <h4>
            {kpiIndex + 1}. {singalKpiData.KPIdata}
          </h4>
        )}
        {singalKpiData && (
          <h5 style={{ color: "GrayText", paddingTop: "10px" }}>
            Description: {singalKpiData.Description}{" "}
          </h5>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "10px 20px",
        }}
      >
        {/* <Button onClick={handleNavigate} style={cancelBtn}>
          Cancel
        </Button> */}
        {confirmText.length !== 0 && (
          <Button onClick={handleNavigate} style={okBtn}>
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ThankyouModal;
