import {React, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, TextField} from "@mui/material";
import {buttoncontainded, inputfieldstyle} from "../../styles";
import {toast} from "react-toastify";
import "./SignIn.css";
import {login} from "../../services/loginAPi";
import encrypt from "../../services/crypto";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {getOrg, getUserEmail, getUserName, getUserRole, setLoginData} from "../../services/loggedIn";

function SignInForm() {
  const [username, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginAttempted, setLoginAttempted] = useState(false);
  const navigate = useNavigate();

  const handleIdentifierChange = (e) => {
    setIdentifier(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const fetchData = async () => {
      // try {
      //   if (getToken()) {
      //     if (getOrg() === "Envint") {
      //       navigate("/home", { replace: true });
      //     } else {
      //       navigate("/home", { replace: true });
      //     }
      //   }
      // } catch (error) {
      //   console.error("Error fetching data:", error);
      // }
      try {
        if (getUserName()) {
          if (getOrg() === "Envint") {
            navigate("/home", {replace: true});
          } else {
            navigate("/reporting/home", { replace: true });
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      username,
      password,
    };
    setLoginAttempted(true);
    const encryptedUserData = encrypt({ userData });
    // // console.log("encryptedUserData", encryptedUserData);
    // try {
    //   const response = await login({ userData: encryptedUserData });
    //   const token = response.data.token;
    //   localStorage.setItem("email", userData.username);
    //   localStorage.setItem("org", response.data.organizationName);
    //   localStorage.setItem("userRole", response.data.userRole);
    //   localStorage.setItem("orgEmail", response.data.organizationEmail);
    //   if (
    //     localStorage.getItem("otpSentFlag") &&
    //     localStorage.getItem("token")
    //   ) {
    //     if (
    //       response.data.organizationName !== "Envint" &&
    //       !response.data.userRole === "SPOC"
    //     ) {
    //       navigate("/reporting_dashboard", { replace: true });
    //     } else {
    //       navigate("/dashboard", { replace: true });
    //     }
    //   } else {
    //     if (response.data.organizationName === "Envint") {
    //       navigate("/varotp", {
    //         state: {
    //           username: userData.username,
    //           token: token,
    //         },
    //       });
    //     } else {
    //       if (response.data.userRole === "SPOC") {
    //         localStorage.setItem("token", response.data.token);
    //         navigate("/dashboard", { replace: true });
    //       } else {
    //         localStorage.setItem("token", response.data.token);
    //         navigate("/reporting_dashboard", { replace: true });
    //       }
    //     }
    //   }
    // }
    try {
      let response = await login({ userData: encryptedUserData });
      // console.log("response", response);


      setLoginData(response);
      // localStorage.setItem("email", userData.username);
      // localStorage.setItem("org", response.data.organizationName);
      // localStorage.setItem("userRole", response.data.userRole);
      // localStorage.setItem("orgEmail", response.data.organizationEmail);
      // localStorage.setItem("countryCurrency", response.data.countryCurrency);
      // localStorage.setItem("loginAttempts", response.data.loginAttempts);
      // localStorage.setItem("userName", response.data.name);
      // localStorage.setItem("email", response.data.userEmail);
      // if (response) localStorage.setItem("access", "true");

      console.log(getUserEmail())

      // if (
      //   localStorage.getItem("otpSentFlag") &&//just check
      //   localStorage.getItem("token")//just check
      // ) {
      // if (
      //   response.data.organizationName !== "Envint" &&
      //   !response.data.userRole === "SPOC"
      // ) {
      //   navigate("/reporting_dashboard", { replace: true });
      // }// else if(response.data.userRole === "SPOC") {
      //   navigate("/dashboard", { replace: true });
      // }
      if (getOrg() === "Envint") {
        // if (
        //   localStorage.getItem("otpSentFlag") === undefined ||
        //   localStorage.getItem("otpSentFlag") === "false"
        // ) {
        //   navigate("/varotp", {
        //     state: {
        //       username: userData.username,
        //       token: token,
        //     },
        //   });
        // } else {
        //   navigate("/home");
        // }
        // localStorage.setItem("token", response.data.token);
        navigate("/home");
      } else {
        if (getUserRole() === "SPOC") {
          // localStorage.setItem("token", response.data.token);
          navigate("/dashboard/home", { replace: true });
        } else {
          // localStorage.setItem("token", response.data.token);
          navigate("/reporting/home", { replace: true });
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Login failed: An error occurred.");
      }
    }
  };

  return (
    <div className="form-container sign-in-container SignIn">
      <form onSubmit={handleSubmit} className="form-l">
        <h1 className="login-heading">Sign In</h1>
        <TextField
          label="Email or Phone"
          variant="outlined"
          type="text" // Changed to 'text' type
          id="identifier"
          value={username}
          onChange={handleIdentifierChange}
          style={inputfieldstyle}
          required
        />
        <TextField
          label="Password"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          id="password"
          value={password}
          onChange={handlePasswordChange}
          style={{ margin: "10px 0", padding: "0" }}
          required
          InputProps={{
            endAdornment: (
              <div
                onClick={togglePasswordVisibility}
                edge="end"
                style={{
                  color: "#3079bd",
                  marginRight: "-10px",
                  padding: "0",
                  cursor: "pointer",
                }}
              >
                {showPassword ? (
                  <VisibilityIcon style={{ padding: "0", width: "20px" }} />
                ) : (
                  <VisibilityOffIcon style={{ padding: "0", width: "20px" }} />
                )}
              </div>
            ),
          }}
        />
        <br />
        <div>
          Forgot Password?<a href="/forgotpassword">&nbsp;Click Here</a>
        </div>
        <br />
        <Button variant="contained" type="submit" style={buttoncontainded}>
          Login
        </Button>
        <br />
        {/* <span>or use your account</span>
        <div className="social-container">
          <a href="#" className="social">
            <FaGooglePlusG />
          </a>
          <a
            href="https://www.linkedin.com/company/envintglobal/"
            className="social"
          >
            <FaLinkedin />
          </a>
        </div> */}
      </form>
    </div>
  );
}

export default SignInForm;
