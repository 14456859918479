import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseurl";
import { ClientNavbar } from "../Navbars/ClientNavbar";
import { toast } from "react-toastify";
import { getOrg } from "../../services/loggedIn";

const ClientLayout = ({ children }) => {
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    const fetchOrgImg = async () => {
      try {
        const organizationName = getOrg();
        const res = await axios.post(
          `${baseUrl}/getOrganizationImage`,
          { organizationName },
          { withCredentials: true }
        );
        const orgImgUrl = (await res.data.imageUrl) || "Logo";
        setImgUrl(orgImgUrl);
      } catch (error) {
        toast.error("Error fetching organization image");
      }
    };

    fetchOrgImg();
  }, []);
  return (
    <div>
      <ClientNavbar orgImg={imgUrl} />
      {children}
    </div>
  );
};

export default ClientLayout;
