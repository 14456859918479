import React, { useEffect, useState } from "react";
import { Accordion, Box, AccordionSummary, AccordionDetails, Button, FormControl, InputLabel, Select, MenuItem, IconButton, Tooltip } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import DashboardLayout from "../../components/DashboradLayout/DashboardLayout";
import b from "../../../components/Org/Img/1000020928.png";
import a from "../../../components/Org/Img/1000020929.png";
import { buttoncontaindedfixed, frameworksimgs2 } from "../../../styles";
import { Profile } from "./Initials";
import AcceptModal from "./AcceptModal";
import axios from "axios";
import {useLocation,useNavigate} from "react-router-dom";
import { getUserEmail } from "../../../services/loggedIn";
import { baseUrl } from "../../../baseurl";
import { Loader } from "../../../components/Loader/Loader";
import { GeneralTables } from "../../../pages/ClientSide/ReportingDashboard/GeneralTables";
import { toast } from "react-toastify";

export const SPOCAccordion = () => {
  const [expanded, setExpanded] = useState(false);
  const [fdata, setFdata] = useState(null);
  const [filteredData, setFilteredData] = useState(null); 
  const [filter, setFilter] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [approvedCount, setApprovedCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [loading,setLoading]=useState(true);
  const [changedKPIList, setChangedKPIList] = useState([]);

  const location=useLocation();
  const email=getUserEmail();
  const plantName=location.state.orgplt;
  const deptName=location.state.orgdept;
  
  const navigate=useNavigate();

  useEffect(()=>{
      const fetchAccordionData=async()=>{
      try {
        setLoading(true)
        const response =await axios.post(`${baseUrl}/getAllSavedResponsesForReview`,
          {
            operationalUnit:plantName,
            department:deptName,
            spocEmail:email
          },{
            withCredentials:true
          }
        )
        setFdata(response.data.KPIData);
        setFilteredData(response.data.KPIData);
      } catch (error) {
        console.log(error)
      }finally{
        setLoading(false)
      }
      }
      fetchAccordionData();
  },[])

  if (loading || !fdata || !filteredData) {
    return <Loader loading={loading} />;
  }
var sum=0;
  Object.keys(fdata).forEach((theme)=>{
    sum=sum+fdata[theme].length;
  })
  const handleAccordionOpenChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

const handleSelectAll = () => {
    const updatedFdata = { ...fdata };
    let approvedCount = 0;
    
    Object.keys(updatedFdata).forEach((theme) => {
      updatedFdata[theme].forEach((detail, i) => {
        const frequency = updatedFdata[theme][i].Year 
          ? { Year: updatedFdata[theme][i].Year } 
          : { Month: updatedFdata[theme][i].Month };
  
        if (allApproved) {
          if (detail.KPIStatus === "approved") {
            updatedFdata[theme][i].KPIStatus = "saved"; 
            setChangedKPIList([]);
          }
        } else {
          if (detail.KPIStatus !== "approved") {
            updatedFdata[theme][i].KPIStatus = "approved";
            
            setChangedKPIList(prevList => {
              const existingIndex = prevList.findIndex(
                kpi => kpi.KPICode === updatedFdata[theme][i].KPICode
              );
  
              const updatedKPI = {
                KPICode: updatedFdata[theme][i].KPICode,
                KPIInput: updatedFdata[theme][i].KPIInput,
                department: deptName,
                KPIStatus: updatedFdata[theme][i].KPIStatus,
                Format: updatedFdata[theme][i].Format,
                operationalUnit: plantName,
                userEmail: updatedFdata[theme][i].capturedBy,
                frequency: frequency,
                isReviewed: true,
                reviewedBy: email,
                isEdited: false,
                isCommented: false
              };
  
              if (existingIndex !== -1) {
                const updatedList = [...prevList];
                updatedList[existingIndex] = updatedKPI;
                return updatedList;
              } else {
                return [...prevList, updatedKPI];
              }
            });
          }
        }
  
        // Count approved KPIs
        if (updatedFdata[theme][i].KPIStatus === "approved") {
          approvedCount++;
        }
      });
    });
  
    setFdata(updatedFdata);
    setApprovedCount(approvedCount);
    setRejectedCount(Object.values(updatedFdata).flat().filter(detail => detail.KPIStatus === "drafted").length);
  };
 
const handleRejectAll = () => {
  const updatedFdata = { ...fdata };
  let rejectedCount = 0;

  Object.keys(fdata).forEach((theme) => {
      fdata[theme].forEach((detail, i) => {
        const frequency = updatedFdata[theme][i].Year 
        ? { Year: updatedFdata[theme][i].Year } 
        : { Month: updatedFdata[theme][i].Month };
          if(allRejected){
            if (detail.KPIStatus === "drafted") {
              updatedFdata[theme][i].KPIStatus = "saved"; // Reset to a default state
              setChangedKPIList([]);
            }
          }
          else{
          if (detail.KPIStatus !== "drafted") {
              updatedFdata[theme][i].KPIStatus = "drafted";
              setChangedKPIList(prevList => {
                const existingIndex = prevList.findIndex(
                    kpi => kpi.KPICode === updatedFdata[theme][i].KPICode
                );
    
                const updatedKPI = {
                    KPICode: updatedFdata[theme][i].KPICode,
                    KPIInput: updatedFdata[theme][i].KPIInput,
                    department: deptName,
                    KPIStatus:updatedFdata[theme][i].KPIStatus,
                    Format: updatedFdata[theme][i].Format,
                    operationalUnit: plantName,
                    userEmail: updatedFdata[theme][i].capturedBy,
                    frequency: frequency,
                    isReviewed: true,
                    reviewedBy: email,
                    isEdited: false,
                    isCommented: false
                };
    
                if (existingIndex !== -1) {
                    // Update the existing KPI with the new status
                    const updatedList = [...prevList];
                    updatedList[existingIndex] = updatedKPI;
                    return updatedList;
                } else {
                    // Add new KPI if it doesn't already exist
                    return [...prevList, updatedKPI];
                }
            });
          }
        }
          if (updatedFdata[theme][i].KPIStatus === "drafted") {
              rejectedCount++;
          }
      });
  });

  setFdata(updatedFdata);
  setRejectedCount(rejectedCount);
  setApprovedCount(Object.values(updatedFdata).flat().filter(detail => detail.KPIStatus === "approved").length);
};

const handleNextClick = () => {
  setShowModal(true);

  // Merge fdata and filteredData into one array
  const allKPIs = [
    ...Object.values(fdata).flat(),
    ...Object.values(filteredData).flat()
  ];

  // Use a Map to track unique KPIs by KPICode
  const uniqueKPIs = new Map();
  
  allKPIs.forEach(kpi => {
    // Use KPICode as the unique identifier for each KPI
    if (!uniqueKPIs.has(kpi.KPICode)) {
      uniqueKPIs.set(kpi.KPICode, kpi);
    }
  });

  // Now count approved and drafted KPIs from the uniqueKPIs
  const approvedCount = Array.from(uniqueKPIs.values()).filter(kpi => kpi.KPIStatus === "approved").length;
  const rejectedCount = Array.from(uniqueKPIs.values()).filter(kpi => kpi.KPIStatus === "drafted").length;

  // Set the counts
  setApprovedCount(approvedCount);
  setRejectedCount(rejectedCount);
};


  const closePopup = () => {
    setShowModal(false);
  };

  const openPopup = () => {
    setShowModal(true);
  };

  const updateKPIStatus = (theme, index, status) => {
    const updatedFdata = { ...filteredData };
    const currentStatus = updatedFdata[theme][index].KPIStatus;

    if (currentStatus !== status) {
        if (currentStatus === "approved") {
            setApprovedCount(approvedCount - 1);
        } else if (currentStatus === "drafted") {
            setRejectedCount(rejectedCount - 1);
        }

        if (status === "approved") {
            setApprovedCount(approvedCount + 1);
        } else if (status === "drafted") {
            setRejectedCount(rejectedCount + 1);
        }

        const frequency = updatedFdata[theme][index].Year 
            ? { Year: updatedFdata[theme][index].Year } 
            : { Month: updatedFdata[theme][index].Month };

        updatedFdata[theme][index].KPIStatus = status;
        updatedFdata[theme][index].isReviewed = true;
        updatedFdata[theme][index].reviewedBy = email;
        updatedFdata[theme][index].isCommented = false;
        updatedFdata[theme][index].isEdited = false;

        setChangedKPIList(prevList => {
            const existingIndex = prevList.findIndex(
                kpi => kpi.KPICode === updatedFdata[theme][index].KPICode
            );

            const updatedKPI = {
                KPICode: updatedFdata[theme][index].KPICode,
                KPIInput: updatedFdata[theme][index].KPIInput,
                department: deptName,
                KPIStatus: status,
                Format: updatedFdata[theme][index].Format,
                operationalUnit: plantName,
                userEmail: updatedFdata[theme][index].capturedBy,
                frequency: frequency,
                isReviewed: true,
                reviewedBy: email,
                isEdited: false,
                isCommented: false
            };

            if (existingIndex !== -1) {
                const updatedList = [...prevList];
                updatedList[existingIndex] = updatedKPI;
                return updatedList;
            } else {
                return [...prevList, updatedKPI];
            }
        });

        setFilteredData(prevFilteredData => {
            const updatedFilteredData = { ...prevFilteredData };
            if (updatedFilteredData[theme]) {
                updatedFilteredData[theme][index] = {
                    ...updatedFilteredData[theme][index],
                    KPIStatus: status,
                    isReviewed: true,
                    reviewedBy: email,
                    isCommented: false,
                    isEdited: false
                };
            }
            return updatedFilteredData;
        });
        setApprovedCount(Object.values(fdata).flat().filter(detail => detail.KPIStatus === "approved").length);
        setRejectedCount(Object.values(fdata).flat().filter(detail => detail.KPIStatus === "drafted").length);
        // setFdata(updatedFdata);
    }
};

const plantName_deptName = `${plantName}_${deptName}`; 
const myObject = {
    [plantName_deptName]: changedKPIList 
};

// console.log(">>>>>",myObject);
 
const handleThumbsUp = (theme, i) => {
  updateKPIStatus(theme, i, "approved");
};

const handleThumbsDown = (theme, i) => {
  updateKPIStatus(theme,i, "drafted");
};
 
const handleFilterChange = (event) => {
  const selectedFilter = event.target.value;
  setFilter(selectedFilter);

  if (selectedFilter === "GRI") {
      const newFilteredData = {};
      Object.keys(fdata).forEach(theme => {
          newFilteredData[theme] = fdata[theme].filter(detail => detail.GRI !== "nan" && detail.BRSR==="nan");
      });
      setFilteredData(newFilteredData);
  } else if (selectedFilter === "BRSR") {
      const newFilteredData = {};
      Object.keys(fdata).forEach(theme => {
          newFilteredData[theme] = fdata[theme].filter(detail => detail.BRSR !== "nan" && detail.GRI==="nan");
      });
      setFilteredData(newFilteredData);
     
  } else if (selectedFilter === "Both") {
      const newFilteredData = {};
      Object.keys(fdata).forEach(theme => {
          newFilteredData[theme] = fdata[theme].filter(detail => detail.GRI !== "nan" && detail.BRSR !== "nan");
      });
      setFilteredData(newFilteredData);
  } else if(selectedFilter==="all") {
      setFilteredData(fdata);
  }
};
  const handleSave=()=>{
    setShowModal(false);
  }
  const themes = Object.keys(filteredData);
  let savedTables = [];
  themes.forEach((theme) => {
    if (fdata[theme]) {
      fdata[theme].forEach((detail, i) => {
        if(detail.Format.includes("Table")){
        if (detail.KPIStatus==="saved"|| detail.KPIStatus==="approved" || detail.KPIStatus==="drafted" ) {
          savedTables.push(detail);
        }
      }});
    }
  });

const handleApprovalRejectionSubmit=async()=>{
  try {
    const response = await axios.post(`${baseUrl}/listOfKPIsForApproveReject`,myObject,
      {
        withCredentials:true
      });
      if(response.status===200){
        toast.success(response.data.successMessages)
        navigate('/dashboard/spoc-review')
      }
  } catch (error) {
    console.log(error);
  }
  setShowModal(false);
}
 
  const allApproved = Object.values(filteredData)?.flat().every(detail => detail.KPIStatus === "approved");
const allRejected = Object.values(filteredData)?.flat().every(detail => detail.KPIStatus === "drafted");

const hasApprovedOrRejectedKPI = Object.values(fdata).flat().some(detail => 
  detail.KPIStatus === "approved" || detail.KPIStatus === "drafted"
);

  return (
    <>
      <DashboardLayout>
        <div className="client-dashboard-container">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: '30px 0'
            }}
          >
            <h1 className="frame_heading">Approve/Reject KPIs</h1>
            <FormControl variant="outlined" sx={{ minWidth: 200 }}>
              <InputLabel id="filter-label">Select Framework</InputLabel>
              <Select
                labelId="filter-label"
                id="filter-dropdown"
                value={filter}
                onChange={handleFilterChange}
                label="Select Framework"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="GRI">GRI</MenuItem>
                <MenuItem value="BRSR">BRSR</MenuItem>
                <MenuItem value="Both">Both</MenuItem>
              </Select>
            </FormControl>
            <Box >
    <Button
      sx={{ textTransform: 'none' }}
      style={{
        color: "white",
        padding: "5px 15px",
        borderRadius: "20px",
        // border: "1px solid gray",
        margin: "0 10px",
        background:"#45b653"
      }}
      onClick={handleSelectAll}
    >
      {allApproved ? "Unapprove All" : "Approve All"}
    </Button>
    <Button
      sx={{ textTransform: 'none' }}
      style={{
        color: "white",
        padding: "5px 15px",
        borderRadius: "20px",
        margin: "0 10px",
        background:"#ff9c00"
      }}
      onClick={handleRejectAll}
    >
      {allRejected ? "Unselect" : "Reject All"}
    </Button>
  </Box>
          </Box>
          {themes?.map((theme, key) => {
            return (
              <Accordion
                sx={{ position: "relative" }}
                key={key}
                className="accordion"
                expanded={expanded === key}
                onChange={handleAccordionOpenChange(key)}
              >
                <AccordionSummary
                  sx={{ zIndex: 0 }}
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4>{theme.replaceAll("_", " ")}</h4>
                  </Box>
                </AccordionSummary>
                {filteredData[theme].map((detail, i) => {
                  const isApproved = detail.KPIStatus === "approved" 
                  const isRejected = detail.KPIStatus === "drafted" 

                  return (
                    
                    <AccordionDetails
                      key={i}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '10px',
                        borderRadius: '15px',
                        border: isApproved ? "1px solid #45b653" : isRejected ? "1px solid red" : "1px solid #ccc",
                        backgroundColor:  isApproved ? "#e3f4f4" : isRejected ? "#ffd1dc" : "transparent"
                      }}
                    >
                    
                      <div style={{ width: '75%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p>{i + 1}. {detail.KPIdata}</p>
                        </div>
                        {detail.Format === "Binary"?
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <label style={{ display: 'flex', margin: '0 20px 0 0' }}>
                              <input type="radio" checked={detail.KPIInput === "yes"} disabled />
                              <span>Yes</span>
                            </label>
                            <label style={{ display: 'flex', margin: '0 20px 0 0' }}>
                              <input type="radio" checked={detail.KPIInput === "no"} disabled />
                              <span>No</span>
                            </label>
                          </div> : detail.Format === "Open Text" ?
                            <textarea style={{ minWidth: "100%", border: '1px solid #eee', padding: "20px", background: "#F0F0F0", height: '100px', resize: 'none' }} name="" id="" disabled>{detail.KPIInput}</textarea>
                            : detail.Format==="Percent" || detail.Format==="Currency" || detail.Format==="Number" ? <input value={detail.KPIInput} style={{width:'20%'}} disabled/>:detail.Format==="Ratio"?
                            <div style={{display:'flex'}}>
                            <input style={{width:'20%'}} value={detail.KPIInput.x} disabled/> : <input style={{width:'20%'}} value={detail.KPIInput.y} disabled/>
                            </div>
                            :detail.Format.includes("Table")  ? 
                              savedTables.map((table, key) => {
                                if (
                                  table.Format === detail.Format &&
                                  table.KPICode === detail.KPICode
                                ) {
                                  return (
                                    <React.Fragment key={key}>
                                      <>
                                        <div>
                                          <br />
                                          <GeneralTables
                                            setTableFromat={table.Format}
                                            frequency={
                                              table?.Month || table?.Year
                                            }
                                            // setKpiinputdata={setKpiinputdata}
                                            tableRendering={table.KPIInput}
                                            status={table.KPIStatus}
                                            kpicode={table.KPICode}
                                            reviewedBy={email}
                                          />
                                        </div>
                                      </>
                                    </React.Fragment>
                                  );
                                }}
                  ):""}
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column', width: '10%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Profile name={(detail.capturedBy)[0]} email={detail.capturedBy} />
                          {(detail.GRI !== "nan" && detail.BRSR==="nan") ?
                            <div style={{ maxWidth: '100px' }}>
                              <img src={a} alt="GRI" style={frameworksimgs2} />
                            </div> :
                            (detail.BRSR !== "nan" && detail.GRI==="nan") ?
                              <div style={{ maxWidth: '100px' }}>
                                <img src={b} alt="BRSR" style={frameworksimgs2} />
                              </div>
                              : (detail.GRI !=="nan" && detail.BRSR!=="nan" )?
                              <div style={{ maxWidth: '100px' }}>
                                <img src={a} alt="GRI" style={frameworksimgs2} />
                                <img src={b} alt="BRSR" style={frameworksimgs2} />
                              </div>:""}
                        </div>
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Tooltip title="Approve">
                        <IconButton onClick={() => handleThumbsUp(theme, i)} disabled={isApproved}>
                          <ThumbUpIcon style={{color:'#45b653'}}/>
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Reject">
                        <IconButton onClick={() => handleThumbsDown(theme, i)} disabled={isRejected}>
                          <ThumbDownIcon style={{color:'#ff9c00'}}/>
                        </IconButton>
                        </Tooltip>
                      </div>
                    </AccordionDetails>
                  )
                })}
              </Accordion>
            )
          })}
          {hasApprovedOrRejectedKPI && (
        <Button onClick={handleNextClick} style={buttoncontaindedfixed}>
          Next
        </Button>
      )}
          {showModal && <AcceptModal
            message="Please select the action to be performed."
            closePopup={closePopup}
            openPopup={openPopup}
            handleSave={handleSave}
            approvedCount={approvedCount}
            rejectedCount={rejectedCount}
            total={sum}
            handleApprovalRejectionSubmit={handleApprovalRejectionSubmit}
          />}
        </div>
      </DashboardLayout>
    </>
  );
};
