import React, {useEffect, useState} from "react";
import "./LandingPage.css";
import {Link, useNavigate} from "react-router-dom";
import {FaUsers, FaWpforms} from "react-icons/fa6";
import {BsBuildings} from "react-icons/bs";
import Hamburger from "../../components/Navbars/Hamburger";
import {Loader} from "../../components/Loader/Loader";
import {getLoginAttempts} from "../../services/loggedIn";

export const LandingPage = () => {
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const loginAttempts = parseInt(getLoginAttempts());

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay
//     // Check if it's the user's first login
//     const tourCompleted = localStorage.getItem("tourCompleted");
//     // // console.log("tour", tourCompleted);
//     if (loginAttempts <= 1 && tourCompleted !== "true") {
//       setRunTour(true);
//     }
    return () => clearTimeout(timer);

  }, []);
  

  const steps = [
    {
      target: "#navbar",
      content: "The menu items here correspond to the cards below.",
      disableBeacon: true,
    },
    {
      target: ".landing-page-container",
      content:
        "These cards provide quick access to different sections of the application.",
    },
    {
      target: ".link-box.blue",
      content: "Click on the Organization Management card to get started.",
    },
  ];

  // const handleJoyrideCallback = (data) => {
  //   const { action, index, status, type } = data;
  //   // // console.log("Joyride callback:", { action, index, status, type });
  //   if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
  //     // // console.log("Tour ended");
  //     setRunTour(false);
  //     localStorage.setItem("tourCompleted", "true");
  //   } else if (action === ACTIONS.UPDATE && type === "step:after") {
  //     // // console.log("Step updated:", index + 1);
  //     setStepIndex(index + 1);
  //   }
  // };

  const handleCardClick = (path) => {
    // // console.log("Navigating to:", path);
    navigate(path);
  };
  if (loading) {
    return <Loader loading={loading}/>
  }

  return (
    <>
      <Hamburger />
      {/* <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showProgress
        showSkipButton={true}
        callback={handleJoyrideCallback}
        spotlightClicks={true}
        styles={{
          options: {
            arrowColor: "#e3ffeb",
            backgroundColor: "#e3ffeb",
            primaryColor: "#000",
            textColor: "#004a14",
            width: 300,
            zIndex: 1000,
          },
        }}
      /> */}
      <div className="landing-page-container">
        <div
          onClick={() => handleCardClick("/organization/management")}
          className="link-box blue"
        >
          <div className="circle">
            <BsBuildings className="circle-icon" />

            {/* <h3>Organization Management</h3> */}
          </div>
          <h4 className="landing-head">
            Organization <br /> Management
          </h4>
        </div>

        <Link to="/user/management" className="link-box green">
          <div className="circle">
            <FaUsers className="circle-icon" />
            {/* <h3>User Management</h3> */}
          </div>
          <h4 className="landing-head">User Management</h4>
        </Link>

        <Link to="/assignment/select-organization" className="link-box orange">
          <div className="circle">
            <FaWpforms className="circle-icon" />
            {/* <h3>Form Manager</h3> */}
          </div>
          <h4 className="landing-head">Assignment Manager</h4>
        </Link>
      </div>
    </>
  );
};
