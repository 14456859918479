import React, { useEffect, useState } from "react";
import "./UserManagement.css";
import Hamburger from "../../components/Navbars/Hamburger.js";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { buttoncontainded, inputfieldstyle1, tablehead } from "../../styles.js";
import { UserRow } from "../../components/Users/UserRow.jsx";
import { UserDetails } from "../../components/Users/UserDetails.jsx";
import { tableheaddata } from "./tableheaddata.js";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { FaFileArrowDown } from "react-icons/fa6";
import { decryptData } from "../../services/crypto.js";
import { deleteUser, fetchAllUser } from "../../services/UsermanagementApi.js";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader/Loader.jsx";
import axios from "axios";

import { baseUrl } from "../../baseurl.js";
import { getOrg, getUserEmail, getUserRole } from "../../services/loggedIn";
import ConfirmModal from "../../components/PopUpDialog/ConfirmModal.jsx";
import { getAllManagersAndConsultant } from "../../services/CommonApis.js";
import { filterOrganizationsForUser } from "../../services/helpingFunctions.js";

// The main component to display the user management page
const UserManagement = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  let [modalMode, setModalMode] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [releventOrgUsers, setReleventOrgUsers] = useState([]);
  const userRole = getUserRole();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
/*
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        // Execute both API calls simultaneously
        const [usersResponse, allUserResponse] = await Promise.all([
          getAllManagersAndConsultant(),
          fetchAllUser(),
        ]);
        // const allUsers = fetchAllUser();

        // Process users data
        const decryptedUsersData = decryptData(
          usersResponse.data.encryptedData
        );
        const parsedUsersData = JSON.parse(decryptedUsersData);
        // console.log("parsedUsersData", parsedUsersData);

        const decryptedData = decryptData(allUserResponse.data.encryptedData);
        const parsedData = JSON.parse(decryptedData);
        console.log("users", parsedData);
        if (userRole === "Manager" || userRole === "Consultant") {
          // Filter users based on organization name
          const currentOrg = parsedData.users.filter((org) => {
            return org.organization === getOrg();
          });
          // console.log("curr", currentOrg);

          // // console.log("filteredUsers", filteredUsers);
          const userEmail = getUserEmail();

          const filteredOrgUsers = await filterOrganizationsForUser(
            userRole,
            userEmail,
            parsedData.users,
            parsedUsersData.users
          );

          // console.log("filteredOrgUsers", filteredOrgUsers);
          if (filteredOrgUsers && currentOrg) {
            // Combine currentOrg and filteredOrgs, removing duplicates
            const combinedOrgsUsers = [
              ...new Set([...currentOrg, ...filteredOrgUsers]),
            ];
            console.log("combinedOrgsUsers", combinedOrgsUsers);
            setReleventOrgUsers(combinedOrgsUsers);
          }
        } else {
          setReleventOrgUsers(parsedData.users);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, []);
*/
   useEffect(() => {
     const fetchUserData = async () => {
       setLoading(true);
       try {
         const allUserResponse = await fetchAllUser();
         // console.log("allUserResponse", allUserResponse);
         // Process users data
         const decryptedData = decryptData(allUserResponse.data.encryptedData);
         const parsedData = JSON.parse(decryptedData);
         setReleventOrgUsers(parsedData.users);
       } catch (error) {
         console.error("Error fetching data:", error);
       } finally {
         setLoading(false);
       }
     };
     fetchUserData();
   }, []);
  const handleView = (user) => {
    setSelectedUser(user);
    console.log("user", user);
      navigate("/view-user", {
         state: {
         user: user,
         },
      });
  };
  const handleClose = () => {
    setModalMode(null);
  };
  const handleAddForm = () => {
    navigate("/user/add-user");
  };
  const handleAddUser = (user) => {
    //add user
    setUsers([...users, user]);
    setModalMode(null);
  };
  const handleDelete = (user) => {
    const e = getUserEmail();
    if (user.email === e) {
      toast.error("Unable to delete logged user");
    } else {
      setUserToDelete(user);
      setIsConfirmModalOpen(true);
    }
    // const e = localStorage.getItem("email");
    // if (user.email === e) {
    //   toast.error("Unable to delete logged user");
    // } else {
    //   if (window.confirm(`Are you sure you want to delete ${user.email}?`)) {
    //     const userData = {
    //       email: user.email,
    //     };
    //     const deletUsers = deleteUser(userData)
    //       .then((response) => {
    //         setUsers((prevUsers) =>
    //           prevUsers.filter((u) => u.email !== user.email)
    //         );
    //         toast.success("User Deleted Successfully!");
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //       });
    //   }
    // }
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      const userData = {
        email: userToDelete.email,
      };
      deleteUser(userData)
        .then((response) => {
          setUsers((prevUsers) =>
            prevUsers.filter((u) => u.email !== userToDelete.email)
          );
          toast.success("User Deleted Successfully!");
        })
        .catch((error) => {
          console.error(error);
        });
      setIsConfirmModalOpen(false);
      setUserToDelete(null);
    }
  };

  const handleUpdate = (user) => {
    navigate("/user/update-user", {
      state: {
        user: user,
      },
    });
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const excelReportingName = "user_data_";

  const handleDownloadExcel = () => {
    let finalExcelReportingName = excelReportingName;

    const timestampInSecondsRounded = new Date()
      .toISOString()
      .split("T")[0]
      .replace(/-/g, "");

    const currentTime = new Date();

    // Get the current time as a string in the format HH:MM:SS
    const currentTimeString = currentTime
      .toLocaleTimeString()
      .split(":")
      .join("")
      .split(" ")[0];

    // Append the current time to the final excel reporting name
    finalExcelReportingName += `${timestampInSecondsRounded}${currentTimeString}.xlsx`;

    const ws = XLSX.utils.json_to_sheet(users);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, finalExcelReportingName);
  };
  const filteredUsers = releventOrgUsers?.filter((user) => {
    return (
      (user.userName &&
        user.userName.toLowerCase().includes(search.toLowerCase())) ||
      (user.email && user.email.toLowerCase().includes(search.toLowerCase())) ||
      (user.mobile && user.mobile.includes(search))
    );
  });

  // Handle case where page might be out of bounds after filtering
  const maxPage = Math.max(
    0,
    Math.ceil(filteredUsers.length / rowsPerPage) - 1
  );
  const currentPage = page > maxPage ? maxPage : page;

  const paginatedUsers = filteredUsers
    .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
    .map((user, id) => (
      <UserRow
        key={user.userName}
        user={user}
        id={id + 1 + currentPage * rowsPerPage}
        onView={handleView}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
      />
    ));
  return (
    <>
      <Hamburger />
      <div className="container-user">
        <h1 className="frame_heading">User Management</h1>
        <div className="add-user-btn-search ">
          <Button
            variant="contained"
            style={buttoncontainded}
            onClick={handleAddForm}
          >
            Add User
          </Button>

          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              id="outlined-basic"
              label="Search User"
              variant="outlined"
              style={inputfieldstyle1}
              onChange={(e) => setSearch(e.target.value)}
            />
            <IconButton variant="outlined" onClick={handleDownloadExcel}>
              <FaFileArrowDown />
            </IconButton>
          </div>
        </div>
        <TableContainer sx={{ maxHeight: 440 }} style={{ overflow: "scroll" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{
              backgroundColor: "#fff",
              borderRadius: "25px",
              padding: "10px",
            }}
          >
            <TableBody>
              <TableRow style={tablehead}>
                {tableheaddata.map((item) => {
                  return (
                    <TableCell
                      style={{
                        fontSize: "1.1rem",
                        color: "#3079bd",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                      key={item.id}
                    >
                      {item.value}
                    </TableCell>
                  );
                })}
              </TableRow>
              {/* {releventOrgUsers
                ?.filter((user) => {
                  return (
                    (user.userName &&
                      user.userName
                        .toLowerCase()
                        .includes(search.toLowerCase())) ||
                    (user.email &&
                      user.email
                        .toLowerCase()
                        .includes(search.toLowerCase())) ||
                    (user.mobile && user.mobile.includes(search))
                  );
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user, id) => (
                  <UserRow
                    key={user.userName}
                    user={user}
                    id={id + 1 + page * rowsPerPage}
                    onView={handleView}
                    onDelete={handleDelete}
                    onUpdate={handleUpdate}
                  />
                ))} */}
              {paginatedUsers}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[4, 12, 20]}
          component="div"
          count={filteredUsers.length} // Set count to the length of filteredUsers
          rowsPerPage={rowsPerPage}
          page={currentPage} // Use updated currentPage based on filtered results
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <Loader loading={loading} />
      <ConfirmModal
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        title="Confirm Delete"
        message={`Are you sure you want to delete ${userToDelete?.userName}?`}
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default UserManagement;
