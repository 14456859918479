import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "../../pages/ClientSide/clientdashboard.css";

export const FrequencyFilter = ({ filterOption, handleFilterChange }) => {
  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel id="demo-simple-select-label">
        Select Frequency (Default All)
      </InputLabel>
      <Select
        value={filterOption}
        onChange={handleFilterChange}
        label="Select Frequency (Default All)"
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="annual">Annual</MenuItem>
        <MenuItem value="monthly">Monthly</MenuItem>
      </Select>
    </FormControl>
  );
};
