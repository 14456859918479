import axios from "axios";
import {baseUrl, config} from "../baseurl";


export const fetchAllUser = () => axios.get(`${baseUrl}/allusers`, config);

export const addUser = (encryptData) => 
  axios.post(
    `${baseUrl}/register`,
    { userData: encryptData },
    config
  );

export const updateUsers = (encryptData) => {
  return axios.put(
    `${baseUrl}/editusers`,
    { encryptedData: encryptData },
    config
  );
};

export const deleteUser = (userData) => {
  return axios.delete(`${baseUrl}/deleteusers`, { ...config, data: userData });
};
