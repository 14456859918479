import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {Backdrop} from "@mui/material";
import encrypt from "../../../../services/crypto";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./ScoreCardTable.css";
import HorizontalProgressBar from "./ProgressBarSpoc/HorizontalProgressBar";
import {getUserEmail} from "../../../../services/loggedIn";

const ScoreCardTable = ({ pillarDataFormat }) => {
  // console.log("pillarDataFormat", pillarDataFormat);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.only("sm"));
  const isMdScreen = useMediaQuery(theme.breakpoints.only("md"));
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [pillarData, setPillarData] = useState([pillarDataFormat]);
  const [loading, setLoading] = useState(false);
  const spocEmail = getUserEmail();

  const currentDate = new Date();
  const getFinancialYear = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    // Financial year starts from April, so if the current month is April or later,
    // it belongs to the current financial year; otherwise, it belongs to the previous financial year
    const financialYearStartMonth = 3; // April (0-based index)
    if (month >= financialYearStartMonth) {
      return `${year}-${year + 1}`;
    } else {
      return `${year - 1}-${year}`;
    }
  };

  const currentYear1 = getFinancialYear(currentDate);
  const currentMonth = new Date().toLocaleString("en-us", { month: "short" });
  const slicedYear = currentDate.getFullYear().toString().slice(2);
  const concatedmonth = `${currentMonth}'${slicedYear}`;
  // // console.log("concatedmonth", concatedmonth);

  const encryptData = encrypt({ spocEmail });

 /* useEffect(() => {
    const fetchPillarData = async () => {
      try {
        setLoading(true);
        const res = await axios.post(
          `${baseUrl}/getPillarCompliance`,
          { encryptedData: encryptData },
          { headers }
        );
        const resData = await res.data;

        const decryptingData = decryptData(resData);

        const parsedData = JSON.parse(decryptingData);
        // console.log("parsedData", parsedData.pillarDataFormat);

        setPillarData(parsedData.pillarDataFormat);
        setLoading(false);
      } catch (error) {
        console.error("error", error);
        setLoading(false);
      }
    };
    fetchPillarData();
  }, []);*/

  useEffect(() => {
    getPillarValues();
  }, []);

  const calculatePillarValues = (pillarName) => {
    const pillar = (pillarDataFormat && pillarDataFormat[pillarName]) || {};
    // // console.log("pillar", pillar);
    const annualFrequency = pillar[currentYear1]?.annualFrequency || {};
    // // console.log("annualFrequency", annualFrequency);
    const monthlyFrequency = pillar[currentYear1]?.monthFrequency || [];
    // // console.log("monthlyFrequency", monthlyFrequency);

    let total = annualFrequency.total || 0;
    let filled = annualFrequency.filled || 0;

    // Sum up all monthly frequencies
    monthlyFrequency.forEach((month) => {
      const monthData = month[Object.keys(month)[0]];
      total += monthData.total || 0;
      filled += monthData.filled || 0;
    });
/*
    if (monthlyFrequency.length > 0) {
      monthlyFrequency.forEach((month) => {
        if (Object.keys(month)[0] === concatedmonth) {
          total = pillar[currentYear1].annualFrequency.total || 0;
          total = total + month[concatedmonth].total || 0;

          filled = pillar[currentYear1].annualFrequency.filled || 0;
          filled = filled + month[concatedmonth].filled || 0;
        } else {
          total = pillar[currentYear1]?.annualFrequency.total || 0;
          filled = pillar[currentYear1]?.annualFrequency.filled || 0;
        }
      });
    } else {
      total = pillar[currentYear1]?.annualFrequency.total || 0;
      filled = pillar[currentYear1]?.annualFrequency.filled || 0;
    }
*/

    return { total, filled };
  };

  const getPillarValues = (pillarName) => {
    const pillar = pillarDataFormat && pillarDataFormat[pillarName];
    if (!pillar) {
      return { total: 0, filled: 0, percent: 0 };
    }

    const { total, filled } = calculatePillarValues(pillarName);
    const percent = total > 0 ? ((filled / total) * 100).toFixed(0) : 0;

    return { total, filled, percent };
   /* const { total, filled } = calculatePillarValues(pillarName);
    // // console.log("test", total ? 0 : ((filled / total) * 100).toFixed(0) || 0);
    return {
      total,
      filled,
      percent: ((filled / total) * 100).toFixed(0) || 0,
    };*/

  };

  const getProgressColor = (percent) => {
    if (percent >= 66) {
      return "green";
    } else if (percent >= 33) {
      return "yellow";
    } else {
      return "red";
    }
  };

  return (
    <>
      {loading ? (
        <Backdrop open={loading}>
          <CircularProgress />
        </Backdrop>
      ) : (
        <div className="stats-box-container">
          {[
            { name: "Environment", color: "#c1f4d3" },
            { name: "Social", color: "#acbbf2" },
            { name: "Governance", color: "#f4dbc1" },
            { name: "Multiple", color: "#cafbfe" },
            { name: "General Disclosures", color: "#FBDBF9" },
            { name: "Management and Process Disclosures", color: "#c0f9f4" },
          ].map((pillar, index) => (
            <div
              key={index}
              className="pillar-stat-box"
              style={{
                // borderBottom: `3px solid ${pillar.color}`,
                background: `linear-gradient(to right, ${pillar.color} 0%,${pillar.color} 5%, white 5%, white 100%)`,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">{pillar.name}</Typography>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    {getPillarValues(pillar.name).filled}/
                  </span>
                  <span>{getPillarValues(pillar.name).total}</span>
                </div>
                {/* <div
                    style={{
                      position: "relative",
                      width: "64px",
                      height: "64px",
                    }}
                  >
                    Double Circular Progress
                    <CircularProgress
                      size={60}
                      variant="determinate"
                      value={100}
                      thickness={3}
                      style={{
                        color: "rgb(207, 238, 249)",
                        position: "absolute",
                      }}
                    />
                    <CircularProgress
                      size={60}
                      variant="determinate"
                      value={getPillarValues(pillar.name).percent}
                      thickness={3}
                      style={{
                        color: getProgressColor(
                          getPillarValues(pillar.name).percent
                        ),
                        position: "absolute",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "45%",
                        left: "47%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                        color: "black",
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        {getPillarValues(pillar.name).percent}%
                      </Typography>
                      <Typography variant="h6" sx={{fontWeight: 'bold'}} >100%</Typography>
                    </div>
                  </div> */}
                {/* <ProgressBar currentScore={getPillarValues(pillar.name).filled} totalScore={getPillarValues(pillar.name).total}/> */}
              </div>
              <HorizontalProgressBar
                percentage={getPillarValues(pillar.name).percent}
                color={pillar.color}
              />
            </div>
          ))}
          <div></div>
        </div>
      )}
    </>
  );
};

export default ScoreCardTable;
