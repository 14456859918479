import React from "react";
import { Card, CardContent } from "@mui/material";

export const SelectionDetails = ({ selectedCount, totalKpiCount }) => {
  return (
    <Card
      style={{
        position: "fixed",
        bottom: "10%",
        right: "10px",
        width: "200px",
        height: "fit-content",
        border: "1px solid #eee",
        borderRadius: "15px",
        zIndex: "100",
      }}
    >
      <CardContent>
        <span>
          KPI's Selected :{" "}
          <span>
            {selectedCount}/{totalKpiCount}
          </span>
        </span>
        {/* <br />
        <span>
          Themes Covered : <span></span>
        </span>
        <span>
          <br />
          Topics Covered : <span></span>
        </span> */}
      </CardContent>
    </Card>
  );
};
