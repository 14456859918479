import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import * as FileSaver from "file-saver";
import { baseUrl } from "../../../baseurl";
import { downloadBtn, previewBtn } from "../../../styles";
import DashboardLayout from "../../components/DashboradLayout/DashboardLayout";
import ClientLayout from "../../../components/ClientLayout/ClientLayout";
import CloseIcon from "@mui/icons-material/Close";
import { FieldListTable } from "../../../components/Tables/Field_list";
import { AddMoreTable } from "../../../components/Tables/Add_more";
import { MatrixTable } from "../../../components/Tables/Matrix";
import { MdCloudDownload, MdRemoveRedEye } from "react-icons/md";
import { toast } from "react-toastify";
import { Loader } from "../../../components/Loader/Loader";
import "./downloadreport.css";
import { getUserEmail, getUserRole } from "../../../services/loggedIn";

const DownloadReport = () => {
  const [showPreview, setShowPreview] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedQuarters, setSelectedQuarters] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedOperationalUnits, setSelectedOperationalUnits] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [financialYears, setFinancialYears] = useState([]);
  const [popupProps, setPopupProps] = useState({});
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupload, setPopupLoad] = useState(false);
  const [kpiFormat, setKpiFormat] = useState("");
  const [kpiInput, setKpiInput] = useState([]);
  const [tableType, setTableType] = useState("");
  const [kpiCode, setKpiCode] = useState("");
  const [frequency, setFrequency] = useState("");
  const userRole = getUserRole();
  const [selectedHeaders, setSelectedHeaders] = useState([]);

  const currentDate = new Date();
  const getFinancialYear = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    // Financial year starts from April, so if the current month is April or later,
    // it belongs to the current financial year; otherwise, it belongs to the previous financial year
    const financialYearStartMonth = 3; // April (0-based index)
    if (month >= financialYearStartMonth) {
      return `${year}-${year + 1}`;
    } else {
      return `${year - 1}-${year}`;
    }
  };
  useEffect(() => {
    if (excelData && excelData.length > 0) {
      setSelectedHeaders(Object.keys(excelData[0]));
    }
  }, [excelData]);

  // const currentYear1 = getFinancialYear(currentDate);
  // const currentMonth = new Date().toLocaleString("en-us", { month: "short" });
  // const slicedYear = currentDate.getFullYear().toString().slice(2);
  // const concatedmonth = `${currentMonth}'${slicedYear}`;

  const filterDataStucture = {
    [selectedYear]: { [selectedQuarters]: selectedMonths },
  };
  const departments = [...selectedDepartments];
  const operationalUnits = [...selectedOperationalUnits];

  const filterData2 = { filterDataStucture, departments, operationalUnits };
  //   // console.log("filterData2", filterData2);

  const handleHeaderCheckboxChange = (header) => {
    setSelectedHeaders((prevSelected) =>
      prevSelected.includes(header)
        ? prevSelected.filter((h) => h !== header)
        : [...prevSelected, header]
    );
  };

  const handleDownload = async () => {
    try {
      // const token = getToken();
      setLoading(true);
      const userEmail = getUserEmail();
      const filter = false;

      const currentDate = new Date()
        .toISOString()
        .replace(/[-T:]/g, "")
        .split(".")[0];

      const fileName = `Report_${userEmail.replace(
        "@",
        "_"
      )}_${currentDate}.xlsx`;
      const res = await axios.post(
        `${baseUrl}/getReport`,
        {
          userEmail,
          filterData: filterData2,
          filter,
          selectedHeaders,
        },
        { withCredentials: true, responseType: "blob" }
      );
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(blob, fileName);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 404) {
        toast.warn("No Data Available to Download");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    // Set initial values for selected year, quarters, months, departments, and operational units
    try {
      setLoading(true);

      const fetchFilterData = async () => {
        // const token = localStorage.getItem("token");

        const userEmail = getUserEmail();
        const filter = true;
        //
        // const headers = {
        //   Authorization: `${token}`,
        //   "Content-Type": "application/json",
        // };

        const res = await axios.post(
          `${baseUrl}/getDocumentFilter`,
          { userEmail, filter },
          { withCredentials: true }
        );

        setFilterData(res.data);
        // console.log("res", res.data);
        setSelectedYear(currentFinancialYear);
        if (
          res.data.filterDataStucture &&
          res.data.filterDataStucture[currentFinancialYear]
        ) {
          setSelectedQuarters(
            Object.keys(
              res.data.filterDataStucture &&
                res.data.filterDataStucture[currentFinancialYear]
            ).filter(
              (quarter) =>
                res.data.filterDataStucture[currentFinancialYear][quarter]
                  .length > 0
            )
          );
          setSelectedMonths(
            Object.values(
              res.data.filterDataStucture &&
                res.data.filterDataStucture[currentFinancialYear]
            ).flatMap((months) => months)
          );
        }
        setSelectedDepartments(res.data.departments);
        setSelectedOperationalUnits(res.data.operationalUnits);

        const fYears = Object.keys(res.data.filterDataStucture);
        setFinancialYears(fYears);
        setLoading(false);
      };

      fetchFilterData();
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const handlePreviewData = async () => {
    try {
      setLoading(true);
      // const token = localStorage.getItem("token");
      const userEmail = getUserEmail();
      const filter = false;

      // const headers = {
      //   Authorization: `${token}`,
      //   "Content-Type": "application/json",
      // };
      const res = await axios.post(
        `${baseUrl}/getPreviewData`,
        { userEmail, filterData: filterData2, filter },
        { withCredentials: true }
      );
      console.log("res.data", res.data);

      if (res.data && res.data?.length > 0) {
        setExcelData(res.data);
        res.data.map((individualExcelData, index) => {
          if (Array.isArray(individualExcelData.KPIInput)) {
            setTableType(individualExcelData.KPIInput[0].Type);
            // console.log("individualExcelData", individualExcelData);
            setFrequency(individualExcelData.Time);
            setKpiInput(individualExcelData.KPIInput);
            setKpiCode(individualExcelData.KPICode);
          }
        });
        setShowPreview(true);
      } else {
        setExcelData(null);
        setShowPreview(false);
        toast.warn("No Data Available to Preview");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  console.log("tableType", tableType);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setSelectedQuarters([]);
    setSelectedMonths([]);
  };

  const handleQuarterChange = (event) => {
    const selectedQuarters = event.target.value;
    setSelectedQuarters(selectedQuarters);
    const selectedMonths = selectedQuarters.flatMap(
      (quarter) => filterData.filterDataStucture[selectedYear][quarter] || []
    );
    setSelectedMonths(selectedMonths);
  };

  const handleMonthChange = (event) => {
    const selectedMonths = event.target.value;
    setSelectedMonths(selectedMonths);
    const selectedQuarters = new Set(
      selectedMonths.flatMap((month) =>
        Object.entries(filterData.filterDataStucture[selectedYear])
          .filter(([_, months]) => months.includes(month))
          .map(([quarter]) => quarter)
      )
    );
    setSelectedQuarters(Array.from(selectedQuarters));
  };

  const handleDepartmentChange = (event) => {
    const selectedDepartments = event.target.value;
    setSelectedDepartments(selectedDepartments);
  };

  const handleOperationalUnitChange = (event) => {
    const selectedOperationalUnits = event.target.value;
    setSelectedOperationalUnits(selectedOperationalUnits);
  };

  const currentFinancialYear = getFinancialYear(currentDate);

  //   // console.log("filterData", filterData);

  const closePopup = () => {
    setPopupOpen(false);
  };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleNav = (individualExcelData) => {
    console.log("individualExcelData", individualExcelData);
    // // console.log("KPIFOrmat", individualExcelData.KPIFormat);
    // // console.log("excelData", excelData);
    let filter = [];
    excelData.map((table) => {
      if (Array.isArray(table.Response)) {
        // // console.log("yes", table.KPIFormat === individualExcelData.KPIFormat);
        // // console.log("1", table.KPIcode);
        if (table.KPIFormat === individualExcelData.KPIFormat) {
          filter.push(table);
        }
      }
    });
    // console.log("filter", filter);
    setPopupOpen(true);
    setPopupProps({
      response: filter,
      tdata: individualExcelData.KPIFormat,
    });
  };

  //   if (excelData && excelData.length > 0) {
  //     excelData.forEach((item) => {
  //       const kpiFormat = item.KPIFormat;
  //       const kpiInput = Array.isArray(item.KPIInput) ? item.KPIInput : "";
  //       // console.log("kpiInput", kpiInput);
  //       setKpiFormat(kpiFormat);
  //     });
  //   }

  useEffect(() => {
    if (excelData && excelData.length > 0) {
      excelData.forEach((item) => {
        const kpiFormat = item.KPIFormat;
        const kpiInput = Array.isArray(item.KPIInput) ? item.KPIInput : "";
        // // console.log("kpiInput", kpiInput);
        setKpiFormat(kpiFormat);
      });
    }
  }, [excelData]);

  // // console.log("kpiInput", kpiInput);
  // console.log("excelData", excelData);

  // console.log("selectedHEaders", selectedHeaders);

  return (
    <div className="container-user">
      {userRole === "SPOC" ? <DashboardLayout /> : <ClientLayout />}
      <Box className="download-report-page-head">
        <h1 className="frame_heading">Download & Preview Report</h1>
        <Box className="preview-download-btns-box">
          <Button
            onClick={() => handlePreviewData()}
            variant="outlined"
            startIcon={<MdRemoveRedEye />}
            // disabled={excelData === null}
            style={previewBtn}
          >
            Preview
          </Button>
          <Button
            onClick={handleDownload}
            variant="outlined"
            startIcon={<MdCloudDownload />}
            style={downloadBtn}
            // disabled={excelData === null}
          >
            Download
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "16px",
          margin: "20px 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            marginBottom: "16px",
            marginLeft: "18px",
          }}
        >
          {/* Year/month select based on frequency */}
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="year-label">Select Year</InputLabel>
            <Select
              labelId="year-label"
              id="year"
              label="Select Year"
              value={selectedYear}
              onChange={handleYearChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Select Year</em>
              </MenuItem>
              {financialYears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="year-label">Select Quarter(s)</InputLabel>
            <Select
              labelId="quarter-label"
              id="quarter"
              label="Select Quarter(s)"
              multiple
              value={selectedQuarters}
              onChange={handleQuarterChange}
              renderValue={(selected) => selected.join(", ")}
              sx={{ maxWidth: 250 }}
            >
              <MenuItem value="">
                <em>Select Quarter</em>
              </MenuItem>
              {filterData &&
                filterData.filterDataStucture &&
                Object.entries(
                  filterData.filterDataStucture[selectedYear] || {}
                ).map(
                  ([quarter, months]) =>
                    months.length > 0 && (
                      <MenuItem key={quarter} value={quarter}>
                        <Checkbox
                          checked={selectedQuarters.includes(quarter)}
                        />
                        <ListItemText primary={quarter} />
                      </MenuItem>
                    )
                )}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="month-label">Select Month(s)</InputLabel>
            <Select
              labelId="month-label"
              id="month"
              multiple
              label="Select Month(s)"
              value={selectedMonths}
              onChange={handleMonthChange}
              renderValue={(selected) => selected.join(", ")}
              sx={{ maxWidth: 250 }}
            >
              <MenuItem value="">
                <em>Select Month</em>
              </MenuItem>
              {filterData &&
                filterData.filterDataStucture &&
                Object.entries(
                  filterData.filterDataStucture[selectedYear] || {}
                )
                  .flatMap(([_, months]) => months)
                  .map((month) => (
                    <MenuItem key={month} value={month}>
                      <Checkbox checked={selectedMonths.includes(month)} />
                      <ListItemText primary={month} />
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <FormControl sx={{ maxWidth: 200 }}>
            <InputLabel id="department-label">Select Department(s)</InputLabel>
            <Select
              labelId="department-label"
              id="department"
              label="Select Department(s)"
              multiple
              value={selectedDepartments}
              onChange={handleDepartmentChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Select Department</em>
              </MenuItem>
              {filterData &&
                filterData?.departments &&
                filterData?.departments.map((department) => (
                  <MenuItem key={department} value={department}>
                    <Checkbox
                      checked={selectedDepartments.includes(department)}
                    />
                    <ListItemText primary={department} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {/* Operational Unit dropdown */}
          <FormControl sx={{ maxWidth: 200 }}>
            <InputLabel id="operational-unit-label">
              Select Operational Unit(s)
            </InputLabel>
            <Select
              labelId="operational-unit-label"
              id="operational-unit"
              label="Select Operational Unit(s)"
              multiple
              value={selectedOperationalUnits}
              onChange={handleOperationalUnitChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Select Operational Unit</em>
              </MenuItem>
              {filterData &&
                filterData?.operationalUnits &&
                filterData?.operationalUnits.map((operationalUnit) => (
                  <MenuItem key={operationalUnit} value={operationalUnit}>
                    <Checkbox
                      checked={selectedOperationalUnits.includes(
                        operationalUnit
                      )}
                    />
                    <ListItemText primary={operationalUnit} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {/* </Box> */}
        </Box>
      </Box>

      <div>
        <Loader loading={loading} />

        {showPreview ? (
          <div className="viewer">
            {excelData && excelData ? (
              <div className="table-responsive">
                <TableContainer sx={{ overflow: "scroll", height: "100vh" }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{
                      borderRadius: "25px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                    className="table"
                  >
                    <thead>
                      <TableRow
                        sx={{
                          position: "sticky",
                          top: "0px",
                          zIndex: 1,
                          // backgroundColor: "#3079bd !important",
                          backgroundColor: "#fff !important",
                          borderRadius: "20px",
                        }}
                      >
                        {excelData &&
                          Object.keys(excelData[0]).map((key) => (
                            <TableCell
                              sx={{
                                color: "#fff",
                              }}
                              key={key}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Checkbox
                                  checked={selectedHeaders.includes(key)}
                                  onChange={() =>
                                    handleHeaderCheckboxChange(key)
                                  }
                                  sx={{
                                    color: "#3079bd",
                                    "&.Mui-checked": {
                                      color: "#3079bd",
                                    },
                                  }}
                                />
                                {key.includes("KPIQuestion") ||
                                key.includes("Response") ? (
                                  <h4
                                    style={{
                                      width: "500px",
                                      fontWeight: "600",
                                      color: "#3079bd",
                                    }}
                                  >
                                    {key}
                                  </h4>
                                ) : (
                                  <h4
                                    style={{
                                      width: "115px",
                                      fontWeight: "600",
                                      color: "#3079bd",
                                    }}
                                  >
                                    {key}
                                  </h4>
                                )}
                              </div>
                            </TableCell>
                          ))}
                      </TableRow>
                    </thead>

                    <TableBody sx={{ backgroundColor: "#fff" }}>
                      {excelData &&
                        excelData.length > 0 &&
                        excelData?.map((individualExcelData, index) => (
                          <TableRow key={index}>
                            {Object.keys(individualExcelData).map((key) => (
                              <TableCell key={key}>
                                {Array.isArray(individualExcelData[key]) ? (
                                  <>
                                    <Button
                                      variant="outlined"
                                      onClick={() =>
                                        handleNav(individualExcelData)
                                      }
                                      style={{
                                        border: "1px solid #3079bd",
                                      }}
                                    >
                                      View Table
                                    </Button>
                                  </>
                                ) : (
                                  individualExcelData[key].toString()
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : (
              <div>No Available Data to Preview</div>
            )}
          </div>
        ) : (
          <h3 style={{ color: "red" }}>No Data to Preview</h3>
        )}
      </div>
      {popupOpen ? (
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          onClose={closePopup}
          aria-labelledby="customized-dialog-title"
          open={true}
        >
          <DialogTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton
              edge="start"
              backgroundColor="inherit"
              onClick={closePopup}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography variant="h6">
              1. {popupProps?.response[0]?.KPIQuestion}
            </Typography>
            {/* {// console.log("tableData", popupProps?.response[0].KPIInput)} */}
            {tableType === "Field-list" ? (
              <FieldListTable
                frequency={frequency}
                tableRendering={popupProps?.response[0].Response}
                tableData={popupProps?.response[0].Response}
                status="saved"
                kpicode={kpiCode}
              />
            ) : tableType === "Add more" ? (
              <AddMoreTable
                frequency={frequency}
                tableRendering={popupProps?.response[0].Response}
                tableData={popupProps?.response[0].Response}
                status="saved"
                kpicode={kpiCode}
              />
            ) : tableType === "Matrix" ? (
              <MatrixTable
                frequency={frequency}
                tableRendering={popupProps?.response[0].Response}
                tableData={popupProps?.response[0].Response}
                status="saved"
                kpicode={kpiCode}
              />
            ) : (
              <FieldListTable
                frequency={frequency}
                tableRendering={popupProps?.response[0].Response}
                tableData={popupProps?.response[0].Response}
                status="saved"
                kpicode={kpiCode}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closePopup}>Close</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Loader loading={popupload} />
      )}
    </div>
  );
};

export default DownloadReport;
