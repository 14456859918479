import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@mui/material";
import {
  inputfieldstyle,
  buttoncontainded,
  inputfieldstyle1,
} from "../../styles";
import { toast } from "react-toastify";
import { useNavigate,useParams } from "react-router-dom";
import { baseUrl } from "../../baseurl";

export const AddOrgForm = ({ onAdd, handleClose }) => {
  let [orgname, setOrgname] = useState("");
  let [orgEmail, setOrgEmail] = useState("");
  const [orgtype, setOrgType] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  let [spocName, setSpocName] = useState("");
  let [spocEmail, setSpocEmail] = useState("");
  const [spocMobile, setSpocMobile] = useState("");
  const [mobileError, setMobileError] = useState(false);
  const [spocmobileError, setSpocMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [spocemailError, setSpocEmailError] = useState(false);
  const [country, setCountry] = useState("");
  const [code, setCode] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidSpocEmail, setIsValidSpocEmail] = useState(false);

  const [image, setImage] = useState("");
  const { organizationEmail } = useParams();

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    // const token = window.localStorage.getItem("token");
    // const headers = {
    //   Authorization: `${token}`,
    //   "Content-Type": "application/json",
    // };
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/countryCodes`, {
          // headers,
          withCredentials: true,
        });
        // Assuming the response data is an object with country names as keys and country codes as values
        setCountryData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);

  const countries = Object.entries(countryData).map(([label, value]) => ({
    label,
    value,
  }));

  const handleChange = (event, newValue) => {
    if (newValue) {
      setCountry(newValue.label);
      setCode(newValue.value);
    } else {
      setCountry(null);
      setCode(null);
    }
  };
  const handleTypeChange = (event) => {
    setOrgType(event.target.value);
  };

  const validateMobileNumber = (value) => {
    const isValid = /^\d+$/.test(value);
    setMobileError(!isValid);
    return isValid;
  };
  const validateSpocMobileNumber = (value) => {
    const isValid = /^\d+$/.test(value);
    setSpocMobileError(!isValid);
    return isValid;
  };

  const validateEmail = (value) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setEmailError(!isValid);
    return isValid;
  };
  const validateSpocEmail = (value) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setSpocEmailError(!isValid);
    return isValid;
  };
  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    setMobile(value);
    validateMobileNumber(value);
  };
  const handleSpocMobileNumberChange = (e) => {
    const value = e.target.value;
    setSpocMobile(value);
    validateSpocMobileNumber(value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setOrgEmail(value);
    validateEmail(value);
  };
  const handleSpocEmailChange = (e) => {
    const value = e.target.value;
    setSpocEmail(value);
    validateSpocEmail(value);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    orgEmail = orgEmail.toLowerCase();
    spocEmail = spocEmail.toLowerCase();
    orgname = orgname.charAt(0).toUpperCase() + orgname.slice(1);
    spocName = spocName.charAt(0).toUpperCase() + spocName.slice(1);

    if (mobile.length === 10 && spocMobile.length === 10) {
      const userData = {
        organizationEmail: orgEmail,
        name: orgname,
        type: orgtype,
        address,
        mobile,
        spocName,
        spocEmail,
        spocMobile,
        code,
        country,
        image
      };

      navigate("/add-dept", {
        state: {
          orgData: userData,
        },
      });
      handleClose();
    } else {
      toast.error("Moible Number should be of 10 digits");
    }
  };
  useEffect(() => {
    // const token = window.localStorage.getItem("token");
    // const headers = {
    //   Authorization: `${token}`,
    // };
    const validateEmail = async () => {
      const isCompleteEmail = /\S+@\S+\.\S+/.test(orgEmail);

      if (isCompleteEmail) {
        const data = {
          organizationEmail: orgEmail,
        };

        await axios
          .post(`${baseUrl}/check-organization`, data, {
            // headers,
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.organizationExists !== null) {
              setIsValidEmail(false);
            } else {
              setIsValidEmail(true);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setIsValidEmail(true);
      }
    };

    validateEmail();
  }, [orgEmail]);

  useEffect(() => {
    // const token = window.localStorage.getItem("token");
    // const headers = {
    //   Authorization: `${token}`,
    // };
    const validateEmail = async () => {
      const isCompleteEmail = /\S+@\S+\.\S+/.test(spocEmail);

      if (isCompleteEmail) {
        const data = {
          spocEmail: spocEmail,
          spocMobile: spocMobile,
        };
        await axios
          .post(`${baseUrl}/check-organization`, data, {
            // headers,
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.spocExists !== false) {
              setIsValidSpocEmail(false);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setIsValidSpocEmail(true);
      }
    };

    validateEmail();
  }, [spocEmail, spocMobile]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Add a new Organization
        </DialogTitle>
        <DialogContent style={{ height: "450px", width: "450px" }} dividers>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              variant="outlined"
              type="email"
              value={orgEmail}
              onChange={handleEmailChange}
              style={inputfieldstyle}
              required
              error={!isValidEmail}
              helperText={!isValidEmail ? "Email already exists." : ""}
            />
            <TextField
              label="Name"
              variant="outlined"
              type="text"
              value={orgname}
              onChange={(e) => setOrgname(e.target.value)}
              style={inputfieldstyle1}
              required
            />
            <FormControl style={inputfieldstyle1}>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={orgtype}
                label="Age"
                onChange={handleTypeChange}
              >
                <MenuItem value={"Client"}>Client</MenuItem>
                <MenuItem value={"Envint"}>Envint</MenuItem>
                <MenuItem value={"Partner"}>Partner</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Address"
              variant="outlined"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              style={inputfieldstyle1}
              required
            />
            <TextField
              label="Mobile Number"
              variant="outlined"
              type="tel"
              value={mobile}
              onChange={handleMobileNumberChange}
              style={inputfieldstyle1}
              required
              error={mobileError}
              helperText={mobileError ? "Enter only numbers" : ""}
            />
            <Autocomplete
              options={countries}
              getOptionLabel={(option) => `${option.label} (${option.value})`}
              renderInput={(params) => (
                <TextField {...params} label="Select a country" />
              )}
              onChange={handleChange}
              style={inputfieldstyle}
            />
            <TextField
              label="SPOC Email"
              variant="outlined"
              type="tel"
              value={spocEmail}
              onChange={handleSpocEmailChange}
              style={{
                width: "80%",
                margin: "10px 0",
                backgroundColor: "#ee",
              }}
              required
              error={!isValidSpocEmail}
              helperText={!isValidSpocEmail ? "Email already exists." : ""}
            />
            <TextField
              label="SPOC Name"
              variant="outlined"
              type="tel"
              value={spocName}
              onChange={(e) => setSpocName(e.target.value)}
              className="capitalize"
              style={inputfieldstyle1}
              required
            />
            <TextField
              label="SPOC Mobile"
              variant="outlined"
              type="tel"
              value={spocMobile}
              onChange={handleSpocMobileNumberChange}
              style={inputfieldstyle1}
              required
              error={spocmobileError}
              helperText={spocmobileError ? "Enter only numbers" : ""}
            />
             <TextField
            label="Add Logo"
            type="file"
            onChange={handleImageChange}
            style={inputfieldstyle}
            InputLabelProps={{
              shrink: true,
            }}
          />
          
            <br />
            <br />
            <Button type="submit" style={buttoncontainded}>
              Continue
            </Button>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
