import { toast } from "react-toastify";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { getCountryCurrency } from "../../services/loggedIn";

import { BsInfoCircle } from "react-icons/bs";
import { useEffect, useState } from "react";
import {
  buttoncontaindedfixed,
  buttoncontaindedfixeddisabled,
} from "../../styles";
import BasicModal from "../PopUpDialog/BasicModal";
import SimpleModal from "../PopUpDialog/SimpleModal";
import ConfirmModal from "../PopUpDialog/ConfirmModal";
import { useNavigate } from "react-router-dom";
import {
  handleSaveDraft,
  handleSubmit,
} from "../../pages/ClientSide/ExtraFiles/formHandler";
import { getUserEmail } from "../../services/loggedIn";
import { useDispatch, useSelector } from "react-redux";
import {
  resetFieldKPIState,
  setKPIinputdata,
} from "../../redux/slice/KpiSlice";

export const FieldListTable = ({
  orgplt,
  orgdept,
  setTableFromat,
  tableData,
  setKpiinputdata = () => {},
  tableRendering,
  tableDraftRendering,
  frequency,
  status,
  kpicode,
  reviewedBy
}) => {
  // // console.log("tableData fieldlist", tableData);
  // // // console.log("status", status);
  // // // console.log("frequency", frequency);
  // // // console.log("kpicode", kpicode);
  // // console.log("tableRendering", tableRendering);
  // console.log("tableDraftRendering", tableDraftRendering);
  // console.log("-----status",status)
  // // console.log("orgdept", orgdept);
  // // console.log("orgplt", orgplt);
  // // console.log("setTableFromat", setTableFromat);
  const [updatedTableData, setUpdatedTableData] = useState();
  const [editableInput, setEditiableInput] = useState();
  const [showModal, setShowModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalInView, setModalInView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filledKpis, setFilledKpis] = useState(0);
  const [draftedKpis, setDraftedKpis] = useState(0);
  const [confirm, setConfirm] = useState(false);
  // const [isDirty, setIsDirty] = useState(false);

  const dispatch = useDispatch();
  const { fieldListKpiInputdata } = useSelector((state) => state.kpi);

  // console.log("fieldListKpiInputdata", fieldListKpiInputdata);

  const navigate = useNavigate();
  const formData = "";
  const fdata = "";
  const currentYears = "";
  const currentMonths = "";
  const data = "";

  useEffect(() => {
    setUpdatedTableData(tableData);
    if (fieldListKpiInputdata.length === 0) {
      // console.log("1");
      setEditiableInput(tableDraftRendering?.map((item) => ({ ...item })));
    } else {
      // console.log("2");
      setEditiableInput(fieldListKpiInputdata);
    }
  }, [tableData, tableDraftRendering, fieldListKpiInputdata]);

  const frequency_ = frequency;

  const handleEditInputChange = (index, key, value) => {
    // console.log("Editing input:", { index, key, value });
    setEditiableInput((prevInput) => {
      if (!prevInput || !Array.isArray(prevInput)) {
        console.error("prevInput is not an array:", prevInput);
        return prevInput; // Return unchanged if it's not an array
      }
      const newInput = [...prevInput];
      if (!newInput[index]) {
        console.error("No item at index:", index);
        return prevInput; // Return unchanged if index doesn't exist
      }
      newInput[index] = {
        ...newInput[index],
        [key]: { ...newInput[index][key], value: value },
      };
      return newInput;
    });
    // editableInput[index][key] = value;
    // setEditiableInput(editableInput);
  };

  const handleInputChange = (index, key, value) => {
    // Create a deep copy of updatedTableData
    let updatedData = updatedTableData;
    // console.log("updatedData", updatedData);

    // Update the value property of the corresponding object
    if (updatedData[index] && updatedData[index][key]) {
      updatedData[index][key].value = value;
    }

    setUpdatedTableData(updatedData);
    //   const hasChanges = updatedData.some((row, rowIndex) =>
    //     Object.keys(row).some((fieldKey) =>
    //         row[fieldKey].value !== "" && row[fieldKey].value !== null && row[fieldKey].value !== undefined
    //     )
    // );
    // setIsDirty(hasChanges);

    // alltable[index][key] = value;
  };

  const handleDraftChange = (index, key, value, flag) => {
    if (flag) {
      // Create a deep copy of updatedTableData
      setEditiableInput((prevInput) => {
        if (!prevInput || !Array.isArray(prevInput)) {
          console.error("prevInput is not an array:", prevInput);
          return prevInput; // Return unchanged if it's not an array
        }
        const newInput = [...prevInput];
        if (!newInput[index]) {
          console.error("No item at index:", index);
          return prevInput; // Return unchanged if index doesn't exist
        }
        newInput[index] = {
          ...newInput[index],
          [key]: { ...newInput[index][key], value: value },
        };
        // console.log("newInput", newInput);
        return newInput;
      });
    }
  };

  const submit = () => {
    const kpi_data = {
      [frequency_ + "~" + kpicode]: updatedTableData,
    };

    toast.success("Fields saved successfully");
    setKpiinputdata(kpi_data);
  };

  const submitForDraft = () => {
    if (typeof setKpiinputdata === "function") {
      const kpi_data = {
        [frequency + "~" + kpicode]: editableInput,
      };

      toast.success("Fields saved successfully");
      // Call setKpiinputdata to update state or context
      dispatch(setKPIinputdata(editableInput));

      setKpiinputdata(kpi_data);
    } else {
      console.error("setKpiinputdata is not a function");
    }
    // setIsDirty(false);
  };

  const handleChange = (index, key, value, flag) => {
    // // console.log("updatedTableData[index][key]", updatedTableData[index][key]);
    // console.log("1");

    if (flag) {
      // Create a deep copy of updatedTableData
      let updatedData = JSON.parse(JSON.stringify(updatedTableData));

      // Update the value property of the corresponding object
      if (updatedData[index] && updatedData[index][key]) {
        updatedData[index][key].value = value;
      }

      // Update the state with the modified data
      setUpdatedTableData(updatedData);
      // console.log("updatedTableData", updatedTableData);
    }
  };

  function numbeval(value) {
    const regex = /^(^$)|(^(0(\.\d{0,4})?|([1-9]\d{0,12})(\.\d{0,4})?))$/;
    return regex.test(value);
  }

  const validateNumber = (value, handleInputyChange, key, index) => {
    // Validate number: maximum 13 places and two decimal places
    if (numbeval(value)) {
      if (status === "drafted") {
        handleDraftChange(index, key, value, numbeval(value));
      } else {
        handleChange(index, key, value, numbeval(value));
      }
    } else {
      // If the input is not valid, don't update the value and display an error
      toast.error("Invalid number format!");
    }
  };

  const validatePerc = (value) => {
    const regex = /^(100(\.00?)?|\d{0,2}(\.\d{0,2})?)$/;
    return regex.test(value);
  };

  const validatePercent = (value, handleInputChange, index, key) => {
    if (validatePerc(value)) {
      handleInputChange(index, key, value);
    } else {
      toast.error("Invalid percent format!");
    }
  };

  const validateURL = (value) => {
    // const validateURL = (value) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValid = regex.test(value);
    if (!isValid) {
      toast.error("Invalid URL format!");
    }
    return isValid;
  };

  const renderCellValue = (item, key, index) => {
    const formatValue = item[key]?.format;
    if (formatValue?.includes("input")) {
      //input-feildtype
      const fieldType = formatValue?.split("-")[1];
      if (formatValue?.includes("MCQ")) {
        const options = formatValue?.match(/\(([^)]+)\)/)[1].split("|");
        return (
          <FormControl
            sx={{ minWidth: 120 }}
            size="small"
            style={{ width: "100%" }}
          >
            <InputLabel id="demo-select-small-label">Select Option</InputLabel>
            <Select
              onChange={(e) => handleInputChange(index, key, e.target.value)}
              label="Select option"
            >
              {/* <MenuItem value="">Select Option</MenuItem> */}
              {options.map((option, idx) => (
                <MenuItem key={idx} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      } else if (fieldType === "Binary") {
        return (
          <FormControl
            sx={{ minWidth: 120 }}
            size="small"
            style={{ width: "100%" }}
          >
            <InputLabel id="demo-select-small-label">Select Option</InputLabel>
            <Select
              onChange={(e) => handleInputChange(index, key, e.target.value)}
              label="Select option"
            >
              {/* <option value="">Select Option</option> */}
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        );
      } else if (formatValue?.includes("input")) {
        switch (fieldType) {
          case "number":
            return (
              <TextField
                style={{ width: "100%" }}
                type="number"
                placeholder="Enter Number"
                onChange={(e) =>
                  validateNumber(e.target.value, handleInputChange, key, index)
                }
              />
            );
          case "wholeNumber":
            return (
              <TextField
                style={{ width: "100%" }}
                type="number"
                placeholder="Enter Number"
                onChange={(e) =>
                  validateNumber(e.target.value, handleInputChange, key, index)
                }
              />
            );
          case "Currency":
            return (
              <>
                <TextField
                  style={{ width: "100%" }}
                  type="text"
                  placeholder="Currency"
                  step="0.01"
                  onChange={(e) => {
                    validateNumber(
                      e.target.value,
                      handleInputChange,
                      key,
                      index
                    );
                  }}
                />
                <span>{getCountryCurrency()} </span>
              </>
            );
          case "decimal":
            return (
              <TextField
                style={{ width: "100%" }}
                type="number"
                step="0.01"
                placeholder="Enter Decimal"
                onChange={(e) => {
                  const { value } = e.target;
                  validateNumber(value, handleInputChange, key, index);
                }}
              />
            );
          case "percent":
            return (
              <TextField
                style={{ width: "100%" }}
                type="percent"
                placeholder="Enter Percent"
                onChange={(e) => {
                  const { value } = e.target;
                  validatePercent(value, handleInputChange, index, key);
                }}
              />
            );
          case "openText":
            return (
              <TextField
                type="text"
                style={{ width: "100%" }}
                placeholder="Enter Text"
                onChange={(e) => handleInputChange(index, key, e.target.value)}
              />
            );
          case "url":
            return (
              <input
                type="text"
                style={{ width: "100%" }}
                placeholder="Enter URL"
                onChange={(e) => {
                  const { value } = e.target;
                  if (validateURL(value)) {
                    handleInputChange(index, key, value);
                  }
                }}
              />
            );

          default:
            return (
              <TextField
                style={{ width: "100%" }}
                type="text"
                placeholder="Enter Text"
                onChange={(e) => handleInputChange(index, key, e.target.value)}
              />
            );
        }
      }
    } else if (item[key]?.format?.includes("Please save")) {
      return null;
    } else if (item.Field_button === "True") {
      return (
        <>
          <Button
            sx={{ textTransform: "none" }}
            // disabled={!isDirty}
            //   style={!isDirty?{
            //     border: "1px solid gray",
            //     background: "gray",
            //     borderRadius: "15px",
            //     color: "white",
            //     padding: "5px 35px",
            //   }:{
            //     border: "1px solid #45b653",
            //     background: "#45b653",
            //     borderRadius: "15px",
            //     color: "white",
            //     padding: "5px 35px",
            //   }}
            style={{
              border: "1px solid #45b653",
              background: "#45b653",
              borderRadius: "15px",
              color: "white",
              padding: "5px 35px",
            }}
            onClick={submit}
          >
            Save
          </Button>
        </>
      );
    } else {
      return <div>{formatValue}</div>;
    }
  };

  const renderCellValueSaved = (item, key, index) => {
    // console.log("item", item[key]);

    if (index === tableData.length - 1) {
      if (item.Field_button) {
        return <div></div>;
      }
    }
    return <div>{item[key]?.value}</div>;
  };

  const renderEditableCellValue = (item, value, index, key) => {
    if (!item || !value) {
      console.error("Invalid item or value:", { item, value, index, key });
      return null;
    }
    if (value?.format?.includes("input")) {
      const fieldType = value.format.split("-")[1];
      const existingValue = item[key]?.value || "";

      if (value.format.includes("MCQ")) {
        const options = item[key]?.format.match(/\(([^)]+)\)/)[1].split("|");
        return (
          <select
            value={existingValue}
            onChange={(e) => handleEditInputChange(index, key, e.target.value)}
          >
            <option value="">Select Option</option>
            {options.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      } else if (fieldType === "Binary") {
        return (
          <select
            value={existingValue}
            onChange={(e) => handleEditInputChange(index, key, e.target.value)}
          >
            <option value="">Select Option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      } else if (
        typeof item[key]?.format === "string" &&
        item[key].format.includes("input")
      ) {
        switch (fieldType) {
          case "number":
          case "decimal":
          case "wholeNumber":
          case "currency":
            return (
              <TextField
                type="text"
                value={existingValue}
                placeholder="Enter Number"
                onChange={(e) => {
                  validateNumber(
                    e.target.value,
                    handleEditInputChange,
                    key,
                    index
                  );
                }}
              />
            );
          case "percent":
            return (
              <TextField
                type="text"
                value={existingValue}
                placeholder="Enter Percent"
                onChange={(e) => {
                  validatePercent(
                    e.target.value,
                    handleEditInputChange,
                    index,
                    key
                  );
                }}
              />
            );
          case "url":
            return (
              <TextField
                type="text"
                value={existingValue}
                placeholder="Enter URL"
                onChange={(e) => {
                  const isValid = validateURL(e.target.value);
                  if (isValid) {
                    handleEditInputChange(index, key, e.target.value);
                  }
                }}
              />
            );
          case "openText":
          default:
            return (
              <TextField
                type="text"
                value={existingValue}
                placeholder="Enter Text"
                onChange={(e) =>
                  handleEditInputChange(index, key, e.target.value)
                }
              />
            );
        }
      }
    }
    if (
      value?.format?.match(/".*"/) &&
      !value?.format?.includes("*") &&
      !value?.format?.includes("/") &&
      !value?.format?.includes("+") &&
      !value?.format?.includes("-") &&
      !value?.format?.includes("input")
    ) {
      // replaced all back slash
      const fieldValue = value?.format?.replace(/"/g, "");
      const existingValue = item[key]?.value || "";

      return (
        <TextField
          type="text"
          value={existingValue}
          onChange={(e) => {
            validateNumber(e.target.value, handleInputChange, key, index);
          }}
          placeholder="Enter Number"
        />
      );
    }
    if (
      value?.format?.includes("(") &&
      value?.format?.includes(")") &&
      (value?.format?.includes("*") ||
        value?.format?.includes("/") ||
        value?.format?.includes("+") ||
        value?.format?.includes("-"))
    ) {
      return null;
    }

    if (
      (value?.format?.includes("*") ||
        value?.format?.includes("/") ||
        value?.format?.includes("+") ||
        value?.format?.includes("-")) &&
      item.autoCompute === "False"
    ) {
      return null;
    }

    if (value?.format === "Header" || value?.format === "Headers") {
      return item.Table_name;
    } else if (value?.format === "Add Details") {
      return (
        <Button style={{ border: "1px solid #3079bd" }}>Add details</Button>
      );
    } else if (
      value?.format === "False" ||
      value?.format === "False " ||
      value?.format === "Total" ||
      value?.format === "auto"
    ) {
      return null;
    } else if (item[key]?.format?.includes("Please save")) {
      return null;
    } else if (item.Field_button === "True") {
      return (
        <>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              border: "1px solid white",
              background: "#45b653",
              borderRadius: "20px",
              color: "white",
              padding: "5px 30px",
            }}
            onClick={submitForDraft}
          >
            Save
          </Button>
        </>
      );
    } else {
      if (typeof value?.format === "string") {
        value = value?.format?.replaceAll("_", " ") || value?.format;
        return value;
      } else {
        return value?.format;
      }
    }
  };

  const openPopup = () => {
    setShowModal(true);
  };

  const handleNavigate = async () => {
    if (!confirm) {
      const returnedValue = await handleClick();
      if (returnedValue !== "0") {
        setShowModal(false);
        setFilledKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
      dispatch(resetFieldKPIState());
    } else {
      setShowModal(false);
    }
  };

  const handleNavigateSaveDraft = async () => {
    if (!confirm) {
      const returnedValue = await handleClickDraft();
      if (returnedValue !== "0") {
        setShowModal(false);
        setDraftedKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
    } else {
      setShowModal(false);
    }
  };
  const updateDrafTableData = (draftStatus) => {
    const determineFrequency = () => {
      if (!frequency) {
        console.warn(`No frequency provided for KPI code: ${kpicode}`);
        return {};
      }

      // Check if the frequency is in the format 'MMM\'YY' (e.g., 'Aug\'24')
      if (/^\w{3}'\d{2}$/.test(frequency)) {
        return { Month: frequency };
      }

      // Check if the frequency is in the format 'YYYY-YYYY' (e.g., '2024-2025')
      if (/^\d{4}-\d{4}$/.test(frequency)) {
        return { Year: frequency };
      }

      // If it's neither a month, a year, nor a year range format we recognize, log a warning and return as-is
      console.warn(`Unexpected frequency format: ${frequency}`);
      return { Frequency: frequency };
    };

    // console.log("determineFrequency", determineFrequency());

    return [
      {
        KPICode: kpicode,
        KPIInput: editableInput,
        format: setTableFromat,
        draftStatus: draftStatus,
        frequency: determineFrequency(),
        department: orgdept,
        operationalUnit: orgplt,
        userEmail: getUserEmail(),
      },
    ];
  };

  const handleClick = () => {
    // console.log("setLoading type:", typeof setLoading);
    setLoading(true);
    // console.log("Loading set to true");

    try {
      // console.log("About to call handleSubmit");
      const returnValue = handleSubmit(
        updateDrafTableData("saved"),
        formData,
        fdata,
        currentYears,
        currentMonths,
        data,
        setLoading,
        setShowConfirmModal,
        setModalInView,
        modalInView,
        [], // editedFields
        "" // countryCode
      );
      // console.log("handleSubmit called successfully");
      return returnValue;
    } catch (error) {
      console.error("Error in handleClick:", error);
      setLoading(false);
      return "0";
    }
  };

  const showToast = true;
  const handleClickDraft = () => {
    const returnValue = handleSaveDraft(
      updateDrafTableData("drafted"),
      formData,
      fdata,
      currentYears,
      currentMonths,
      data,
      setLoading,
      showToast
    );
    return returnValue;
  };

  const closePopup = () => {
    setShowModal(false);
    setSecondModal(false);
    setShowConfirmModal(false);
    setModalInView(false);
    // fetchData();
  };

  const closeSimplePopup = () => {
    setSecondModal(false);
    navigate("/reporting/dashboard");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <TableContainer>
        {(status === "unfilled" || (status === "drafted" && reviewedBy===null)) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "10px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <BsInfoCircle
                style={{ color: "red", fontWeight: "600", marginRight: "5px" }}
              />
              <Typography
                variant="body1"
                sx={{
                  color: "red",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                Please Save the Table Details Before Submitting
              </Typography>
            </div>
            <Button
              onClick={status === "unfilled" ? submit : submitForDraft}
              sx={{ textTransform: "none" }}
              // disabled={!isDirty}
              // style={!isDirty?{
              //   border: "1px solid gray",
              //   background: "gray",
              //   borderRadius: "15px",
              //   color: "white",
              //   padding: "5px 35px",
              // }:{
              //   border: "1px solid #45b653",
              //   background: "#45b653",
              //   borderRadius: "15px",
              //   color: "white",
              //   padding: "5px 35px",
              // }}
              style={{
                border: "1px solid #45b653",
                background: "#45b653",
                borderRadius: "15px",
                color: "white",
                padding: "5px 35px",
              }}
            >
              Save
            </Button>
          </Box>
        )}
        <Table>
          <TableBody
            style={{
              padding: "2rem",
            }}
          >
            {status === "unfilled" &&
              tableData.map((item, index) => {
                return (
                  <>
                    <TableRow key={index}>
                      {Object.keys(item)
                        .sort()
                        .map((key, keyIndex) => {
                          if (key.startsWith("Field_")) {
                            return (
                              <>
                                <TableCell
                                  key={keyIndex}
                                  style={{
                                    wordBreak: "break-word",
                                    // backgroundColor:
                                    //   keyIndex=== 0 ? "#3079bd" :"transparent",
                                    backgroundColor:
                                      keyIndex === 0
                                        ? "#E3F4F4"
                                        : "transparent",
                                    visibility:
                                      item[key]?.format?.includes("Please") ||
                                      item[key]?.format?.includes("save")
                                        ? "hidden"
                                        : "visible",
                                    // color: keyIndex === 0 ? "white" : "",
                                  }}
                                >
                                  <div style={{ width: "100%" }}>
                                    {renderCellValue(item, key, index)}
                                  </div>
                                </TableCell>
                              </>
                            );
                          }
                          return null;
                        })}
                    </TableRow>
                  </>
                );
              })}

            {(status === "saved" || status==="approved" ||  (reviewedBy!==null && status==="drafted")) &&
              tableRendering?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    {Object.keys(item)
                      .sort()
                      .map((key, keyIndex) => {
                        if (key.startsWith("Field_")) {
                          return (
                            <TableCell
                              key={keyIndex}
                              style={{
                                wordBreak: "break-word",
                                backgroundColor:
                                  keyIndex === 0 ? "#E3F4F4" : "transparent",
                                // color: keyIndex === 0 ? "white" : "",
                                width: keyIndex === 0 ? "400px" : "",
                              }}
                            >
                              {/* <div style={{ width: "100%" }}> */}
                              {renderCellValueSaved(item, key, index)}
                              {/* </div> */}
                            </TableCell>
                          );
                        }
                        return null;
                      })}
                  </TableRow>
                );
              })}
            {status === "drafted" &&
              editableInput?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    {Object.keys(item)
                      .sort()
                      .map((key, keyIndex) => {
                        if (key.startsWith("Field_")) {
                          return (
                            <TableCell
                              key={keyIndex}
                              style={{
                                wordBreak: "break-word",
                                backgroundColor:
                                  keyIndex === 0 ? "#E3F4F4" : "transparent",
                                // color: keyIndex === 0 ? "white" : "",
                                width: keyIndex === 0 ? "400px" : "",
                              }}
                            >
                              {/* <div style={{ width: "100%" }}> */}
                              {renderEditableCellValue(
                                item,
                                item[key],
                                index,
                                key
                              )}
                              {/* </div> */}
                            </TableCell>
                          );
                        }
                        return null;
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        type="button"
        onClick={openPopup}
        style={
          status === "saved"
            ? buttoncontaindedfixeddisabled
            : buttoncontaindedfixed
        }
        disabled={status === "saved"}
      >
        Next
      </Button>

      {showModal && (
        <BasicModal
          message="Please select the action to be performed."
          closePopup={closePopup}
          openPopup={openPopup}
          handleNavigate={handleNavigate}
          confirm={confirm}
          setConfirm={setConfirm}
          handleClickDraft={handleClickDraft}
          handleNavigateSaveDraft={handleNavigateSaveDraft}
        />
      )}

      {secondModal && (
        <SimpleModal
          openPopup={secondModal}
          message={`Good Job! You have filled ${filledKpis} response`}
          draftMessage={`Good Job! You have Drafted ${draftedKpis} response`}
          closeSimplePopup={closeSimplePopup}
          closePopup={closePopup}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          open={showConfirmModal}
          title={"Confirm Submitting"}
          onClose={closePopup}
          message={"Drafted Data will also we saved after confirming"}
          confirmText={"confirm"}
          cancelText={"cancel"}
          onConfirm={handleNavigate}
        />
      )}
    </>
  );
};
