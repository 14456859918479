import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../baseurl";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  buttoncontaindedfixed,
  inputfieldstyle,
  inputfieldstyle1,
} from "../../styles";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import EditOrgMultiStep from "./EditOrgMultiStep";
import { decryptData } from "../../services/crypto";
import { getOrg } from "../../services/loggedIn";
import { useTheme } from "@mui/material/styles";

const EditOrgDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("state", location.state);
  const user = location.state.user;
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [organizationName, setorganizationName] = useState(
    user.organizationName
  );
  const [organizationEmail, setOrganizationEmail] = useState(
    user.organizationEmail
  );

  const [type, setType] = useState(user.type);
  const [address, setAddress] = useState(user.address);
  const [mobile, setMobile] = useState(user.mobile);
  const [spocEmail, setSpocEmail] = useState(user.spocEmail);
  const [spocName, setSpocName] = useState(user.spocName);
  const [spocMobile, setSpocMobile] = useState(user.spocMobile);
  const [mobileError, setMobileError] = useState(false);
  const [spocmobileError, setSpocMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [spocemailError, setSpocEmailError] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [country, setCountry] = useState(user.country);
  const [countryCode, setCode] = useState(user.countryCode);
  const [allManagers, setAllManagers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState(
    user.selectedManagers
  );

  const availableManagers = user.selectedManagers;

  const validateMobileNumber = (value) => {
    const isValid = /^\d+$/.test(value);
    setMobileError(!isValid);
    return isValid;
  };
  const validateSpocMobileNumber = (value) => {
    const isValid = /^\d+$/.test(value);
    setSpocMobileError(!isValid);
    return isValid;
  };

  const validateEmail = (value) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setEmailError(!isValid);
    return isValid;
  };
  const validateSpocEmail = (value) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setSpocEmailError(!isValid);
    return isValid;
  };
  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);

    setMobile(limitedValue);
  };
  const handleSpocMobileNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);

    setSpocMobile(limitedValue);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setOrganizationEmail(value);
    validateEmail(value);
  };
  const handleSpocEmailChange = (e) => {
    const value = e.target.value;
    setSpocEmail(value);
    validateSpocEmail(value);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/countryCodes`);
        // Assuming the response data is an object with country names as keys and country codes as values
        setCountryData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    const getAllEnvintUsers = async () => {
      try {
        // const token = window.localStorage.getItem("token");
        // const headers = {
        //   Authorization: `${token}`,
        //   "Content-Type": "application/json",
        // };
        const organizationName = getOrg();
        const res = await axios.post(
          `${baseUrl}/getAllManagersAndConsultant`,
          { organizationName },
          { withCredentials: true }
        );
        const decryptedData = decryptData(res.data.encryptedData);
        const parsedData = JSON.parse(decryptedData);
        // console.log("parsedData", parsedData);
        setAllManagers(parsedData.users.Manager);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
    getAllEnvintUsers();
  }, []);
  const countries = Object.entries(countryData).map(([label, value]) => ({
    label,
    value,
  }));
  const handleDropdownChange = (event, newValue) => {
    if (newValue) {
      setCountry(newValue.label);
      setCode(newValue.value);
    } else {
      setCountry(""); // Handle case where user clears the selection
      setCode("");
    }
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const { priorityTags, spocPassword, operationalUnits, departments } = user;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (mobile.length === 10 && spocMobile.length === 10) {
      const orginitialData = {
        organizationName: organizationName,
        organizationEmail: organizationEmail,
        departments,
        operationalUnits,
        mobile,
        countryCode,
        country,
        spocPassword,
        priorityTags,
        type,
        address,
        spocEmail,
        spocMobile,
        spocName,
        selectedManagers,
      };
      navigate("/organization/edit-dept", {
        state: {
          orginitialData: orginitialData,
          skills: location.state.skills,
          parsedDepts: location.state.parsedDepts,
          selectedDept: location.state.selectedDept,
          operationalUnits: location.state.operationalUnits,
          selectedItems: location.state.selectedItems,
          availableManagers: availableManagers,
        },
      });
      //   handleClose();
    } else {
      toast.error("Mobile number should be of 10 digits");
    }
  };

  // console.log("availableMan", availableManagers);

  const handleManagerChange = (event) => {
    const selectedManager = event.target.value;
    setSelectedManagers(selectedManager);
  };

  // console.log("selected", selectedManagers);

  const step = 0;
  return (
    <div className="add-container">
      <Box sx={{ marginBottom: "20px" }}>
        <EditOrgMultiStep step={step} />
      </Box>
      <h3 className="frame_heading">Organization Details</h3>
      <Box
        sx={{
          width: "50%",
          margin: "0px auto",
          padding: "20px",
          borderRadius: "20px",
        }}
        boxShadow={3}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                variant="outlined"
                type="email"
                value={organizationEmail}
                onChange={handleEmailChange}
                style={inputfieldstyle1}
                required
                disabled
                error={emailError}
                helperText={emailError ? "Enter a valid email address" : ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                variant="outlined"
                type="text"
                value={organizationName}
                onChange={(e) => setorganizationName(e.target.value)}
                style={inputfieldstyle1}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl style={inputfieldstyle1}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  defaultValue={type}
                  label="Age"
                  onChange={handleTypeChange}
                >
                  <MenuItem value={"Client"}>Client</MenuItem>
                  <MenuItem value={"Envint"}>Envint</MenuItem>
                  <MenuItem value={"Partner"}>Partner</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Address"
                variant="outlined"
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                style={inputfieldstyle1}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Mobile Number"
                variant="outlined"
                type="tel"
                value={mobile}
                onChange={handleMobileNumberChange}
                style={inputfieldstyle1}
                error={mobileError}
                helperText={mobileError ? "Enter only numbers" : ""}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={countries}
                getOptionLabel={(option) => `${option.label} (${option.value})`}
                renderInput={(params) => (
                  <TextField {...params} label="Select a country" />
                )}
                onChange={handleDropdownChange}
                style={inputfieldstyle}
                value={{ label: country, value: countryCode }} // Set default value here
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label && option.value === value.value
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="SPOC Email"
                variant="outlined"
                type="tel"
                value={spocEmail}
                onChange={handleSpocEmailChange}
                style={inputfieldstyle1}
                required
                error={spocemailError}
                helperText={spocemailError ? "Enter a valid email address" : ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="SPOC Name"
                variant="outlined"
                type="tel"
                value={spocName}
                onChange={(e) => setSpocName(e.target.value)}
                style={inputfieldstyle1}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="SPOC Mobile"
                variant="outlined"
                type="tel"
                value={spocMobile}
                onChange={handleSpocMobileNumberChange}
                style={inputfieldstyle1}
                error={spocmobileError}
                helperText={spocmobileError ? "Enter only numbers" : ""}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="manager-label">Select Manager</InputLabel>
                <Select
                  labelId="manager-label"
                  id="manager-label"
                  label="Select Manager"
                  value={selectedManagers}
                  onChange={handleManagerChange}
                  renderValue={(selected) => {
                    // Find the manager object for the selected email
                    const selectedManager = allManagers.find(
                      (manager) => manager.email === selected
                    );
                    // Return the selected manager name
                    return selectedManager ? selectedManager.userName : "";
                  }}
                >
                  {allManagers.map((manager, index) =>
                    isMediumScreen ? (
                      <MenuItem key={index} value={manager.email}>
                        <ListItemText
                          primary={manager.userName}
                          secondary={manager.email}
                        />
                      </MenuItem>
                    ) : (
                      <MenuItem key={index} value={manager.email}>
                        <Box width="100%">
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <ListItemText primary={manager.userName} />
                            </Grid>
                            <Grid item xs={6}>
                              <ListItemText secondary={manager.email} />
                            </Grid>
                          </Grid>
                        </Box>
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <br />
          <br />
          <Button type="submit" style={buttoncontaindedfixed}>
            Continue
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default EditOrgDetails;
