import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { cancelBtn, okBtn } from "../../styles";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmModal = ({
  open,
  onClose,
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {/* <h5 style={{ fontFamily: "Poppins, sans-serif", color: "grey" }}>
          Confirm Submitting
        </h5> */}
        <IconButton
          edge="end"
          backgroundColor="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: "450px" }}>
        <h2 style={{ textAlign: "center" }}>{message}</h2>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
          margin: "20px 0",
        }}
      >
        <Button onClick={onClose} style={cancelBtn}>
          {cancelText}
        </Button>
        <Button type="button" onClick={onConfirm} style={okBtn}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
