import React from "react";
import {useLocation} from "react-router-dom";
import "./Hstyle.css";
import CustomNavLink from "./CustomNavLink";
import {envintnavlist} from "./clientnavbardata";
import {ProfileIcon} from "./ProfileIcon";

const Navbar = ({ handleLogout }) => {
  const location = useLocation();

  const activeStyle = {
    color: "#2f7abd",
    fontWeight: "bold",
  };
  const isActive = (basePath) => location.pathname.startsWith(basePath);
  return (
    <>
      <ul
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          textDecoration: "none",
          backgroundColor: "#f9f8f9",
          padding: "12px 16px",
          borderRadius: "20px",
          width: "70%",
        }}
      >
      {envintnavlist.map((item)=>{
        return(
          <li
          style={{
            display: "flex",
            padding: "0px",
            textDecoration: "none",
            listStyle: "none",
          }}
          key={item.id}
        >
        <CustomNavLink to={item.navigateTo}>
        <h3>{item.name}</h3>
        </CustomNavLink>
        </li>
        )
      })}
      </ul>
      <li className="profile-icon">
       <ProfileIcon handleLogout={handleLogout}/>
      </li>
    </>
  );
};

export default Navbar;
