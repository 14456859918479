import { Avatar, Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import {ENVINT_BLUE} from "../../library/constants";

const getInitials = (name) => {
  const words = name.split(" ");
  return words[0][0] + words[words.length - 1][0];
};

const NameCard = ({ details}) => {
  const frameHeadingStyle = {
    display: "flex",
    alignItems: "center",
  };

  const cardStyle = {
    textAlign: "left",
    padding: "1.5rem 3.5rem",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "1rem",
  };

  const avatarStyle = {
    backgroundColor: ENVINT_BLUE,
    width: 56,
    height: 56,
    marginRight: "15px",
    fontSize: "20px",
  };

  const userNameStyle = {
    color: ENVINT_BLUE,
    fontSize: "1.5rem",
    fontWeight: "bold",
  };

  const envintLogoStyle = {
    marginLeft: "auto",
    width: 56,
    height: 56,
    objectFit: "contain",
  };

  if (details.organizationName) {
    return (
      <Card sx={{ borderRadius: "1rem", marginBottom: "20px" }} elevation={3}>
        <CardContent sx={cardStyle}>
          <Box sx={frameHeadingStyle}>
            {details.image ? (
              <CardMedia
                component="img"
                sx={{ width: 56, height: 56, marginRight: "15px", borderRadius: "50%" }}
                image={details.image}
                alt={`${details.organizationName} logo`}
              />
            ) : (
              <Avatar sx={avatarStyle}>
                {getInitials(details.organizationName)}
              </Avatar>
            )}
            <Typography sx={userNameStyle}>
              {details.organizationName}
            </Typography>
            <CardMedia
              component="img"
              sx={envintLogoStyle}
              image="https://envintglobal.com/wp-content/uploads/2024/03/envint-circle-logo.png"
              alt="Envint Logo"
            />
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ borderRadius: "1rem", marginBottom: "20px" }} elevation={3}>
      <CardContent sx={cardStyle}>
        <Box sx={frameHeadingStyle}>
          <Avatar sx={avatarStyle}>
            {getInitials(details.userName)}
          </Avatar>
          <Typography sx={userNameStyle}>
            {details.userName}
          </Typography>
          {details.adminRights && (
            <Typography sx={{
              marginLeft: "auto",
              fontSize: "1rem",
              fontWeight: "bold",
              color: "#fff",
              backgroundColor: ENVINT_BLUE,
              padding: "0.5rem 1rem",
              borderRadius: "4px",
            }}>
              {details.adminRights}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default NameCard;
