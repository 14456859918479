import React, {useEffect, useState} from "react";
import DashHamburger from "../Navbars/DashHamburger";
import {baseUrl} from "../../../baseurl";
import axios from "axios";
import {toast} from "react-toastify";
import {getOrg} from "../../../services/loggedIn";

const DashboardLayout = ({ children }) => {
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    const fetchOrgImg = async () => {
      try {
        // const token = localStorage.getItem("token");
        const organizationName = getOrg();
        // const headers = {
        //   Authorization: `${token}`,
        // };
        const res = await axios.post(
          `${baseUrl}/getOrganizationImage`,
          { organizationName },
          { withCredentials: true  }
        );
        const orgImgUrl = await res.data.imageUrl;
        setImgUrl(orgImgUrl);
      } catch (error) {
        toast.error("Cannot fetch organization image");
      }
    };

    fetchOrgImg();
  }, []);
  return (
    <>
      <DashHamburger orgImgUrl={imgUrl} />
      {children}
    </>
  );
};

export default DashboardLayout;
