import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { BsInfoCircle } from "react-icons/bs";
import "./AddDept.css";
import { useNavigate, useLocation } from "react-router-dom";
import {
  backBtnfixed,
  btnMapDept,
  buttoncontainded,
  buttoncontaindedfixed,
  disabledbuttoncontainded,
} from "../../../styles";
import { toast } from "react-toastify";
import Hamburger from "../../Navbars/Hamburger";
import { getAllDepartments } from "../../../services/OrganizationManagemnetApi";
import MultiStep from "../../../pages/MultiStepAddOrg/MultiStep";

export const AddDept = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const orgData = location.state.orgData;
  // console.log("orgData add dept", orgData);
  const deptData = location.state;
  // console.log("deptData", deptData);
  const [skills, setSkills] = useState(location.state?.skills || []);
  const [newSkill, setNewSkill] = useState("");
  const [operationalUnits, setOperationalUnits] = useState(
    location.state?.operationalUnits || []
  );

  // const organizationEmail=location.state.organizationEmail;
  // const image=location.state.image;
  const [newUnit, setNewUnit] = useState("");
  const [modalshow, setModalshow] = useState(false);
  const [selectedDept, setSelectedDept] = useState({});
  const [allDept, setAllDept] = useState([]);
  const [selectedDepartmentsAll, setSelectedDepartmentsAll] = useState([]);
  const selectedItems = location.state?.selectedItems;

  // // console.log("selectedDept", selectedDept);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Add this useEffect hook
  useEffect(() => {
    if (location.state?.skillsWithDepartments !== undefined || {}) {
      const loadedDepts = {};
      location.state?.skillsWithDepartments?.forEach((item, index) => {
        const key = Object.keys(item)[0];
        const value = item[key]
          .split(",")
          .map((dept) => dept.replace(/_/g, " "));
        loadedDepts[index] = value;
      });
      setSelectedDept(loadedDepts);

      const allLoadedDepts = new Set();
      Object.values(loadedDepts).forEach((depts) => {
        depts.forEach((dept) => allLoadedDepts.add(dept));
      });
      setSelectedDepartmentsAll(Array.from(allLoadedDepts));
    }
  }, [location.state]);

  useEffect(() => {
    try {
      const fetchAllDepts = async () => {
        const res = await getAllDepartments();

        // axios.get(`${baseUrl}/getAllDepartments`, {
        //   headers,
        // });
        const data = res.data.departments;
        const allDept = data.map((dept) => dept.replace(/_/g, " "));
        setAllDept(allDept);
      };

      fetchAllDepts();
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  }, []);

  const handleAddUnit = () => {
    const trimmedNewUnit = newUnit.trim().toLowerCase(); // Convert to lowercase for case-insensitive comparison

    if (
      trimmedNewUnit !== "" &&
      !operationalUnits.some((unit) => unit.toLowerCase() === trimmedNewUnit)
    ) {
      setOperationalUnits([...operationalUnits, newUnit.trim()]);
      setNewUnit("");
    } else {
      toast.error("Operational Unit already entered");
    }
  };

  const handleDeleteUnit = (index) => {
    const updatedUnits = [...operationalUnits];
    updatedUnits.splice(index, 1);
    setOperationalUnits(updatedUnits);
  };

  const handleAddSkill = () => {
    const trimmedNewSkill = newSkill.trim().toLowerCase(); // Convert to lowercase for case-insensitive comparison

    if (
      trimmedNewSkill !== "" &&
      !skills.some((skill) => skill.toLowerCase() === trimmedNewSkill)
    ) {
      setSkills([...skills, newSkill.trim()]);
      setNewSkill("");
      setModalshow(true);
    } else {
      toast.error("Department already entered");
    }
  };

  const handleDeleteSkill = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);

    // Update selectedDept
    const updatedSelectedDept = { ...selectedDept };
    delete updatedSelectedDept[index];

    // Re-index the remaining selected departments
    const reIndexedSelectedDept = {};
    Object.keys(updatedSelectedDept).forEach((key) => {
      const newIndex = key > index ? key - 1 : key;
      reIndexedSelectedDept[newIndex] = updatedSelectedDept[key];
    });

    setSelectedDept(reIndexedSelectedDept);

    // Update selectedDepartmentsAll
    const updatedSelectedDepartmentsAll = new Set();
    Object.values(reIndexedSelectedDept).forEach((depts) => {
      depts.forEach((dept) => updatedSelectedDepartmentsAll.add(dept));
    });

    setSelectedDepartmentsAll(Array.from(updatedSelectedDepartmentsAll));
  };

  const skillsWithDepartments = skills.map((skill, index) => {
    const selectedDepartmentsForSkill = selectedDept[index] || [];
    const deptString = selectedDepartmentsForSkill.join(",").replace(/ /g, "_");
    return { [skill]: deptString };
  });

  const handleSubmit = () => {
    if (!hasAtLeastOneDepartmentSelected()) {
      return toast.error(
        "Add atleast one or more Departments in the newly added Department"
      );
    }

    if (skills.length === 0) {
      toast.error("At least one department is required.");
    } else if (operationalUnits.length === 0) {
      toast.error("At least one operational unit is required.");
    } else {
      // console.log("Step 2 <<<<<<<",orgData.image,">>>>")
      navigate("/organization/add-dept-table", {
        state: {
          skills: skills,
          operationalUnits: operationalUnits,
          orgData: orgData,
          skillsWithDepartments: skillsWithDepartments,
          selectedItems: selectedItems,
          selectedManagers: location.state.selectedManagers,
          // organizationEmail:organizationEmail,
          // image:image
        },
      });
    }
  };
  const handleClose = () => {
    setModalshow(!modalshow);
  };

  const handleDeptChange = (event, skillIndex) => {
    const { value } = event.target;
    const newSelectedDepts = value;

    // Update selectedDept
    const updatedSelectedDept = {
      ...selectedDept,
      [skillIndex]: newSelectedDepts,
    };
    setSelectedDept(updatedSelectedDept);

    // Update selectedDepartmentsAll
    const updatedSelectedDepartmentsAll = new Set();
    skills.forEach((_, index) => {
      const selectedDeptsForSkill = updatedSelectedDept[index] || [];
      selectedDeptsForSkill.forEach((dept) =>
        updatedSelectedDepartmentsAll.add(dept)
      );
    });

    setSelectedDepartmentsAll(Array.from(updatedSelectedDepartmentsAll));
  };

  const isOptionDisabled = (dept, skillIndex) => {
    const isSelectedInCurrentDropdown = (
      selectedDept[skillIndex] || []
    ).includes(dept);
    const isSelectedInAnyOtherDropdown =
      selectedDepartmentsAll.includes(dept) && !isSelectedInCurrentDropdown;

    return isSelectedInAnyOtherDropdown;
  };

  const handleContinue = () => {
    if (!hasAtLeastOneDepartmentSelected()) {
      return toast.error(
        "Add atleast one or more Departments in the newly added Department"
      );
    }
    setModalshow(false);
  };

  const handleOpenModal = () => {
    setModalshow(true);
  };

  const hasAtLeastOneDepartmentSelected = () => {
    // const newSkillKey = skills;
    // // console.log("newSkills", newSkillKey);
    // if (!newSkillKey) return false; // No new skill added

    // const newSkillName = Object.keys(newSkillKey);
    // // console.log("newSkillName", newSkillName);
    // const newSkillDepartments = selectedDept[skills.length - 1];

    // return newSkillDepartments && newSkillDepartments.length > 0;

    const newSkillIndex = skills.length - 1;
    const newSkillDepartments = selectedDept[newSkillIndex];
    // console.log("newSkillDepartments", newSkillDepartments);

    return Array.isArray(newSkillDepartments) && newSkillDepartments.length > 0;
  };

  const handleBack = () => {
    if (!hasAtLeastOneDepartmentSelected()) {
      return toast.error(
        "Add atleast one or more Departments in the newly added Department"
      );
    }
    navigate("/organization/add-org", {
      state: {
        orgData: location.state.orgData,
        skills: skills,
        operationalUnits: operationalUnits,
        skillsWithDepartments: skillsWithDepartments,
        selectedItems: selectedItems,
        selectedManagers: location.state.selectedManagers,
      },
    });
  };

  const step = 1;

  return (
    <>
      <Hamburger />

      <div className="add-dept-body">
        <div className="add-container">
          <Box sx={{ marginBottom: "20px" }}>
            <MultiStep step={step} />
          </Box>
          <div open={true} style={{ minWidth: "100%", minHeight: "50%" }}>
            <h3 className="frame_heading">
              Add Departments and Operation Units
            </h3>
            <br />
            <Divider />
            <div className="add-dept-container">
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <BsInfoCircle />
                &nbsp; Type and press enter to add departments and units
              </p>
              <h4 className="dept-head">Add All Departments</h4>
              <TextField
                className="add-dept-input"
                variant="outlined"
                required
                value={newSkill}
                autoCapitalize={newSkill}
                onChange={(e) => setNewSkill(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleAddSkill()}
              />
              <div className="dept-chip-box" style={{ position: "relative" }}>
                {skills.length === 0 && (
                  <div style={{ color: "red", margin: "20px" }}>
                    No departments added{" "}
                  </div>
                )}
                {skills.map((skill, index) => (
                  <Chip
                    key={index}
                    label={skill}
                    onDelete={() => handleDeleteSkill(index)}
                    style={{ margin: "5px" }}
                    className="chip"
                  />
                ))}
                <button style={btnMapDept} onClick={handleOpenModal}>
                  Map Departments
                </button>
              </div>
              <br />
              <Divider />
              <h4 className="dept-head">Add All Operational Units</h4>
              <TextField
                variant="outlined"
                value={newUnit}
                required
                onChange={(e) => setNewUnit(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleAddUnit()}
                className="add-dept-input"
              />
              <div className="dept-chip-box">
                {operationalUnits.length === 0 && (
                  <div style={{ color: "red", margin: "20px" }}>
                    No Operational Units added{" "}
                  </div>
                )}
                {operationalUnits.map((unit, index) => (
                  <Chip
                    key={index}
                    label={unit}
                    onDelete={() => handleDeleteUnit(index)}
                    style={{ margin: "5px" }}
                    className="chip"
                  />
                ))}
              </div>
              <br />
            </div>
            <Button onClick={handleBack} style={backBtnfixed}>
              Back
            </Button>
            <Button onClick={handleSubmit} style={buttoncontaindedfixed}>
              Continue
            </Button>
          </div>
        </div>
        {modalshow && (
          <Dialog open={modalshow} onClose={handleClose}>
            <DialogTitle>
              Mark departments
              <Typography
                variant="body1"
                sx={{ color: "red", fontWeight: "600" }}
              >
                Add atleast one or more Departments.
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Table className="add-dept-table">
                <TableBody>
                  {skills.map((skill, skillIndex) => (
                    <TableRow key={skillIndex}>
                      <TableCell>{skill}</TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel id="depts">
                            Select Default Departments
                          </InputLabel>

                          <Select
                            id="depts"
                            multiple
                            value={selectedDept[skillIndex] || []}
                            onChange={(event) =>
                              handleDeptChange(event, skillIndex)
                            }
                            renderValue={(selected) => selected.join(", ")}
                            label="Select Default Departments"
                            sx={{ width: "400px" }}
                          >
                            {allDept &&
                              allDept?.map((unit, unitIndex) => (
                                <MenuItem
                                  key={unitIndex}
                                  value={unit}
                                  disabled={isOptionDisabled(unit, skillIndex)}
                                >
                                  <Checkbox
                                    checked={(
                                      selectedDept[skillIndex] || []
                                    ).includes(unit)}
                                  />
                                  <ListItemText primary={unit} />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button
                className="add-dept-clrbtn"
                style={
                  !hasAtLeastOneDepartmentSelected()
                    ? disabledbuttoncontainded
                    : buttoncontainded
                }
                onClick={handleContinue}
                disabled={!hasAtLeastOneDepartmentSelected()}
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </>
  );
};
