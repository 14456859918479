import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../../baseurl";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  backBtnfixed,
  buttoncontaindedfixed,
  buttoncontaindedfixeddisabled,
} from "../../../styles";
import "../clientdashboard.css";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { GeneralTables } from "./GeneralTables";
import Slide from "@mui/material/Slide";
import { FrequencyFilter } from "../../../components/Filter/FrequencyFilter";
import { handleSaveDraft, handleSubmit } from "../ExtraFiles/formHandler";
import ClientLayout from "../../../components/ClientLayout/ClientLayout";
import encrypt, { decryptData } from "../../../services/crypto";
import {
  countryCodes,
  getAssignmentKPIData,
} from "../../../services/ClientSideApis";

import BasicModal from "../../../components/PopUpDialog/BasicModal";
import SimpleModal from "../../../components/PopUpDialog/SimpleModal";
import ConfirmModal from "../../../components/PopUpDialog/ConfirmModal";
import LinearProgressBar from "./ProgressBar/LinearProgressBar";
import { Loader } from "../../../components/Loader/Loader";
import { getUserEmail } from "../../../services/loggedIn";
import {
  MdNotifications,
  MdNotificationsNone,
  MdOutlineErrorOutline,
  MdRemoveRedEye,
} from "react-icons/md";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThankyouModal from "../../../components/PopUpDialog/ThankyouModal";

export const DataAccordionNew = () => {
  const [fdata, setData] = useState(null);
  const [monthData, setMonthData] = useState(null);
  const [yearData, setYearData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  console.log("data", data);

  const {
    savedCount,
    totalCount,
    orgplt,
    orgdept,
    approvedCount,
    rejectedCount,
  } = data;
  // // console.log("savedCount", savedCount);
  // // console.log("totalCount", totalCount);
  const [kpiinputdata, setKpiinputdata] = useState();
  const [tableRendering, setTableRendering] = useState();
  const [tableDraftRendering, setTableDraftRendering] = useState();
  const [formData, setFormData] = useState({});
  const [currentYears, setCurrentYears] = useState({});
  const [currentMonths, setCurrentMonths] = useState({});
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupProps, setPopupProps] = useState({});
  const [statusdata, setStatusdata] = useState({});
  const [editedFields, setEditedFields] = useState([]);
  const [popupload, setPopupLoad] = useState(false);
  const [filterOption, setFilterOption] = useState("all");
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [code, setCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [filledKpis, setFilledKpis] = useState(0);
  const [draftedKpis, setDraftedKpis] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalInView, setModalInView] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [kpiStatus, setKPIStatus] = useState({});
  const [openDesModal, setOpenDesModal] = useState(false);
  const [singalKpiData, setSingalKpiData] = useState();
  const [kpiIndex, setKpiIndex] = useState("");
  const [isReviewed, setIsReviewed] = useState();
  const [reviewedBy, setReviwedBy] = useState("");

  const handleOpenDecsModal = (KPIData, kpiIndex) => {
    setOpenDesModal(true);
    setSingalKpiData(KPIData);
    setKpiIndex(kpiIndex);
  };

  const handleAccordionOpenChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  console.log("setKpiinputdata", kpiinputdata);

  const openPopup = () => {
    setShowModal(true);
  };

  const handleNavigate = async () => {
    if (!confirm) {
      const returnedValue = await handleClick();
      if (returnedValue !== "0") {
        setShowModal(false);
        setFilledKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
    } else {
      setShowModal(false);
    }
  };

  const handleNavigateSaveDraft = async () => {
    if (!confirm) {
      const returnedValue = await handleClickDraft();
      if (returnedValue !== "0") {
        setShowModal(false);
        setDraftedKpis(returnedValue);
        setSecondModal(true);
        fetchData();
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
    } else {
      setShowModal(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await countryCodes();
        setCountryData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);

  const updateDrafTableData = [];
  const handleClick = () => {
    const returnValue = handleSubmit(
      updateDrafTableData,
      formData,
      fdata,
      currentYears,
      currentMonths,
      data,
      setLoading,
      setShowConfirmModal,
      setModalInView,
      modalInView,
      editedFields,
      code
    );
    // fetchData();
    console.log("------returnvalue----", returnValue);

    return returnValue;
  };

  const handleClickDraft = () => {
    const returnValue = handleSaveDraft(
      updateDrafTableData,
      formData,
      fdata,
      currentYears,
      currentMonths,
      data,
      setLoading,
      baseUrl,
      setIsSavingDraft,
      editedFields,
      code,
      true
    );
    return returnValue;
  };

  const closePopup = () => {
    setPopupOpen(false);
    setShowModal(false);
    setSecondModal(false);
    setShowConfirmModal(false);
    setModalInView(false);
    // fetchData();
  };

  const closeSimplePopup = () => {
    setSecondModal(false);
    navigate("/reporting/dashboard");
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const storedData = localStorage.getItem("draftData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setFormData(parsedData.formData || {});
      setCurrentYears(parsedData.currentYears || []);
      setCurrentMonths(parsedData.currentMonths || []);
      setEditedFields(parsedData.editedFields || []);
    } else {
      fetchData(); // Fetch data from backend if no draft data exists
    }
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = {
        operationalUnit: data.orgplt,
        department: data.orgdept,
        userEmail: getUserEmail(),
      };

      const encryptData = encrypt({ res });

      const response = await getAssignmentKPIData(encryptData);

      const decryptedData = decryptData(response.data.KPIData);
      const parsedData = JSON.parse(decryptedData);
      console.log("dataaccordion------", JSON.parse(decryptedData));

      const decryptedMonthData = decryptData(response.data.KPIMonthData);
      const parsedMonthData = JSON.parse(decryptedMonthData);

      const decryptedYearData = decryptData(response.data.KPIAnnualData);
      const parsedYearData = JSON.parse(decryptedYearData);

      setData(parsedData.themeBasedGrouped);
      setMonthData(parsedMonthData.monthDataGrouped);
      setYearData(parsedYearData.annualDataGrouped);

      const initialFormData = {};
      const initialCurrentYears = {};
      const initialCurrentMonths = {};
      const initialKPIStatus = {};
      const transformedData = {};

      // Process the fetched data
      Object.keys(parsedData.themeBasedGrouped).forEach((theme) => {
        parsedData.themeBasedGrouped[theme].forEach((entry) => {
          const kpiCode = entry.KPICode;
          const monthYear = entry.Month ? entry.Month : entry.Year;
          const status = entry.KPIStatus;

          if (!transformedData[kpiCode]) {
            transformedData[kpiCode] = [];
          }

          transformedData[kpiCode].push({ [monthYear]: status });
          initialKPIStatus[kpiCode] = status; // Store the KPI status
        });
      });
      setStatusdata(transformedData);

      Object.keys(parsedData.themeBasedGrouped).forEach((theme) => {
        parsedData.themeBasedGrouped[theme].forEach((detail) => {
          if (detail.KPICode) {
            if (
              detail.KPIStatus === "saved" &&
              detail.Format.includes("Table")
            ) {
              setTableRendering(detail.KPIInput);
            }

            if (
              detail.KPIStatus === "drafted" &&
              detail.Format.includes("Table")
            ) {
              // console.log("detail", detail.KPIStatus);
              setTableDraftRendering(detail.KPIInput);
            }
            if (detail.Year) {
              initialCurrentYears[detail.KPICode] = detail.Year;
            } else if (detail.Month) {
              initialCurrentMonths[detail.KPICode] = detail.Month;
            }
            initialFormData[detail.KPICode] = detail.KPIInput;
          }
          if (detail.isReviewed === true) {
            setIsReviewed(detail.isReviewed);
            setReviwedBy(detail.reviewedBy);
          }
        });
      });

      // Check for draft data in localStorage
      const storedData = localStorage.getItem("draftData");
      if (storedData) {
        const parsedStoredData = JSON.parse(storedData);

        // Merge the draft data with the fetched data
        Object.keys(parsedStoredData.formData).forEach((kpiCode) => {
          if (parsedStoredData.editedFields.includes(kpiCode)) {
            initialFormData[kpiCode] = parsedStoredData.formData[kpiCode];
            initialKPIStatus[kpiCode] = "drafted"; // Update status for drafted fields
          }
        });

        // Merge years and months
        Object.assign(initialCurrentYears, parsedStoredData.currentYears);
        Object.assign(initialCurrentMonths, parsedStoredData.currentMonths);
      }

      // Set the state with the merged data
      setFormData(initialFormData);
      setCurrentYears(initialCurrentYears);
      setCurrentMonths(initialCurrentMonths);
      setKPIStatus(initialKPIStatus); // Set the KPI statuses
    } catch (error) {
      // Set the error message as a string instead of the entire error object
      setError(error.message || "An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [data.orgplt, data.orgdept]);

  if (kpiinputdata) {
    const [tableFrequency, tableKPICode] =
      Object.keys(kpiinputdata)[0].split("~");
    const tableKPIInput = kpiinputdata[Object.keys(kpiinputdata)[0]];

    Object.values(fdata).forEach((themeArray) => {
      themeArray.forEach((detail) => {
        if (
          detail.KPICode === tableKPICode &&
          (detail.Year === tableFrequency || detail.Month === tableFrequency)
        ) {
          if (detail.Year) {
            currentYears[detail.KPICode] = detail.Year;
          } else if (detail.Month) {
            currentMonths[detail.KPICode] = detail.Month;
          }
          formData[detail.KPICode] = tableKPIInput;
        }
      });
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    //if we get multiple type of inputs convert it to switch
    if (name.startsWith("telephone+")) {
      // Handle the "Telephone" input type
      const kpiCode = name.split("+")[1];
      const newValue = {
        countryCode: code, // Use the selected country code
        number: value,
      };
      setFormData((prevState) => ({
        ...prevState,
        [kpiCode]: newValue,
      }));
    } else if (name.includes("+")) {
      const [type, kpiCode] = name.split("+");

      if (type === "x" || type === "y") {
        const ratioObject = formData[kpiCode] || { x: "", y: "" };
        const newValue = { ...ratioObject, [type]: value };

        setFormData((prevState) => ({
          ...prevState,
          [kpiCode]: newValue,
        }));
      }
    } else {
      // For other inputs, update the form data normally
      const newValue = value;
      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    }
  };

  //rahul
  const isFieldEdited = (kpiCode) => {
    // Check if the field is edited based on its KPI code
    return editedFields.includes(kpiCode);
  };

  //rahul
  const handleInputChange = (e, kpiCode) => {
    // Mark the field as edited when the input changes
    if (!isFieldEdited(kpiCode)) {
      setEditedFields([...editedFields, kpiCode]);
    }
    handleChange(e); // Call handleChange function to update formData state
  };

  // console.log("modal", modalInView);

  // Handle draft save on input blur
  const handleInputBlur = (e, kpiCode) => {
    const { name, value } = e.target;

    let newValue;

    // Handle the ratio input case
    if (name.includes("+")) {
      const [type] = name.split("+");

      if (type === "x" || type === "y") {
        const ratioObject = formData[kpiCode] || { x: "", y: "" };
        newValue = { ...ratioObject, [type]: value };
      } else {
        newValue = value;
      }
    } else {
      newValue = value;
    }

    // Update the form data with the new input value
    setFormData((prevData) => ({
      ...prevData,
      [kpiCode]: newValue,
    }));

    // Mark this field as edited, avoiding duplicates
    setEditedFields((prevFields) => {
      if (!prevFields.includes(kpiCode)) {
        return [...prevFields, kpiCode];
      }
      return prevFields;
    });

    // Retrieve any existing draft data from localStorage
    const storedData = JSON.parse(localStorage.getItem("draftData")) || {
      formData: {},
      currentYears: {},
      currentMonths: {},
      editedFields: [],
    };

    // Merge the new data with the existing data
    const mergedFormData = {
      ...storedData.formData,
      [kpiCode]: newValue,
    };

    const mergedCurrentYears = {
      ...storedData.currentYears,
      ...(currentYears[kpiCode] ? { [kpiCode]: currentYears[kpiCode] } : {}),
    };

    const mergedCurrentMonths = {
      ...storedData.currentMonths,
      ...(currentMonths[kpiCode] ? { [kpiCode]: currentMonths[kpiCode] } : {}),
    };

    const mergedEditedFields = [
      ...new Set([...storedData.editedFields, kpiCode]),
    ];

    // Save the merged data back to localStorage
    const currentTime = new Date().getTime();
    const dataToStore = {
      formData: mergedFormData,
      currentYears: mergedCurrentYears,
      currentMonths: mergedCurrentMonths,
      editedFields: mergedEditedFields,
      timestamp: currentTime,
    };
    localStorage.setItem("draftData", JSON.stringify(dataToStore));

    // Schedule the delayed submission
    scheduleDelayedSubmission();
  };

  const scheduleDelayedSubmission = () => {
    if (window.draftSubmissionTimeout) {
      clearTimeout(window.draftSubmissionTimeout);
    }

    window.draftSubmissionTimeout = setTimeout(() => {
      submitDraftData();
    }, 10 * 60 * 1000); // 10 minutes in milliseconds
  };

  const submitDraftData = () => {
    const storedData = localStorage.getItem("draftData");

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const currentTime = new Date().getTime();

      if (currentTime - parsedData.timestamp >= 10 * 60 * 1000) {
        const relevantYears = Object.fromEntries(
          Object.entries(parsedData.currentYears).filter(([key]) =>
            parsedData.editedFields.includes(key)
          )
        );

        const relevantMonths = Object.fromEntries(
          Object.entries(parsedData.currentMonths).filter(([key]) =>
            parsedData.editedFields.includes(key)
          )
        );

        handleSaveDraft(
          parsedData.formData,
          relevantYears,
          relevantMonths,
          setLoading,
          baseUrl,
          setIsSavingDraft,
          parsedData.editedFields,
          false
        );

        // Clear the stored data after submission
        localStorage.removeItem("draftData");
      } else {
        scheduleDelayedSubmission();
      }
    }
  };

  useEffect(() => {
    scheduleDelayedSubmission();

    return () => {
      if (window.draftSubmissionTimeout) {
        clearTimeout(window.draftSubmissionTimeout);
      }
    };
  }, []);

  const handleNextYear = (theme, kpiCode) => {
    const currentYearRange = currentYears[kpiCode].split("-");
    const startYear = parseInt(currentYearRange[0]);
    const endYear = parseInt(currentYearRange[1]);
    const nextStartYear = startYear + 1;
    const nextEndYear = endYear + 1;
    const nextYearRange = `${nextStartYear}-${nextEndYear}`;

    const nextYearData = fdata[theme].find(
      (item) =>
        item.KPICode === kpiCode &&
        item.Year &&
        parseInt(item.Year.split("-")[0]) === nextStartYear
    );

    if (nextYearData) {
      const newCurrentYears = { ...currentYears, [kpiCode]: nextYearRange };
      setCurrentYears(newCurrentYears);
      const newData = { ...formData, [kpiCode]: nextYearData.KPIInput };
      if (
        Array.isArray(nextYearData.KPIInput) &&
        nextYearData.KPIInput.length > 0
      ) {
        setTableRendering(nextYearData.KPIInput);
      }
      setFormData(newData);
    } else {
      showAlert(`No next year data available for ${kpiCode}.`);
    }
  };

  // Handle previous year navigation
  const handlePreviousYear = (theme, kpiCode) => {
    const currentYearRange = currentYears[kpiCode].split("-");
    const startYear = parseInt(currentYearRange[0]);
    const endYear = parseInt(currentYearRange[1]);
    const prevStartYear = startYear - 1;
    const prevEndYear = endYear - 1;
    const prevYearRange = `${prevStartYear}-${prevEndYear}`;

    const prevYearData = fdata[theme].find(
      (item) =>
        item.KPICode === kpiCode &&
        item.Year &&
        parseInt(item.Year.split("-")[0]) === prevStartYear
    );

    if (prevYearData) {
      const newCurrentYears = { ...currentYears, [kpiCode]: prevYearRange };
      setCurrentYears(newCurrentYears);
      const newData = { ...formData, [kpiCode]: prevYearData.KPIInput };
      if (
        Array.isArray(prevYearData.KPIInput) &&
        prevYearData.KPIInput.length > 0
      ) {
        setTableRendering(prevYearData.KPIInput);
      }
      setFormData(newData);
    } else {
      showAlert(`No previous year data available for ${kpiCode}.`);
    }
  };

  const getNextMonth = (currentMonth) => {
    const [monthAbbreviation, year] = currentMonth.split("'");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let monthIndex = monthNames.indexOf(monthAbbreviation);
    let nextYear = parseInt(year);

    monthIndex++; // Move to the next month

    if (monthIndex === 12) {
      // If next month is December, increment the year
      monthIndex = 0; // January
      nextYear++;
    }

    const nextMonthAbbreviation = monthNames[monthIndex];
    return `${nextMonthAbbreviation}'${nextYear.toString().slice(-2)}`;
  };

  const getPreviousMonth = (currentMonth) => {
    const [monthAbbreviation, year] = currentMonth.split("'");
    let monthIndex = monthNames.indexOf(monthAbbreviation);
    let prevYear = parseInt(year);

    monthIndex--; // Move to the previous month

    if (monthIndex === -1) {
      // If previous month is December, decrement the year
      monthIndex = 11; // December
      prevYear--;
    }

    const prevMonthAbbreviation = monthNames[monthIndex];
    return `${prevMonthAbbreviation}'${prevYear.toString().slice(-2)}`;
  };

  const hasPreviousMonth = (theme, kpiCode) => {
    const currentMonth = currentMonths[kpiCode];
    const [currentMonthAbbreviation, currentYear] = currentMonth.split("'");
    const currentMonthIndex = monthNames.indexOf(currentMonthAbbreviation);
    let prevYear = parseInt(currentYear);
    let prevMonthIndex = currentMonthIndex - 1;

    // If the current month is January, decrement the year
    if (prevMonthIndex === -1) {
      prevMonthIndex = 11; // December
      prevYear--;
    }

    const prevMonthAbbreviation = monthNames[prevMonthIndex];
    const prevMonth = `${prevMonthAbbreviation}'${prevYear
      .toString()
      .slice(-2)}`;

    return fdata[theme].some(
      (item) => item.KPICode === kpiCode && item.Month === prevMonth
    );
  };

  // Function to check if there is data available for the next month
  const hasNextMonth = (theme, kpiCode) => {
    const currentMonth = currentMonths[kpiCode];
    const [currentMonthAbbreviation, currentYear] = currentMonth.split("'");
    const currentMonthIndex = monthNames.indexOf(currentMonthAbbreviation);
    let nextYear = parseInt(currentYear);
    let nextMonthIndex = currentMonthIndex + 1;

    // If the current month is December, increment the year
    if (nextMonthIndex === 12) {
      nextMonthIndex = 0; // January
      nextYear++;
    }

    const nextMonthAbbreviation = monthNames[nextMonthIndex];
    const nextMonth = `${nextMonthAbbreviation}'${nextYear
      .toString()
      .slice(-2)}`;

    return fdata[theme].some(
      (item) => item.KPICode === kpiCode && item.Month === nextMonth
    );
  };

  const handleNextMonth = (theme, kpiCode) => {
    const currentMonth = currentMonths[kpiCode];
    const nextMonth = getNextMonth(currentMonth);
    const nextMonthData = fdata[theme].find(
      (item) => item.KPICode === kpiCode && item.Month === nextMonth
    );
    if (nextMonthData) {
      const newCurrentMonths = { ...currentMonths, [kpiCode]: nextMonth };
      setCurrentMonths(newCurrentMonths);
      const newData = { ...formData, [kpiCode]: nextMonthData.KPIInput };
      setFormData(newData);
    } else {
      showAlert(`No next month data available for ${kpiCode}.`);
    }
  };

  const handlePreviousMonth = (theme, kpiCode) => {
    const currentMonth = currentMonths[kpiCode];
    const prevMonth = getPreviousMonth(currentMonth);
    const prevMonthData = fdata[theme].find(
      (item) => item.KPICode === kpiCode && item.Month === prevMonth
    );
    if (prevMonthData) {
      const newCurrentMonths = { ...currentMonths, [kpiCode]: prevMonth };
      setCurrentMonths(newCurrentMonths);
      const newData = { ...formData, [kpiCode]: prevMonthData.KPIInput };
      setFormData(newData);
    } else {
      showAlert(`No previous month data available for ${kpiCode}.`);
    }
  };

  const showAlert = (message) => {
    toast.info(message);
  };

  if (loading) {
    return <Loader loading={loading} />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!fdata) {
    return null;
  }

  const hasMonthData = (theme, kpiCode) => {
    return fdata[theme].some(
      (item) => item.KPICode === kpiCode && item.Month !== undefined
    );
  };

  const hasYearData = (theme, code) => {
    return fdata[theme].some(
      (item) => item.KPICode === code && item.Year !== undefined
    );
  };

  const themes = Object.keys(fdata);
  // console.log("f-data",fdata);
  // console.log("themes------",themes)
  const getYearRange = (year) => {
    return `${year}`;
  };
  const hasPreviousYear = (theme, kpiCode) => {
    // console.log(" currentYears", currentYears);

    const currentYearRange = currentYears[kpiCode]?.split("-");
    const startYear = parseInt(currentYearRange[0]);
    const prevStartYear = startYear - 1;
    return fdata[theme].some(
      (item) =>
        item.KPICode === kpiCode &&
        item.Year &&
        parseInt(item.Year.split("-")[0]) === prevStartYear
    );
  };

  // Function to check if there is data available for the next year
  const hasNextYear = (theme, kpiCode) => {
    const currentYearRange = currentYears[kpiCode].split("-");
    const endYear = parseInt(currentYearRange[1]);
    const nextEndYear = endYear + 1;
    return fdata[theme].some(
      (item) =>
        item.KPICode === kpiCode &&
        item.Year &&
        parseInt(item.Year.split("-")[1]) === nextEndYear
    );
  };

  const getPreviousYearRange = (yearRange) => {
    if (!yearRange) return ""; // Return an empty string or handle it as per your requirement
    const [startYear, endYear] = yearRange.split("-");
    const prevStartYear = parseInt(startYear) - 1;
    const prevEndYear = parseInt(endYear) - 1;
    return `${prevStartYear}-${prevEndYear}`;
  };

  const getNextYearRange = (yearRange) => {
    if (!yearRange) return ""; // Return an empty string or handle it as per your requirement
    const [startYear, endYear] = yearRange.split("-");
    const nextStartYear = parseInt(startYear) + 1;
    const nextEndYear = parseInt(endYear) + 1;
    return `${nextStartYear}-${nextEndYear}`;
  };

  const getMonthYear = (month) => {
    const [monthName, year] = month.split("'");
    return { month: monthName, year: parseInt(year) };
  };
  const getPrevMonth = (currentMonth) => {
    const { month, year } = getMonthYear(currentMonth);
    const monthIndex = new Date(Date.parse(month + " 1, " + year)).getMonth();
    let prevYear = year; // Initialize previous year with the current year

    // If the current month is January, decrement the year
    if (monthIndex === 0) {
      prevYear -= 1;
    }

    const prevMonthIndex = monthIndex === 0 ? 11 : monthIndex - 1;
    const prevMonth = new Date(prevYear, prevMonthIndex).toLocaleDateString(
      "default",
      { month: "short" }
    );
    return `${prevMonth}'${prevYear.toString().slice(-2)}`;
  };

  const getMonth = (currentMonth) => {
    const { month, year } = getMonthYear(currentMonth);
    const monthIndex = new Date(Date.parse(month + " 1, " + year)).getMonth();
    let nextYear = year; // Initialize next year with the current year

    if (monthIndex === 11) {
      nextYear += 1;
    }

    const nextMonthIndex = monthIndex === 11 ? 0 : monthIndex + 1;
    const nextMonth = new Date(nextYear, nextMonthIndex).toLocaleDateString(
      "default",
      { month: "short" }
    );
    return `${nextMonth}'${nextYear.toString().slice(-2)}`;
  };

  // // console.log("fdata", fdata);

  const handleSavedTable = async (incommingTableForamt) => {
    // console.log("incommingTableForamt", incommingTableForamt);

    let filter = [];
    filledTable.map((table) => {
      if (
        table.Format === incommingTableForamt &&
        table.KPIStatus === "saved"
      ) {
        filter.push(table);
      }
    });

    if (filter.length > 0) {
      navigate("/reporting/saved-drafted", {
        state: {
          orgplt: orgplt,
          orgdept: orgdept,
          responseSaved: filter,
          tdata: incommingTableForamt,
        },
      });
    } else {
      toast.warning("No Saved Data Present");
    }
    // setPopupOpen(true);
    // setPopupProps({
    //   response: filter.length > 0 ? filter : filterDrated,
    //   tdata: incommingTableForamt,
    // });
  };

  const handleDraftTable = async (incommingTableForamt) => {
    let filterDrated = [];
    draftedTable.map((table, index) => {
      if (
        table.Format === incommingTableForamt &&
        table.KPIStatus === "drafted"
      ) {
        filterDrated.push(table);
      }
    });
    // console.log("filterDrated", filterDrated.length);

    if (filterDrated.length > 0) {
      navigate("/reporting/saved-drafted", {
        state: {
          orgplt: orgplt,
          orgdept: orgdept,
          responseDrafted: filterDrated,
          tdata: incommingTableForamt,
        },
      });
    } else {
      toast.warning("No Draft Data Present");
    }
  };

  let filledTable = [];
  let unfilledTables = [];
  let draftedTable = [];
  const uniqueKPICodes = new Set();
  themes.forEach((theme) => {
    if (fdata[theme]) {
      fdata[theme].forEach((detail, i) => {
        if (detail.Format.includes("Table")) {
          if (detail.KPIStatus === "saved") {
            filledTable.push(detail);
          }
          if (detail.KPIStatus === "drafted") {
            // console.log("detail", detail);
            draftedTable.push(detail);
          }
          if (detail.KPIStatus === "unfilled") {
            unfilledTables.push(detail);
          }
          // if (detail.KPIStatus === "drafted") {
          //   unfilledTables.push(detail);
          // }
        }
      });
    }
  });

  // console.log("====",filledTable)

  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    setFilterOption(selectedFilter);
  };

  let dataToMap;
  const draft = (detail) => {
    const status = currentMonths[detail.KPICode]
      ? statusdata[detail.KPICode].find((entry) =>
          entry.hasOwnProperty(currentMonths[detail.KPICode])
        )?.[currentMonths[detail.KPICode]]
      : statusdata[detail.KPICode].find((entry) =>
          entry.hasOwnProperty(currentYears[detail.KPICode])
        )?.[currentYears[detail.KPICode]];

    return status === "drafted";
  };
  const saved = (detail) => {
    if (currentMonths[detail.KPICode]) {
      return (
        statusdata[detail.KPICode].find((entry) =>
          entry.hasOwnProperty(currentMonths[detail.KPICode])
        )?.[currentMonths[detail.KPICode]] === "saved"
      );
    } else {
      return (
        statusdata[detail.KPICode].find((entry) =>
          entry.hasOwnProperty(currentYears[detail.KPICode])
        )?.[currentYears[detail.KPICode]] === "saved"
      );
    }
  };

  const renderNumbers = (KpiData) => {
    // console.log("KpiData", KpiData);
    const arrayLength = KpiData?.length;
    const savedCount = KpiData?.filter(
      (item) => item.KPIStatus === "saved"
    ).length;
    // console.log("savedCount/arraylength", `${savedCount} / ${arrayLength}`);
    return (
      <h3
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px 10px",
        }}
      >
        {savedCount ? savedCount : 0}/
        <span style={{ fontSize: "14px" }}>
          {arrayLength ? arrayLength : 0}
        </span>{" "}
      </h3>
    );
  };

  const renderWarningIcon = (KpiData) => {
    // console.log("KpiData", KpiData);

    const isReviewedTrue = KpiData?.filter(
      (singleKpi, index) =>
        // console.log("singleKpi", singleKpi.isReviewed === true)
        singleKpi.isReviewed === true && singleKpi.kpiStatus === "drafted"
    );

    console.log("isReviewedTrue", isReviewedTrue);

    return isReviewedTrue?.length > 0 ? (
      <Tooltip
        title="Contains Rejected KPI's"
        placement="left"
        PopperProps={{
          style: { zIndex: 1500 },
        }}
      >
        <MdOutlineErrorOutline
          style={{
            fontSize: "28px",
            color: "#ff9c00",
            cursor: "pointer",
          }}
        />
      </Tooltip>
    ) : null;
  };

  return (
    <div style={{ padding: "20px" }}>
      <ClientLayout>
        <form
          onSubmit={(e) => e.preventDefault()}
          className="client-dashboard-container"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "20px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 className="frame_heading">Fill KPI's for :</h1>
              <span
                style={{
                  color: "#45b653",
                  fontSize: "1.2rem",
                  fontWeight: 700,
                }}
              >{`${orgdept}`}</span>
              <span
                style={{
                  color: "#45b653",
                  fontSize: "1.2rem",
                  fontWeight: 700,
                }}
              >{`${orgplt}`}</span>
            </div>
            <div
              style={{
                width: "300px",
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <FrequencyFilter
                filterOption={filterOption}
                handleFilterChange={handleFilterChange}
              />
              <div
                className={
                  isReviewed === true &&
                  (approvedCount > 0 || rejectedCount > 0) &&
                  "profile-icon"
                }
              >
                <MdNotifications
                  style={{
                    fontSize: "28px",
                    color:
                      isReviewed === true &&
                      (approvedCount > 0 || rejectedCount > 0)
                        ? "#3079bd"
                        : "#000000",
                  }}
                />
                <div className="notification-icon">
                  {isReviewed === true &&
                    (approvedCount > 0 || rejectedCount > 0) && (
                      <div className="notify"></div>
                    )}
                </div>
                <div
                  style={{ width: "max-content" }}
                  className="profile-dropdown"
                >
                  <Box
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Approved">
                      <div
                        style={{
                          padding: "10px 20px",
                          background: "#e3f4f4",
                          borderRadius: "15px",
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          color: "gray",
                          margin: "0 10px",
                        }}
                      >
                        <ThumbUpIcon />
                        {approvedCount}
                        {/* 1 */}
                      </div>
                    </Tooltip>
                    <Tooltip title="Rejected">
                      <div
                        style={{
                          padding: "10px 20px",
                          background: "#ffd1dc",
                          borderRadius: "15px",
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          color: "gray",
                          margin: "0 10px",
                        }}
                      >
                        <ThumbDownIcon />
                        {rejectedCount}
                        {/* 2 */}
                      </div>
                    </Tooltip>
                  </Box>
                  <span style={{ color: "#8C8D91" }}>Reviewd By: </span>
                  <span>{reviewedBy}</span>
                </div>
              </div>
            </div>
          </Box>
          <Divider />
          <br />
          {themes.map((theme, key) => (
            <Accordion
              sx={{ position: "relative", zIndex: 0 }}
              key={key}
              className="accordion"
              expanded={expanded === key}
              onChange={handleAccordionOpenChange(key)}
            >
              <AccordionSummary
                sx={{ zIndex: 0 }}
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4>{theme.replaceAll("_", " ")}</h4>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",

                      gap: "20px",
                    }}
                  >
                    {renderWarningIcon(
                      filterOption === "annual"
                        ? yearData[theme]
                        : filterOption === "monthly"
                        ? monthData[theme]
                        : fdata[theme]
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "20px",
                        width: "285px",
                      }}
                    >
                      {
                        <LinearProgressBar
                          KpiData={
                            filterOption === "annual"
                              ? yearData[theme]
                              : filterOption === "monthly"
                              ? monthData[theme]
                              : fdata[theme]
                          }
                        />
                      }
                      {renderNumbers(
                        filterOption === "annual"
                          ? yearData[theme]
                          : filterOption === "monthly"
                          ? monthData[theme]
                          : fdata[theme]
                      )}
                    </Box>
                  </Box>
                </Box>
              </AccordionSummary>
              {
                ((dataToMap =
                  filterOption === "annual"
                    ? yearData[theme]
                    : filterOption === "monthly"
                    ? monthData[theme]
                    : fdata[theme]),
                dataToMap && dataToMap.length > 0 ? (
                  dataToMap.map((detail, i) => {
                    if (!uniqueKPICodes.has(detail.KPICode)) {
                      uniqueKPICodes.add(detail.KPICode);
                      return (
                        <>
                          <AccordionDetails
                            sx={{
                              borderRadius: "20px",
                              border:
                                detail.isReviewed === true &&
                                detail.KPIStatus === "approved"
                                  ? "1px solid #45b653"
                                  : detail.isReviewed === true &&
                                    detail.KPIStatus === "drafted"
                                  ? "1px solid red"
                                  : "",
                              backgroundColor:
                                detail.isReviewed === true &&
                                detail.KPIStatus === "approved"
                                  ? "#e3f4f4"
                                  : detail.isReviewed === true &&
                                    detail.KPIStatus === "drafted"
                                  ? "#ffd1dc"
                                  : "transparent",
                              margin: "12px 0px",
                            }}
                            key={i}
                          >
                            <>
                              <p
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  alignItems: "center",
                                }}
                              >
                                {i + 1}.{detail.KPIdata}{" "}
                                {detail.Description && (
                                  <>
                                    <MdRemoveRedEye
                                      style={{ fontSize: "18px" }}
                                      onClick={() =>
                                        handleOpenDecsModal(detail, i)
                                      }
                                    />{" "}
                                  </>
                                )}{" "}
                              </p>
                              {detail.KPICode &&
                                hasYearData(theme, detail.KPICode) &&
                                !detail.Format.includes("Table") && (
                                  <p>
                                    Year:{" "}
                                    {getYearRange(currentYears[detail.KPICode])}
                                  </p>
                                )}
                              {detail.KPICode &&
                                hasMonthData(theme, detail.KPICode) &&
                                !detail.Format.includes("Table") && (
                                  <p>Month: {currentMonths[detail.KPICode]}</p>
                                )}
                            </>

                            {/* {detail.Format.includes("Table") &&
                              console.log("detail", detail)} */}
                            {detail.Format.includes("Table") &&
                              detail.KPIStatus === "unfilled" &&
                              unfilledTables.map((table, key) => {
                                if (
                                  table.Format === detail.Format &&
                                  table.KPICode === detail.KPICode
                                ) {
                                  return (
                                    <React.Fragment key={key}>
                                      <br />
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                      >
                                        {table.Month
                                          ? `Month: ${table.Month}`
                                          : `Year: ${table.Year}`}
                                        <br />
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "20px",
                                          }}
                                        >
                                          <Button
                                            onClick={() =>
                                              handleSavedTable(detail.Format)
                                            }
                                            style={{
                                              border: "1px solid #3079bd",
                                              // background: "white",
                                            }}
                                          >
                                            View Previous Tables
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              handleDraftTable(detail.Format)
                                            }
                                            style={{
                                              border: "1px solid #3079bd",
                                              // background: "white",
                                            }}
                                          >
                                            Draft Tables
                                          </Button>
                                        </div>
                                      </div>
                                      <GeneralTables
                                        setTableFromat={table.Format}
                                        frequency={table?.Month || table?.Year}
                                        setKpiinputdata={setKpiinputdata}
                                        tableRendering={tableRendering}
                                        status={table.KPIStatus}
                                        kpicode={table.KPICode}
                                      />
                                    </React.Fragment>
                                  );
                                }
                              })}
                            {/* {detail.Format.includes("Table") &&
                              detail.KPIStatus === "drafted" &&
                              draftedTable.map((table, key) => {
                                console.log("table", table);
                                if (
                                  table.Format === detail.Format &&
                                  table.KPICode === detail.KPICode
                                ) {
                                  console.log("table status", table.KPIStatus);
                                  return (
                                    <React.Fragment key={key}>
                                      <>
                                        <div>
                                          <br />
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              width: "100%",
                                            }}
                                          >
                                            {table.Month
                                              ? `Month: ${table.Month}`
                                              : `Year: ${table.Year}`}
                                            <br />
                                          </div>
                                          <GeneralTables
                                            setTableFromat={table.Format}
                                            frequency={
                                              table?.Month || table?.Year
                                            }
                                            setKpiinputdata={setKpiinputdata}
                                            tableRendering={tableRendering}
                                            tableDraftRendering={
                                              tableDraftRendering
                                            }
                                            status={table.KPIStatus}
                                            kpicode={table.KPICode}
                                          />
                                        </div>
                                      </>
                                    </React.Fragment>
                                  );
                                }
                              })} */}

                            {detail.Format.includes("Table") && (
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                                marginTop="10px"
                              >
                                {/* <span
                                style={{
                                  flex: 1,
                                  color: "red",
                                }}
                              >
                                Previously filled tables can be viewed through the button
                              </span> */}

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "20px",
                                  }}
                                >
                                  <Button
                                    onClick={() =>
                                      handleSavedTable(detail.Format)
                                    }
                                    style={{
                                      border: "1px solid #3079bd",
                                      // background: "white",
                                    }}
                                  >
                                    View Previous Tables
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      handleDraftTable(detail.Format)
                                    }
                                    style={{
                                      border: "1px solid #3079bd",
                                      // background: "white",
                                    }}
                                  >
                                    Draft Tables
                                  </Button>
                                </div>
                              </Box>
                            )}

                            {/* {popupOpen ? (
                              <PopUpDialog
                                orgdept={orgdept}
                                orgplt={orgplt}
                                popupProps={popupProps}
                                closePopup={closePopup}
                                Transition={Transition}
                                i={i}
                              />
                            ) : (
                              <Loader loading={popupload} />
                            )} */}
                            {(() => {
                              const validateNumberInput = (value) => {
                                const regex =
                                  /^(^$)|(^(0(\.\d{0,4})?|([1-9]\d{0,12})(\.\d{0,4})?))$/;

                                // Test if the value matches the regex pattern
                                return regex.test(value);
                              };
                              const handleNumChange = (e) => {
                                const { name, value } = e.target;
                                if (validateNumberInput(value)) {
                                  handleChange(e);
                                  handleInputChange(e, detail.KPICode);
                                } else {
                                  toast.error(
                                    "Please enter a valid number which is not more that 13 of length and less then four decimal places."
                                  );
                                }
                              };
                              const validatePercentage = (value) => {
                                const regex =
                                  /^$|^(?:100(?:\.0{1,2})?|\d{0,2}(?:\.\d{0,2})?)$/;
                                // Test if the value matches the regex pattern
                                return regex.test(value);
                              };

                              const validatePhoneNumber = (number) => {
                                const phoneRegex = /^[+\d]+(?:[\s.-]*\d){10,}$/;
                                return phoneRegex.test(number);
                              };

                              const handlePhoneChange = (e) => {
                                const { name, value } = e.target;

                                if (validatePh(value)) {
                                  handleChange({
                                    target: {
                                      name,
                                      value,
                                    },
                                  });
                                  // handleInputChange({ target: { name, value } }, name.split("+")[1]); // You might want to handle this line according to your requirement
                                } else {
                                  toast.error("Please enter a valid number.");
                                }
                              };

                              function validatePh(e) {
                                const phoneRegex = /^\d{0,10}$/;
                                return phoneRegex.test(e);
                              }

                              // Function to handle percentage input change
                              const handlePercentageChange = (e) => {
                                const { name, value } = e.target;
                                if (validatePercentage(value)) {
                                  handleChange(e, detail.KPICode);
                                  handleInputChange(e, detail.KPICode);
                                } else {
                                  // Show error dynamically
                                  toast.error(
                                    "Please enter a valid percentage (greater than 0 and less than or equal to 100)."
                                  );
                                }
                              };
                              const validateURL = (value) => {
                                // const validateURL = (value) => {
                                const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                                const isValid = regex.test(value);
                                if (!isValid) {
                                  toast.error("Invalid URL format!");
                                }
                                return isValid;
                              };

                              const handleUrlChange = (e) => {
                                const { name, value } = e.target;
                                if (validateURL(value)) {
                                  handleChange(e);
                                  handleInputChange(e, detail.KPICode);
                                } else {
                                  toast.error("Please enter a valid URL.");
                                }
                              };

                              function validateWholeNumber(value) {
                                // Check if the entered value is a positive whole number
                                const isWholeNumber = /^\d{0,13}$/.test(value);

                                return isWholeNumber;
                              }

                              const handleWholeNumChange = (e) => {
                                const { name, value } = e.target;
                                if (validateWholeNumber(value)) {
                                  handleChange(e);
                                  handleInputChange(e, detail.KPICode);
                                } else {
                                  toast.error(
                                    "Please enter a valid whole number."
                                  );
                                }
                              };

                              const validateSpace = (value) => {
                                const noLeadingSpace =
                                  value === "" || /^[^\s].*/.test(value);
                                return noLeadingSpace;
                              };

                              const handleOpenTextChange = (e) => {
                                const { name, value } = e.target;
                                if (validateSpace(value)) {
                                  handleChange(e);
                                  handleInputChange(e, detail.KPICode);
                                } else {
                                  toast.error("No Leading Space Allowed.");
                                }
                              };

                              // Inside the component
                              if (detail.Format.includes("Table")) {
                                //above
                                // return "Table"
                              } else if (detail.Format.includes("Ratio (")) {
                                const n = detail.Format.split(/[()|]/);
                                const nue = n[1].trim();
                                const d = n[2].trim();
                                return (
                                  <>
                                    {detail.KPIStatus === "drafted" && (
                                      <h5
                                        style={{
                                          opacity: "0.5",
                                        }}
                                      >
                                        drafted
                                      </h5>
                                    )}

                                    {detail.KPIStatus === "saved" && (
                                      <h5
                                        style={{
                                          opacity: "0.5",
                                          paddingBottom: "6px",
                                        }}
                                      >
                                        saved
                                      </h5>
                                    )}
                                    <input
                                      type="number"
                                      value={formData[detail.KPICode]?.x || ""}
                                      style={{
                                        width: "16%",
                                        backgroundColor: draft(detail)
                                          ? "#CBD5C0"
                                          : saved(detail)
                                          ? "#EEEAEA"
                                          : "",
                                      }}
                                      onChange={(e) => {
                                        handleNumChange(e);
                                      }}
                                      placeholder={nue}
                                      name={`x+${detail.KPICode}`}
                                      disabled={saved(detail)}
                                    />
                                    <span> : </span>
                                    <input
                                      type="number"
                                      value={formData[detail.KPICode]?.y || ""}
                                      style={{
                                        width: "16%",
                                        backgroundColor: draft(detail)
                                          ? "#CBD5C0"
                                          : "white",
                                      }}
                                      onChange={(e) => {
                                        handleNumChange(e);
                                      }}
                                      placeholder={d}
                                      name={`y+${detail.KPICode}`}
                                      disabled={saved(detail)}
                                    />
                                  </>
                                );
                              } else {
                                switch (detail.Format) {
                                  case "Binary":
                                    return (
                                      <>
                                        {detail.KPIStatus === "drafted" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                            }}
                                          >
                                            drafted
                                          </h5>
                                        )}

                                        {detail.KPIStatus === "saved" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                              paddingBottom: "6px",
                                            }}
                                          >
                                            saved
                                          </h5>
                                        )}
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "20px",
                                          }}
                                        >
                                          <label
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              name={detail.KPICode}
                                              value="yes"
                                              checked={
                                                formData[detail.KPICode] ===
                                                "yes"
                                              }
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleInputChange(
                                                  e,
                                                  detail.KPICode
                                                );
                                              }}
                                              style={{
                                                backgroundcolor: draft(detail)
                                                  ? "#CBD5C0"
                                                  : saved(detail)
                                                  ? "#EEEAEA"
                                                  : "",
                                              }}
                                              disabled={saved(detail)}
                                              onBlur={(e) =>
                                                handleInputBlur(
                                                  e,
                                                  detail.KPICode
                                                )
                                              }
                                            />
                                            <span
                                              style={{ paddingLeft: "8px" }}
                                            >
                                              Yes
                                            </span>
                                          </label>
                                          <label
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              value="no"
                                              name={detail.KPICode}
                                              checked={
                                                formData[detail.KPICode] ===
                                                "no"
                                              }
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleInputChange(
                                                  e,
                                                  detail.KPICode
                                                );
                                              }}
                                              style={{
                                                backgroundColor: draft(detail)
                                                  ? "#CBD5C0"
                                                  : saved(detail)
                                                  ? "#EEEAEA"
                                                  : "",
                                              }}
                                              disabled={saved(detail)}
                                              onBlur={(e) =>
                                                handleInputBlur(
                                                  e,
                                                  detail.KPICode
                                                )
                                              }
                                            />
                                            <span
                                              style={{ paddingLeft: "8px" }}
                                            >
                                              No
                                            </span>
                                          </label>
                                        </div>
                                      </>
                                    );
                                  case "Number":
                                    return (
                                      <>
                                        {detail.KPIStatus === "drafted" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                            }}
                                          >
                                            drafted
                                          </h5>
                                        )}

                                        {detail.KPIStatus === "saved" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                              paddingBottom: "6px",
                                            }}
                                          >
                                            saved
                                          </h5>
                                        )}
                                        <input
                                          type="text"
                                          name={detail.KPICode}
                                          value={formData[detail.KPICode]}
                                          placeholder={detail.Format}
                                          className="client-form-input"
                                          style={{
                                            width: "16%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                              ? "#EEEAEA"
                                              : "",
                                          }}
                                          step="0.01"
                                          onChange={(e) => {
                                            handleNumChange(e);
                                          }}
                                          disabled={saved(detail)}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />
                                      </>
                                    );
                                  case "Currency":
                                    return (
                                      <>
                                        {detail.KPIStatus === "drafted" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                            }}
                                          >
                                            drafted
                                          </h5>
                                        )}

                                        {detail.KPIStatus === "saved" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                              paddingBottom: "6px",
                                            }}
                                          >
                                            saved
                                          </h5>
                                        )}
                                        <input
                                          type="text"
                                          name={detail.KPICode}
                                          value={formData[detail.KPICode]}
                                          placeholder={detail.Format}
                                          className="client-form-input"
                                          style={{
                                            width: "16%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                              ? "#EEEAEA"
                                              : "",
                                          }}
                                          step="0.01"
                                          onChange={(e) => {
                                            handleNumChange(e);
                                          }}
                                          disabled={saved(detail)}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />
                                        <span>
                                          {localStorage.getItem(
                                            "countryCurrency"
                                          )}{" "}
                                        </span>
                                      </>
                                    );
                                  case "MCQ":
                                    return (
                                      <div>
                                        {detail.KPIStatus === "drafted" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                            }}
                                          >
                                            drafted
                                          </h5>
                                        )}

                                        {detail.KPIStatus === "saved" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                              paddingBottom: "6px",
                                            }}
                                          >
                                            saved
                                          </h5>
                                        )}
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                          }}
                                        >
                                          {detail.Remarks
                                            ? detail.Remarks.split(",").map(
                                                (option, index) => (
                                                  <label
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      gap: "3px",
                                                    }}
                                                    key={index}
                                                  >
                                                    <input
                                                      type="radio"
                                                      name={detail.KPICode}
                                                      value={option}
                                                      checked={
                                                        formData[
                                                          detail.KPICode
                                                        ] === option
                                                      }
                                                      onChange={(e) => {
                                                        handleChange(e);
                                                        handleInputChange(
                                                          e,
                                                          detail.KPICode
                                                        );
                                                      }}
                                                      disabled={saved(detail)}
                                                      style={{
                                                        width: "16%",
                                                        marginRight: "5px",
                                                        backgroundColor: draft(
                                                          detail
                                                        )
                                                          ? "#CBD5C0"
                                                          : saved(detail)
                                                          ? "#EEEAEA"
                                                          : "",
                                                      }}
                                                      onBlur={(e) =>
                                                        handleInputBlur(
                                                          e,
                                                          detail.KPICode
                                                        )
                                                      }
                                                    />
                                                    {option}
                                                  </label>
                                                )
                                              )
                                            : "No remark from Backend"}
                                        </div>
                                      </div>
                                    );
                                  case "Percent":
                                    return (
                                      <>
                                        {detail.KPIStatus === "drafted" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                            }}
                                          >
                                            drafted
                                          </h5>
                                        )}
                                        {detail.KPIStatus === "saved" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                              paddingBottom: "6px",
                                            }}
                                          >
                                            saved
                                          </h5>
                                        )}
                                        <input
                                          type="percent"
                                          name={detail.KPICode}
                                          value={
                                            formData[detail.KPICode]
                                              ? formData[
                                                  detail.KPICode
                                                ].replace("%", "")
                                              : ""
                                          }
                                          disabled={saved(detail)}
                                          placeholder={detail.Format}
                                          style={{
                                            width: "8%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                              ? "#EEEAEA"
                                              : "",
                                          }}
                                          min="0"
                                          max="100"
                                          onChange={(e) => {
                                            handlePercentageChange(e);
                                          }}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />{" "}
                                        %
                                      </>
                                    );
                                  case "Ratio":
                                    return (
                                      <>
                                        {detail.KPIStatus === "drafted" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                            }}
                                          >
                                            drafted
                                          </h5>
                                        )}

                                        {detail.KPIStatus === "saved" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                              paddingBottom: "6px",
                                            }}
                                          >
                                            saved
                                          </h5>
                                        )}
                                        <input
                                          type="number"
                                          value={
                                            formData[detail.KPICode]?.x || ""
                                          }
                                          style={{
                                            width: "16%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                              ? "#EEEAEA"
                                              : "",
                                          }}
                                          onChange={(e) => {
                                            handleNumChange(e);
                                          }}
                                          placeholder="Numerator"
                                          name={`x+${detail.KPICode}`}
                                          disabled={saved(detail)}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />
                                        <span> : </span>
                                        <input
                                          type="number"
                                          value={
                                            formData[detail.KPICode]?.y || ""
                                          }
                                          style={{
                                            width: "16%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                              ? "#EEEAEA"
                                              : "",
                                          }}
                                          onChange={(e) => {
                                            handleNumChange(e);
                                          }}
                                          placeholder="Denominator"
                                          name={`y+${detail.KPICode}`}
                                          disabled={saved(detail)}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />
                                      </>
                                    );
                                  case "Telephone":
                                    let parsedData;
                                    if (
                                      formData[detail.KPICode] !== undefined &&
                                      formData[detail.KPICode] !== null
                                    ) {
                                      try {
                                        parsedData = JSON.parse(
                                          formData[detail.KPICode]
                                        );
                                      } catch (error) {
                                        console.error(
                                          "Failed to parse JSON:",
                                          error
                                        );
                                        parsedData = {};
                                      }
                                    } else {
                                      parsedData = {};
                                    }
                                    // // console.log("Parsed Data", parsedData);
                                    const number = parsedData?.number || "";
                                    const countryCode =
                                      parsedData?.countryCode || "";
                                    return (
                                      <>
                                        {detail.KPIStatus === "drafted" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                            }}
                                          >
                                            drafted
                                          </h5>
                                        )}

                                        {detail.KPIStatus === "saved" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                              paddingBottom: "6px",
                                            }}
                                          >
                                            saved
                                          </h5>
                                        )}
                                        <span>
                                          <select
                                            value={countryCode || code}
                                            onChange={(e) =>
                                              setCode(e.target.value)
                                            }
                                            style={{
                                              width: "180px",
                                              backgroundColor: draft(detail)
                                                ? "#CBD5C0"
                                                : saved(detail)
                                                ? "#EEEAEA"
                                                : "",
                                            }}
                                            disabled={saved(detail)}
                                            onBlur={(e) =>
                                              handleInputBlur(e, detail.KPICode)
                                            }
                                          >
                                            <option value="">
                                              Select country
                                            </option>
                                            {Object.entries(countryData).map(
                                              ([country, code]) => (
                                                <option
                                                  onBlur={(e) =>
                                                    handleInputBlur(
                                                      e,
                                                      detail.KPICode
                                                    )
                                                  }
                                                  key={country}
                                                  value={code}
                                                >
                                                  {country} {"(" + code + ")"}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          {"  "}
                                          <input
                                            type="text"
                                            name={detail.KPICode}
                                            value={
                                              number
                                                ? number
                                                : formData[detail.KPICode]
                                            }
                                            style={{
                                              width: "16%",
                                              backgroundColor: draft(detail)
                                                ? "#CBD5C0"
                                                : saved(detail)
                                                ? "#EEEAEA"
                                                : "",
                                            }}
                                            placeholder="Telephone"
                                            disabled={saved(detail)}
                                            onChange={(e) =>
                                              handlePhoneChange(e)
                                            }
                                            onBlur={(e) =>
                                              handleInputBlur(e, detail.KPICode)
                                            }
                                          />
                                        </span>
                                      </>
                                    );

                                  case "url":
                                    return (
                                      <>
                                        {detail.KPIStatus === "drafted" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                            }}
                                          >
                                            drafted
                                          </h5>
                                        )}

                                        {detail.KPIStatus === "saved" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                              paddingBottom: "6px",
                                            }}
                                          >
                                            saved
                                          </h5>
                                        )}
                                        <input
                                          type="text"
                                          value={
                                            formData[detail.KPICode]?.y || ""
                                          }
                                          placeholder="Enter URL"
                                          style={{
                                            width: "16%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                              ? "#EEEAEA"
                                              : "",
                                          }}
                                          onChange={(e) => {
                                            const { value } = e.target;
                                            if (validateURL(value)) {
                                              handleUrlChange(e);
                                            }
                                          }}
                                          name={`y+${detail.KPICode}`}
                                          disabled={saved(detail)}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />
                                      </>
                                    );
                                  case "wholenumber":
                                    return (
                                      <>
                                        {detail.KPIStatus === "drafted" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                            }}
                                          >
                                            drafted
                                          </h5>
                                        )}

                                        {detail.KPIStatus === "saved" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                              paddingBottom: "6px",
                                            }}
                                          >
                                            saved
                                          </h5>
                                        )}
                                        <input
                                          type="text"
                                          name={detail.KPICode}
                                          value={formData[detail.KPICode]}
                                          placeholder={detail.Format}
                                          className="client-form-input"
                                          style={{
                                            width: "16%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                              ? "#EEEAEA"
                                              : "",
                                          }}
                                          step="0.01"
                                          onChange={(e) => {
                                            handleWholeNumChange(e);
                                          }}
                                          disabled={saved(detail)}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />
                                      </>
                                    );
                                  default:
                                    return (
                                      <>
                                        {detail.KPIStatus === "drafted" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                            }}
                                          >
                                            drafted
                                          </h5>
                                        )}

                                        {detail.KPIStatus === "saved" && (
                                          <h5
                                            style={{
                                              opacity: "0.5",
                                              paddingBottom: "6px",
                                            }}
                                          >
                                            saved
                                          </h5>
                                        )}
                                        <div>
                                          <textarea
                                            name={detail.KPICode}
                                            onChange={(e) => {
                                              handleOpenTextChange(e);
                                            }}
                                            placeholder={"Enter the response"}
                                            value={
                                              formData[detail.KPICode] || ""
                                            }
                                            rows="8"
                                            // cols="230"
                                            style={{
                                              backgroundColor: draft(detail)
                                                ? "#CBD5C0"
                                                : saved(detail)
                                                ? "#EEEAEA"
                                                : "",
                                              maxWidth: "100%",
                                              minWidth: "100%",
                                            }}
                                            disabled={saved(detail)}
                                            onBlur={(e) =>
                                              handleInputBlur(e, detail.KPICode)
                                            }
                                          />
                                        </div>
                                      </>
                                    );
                                }
                              }
                            })()}
                            <div>
                              {!detail.Format.includes("Table") &&
                                hasYearData(theme, detail.KPICode) && (
                                  <div>
                                    <Button
                                      onClick={() =>
                                        handlePreviousYear(
                                          theme,
                                          detail.KPICode
                                        )
                                      }
                                      disabled={
                                        !hasPreviousYear(theme, detail.KPICode)
                                      }
                                    >
                                      {getPreviousYearRange(
                                        currentYears[detail.KPICode]
                                      )}
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleNextYear(theme, detail.KPICode)
                                      }
                                      disabled={
                                        !hasNextYear(theme, detail.KPICode)
                                      }
                                    >
                                      {getNextYearRange(
                                        currentYears[detail.KPICode]
                                      )}
                                    </Button>
                                  </div>
                                )}
                              <div>
                                {!detail.Format.includes("Table") &&
                                  hasMonthData(theme, detail.KPICode) && (
                                    <>
                                      <Button
                                        onClick={() =>
                                          handlePreviousMonth(
                                            theme,
                                            detail.KPICode
                                          )
                                        }
                                        disabled={
                                          !hasPreviousMonth(
                                            theme,
                                            detail.KPICode
                                          )
                                        }
                                      >
                                        {getPrevMonth(
                                          currentMonths[detail.KPICode]
                                        )}
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          handleNextMonth(theme, detail.KPICode)
                                        }
                                        disabled={
                                          !hasNextMonth(theme, detail.KPICode)
                                        }
                                      >
                                        {getMonth(
                                          currentMonths[detail.KPICode]
                                        )}
                                      </Button>
                                    </>
                                  )}
                              </div>
                            </div>
                          </AccordionDetails>
                        </>
                      );
                    }
                    return null;
                  })
                ) : (
                  <p
                    style={{
                      backgroundColor: "#FF6969",
                      borderRadius: "8px",
                      margin: "20px",
                      padding: "10px",
                      color: "white",
                    }}
                  >
                    No data available for {filterOption} filter
                  </p>
                ))
              }
            </Accordion>
          ))}
          {error && <div>{error}</div>}
          <Button
            type="button"
            onClick={() => navigate(-1)}
            style={backBtnfixed}
          >
            Back
          </Button>

          {savedCount !== totalCount && (
            <Button
              type="button"
              onClick={openPopup}
              style={
                savedCount === totalCount
                  ? buttoncontaindedfixeddisabled
                  : buttoncontaindedfixed
              }
              disabled={savedCount === totalCount}
            >
              Next
            </Button>
          )}

          {/* <Button
            type="button"
            onClick={handleClickDraft}
            style={buttoncontaindeddraft}
          >
            Save Draft
          </Button> */}
        </form>
        <Loader loading={loading} />

        {showModal && (
          <BasicModal
            message="Please select the action to be performed."
            closePopup={closePopup}
            openPopup={openPopup}
            handleNavigate={handleNavigate}
            confirm={confirm}
            setConfirm={setConfirm}
            handleClickDraft={handleClickDraft}
            handleNavigateSaveDraft={handleNavigateSaveDraft}
          />
        )}
        {secondModal && (
          <SimpleModal
            openPopup={secondModal}
            message={`Good Job! You have filled ${filledKpis} response`}
            draftMessage={`Good Job! You have Draft ${draftedKpis} response`}
            closeSimplePopup={closeSimplePopup}
            closePopup={closePopup}
          />
        )}

        {showConfirmModal && (
          <ConfirmModal
            open={showConfirmModal}
            title={"Confirm Submitting"}
            onClose={closePopup}
            message={"Draft Data will also we saved after confirming"}
            confirmText={"confirm"}
            cancelText={"cancel"}
            onConfirm={handleNavigate}
          />
        )}

        {openDesModal && (
          <ThankyouModal
            closePopup={() => setOpenDesModal(false)}
            openPopup={handleOpenDecsModal}
            handleNavigate={() => setOpenDesModal(false)}
            message={""}
            title={""}
            confirmText={""}
            singalKpiData={singalKpiData}
            kpiIndex={kpiIndex}
          />
        )}
      </ClientLayout>
    </div>
  );
};
