import { Card, Tooltip } from "@mui/material";
import React from "react";
import { BsInfoCircle } from "react-icons/bs";

const FrameWorkCard = ({
  frameworkData,
  selectedCategories,
  handleCategoryCheckboxChange,
  handleSubcategoryCheckboxChange,
  selectedSubcategories,
  handleCheckboxChange,
  selectedData,
}) => {
  return (
    <div className="pillar-container">
      {Object.keys(frameworkData).map((category, index) => (
        <div
          key={index}
          className={
            category === "Governance"
              ? "pillar-cards pink"
              : category === "Environment"
              ? "pillar-cards env-bg"
              : category === "Multiple"
              ? "pillar-cards esg"
              : category === "General Disclosures"
              ? "pillar-cards orange1"
              : category === "Management and Process Disclosures"
              ? "pillar-cards teal"
              : "pillar-cards blue1"
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <label
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                className="pillar-checkbox"
                onChange={() => handleCategoryCheckboxChange(category)}
                checked={selectedCategories.includes(category) || false}
              />
              {category}
            </label>
            <Tooltip
              title="Pillar"
              arrow
              placement="top"
              TransitionProps={{ timeout: 500 }}
            >
              <div>
                <BsInfoCircle />
              </div>
            </Tooltip>
          </div>

          {selectedCategories.includes(category) && (
            <>
              <Card
                style={{
                  padding: "10px",
                  margin: "10px 0",
                  backgroundColor: "rgb(227, 249, 225)",
                  position: "relative",
                }}
                variant="outlined"
              >
                {/* <br /> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "end",
                    position: "absolute",
                    right: "10px",
                  }}
                >
                  <Tooltip
                    title="Theme"
                    arrow
                    placement="top"
                    TransitionProps={{ timeout: 500 }}
                  >
                    <div>
                      <BsInfoCircle />
                    </div>
                  </Tooltip>
                </div>

                {Object.entries(frameworkData[category]).map(
                  ([subcategory, elements], index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems:"flex-start",
                        justifyContent:"flex-start",
                        width: "100%",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <input
                          type="checkbox"
                          className="pillar-checkbox"
                          onChange={() =>
                            handleSubcategoryCheckboxChange(
                              category,
                              subcategory
                            )
                          }
                          disabled={elements.length === 0}
                          checked={
                            selectedSubcategories[category]?.includes(
                              subcategory
                            ) || false
                          }
                        />
                        {subcategory}
                      </div>
                      {selectedSubcategories[category]?.includes(subcategory) &&
                        (elements.length > 0 ? (
                          <Card
                            style={{
                              margin: "10px 0",
                              width: "90%",
                              padding: "10px",
                              backgroundColor: "rgb(252, 243, 237)",
                              position: "relative",
                              display:'flex',
                              flexDirection:"column",
                              alignItems:"flex-start",
                              justifyContent:"flex-start"
                            }}
                            variant="outlined"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "end",
                                position: "absolute",
                                right: "5px",
                              }}
                            >
                              <Tooltip
                                title="Topic"
                                arrow
                                placement="top"
                                TransitionProps={{ timeout: 500 }}
                              >
                                <div>
                                  <BsInfoCircle />
                                </div>
                              </Tooltip>
                            </div>
                            {elements.map((element, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "flex-start",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  className="pillar-checkbox"
                                  onChange={() =>
                                    handleCheckboxChange(
                                      category,
                                      subcategory,
                                      element
                                    )
                                  }
                                  checked={
                                    selectedData[category]?.[subcategory]?.[
                                      element
                                    ] || false
                                  }
                                />
                                {element}
                              </div>
                            ))}
                          </Card>
                        ) : (
                          <span
                            style={{
                              textAlign: "center",
                              color: "red",
                              margin: "5px 0 10px",
                            }}
                          >
                            No topics available
                          </span>
                        ))}
                    </div>
                  )
                )}
              </Card>
            </>
          )}
          {/* / */}
        </div>
      ))}
    </div>
  );
};

export default FrameWorkCard;
