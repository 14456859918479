import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import {FaUserAlt} from "react-icons/fa";
import Typography from "@mui/material/Typography";

export const UserDetails = ({ user, handleClose }) => {
  const operationalUnitsData =
    user.operationalUnits != "N/A" ? JSON.parse(user.operationalUnits) : null;
  const allPlants = operationalUnitsData
    ? Array.from(
        new Set(operationalUnitsData.map((item) => Object.keys(item)[0]))
      ).join(", ")
    : "N/A";

  // Extracting all unique categories
  const allCategories = !operationalUnitsData
    ? null
    : Array.from(
        new Set(
          operationalUnitsData
            .flatMap((item) => Object.values(item)[0])
            .flatMap((categories) => categories.split(","))
            .map((category) => category.trim())
        )
      ).join(",");

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <FaUserAlt style={{ fontSize: "2rem", margin: "0 10px" }} />
          &nbsp;{user.userName}
        </DialogTitle>
        <IconButton
          aria-label="close"
          // onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {/* <CloseIcon /> */}
        </IconButton>
        <DialogContent style={{ height: "450px", width: "450px" }} dividers>
          <br />

          <Typography gutterBottom>
            <strong>User Id : </strong> {user.userId}
          </Typography>
          <Typography gutterBottom>
            <strong>User Name : </strong> {user.userName}
          </Typography>
          <Typography gutterBottom>
            <strong>Email Id : </strong> {user.email}
          </Typography>
          <Typography gutterBottom>
            <strong>Mobile Number: </strong> {user.countryCode} {user.mobile}
          </Typography>
          <br />
          <Typography gutterBottom>
            <strong>Departments : </strong>
            {allCategories ? allCategories : "N/A"}
          </Typography>
          <Typography gutterBottom>
            <strong>Operational Units : </strong> {allPlants}
          </Typography>
          <br />
          <Typography gutterBottom>
            <strong>Organization : </strong> {user.organization}
          </Typography>
          <Typography gutterBottom>
            <strong>Admin Rights : </strong> {user.adminRights}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
