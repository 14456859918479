import {Box, Card, CardContent, Container, Grid, Typography} from "@mui/material";
import React from "react";
import {ENVINT_BLUE} from "../../library/constants";

const camelCaseToReadable = (str) => {
  if (str.includes('_')) {
    return str
      .split('_')
      .filter(Boolean)
      .map((part, index) => index === 0 ? part : part.replace(/^./, (char) => char.toUpperCase()))
      .join(' ');
  } else {
    return str
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (char) => char.toUpperCase());
  }
};

const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const DetailsCard = ({details, heading, isSmallScreen}) => {
  const CardStyle = {
    textAlign: "left",
    padding: isSmallScreen ? "1rem 1.5rem" : "1.5rem 3.5rem",
    display: "flex",
    flexDirection: isSmallScreen ? "column" : "row",
    alignItems: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "1rem",
  };

  const headingStyle = {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: ENVINT_BLUE,
    margin: "0.5rem 3rem 0.5rem 0",
  };

  const detailChunks = chunkArray(Object.entries(details), 4);

  return (
    <Card sx={{borderRadius: "1rem", marginBottom: "20px"}} elevation={3}>
      <CardContent sx={CardStyle}>
        <Box>
          <Typography variant="h5" sx={headingStyle}>
            {heading || "Basic Details"}
          </Typography>
        </Box>
        <Container>
          <Grid container spacing={2}>
            {detailChunks.map((chunk, index) => (
              <Grid item xs={12} md={6} key={index}>
                {chunk.map(([key, value]) => (
                  <Typography variant="body2" color="text.secondary" key={key}>
                    {camelCaseToReadable(key)}: {value}
                  </Typography>
                ))}
              </Grid>
            ))}
          </Grid>
        </Container>
      </CardContent>
    </Card>
  );
};

export default DetailsCard;