import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const activeStyle = {
  color: "#3079bd",
  fontWeight: "bold",
};

const CustomNavLink = ({
  to,
  children,
  isSpocNavbar,
  isClickable = true,
  isTrackerMenu = false,
}) => {
  const location = useLocation();

  const isActive = (path) => {
    if (path === "/") {
      return location.pathname === path;
    }

    if (isSpocNavbar) {
      const currentPath = location.pathname;
      return currentPath.startsWith(to);
    } else if (isTrackerMenu) {
      // Check if current path starts with /dashboard/tracker
      return location.pathname.startsWith("/dashboard/tracker");
    } else {
      // Split both the current path and the link path
      const currentPathParts = location.pathname.split("/").filter(Boolean);
      const linkPathParts = path.split("/").filter(Boolean);

      // Check if the first part matches and the lengths are the same
      return (
        currentPathParts[0] === linkPathParts[0] &&
        currentPathParts.length === linkPathParts.length
      );
    }
  };

  const commonStyle = {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    justifyContent: "center",
    color: "gray",
    ...(isActive(to) ? activeStyle : {}),
  };

  if (isSpocNavbar && !isClickable) {
    return (
      <div
        style={{
          ...commonStyle,
          cursor: "default",
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <NavLink to={to} style={() => commonStyle}>
      {children}
    </NavLink>
  );
};

export default CustomNavLink;
