// Import React and other dependencies
import React, {useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {Button, TextField} from "@mui/material";
import {buttoncontainded, inputfieldstyle} from "../../styles";
import {toast} from "react-toastify";
import {baseUrl} from "../../baseurl";

function ForgotPasswords(prop) {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  function handleSubmitEmail(e) {
    e.preventDefault();
    const json = {
      userKey: email,
    };
    axios
      .post(`${baseUrl}/forgotpassword`, json)
      .then((response) => {
        if (response.data) {
          localStorage.setItem("email", email);
          setEmail("");
          prop.clcike("FrogotPassword");
          toast.success("Sent Email");
        } else {
          // alert(response.data.message);
          toast.success(response.data.message);
          prop.clcike("New Password");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.message);
      });
  }
  return (
    <div className="form-container sign-up-container">
      <form onSubmit={handleSubmitEmail} className="form-l">
        <TextField
          label="Registered Email"
          variant="outlined"
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
          style={inputfieldstyle}
          required
        />
        {email ? (
          <Button
            className="button-s"
            id="signIn"
            onClick={handleSubmitEmail}
            variant="contained"
            style={buttoncontainded}
          >
            Send Otp
          </Button>
        ) : (
          <Button
            className="button-s"
            onClick={() => prop.clcike("ForgotPassword")}
            variant="contained"
            style={buttoncontainded}
          >
            Send Otp
          </Button>
        )}
      </form>
    </div>
  );
}

export default ForgotPasswords;
