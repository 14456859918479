import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../baseurl";
import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import "./organization.css";
import { buttoncontaindedfixed, inputfieldstyle1 } from "../../styles";
import Hamburger from "../../components/Navbars/Hamburger.js";
import { decryptData } from "../../services/crypto.js";
import {
  fetchAllOrganizations,
  getAllOrganizations,
} from "../../services/OrganizationManagemnetApi.js";
import { toast } from "react-toastify";
import MultiStepAssignManager from "../../pages/MultiStepAssignManager/MultiStepAssignManager.jsx";
import OrganizationCards from "../AssignmentManager/OrganizationCards.jsx";
import { Loader } from "../Loader/Loader.jsx";
import { getOrg, getUserEmail, getUserRole } from "../../services/loggedIn";
import { getAllManagersAndConsultant } from "../../services/CommonApis.js";
import { filterOrganizationsForUser } from "../../services/helpingFunctions.js";

export const Organizations = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedOption = location.state?.selectedOption;
  const selectedData0 = location.state?.selectedData;
  const selectedCategories = location.state?.selectedCategories;
  const selectedSubcategories = location.state?.selectedSubcategories;
  // // console.log("sele", location.state);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [org, setOrg] = useState({
    organizationEmail: "",
    name: "",
  });
  const [search, setSearch] = useState("");
  const [selectedOrg, setSelectedOrg] = useState("");
  const [filtereOrgData, setFilteredOrgData] = useState([]);

  useEffect(() => {
    if (location.state?.org?.org) {
      const organizationName = location.state?.org?.org?.name;
      const organizationObject = organizations.find(
        (org) => org.organizationName === organizationName
      );

      if (organizationObject) {
        setOrg({
          organizationEmail: organizationObject.organizationEmail,
          name: organizationObject.organizationName,
        });
        setSelectedOrg(organizationObject.organizationEmail);
      }
    }
  }, [location.state, organizations]);
/*
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        setLoading(true);
        const organizationName = getOrg();
        // Execute both API calls simultaneously
        const [usersResponse, organizationsResponse] = await Promise.all([
          getAllManagersAndConsultant(),
          fetchAllOrganizations(),
        ]);

        // Process users data
        const decryptedUsersData = decryptData(
          usersResponse.data.encryptedData
        );
        const parsedUsersData = JSON.parse(decryptedUsersData);

        const decryptedData = decryptData(organizationsResponse.data);
        const parsedData = JSON.parse(decryptedData);
        console.log("parsedData", parsedData);
        const filteredOrganizations = parsedData.unmarshalledData.filter(
          (x) => x.deleteStatus === false
        );
        console.log(filteredOrganizations);
        const userRole = getUserRole();
        const userEmail = getUserEmail();

        if (userRole === "Manager" || userRole === "Consultant") {
          const currentOrg = filteredOrganizations.filter(
            (org) => org.organizationName === organizationName
          );
          console.log("-----", currentOrg);

          const filteredOrgs = await filterOrganizationsForUser(
            userRole,
            userEmail,
            filteredOrganizations,
            parsedUsersData.users
          );

          // Combine currentOrg and filteredOrgs, removing duplicates
          const combinedOrgs = [...new Set([...currentOrg, ...filteredOrgs])];
          setFilteredOrgData(combinedOrgs);
        } else {
          setFilteredOrgData(filteredOrganizations);
        }

        // console.log("organizations", filteredOrganizations);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizations();
  }, []);
*/
  // function handleSelectChange(event) {
  //   const selectedOrgEmail = event.target.value;
  //   // console.log("selectedOrgEmail", selectedOrgEmail);
  //   const selectedOrgObject = organizations.find(
  //     (org) => org.organizationEmail === selectedOrgEmail
  //   );
  //   // console.log("selectedOrgObject", selectedOrgObject);

  //   setOrg({
  //     organizationEmail: selectedOrgObject.organizationEmail,
  //     name: selectedOrgObject.organizationName,
  //   });
  // }

  useEffect(() => {
    const fetchOrganizations = async () => {
      setLoading(true);
      try {
        // Execute both API calls simultaneously
        const organizationsResponse = await fetchAllOrganizations();
        const decryptedData = decryptData(organizationsResponse.data);
        const parsedData = JSON.parse(decryptedData);
         const envintIndex = parsedData.unmarshalledData.findIndex(
            (org) => org.organizationName === "Envint"
         );
         // swap the first element with the envint element
         if (envintIndex !== -1) {
            const temp = parsedData.unmarshalledData[0];
            parsedData.unmarshalledData[0] = parsedData.unmarshalledData[envintIndex];
            parsedData.unmarshalledData[envintIndex] = temp;
         }
        setFilteredOrgData(parsedData.unmarshalledData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizations();
  }, []);

  function handleSelectChange(selectedOrg) {
    // console.log("selectedOrg", selectedOrg);

    setOrg({
      organizationEmail: selectedOrg.organizationEmail,
      name: selectedOrg.organizationName,
    });
    setSelectedOrg(selectedOrg.organizationEmail);
  }

  function handleButtonClick() {
    const { organizationEmail, name } = org;

    if (!organizationEmail && !name) {
      return toast.error("Select at least one Organization");
    }

    navigate("/assignment/select-priority", {
      state: {
        org: org,
        selectedOption: selectedOption,
        selectedData: selectedData0,
        selectedCategories: selectedCategories,
        selectedSubcategories: selectedSubcategories,
        responseData: location.state?.responseData,
        scorecard: location.state?.scorecard,
        changedFrequencies: location.state?.changedFrequencies,
        checkedRows: location.state?.checkedRows,
        selectedFrequencies: location.state?.selectedFrequencies,
        checkedAll: location.state?.checkedAll,
        frameworkFilter: location.state?.frameworkFilter,
        selectedCount: location.state?.selectedCount,
        selectedUsers: location.state?.selectedUsers,
      },
    });
  }

  const step = 0;
  // // console.log("org", org);

  return (
    <div className="add-container">
      <Hamburger />
      <div>
        <MultiStepAssignManager step={step} />
      </div>
      <div className="org-container">
        <Box className="org-card" variant="outlined">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h3 className="frame_heading" style={{ width: "100%" }}>
              Select Organization
            </h3>
            <TextField
              id="outlined-basic"
              label="Search Organization"
              variant="outlined"
              style={inputfieldstyle1}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          <Divider />
          <br />
          {/* <Select
            onChange={handleSelectChange}
            value={org.organizationEmail || ""}
            style={{ width: "50%", margin: "40px 0", display: "block" }}
          >
            {organizations.map((value) => (
              <MenuItem
                key={value.organizationEmail}
                value={value.organizationEmail}
              >
                {value.organizationName}
              </MenuItem>
            ))}
          </Select> */}
          <Grid container spacing={3}>
            {filtereOrgData
              ?.filter(
                (org) =>
                  org.organizationName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  org.organizationEmail?.includes(search.toLowerCase())
              )
              .map((org, index) => (
                <Grid item xs={12} sm={6} md={4} key={org.organizationEmail}>
                  <OrganizationCards
                    handleSelectChange={handleSelectChange}
                    org={org}
                    index={index}
                    selectedOrg={selectedOrg}
                  />
                </Grid>
              ))}
          </Grid>
          <Button style={buttoncontaindedfixed} onClick={handleButtonClick}>
            Continue
          </Button>
        </Box>
      </div>
      <Loader loading={loading} />
    </div>
  );
};
