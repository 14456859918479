import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getCountryCurrency } from "../../services/loggedIn";
import {
  buttoncontaindedfixed,
  buttoncontaindedfixeddisabled,
  userdeletebutton,
} from "../../styles";
import { BsInfoCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../PopUpDialog/ConfirmModal";
import SimpleModal from "../PopUpDialog/SimpleModal";
import BasicModal from "../PopUpDialog/BasicModal";
import {
  handleSaveDraft,
  handleSubmit,
} from "../../pages/ClientSide/ExtraFiles/formHandler";
import { getUserEmail } from "../../services/loggedIn";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAddMoreKPIState,
  resetAllKPIState,
  resetFieldKPIState,
  setAddMoreKpiInputdata,
} from "../../redux/slice/KpiSlice";

export const AddMoreTable = ({
  setTableFromat,
  orgdept,
  orgplt,
  tableData,
  setKpiinputdata = () => {},
  tableRendering,
  tableDraftRendering,
  frequency,
  kpicode,
  status,
  reviewedBy
}) => {
  

  const { addMoreKpiInputdata } = useSelector((state) => state.kpi);

  // console.log("addMoreKpiInputdata", addMoreKpiInputdata);
  // console.log("tableRendering------", tableRendering);
  // console.log("-----status",status)
  console.log("-----reviewedBy",reviewedBy)

  const [tableData1, setTableData1] = useState(tableData);
  const [filledKpis, setFilledKpis] = useState(0);
  const [draftedKpis, setDraftedKpis] = useState(0);
  const [jsonData, setJsonData] = useState([tableData[0]]);
  const [editableInput, setEditiableInput] = useState();
  const [showModal, setShowModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalInView, setModalInView] = useState(false);
  const [loading, setLoading] = useState(false);
  const formData = "";
  const fdata = "";
  const currentYears = "";
  const currentMonths = "";
  const data = "";

  // Create an array to store input values for each row
  const [inputValues, setInputValues] = useState(
    Array(tableData1.length).fill({})
  );
  const [confirm, setConfirm] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (addMoreKpiInputdata.length === 0) {
      setEditiableInput(tableDraftRendering?.map((item) => ({ ...item })));
    } else {
      setEditiableInput(addMoreKpiInputdata);
    }
  }, [tableDraftRendering, addMoreKpiInputdata]);

  const addRow = () => {
    if (status === "unfilled") {
      const newRow = {};
      for (const key in tableData1[0]) {
        newRow[key] = tableData1[1][key];
      }
      setTableData1([...tableData1, newRow]);
    } else if (status === "drafted") {
      const newEditableRow = {};
      for (const key in editableInput[0]) {
        if (key.startsWith("Field_")) {
          newEditableRow[key] = { ...editableInput[1][key], value: "" };
        } else {
          // Copy metadata fields directly from an existing row
          newEditableRow[key] = editableInput[1][key];
        }
      }
      setEditiableInput([...editableInput, newEditableRow]);
    }

    setInputValues([...inputValues, {}]);
  };
  // console.log("edit", editableInput);

  const deleteRow = (index) => {
    if (status === "unfilled") {
      const updatedData = [...tableData1];
      updatedData.splice(index, 1);
      setTableData1(updatedData);
    } else if (status === "drafted") {
      const updatedDraftData = [...editableInput];
      updatedDraftData.splice(index, 1);

      // Update the No_of_rows_columns field
      const rowsColumns = updatedDraftData[0].No_of_rows_columns.split(" x ");
      const newRowCount = parseInt(rowsColumns[0]) - 1;
      updatedDraftData.forEach((row) => {
        row.No_of_rows_columns = `${newRowCount} x ${rowsColumns[1]}`;
      });

      setEditiableInput(updatedDraftData);
    }

    const updatedInputValues = [...inputValues];
    updatedInputValues.splice(index, 1);
    setInputValues(updatedInputValues);

    const updatedJsonData = [...jsonData];
    updatedJsonData.splice(index, 1);
    setJsonData(updatedJsonData.filter(Boolean));
  };

  const handleChange = (value, rowIndex, fieldKey) => {
    //add ratio type

    // Create a deep copy of updatedTableData
    let updatedData = JSON.parse(JSON.stringify(tableData1));
    // console.log("updatedData", updatedData);

    // Update the value property of the corresponding object
    if (updatedData[rowIndex] && updatedData[rowIndex][fieldKey]) {
      updatedData[rowIndex][fieldKey].value = value;
    }
    setTableData1(updatedData);

    const updatedInputValues = [...inputValues];
    updatedInputValues[rowIndex][fieldKey] = value;
    setInputValues(updatedInputValues);

    const newRowJson = {};

    Object.keys(tableData1[0]).forEach((key) => {
      newRowJson[key] = tableData1[0][key];
    });

    updatedData[rowIndex] &&
      Object.keys(updatedData[rowIndex]).forEach((key) => {
        if (updatedData[rowIndex][key]) {
          newRowJson[key] = updatedData[rowIndex][key];
        }
      });

    setJsonData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex] = newRowJson;
      return newData.filter(Boolean);
    });
    // const updatedInputValues = [...inputValues];
    // updatedInputValues[rowIndex] = {
    //   ...updatedInputValues[rowIndex],
    //   [fieldKey]: { format: tableData1[0][fieldKey].format, value },
    // };
    // setInputValues(updatedInputValues);

    // const newRowJson = { ...tableData1[0] };
    // newRowJson[fieldKey] = { format: tableData1[0][fieldKey].format, value };

    // setJsonData((prevData) => {
    //   const newData = [...prevData];
    //   newData[rowIndex] = newRowJson;
    //   return newData.filter(Boolean);
    // });
    // const hasChanges = updatedInputValues.some((row) =>
    //   Object.values(row).some((val) => val !== "" && val !== null && val !== undefined)
    // );
    // setIsDirty(hasChanges);
  };

  const handleDraftChange = (index, key, value, flag) => {
    if (flag) {
      // Create a deep copy of updatedTableData
      let updatedData = JSON.parse(JSON.stringify(editableInput));

      // Update the value property of the corresponding object
      if (updatedData[index] && updatedData[index][key]) {
        updatedData[index][key].value = value;
      }

      // Update the state with the modified data
      setEditiableInput(updatedData);
      // console.log("editableInput", editableInput);
    }
  };

  const saveFields = () => {
    // if (!isDirty) {
    //   toast.error("Please enter data in at least one field before saving.");
    //   return;
    // }

    const updatedJsonData = [...jsonData];
    const isTableData0Present = updatedJsonData.some((obj) =>
      Object.keys(obj).every((key) => obj[key] === tableData[0][key])
    );

    if (!isTableData0Present) {
      updatedJsonData.push(tableData[0]);
    }

    const filteredJsonData = updatedJsonData.filter(
      (obj) => Object.keys(obj).length !== 0
    );
    setJsonData(filteredJsonData);

    const kpi_data = {
      [frequency + "~" + kpicode]: filteredJsonData,
    };
    toast.success("Fields saved successfully");
    setKpiinputdata(kpi_data);

    // setIsDirty(false);
    // setIsDraftDirty(false);
  };

  const submitForDraft = () => {
    // console.log("dis");

    if (typeof setKpiinputdata === "function") {
      // // console.log("Saving draft data:", updatedTableData);
      const kpi_data = {
        [frequency + "~" + kpicode]: editableInput,
      };

      toast.success("Fields saved successfully");
      // Call setKpiinputdata to update state or context
      dispatch(setAddMoreKpiInputdata(editableInput));

      setKpiinputdata(kpi_data);
    } else {
      console.error("setKpiinputdata is not a function");
    }
  };

  const tableHeader = (
    // E3F4F4
    <TableRow style={{ background: "#E3F4F4", borderRadius: "20px" }}>
      {Object.keys(tableData[0])
        .sort()
        .map((key, index) => {
          if (key.startsWith("Field_")) {
            return (
              <TableCell style={{}} key={index}>
                {tableData[0][key]?.format}
              </TableCell>
            );
          }
          return null;
        })}
    </TableRow>
  );

  const tableDraftHeader = (
    // E3F4F4
    <TableRow style={{ background: "#E3F4F4", borderRadius: "20px" }}>
      {editableInput &&
        Object.keys(editableInput[0])
          .sort()
          .map((key, index) => {
            if (key.startsWith("Field_")) {
              return (
                <TableCell style={{}} key={index}>
                  {editableInput[0][key]?.format}
                </TableCell>
              );
            }
            return null;
          })}
    </TableRow>
  );

  const renderCellValueAddmore = (value) => {
    // if (
    //   typeof cellData === "object" &&
    //   cellData !== null &&
    //   "format" in cellData &&
    //   "value" in cellData
    // ) {
    //   return cellData.value;
    // } else {
    //   return cellData;
    // }
    // // console.log("valu", value);

    return value;
  };

  const handleEditInputChange = (index, key, value, item) => {
    // console.log("item", item[key]?.format);

    if (
      item[key]?.format?.includes("input-number") ||
      item[key]?.format?.includes("input-decimal") ||
      item[key]?.format?.includes("input-currency")
    ) {
      if (valnum(value) || value === "") {
        setEditiableInput((prevInput) => {
          const newInput = [...prevInput];
          newInput[index] = {
            ...newInput[index],
            [key]: { ...newInput[index][key], value: value },
          };
          return newInput;
        });
      } else {
        toast.error("Invalid number format!");
      }
    } else {
      setEditiableInput((prevInput) => {
        const newInput = [...prevInput];
        newInput[index] = {
          ...newInput[index],
          [key]: { ...newInput[index][key], value: value },
        };
        return newInput;
      });
      // const hasChanges = editableInput.some((row) =>
      //   Object.values(row).some((val) => val !== "" && val !== null && val !== undefined)
      // );
      // setIsDraftDirty(hasChanges);
    }
    // // console.log("edit", editableInput);
    // editableInput[index][key] = value;
    // setEditiableInput(editableInput);
    // // console.log("editableInput", editableInput);
  };

  const renderEditableCellValue = (item, value, index, key) => {
    if (!item || !value) {
      console.error("Invalid item or value:", { item, value, index, key });
      return null;
    }
    if (value?.format?.includes("input")) {
      const fieldType = value.format.split("-")[1];
      const existingValue = item[key]?.value || "";

      if (value.format.includes("MCQ")) {
        const options = item[key]?.format.match(/\(([^)]+)\)/)[1].split("|");
        return (
          <select
            value={existingValue}
            onChange={(e) =>
              handleEditInputChange(index, key, e.target.value, item)
            }
            style={{ width: "200px" }}
          >
            <option value="">Select Option</option>
            {options.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      } else if (fieldType === "Binary") {
        return (
          <select
            value={existingValue}
            onChange={(e) =>
              handleEditInputChange(index, key, e.target.value, item)
            }
            style={{ width: "200px" }}
          >
            <option value="">Select Option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      } else if (
        typeof item[key]?.format === "string" &&
        item[key].format.includes("input")
      ) {
        switch (fieldType) {
          case "number":
          case "decimal":
          case "wholeNumber":
          case "currency":
            return (
              <TextField
                type="text"
                value={item[key]?.value || ""}
                placeholder="Enter Number"
                onChange={(e) => {
                  handleEditInputChange(index, key, e.target.value, item);
                }}
                style={{ width: "200px" }}
              />
            );
          case "percent":
            return (
              <TextField
                type="text"
                value={existingValue}
                placeholder="Enter Percent"
                onChange={(e) => {
                  validatePercent(
                    e.target.value,
                    handleEditInputChange,
                    index,
                    key
                  );
                }}
                style={{ width: "200px" }}
              />
            );
          case "url":
            return (
              <TextField
                type="text"
                value={existingValue}
                placeholder="Enter URL"
                onChange={(e) => {
                  const isValid = validateURL(e.target.value);
                  if (isValid) {
                    handleEditInputChange(index, key, e.target.value, item);
                  }
                }}
                style={{ width: "200px" }}
              />
            );
          case "openText":
          default:
            return (
              <TextField
                type="text"
                value={existingValue}
                placeholder="Enter Text"
                onChange={(e) =>
                  handleEditInputChange(index, key, e.target.value, item)
                }
                style={{ width: "200px" }}
              />
            );
        }
      }
    }
    if (
      value?.format?.match(/".*"/) &&
      !value?.format?.includes("*") &&
      !value?.format?.includes("/") &&
      !value?.format?.includes("+") &&
      !value?.format?.includes("-") &&
      !value?.format?.includes("input")
    ) {
      // replaced all back slash
      const existingValue = item[key]?.value || "";

      return (
        <TextField
          type="text"
          value={existingValue}
          onChange={(e) => {
            validateNumber(e.target.value, handleEditInputChange, key, index);
          }}
          placeholder="Enter Number"
          style={{ width: "200px" }}
        />
      );
    }

    if (value?.format === "Header" || value?.format === "Headers") {
      return item.Table_name;
    } else if (value?.format === "Add Details") {
      return (
        <Button style={{ border: "1px solid #3079bd" }}>Add details</Button>
      );
    } else if (
      value?.format === "False" ||
      value?.format === "False " ||
      value?.format === "Total" ||
      value?.format === "auto"
    ) {
      return null;
    } else if (item[key]?.format?.includes("Please save")) {
      return null;
    } else if (item.Field_button === "True") {
      return (
        <>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              border: "1px solid white",
              background: "#45b653",
              borderRadius: "20px",
              color: "white",
              padding: "5px 30px",
            }}
            onClick={submitForDraft}
          >
            Save
          </Button>
        </>
      );
    } else {
      if (typeof value?.format === "string") {
        value = value?.format?.replaceAll("_", " ") || value?.format;
        return value;
      } else {
        return value?.value || value?.format || null;
      }
    }
  };

  function valnum(value) {
    const regex = /^(^$)|(^(0(\.\d{0,4})?|([1-9]\d{0,12})(\.\d{0,4})?))$/;
    return regex.test(value);
  }

  const validateNumber = (value, handleChange, rowIndex, key) => {
    // Validate number: maximum 13 places and two decimal places
    // const regex = /^(^$)|(^(0(\.\d{0,4})?|([1-9]\d{0,12})(\.\d{0,4})?))$/;
    // const isValid = regex.test(value);
    if (valnum(value)) {
      handleChange(value, rowIndex, key);
    } else {
      toast.error("Invalid number format!");
    }
    // return isValid;
  };

  function validateWholeNumber(newValue, handleChange, rowIndex, key) {
    // Check if the entered value is a positive whole number
    const isWholeNumber = /^[0-9]\d*$/.test(newValue);

    // If it's a positive whole number, call the handleChange function to update the state
    if (isWholeNumber || newValue === "") {
      handleChange(newValue, rowIndex, key);
    } else {
      toast.error("Invalid WholeNumber format!");
    }
  }

  const validateURL = (value, handleChange, rowIndex, key) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValid = regex.test(value);
    if (isValid) {
      handleChange(value, rowIndex, key);
    } else {
      toast.error("Invalid URL format!");
    }
    return isValid;
  };

  const validatePercent = (value, handleChange, rowIndex, key) => {
    const regex = /^(100(\.00?)?|\d{0,2}(\.\d{0,2})?)$/;
    const isValid = regex.test(value);
    if (isValid) {
      handleChange(value, rowIndex, key);
    } else {
      toast.error("Invalid percent format!");
    }
    return isValid;
  };

  const handleBack = () => {
    navigate(-1);
  };

  const openPopup = () => {
    setShowModal(true);
  };

  const updateDrafTableData = (draftStatus) => {
    const determineFrequency = () => {
      if (!frequency) {
        console.warn(`No frequency provided for KPI code: ${kpicode}`);
        return {};
      }

      // Check if the frequency is in the format 'MMM\'YY' (e.g., 'Aug\'24')
      if (/^\w{3}'\d{2}$/.test(frequency)) {
        return { Month: frequency };
      }

      // Check if the frequency is in the format 'YYYY-YYYY' (e.g., '2024-2025')
      if (/^\d{4}-\d{4}$/.test(frequency)) {
        return { Year: frequency };
      }

      // If it's neither a month, a year, nor a year range format we recognize, log a warning and return as-is
      console.warn(`Unexpected frequency format: ${frequency}`);
      return { Frequency: frequency };
    };

    // console.log("determineFrequency", determineFrequency());

    return [
      {
        KPICode: kpicode,
        KPIInput: editableInput,
        format: setTableFromat,
        draftStatus: draftStatus,
        frequency: determineFrequency(),
        department: orgdept,
        operationalUnit: orgplt,
        userEmail: getUserEmail(),
      },
    ];
  };

  const handleNavigate = async () => {
    if (!confirm) {
      const returnedValue = await handleClick();
      if (returnedValue !== "0") {
        setShowModal(false);
        setFilledKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
      dispatch(resetAddMoreKPIState());
    } else {
      setShowModal(false);
    }
  };

  const handleNavigateSaveDraft = async () => {
    if (!confirm) {
      const returnedValue = await handleClickDraft();
      if (returnedValue !== "0") {
        setShowModal(false);
        setDraftedKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
    } else {
      setShowModal(false);
    }
  };

  const handleClick = () => {
    // console.log("setLoading type:", typeof setLoading);
    setLoading(true);
    // console.log("Loading set to true");

    try {
      // console.log("About to call handleSubmit");
      const returnValue = handleSubmit(
        updateDrafTableData("saved"),
        formData,
        fdata,
        currentYears,
        currentMonths,
        data,
        setLoading,
        setShowConfirmModal,
        setModalInView,
        modalInView,
        [], // editedFields
        "" // countryCode
      );
      // console.log("handleSubmit called successfully");
      return returnValue;
    } catch (error) {
      console.error("Error in handleClick:", error);
      setLoading(false);
      return "0";
    }
  };

  const showToast = true;
  const handleClickDraft = () => {
    const returnValue = handleSaveDraft(
      updateDrafTableData("drafted"),
      formData,
      fdata,
      currentYears,
      currentMonths,
      data,
      setLoading,
      showToast
    );
    return returnValue;
  };

  const closePopup = () => {
    setShowModal(false);
    setSecondModal(false);
    setShowConfirmModal(false);
    setModalInView(false);
    // fetchData();
  };

  const closeSimplePopup = () => {
    setSecondModal(false);
    navigate("/reporting/dashboard");
  };

  return (
    <>
      <TableContainer
        sx={{
          overflowX: "auto",
          // width: status === "drafted" ? "2500px" : "auto",
        }}
      >
        {(status === "unfilled" || (status === "drafted" && reviewedBy===null)) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "10px",
              gap: "12px",
            }}
          >
            <BsInfoCircle style={{ color: "red", fontWeight: "600" }} />
            <Typography
              variant="body1"
              sx={{
                color: "red",
                display: "flex",
                justifyContent: "start",
              }}
            >
              Please Save the Table Details Before Submitting
            </Typography>
          </Box>
        )}
        <Table>
          {status === "unfilled" && tableHeader}
          {status === "drafted" && tableDraftHeader}
          <TableBody>
            {status === "unfilled" &&
              tableData1?.sort().map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.keys(row)
                    .sort()
                    .map((key, index) => {
                      if (
                        key.startsWith("Field_") &&
                        row[key]?.format?.startsWith("input")
                      ) {
                        //input-feildType
                        //input
                        // Extract the input type from the row[key] value
                        const inputType = row[key]?.format; // Extracts input type from "input-dropDown(Male|Female|Prefer Not to Say)"

                        if (inputType && inputType.includes("input-MCQ")) {
                          const options = row[key]?.format
                            .match(/\(([^)]+)\)/)[1]
                            .split("|"); // Extracts options from "input-dropDown(Male|Female|Prefer Not to Say)"

                          return (
                            <TableCell key={index}>
                              <select
                                value={inputValues[rowIndex][key] || ""}
                                onChange={(e) =>
                                  handleChange(e.target.value, rowIndex, key)
                                }
                                style={{ width: "200px" }}
                              >
                                <option value="">Select Option</option>
                                {options.map((option, idx) => (
                                  <option key={idx} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </TableCell>
                          );
                        } else {
                          // Render input element based on input type [format]
                          switch (inputType) {
                            case "input-openText":
                              return (
                                <TableCell key={index}>
                                  <TextField
                                    type="text"
                                    value={inputValues[rowIndex][key] || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        rowIndex,
                                        key
                                      )
                                    }
                                    placeholder="open-text"
                                    style={{ width: "200px" }}
                                  />
                                </TableCell>
                              );
                            case "input-wholeNumber":
                              return (
                                <TableCell key={index}>
                                  <TextField
                                    type="number"
                                    value={inputValues[rowIndex][key] || ""}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      validateWholeNumber(
                                        value,
                                        handleChange,
                                        rowIndex,
                                        key
                                      );
                                    }}
                                    placeholder="number"
                                    style={{ width: "200px" }}
                                  />
                                </TableCell>
                              );
                            case "input-percent":
                              return (
                                <TableCell key={index}>
                                  <TextField
                                    type="percent"
                                    value={inputValues[rowIndex][key] || ""}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      validatePercent(
                                        value,
                                        handleChange,
                                        rowIndex,
                                        key
                                      );
                                    }}
                                    placeholder="Enter Percentage"
                                    style={{ width: "200px" }}
                                  />
                                </TableCell>
                              );
                            case "input-decimal":
                            case "input-number":
                              return (
                                <TableCell key={index}>
                                  <TextField
                                    type="number"
                                    step="any"
                                    value={inputValues[rowIndex][key] || ""}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      validateNumber(
                                        value,
                                        handleChange,
                                        rowIndex,
                                        key
                                      );
                                    }}
                                    placeholder="decimal"
                                    style={{ width: "200px" }}
                                  />
                                </TableCell>
                              );
                            case "input-currency":
                              return (
                                <TableCell key={index}>
                                  <TextField
                                    type="number"
                                    step="any"
                                    value={inputValues[rowIndex][key] || ""}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      validateNumber(
                                        value,
                                        handleChange,
                                        rowIndex,
                                        key
                                      );
                                    }}
                                    placeholder="decimal"
                                    style={{ width: "200px" }}
                                  />
                                  <span>
                                    {localStorage.getItem("countryCurrency")}{" "}
                                  </span>
                                </TableCell>
                              );
                            case "input-url":
                              return (
                                <TableCell key={index}>
                                  <TextField
                                    type="text"
                                    placeholder="Enter URL"
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      validateURL(
                                        value,
                                        handleChange,
                                        rowIndex,
                                        key
                                      );
                                    }}
                                    style={{ width: "200px" }}
                                  />
                                </TableCell>
                              );
                            case "input-Binary":
                              return (
                                <TableCell key={index}>
                                  <select
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        rowIndex,
                                        key
                                      )
                                    }
                                    style={{ width: "200px" }}
                                  >
                                    <option value="">Select Option</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </TableCell>
                              );
                            default:
                              return (
                                <TableCell key={index}>
                                  <TextField
                                    type="text"
                                    value={inputValues[rowIndex][key] || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        rowIndex,
                                        key
                                      )
                                    }
                                    placeholder="open-text"
                                    style={{ width: "200px" }}
                                  />
                                </TableCell>
                              );
                          }
                        }
                      }
                    })}
                  {rowIndex > 1 && (
                    <TableCell>
                      <Button
                        onClick={() => deleteRow(rowIndex)}
                        style={userdeletebutton}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            {(status === "saved" || status==="approved" || (reviewedBy!==null && status==="drafted")) &&
              tableRendering?.sort().map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  style={
                    rowIndex === 0
                      ? {
                          background: "#E3F4F4",
                          //E3F4F4
                        }
                      : {}
                  }
                >
                  {Object.keys(row)
                    .sort()
                    .map((key, index) => {
                      if (key.startsWith("Field_")) {
                        return (
                          <TableCell
                            key={index}
                            // style={rowIndex === 0 ? { color: "#fff" } : {}}
                          >
                            {renderCellValueAddmore(row[key])}
                          </TableCell>
                        );
                      }
                      // else {
                      //   // Render input element based on input type [format]
                      //   const inputType = row[key]?.format;
                      //   switch (inputType) {
                      //     case "input-openText":
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="text"
                      //             value={inputValues[rowIndex][key] || ""}
                      //             onChange={(e) =>
                      //               handleChange(e.target.value, rowIndex, key)
                      //             }
                      //             placeholder="open-text"
                      //             style={{ width: "200px" }}
                      //           />
                      //         </TableCell>
                      //       );
                      //     case "input-wholeNumber":
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="number"
                      //             value={inputValues[rowIndex][key] || ""}
                      //             onChange={(e) => {
                      //               const { value } = e.target;
                      //               validateWholeNumber(
                      //                 value,
                      //                 handleChange,
                      //                 rowIndex,
                      //                 key
                      //               );
                      //             }}
                      //             placeholder="number"
                      //             style={{ width: "200px" }}
                      //           />
                      //         </TableCell>
                      //       );
                      //     case "input-percent":
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="percent"
                      //             value={inputValues[rowIndex][key] || ""}
                      //             onChange={(e) => {
                      //               const { value } = e.target;
                      //               validatePercent(
                      //                 value,
                      //                 handleChange,
                      //                 rowIndex,
                      //                 key
                      //               );
                      //             }}
                      //             placeholder="Enter Percentage"
                      //             style={{ width: "200px" }}
                      //           />
                      //         </TableCell>
                      //       );
                      //     case "input-decimal":
                      //     case "input-number":
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="number"
                      //             step="any"
                      //             value={inputValues[rowIndex][key] || ""}
                      //             onChange={(e) => {
                      //               const { value } = e.target;
                      //               validateNumber(
                      //                 value,
                      //                 handleChange,
                      //                 rowIndex,
                      //                 key
                      //               );
                      //             }}
                      //             placeholder="decimal"
                      //             style={{ width: "200px" }}
                      //           />
                      //         </TableCell>
                      //       );
                      //     case "input-currency":
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="number"
                      //             step="any"
                      //             value={inputValues[rowIndex][key] || ""}
                      //             onChange={(e) => {
                      //               const { value } = e.target;
                      //               validateNumber(
                      //                 value,
                      //                 handleChange,
                      //                 rowIndex,
                      //                 key
                      //               );
                      //             }}
                      //             placeholder="decimal"
                      //             style={{ width: "200px" }}
                      //           />
                      //           <span>{getCountryCurrency()} </span>
                      //         </TableCell>
                      //       );
                      //     case "input-url":
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="text"
                      //             placeholder="Enter URL"
                      //             onChange={(e) => {
                      //               const { value } = e.target;
                      //               validateURL(
                      //                 value,
                      //                 handleChange,
                      //                 rowIndex,
                      //                 key
                      //               );
                      //             }}
                      //             style={{ width: "200px" }}
                      //           />
                      //         </TableCell>
                      //       );
                      //     case "input-Binary":
                      //       return (
                      //         <TableCell key={index}>
                      //           <select
                      //             onChange={(e) =>
                      //               handleChange(e.target.value, rowIndex, key)
                      //             }
                      //             style={{ width: "200px" }}
                      //           >
                      //             <option value="">Select Option</option>
                      //             <option value="Yes">Yes</option>
                      //             <option value="No">No</option>
                      //           </select>
                      //         </TableCell>
                      //       );
                      //     default:
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="text"
                      //             value={inputValues[rowIndex][key] || ""}
                      //             onChange={(e) =>
                      //               handleChange(e.target.value, rowIndex, key)
                      //             }
                      //             placeholder="open-text"
                      //             style={{ width: "200px" }}
                      //           />
                      //         </TableCell>
                      //       );
                      //   }
                      // }
                    })}
                </TableRow>
              ))}
            {status === "drafted" &&
              editableInput?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    {Object.keys(item)
                      // .filter((keyFilter) => keyFilter.startsWith("Field_"))
                      .sort()
                      .map((keyMap, keyIndex) => {
                        if (
                          keyMap.startsWith("Field_") &&
                          item[keyMap]?.format?.startsWith("input")
                        ) {
                          return (
                            <TableCell
                              key={keyIndex}
                              style={{
                                wordBreak: "break-word",
                                // backgroundColor: keyIndex === 0 ? "#E3F4F4" : "",
                                padding: "8px 12px",
                              }}
                            >
                              {renderEditableCellValue(
                                item,
                                item[keyMap],
                                index,
                                keyMap
                              )}
                            </TableCell>
                          );
                        }
                      })}
                    {index > 1 && (
                      <TableCell>
                        <Button
                          onClick={() => deleteRow(index)}
                          style={userdeletebutton}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>

          {(status === "unfilled" || (status === "drafted" && reviewedBy===null)) && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                onClick={addRow}
                sx={{ textTransform: "none" }}
                style={{
                  margin: "10px",
                  width: "125px",
                  border: "1px solid #3079bd",
                  borderRadius: "15px",
                  color: "#fff",
                  backgroundColor: "#3079bd",
                }}
              >
                Add Row
              </Button>

              <Button
                onClick={status === "drafted" ? submitForDraft : saveFields}
                // disabled={status==="unfilled"?!isDirty:!isDraftDirty}
                sx={{ textTransform: "none" }}
                style={{
                  border: "1px solid #45b653",
                  background: "#45b653",
                  borderRadius: "15px",
                  color: "white",
                  padding: "5px 35px",
                }}
              >
                Save
              </Button>
            </div>
          )}
        </Table>
      </TableContainer>
      <Button
        type="button"
        onClick={openPopup}
        style={
          status === "saved"
            ? buttoncontaindedfixeddisabled
            : buttoncontaindedfixed
        }
        disabled={status === "saved"}
      >
        Next
      </Button>

      {showModal && (
        <BasicModal
          message="Please select the action to be performed."
          closePopup={closePopup}
          openPopup={openPopup}
          handleNavigate={handleNavigate}
          confirm={confirm}
          setConfirm={setConfirm}
          handleClickDraft={handleClickDraft}
          handleNavigateSaveDraft={handleNavigateSaveDraft}
        />
      )}

      {secondModal && (
        <SimpleModal
          openPopup={secondModal}
          message={`Good Job! You have filled ${filledKpis} response`}
          draftMessage={`Good Job! You have Drafted ${draftedKpis} response`}
          closeSimplePopup={closeSimplePopup}
          closePopup={closePopup}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          open={showConfirmModal}
          title={"Confirm Submitting"}
          onClose={closePopup}
          message={"Drafted Data will also we saved after confirming"}
          confirmText={"confirm"}
          cancelText={"cancel"}
          onConfirm={handleNavigate}
        />
      )}
    </>
  );
};
