import axios from "axios";
import { baseUrl } from "../../baseurl";

export const getDepartmentCompliance = (encryptData) => {
  return axios.post(
    `${baseUrl}/getDepartmentCompliance`,
    { encryptedData: encryptData },
    { withCredentials: true }
  );
};

export const getOUCompliance = (encryptData) => {
  return axios.post(
    `${baseUrl}/getOUCompliance`,
    { encryptedData: encryptData },
    { withCredentials: true }
  );
};

export const useradoption = (encryptData) => {
  return axios.post(
    `${baseUrl}/useradoption`,
    { encryptedData: encryptData }, // Remove frequency from the payload
    {
      withCredentials: true,
    }
  );
};
