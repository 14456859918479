import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { backBtnfixed, buttoncontaindedfixed } from "../../styles";
import Hamburger from "../Navbars/Hamburger";
import { FramerScroll } from "../FramerScroll/FramerScroll";
import { toast } from "react-toastify";
import encrypt from "../../services/crypto";
import { updateUsers } from "../../services/UsermanagementApi";
import EditUserMultiStep from "../../pages/MultiStepEditUser/EditUserMultiStep";

const DepartmentChange = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("state1", location.state);
  const [checkedDepartments, setCheckedDepartments] = useState(
    location.state.checkedDepartments || {}
  );
  // const userData0 = location.state.data;
  // const data = location.state.backenddata;
  // const data0 = data.filter((x) => x.organizationName === userData0.organization);
  // const tableData = JSON.parse(JSON.parse(data0[0].operationalUnits));
  // const userData = JSON.parse(JSON.parse(userData0.operationalUnits));
  const userData0 = location.state.data;
  const data = location.state.backenddata;
  console.log("data", data);
  const data0 = data.filter(
    (x) => x.organizationName === userData0.organization
  );
  // const tableData = JSON.parse(data0[0].operationalUnits);
  const userData = JSON.parse(userData0.operationalUnits);
  const tableData = JSON.parse(JSON.parse(data0[0].operationalUnits));
  console.log("tableData", tableData);

  // const userData = JSON.parse(JSON.parse(userData0.operationalUnits));

  // Extracting all unique departments from the provided data
  const allDepartments = Array.from(
    new Set(tableData.flatMap((item) => Object.values(item)[0].split(", ")))
  );
  //   const allDepartments = Array.from(
  //   new Set(
  //     Array.isArray(tableData)
  //       ? tableData.flatMap((item) =>
  //           Object.values(item).map((departments) => departments.split(', '))
  //         )
  //       : []
  //   )
  // );

  // Extracting all unique plants from the provided data
  const allPlants = tableData.map((item) => Object.keys(item)[0]);
  //   useEffect(() => {
  //   const fetchUsers = () => {
  //     // Initialize checkedDepartments based on userData.operationalUnits
  //     const initialCheckedDepartments = {};
  //
  //     userData.forEach((unit) => {
  //       Object.entries(unit).forEach(([plant, categoriesArray]) => {
  //         initialCheckedDepartments[plant] = categoriesArray;
  //       });
  //     });
  //
  //     setCheckedDepartments(initialCheckedDepartments);
  //   };h
  //
  //   fetchUsers();
  // }, []);

  // useEffect(() => {
  //   const fetchusers = () => {
  //     // Initialize checkedDepartments based on userData.operationalUnits
  //     userData.forEach((unit) => {
  //       // Iterate over the array of objects
  //       Object.entries(unit).forEach(([plant, categoriesArray]) => {
  //         setCheckedDepartments((prevCheckedDepartments) => {
  //           return { ...prevCheckedDepartments, [plant]: categoriesArray };
  //         });
  //       });
  //     });
  //   };
  //   fetchusers();
  // }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    if (Object.keys(checkedDepartments).length === 0) {
      const initialCheckedDepartments = {};
      JSON.parse(userData0.operationalUnits).forEach((unit) => {
        Object.entries(unit).forEach(([plant, departments]) => {
          initialCheckedDepartments[plant] = departments;
        });
      });
      setCheckedDepartments(initialCheckedDepartments);
    }
    // console.log("Initial checkedDepartments:", checkedDepartments);
  }, []);

  // const toggleCheckbox = (plant, category) => {
  //   setCheckedDepartments((prevCheckedDepartments) => {
  //     const currentCheckedDepartments = prevCheckedDepartments[plant] || [];
  //     const updatedDepartments = currentCheckedDepartments.includes(category)
  //       ? currentCheckedDepartments.filter((dept) => dept !== category)
  //       : [...currentCheckedDepartments, category];
  //
  //     return { ...prevCheckedDepartments, [plant]: updatedDepartments };
  //   });
  // };
  //   const toggleCheckbox = (plant, category) => {
  //   setCheckedDepartments((prevCheckedDepartments) => {
  //     const currentCheckedDepartments = prevCheckedDepartments[plant] || [];
  //     const updatedDepartments = currentCheckedDepartments.includes(category)
  //       ? currentCheckedDepartments.filter((dept) => dept !== category)
  //       : [...currentCheckedDepartments, category];
  //
  //     return { ...prevCheckedDepartments, [plant]: updatedDepartments };
  //   });
  // };
  // const toggleCheckbox = (plant, category) => {
  //   setCheckedDepartments((prevCheckedDepartments) => ({
  //     ...prevCheckedDepartments,
  //     [plant]: Array.from(
  //       new Set(
  //         (prevCheckedDepartments[plant] || []).includes(category)
  //           ? prevCheckedDepartments[plant].filter((dept) => dept !== category)
  //           : [...(prevCheckedDepartments[plant] || []), category]
  //       )
  //     ),
  //   }));
  // };
  const toggleCheckbox = (plant, department) => {
    setCheckedDepartments((prevCheckedDepartments) => {
      const updatedDepartments = [...(prevCheckedDepartments[plant] || [])];
      const index = updatedDepartments.indexOf(department);
      if (index > -1) {
        updatedDepartments.splice(index, 1);
      } else {
        updatedDepartments.push(department);
      }
      return {
        ...prevCheckedDepartments,
        [plant]: updatedDepartments,
      };
    });
  };

  // console.log("selected depts", checkedDepartments);

  const handleSubmit = () => {
    // Get the plants that are actually displayed in the UI
    const displayedPlants = allPlants;

    // Filter checkedDepartments to only include displayed plants
    const filteredCheckedDepartments = Object.keys(checkedDepartments)
      .filter((plant) => displayedPlants.includes(plant))
      .reduce((obj, key) => {
        obj[key] = checkedDepartments[key];
        return obj;
      }, {});

    const convertedData = Object.entries(filteredCheckedDepartments).map(
      ([plant, depts]) => ({
        [plant]: depts,
      })
    );

    // Update userData0.operationalUnits with the JSON string
    userData0.operationalUnits = convertedData;
    // const token = getToken();
    // const headers = {
    //   Authorization: `${token}`,
    //   "Content-Type": "application/json",
    // };
    console.log("userData0", userData0);

    const encryptData = encrypt({ userData0 });

    // axios
    //   .put(`${baseUrl}/editusers`, { encryptedData: encryptData }, { headers })
    const updateUser = updateUsers(encryptData)
      .then((response) => {
        navigate("/organization_list", {
          state: { organizationName: userData0.organization },
        });
        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.error(error);
      });
  };

  const handleBack = () => {
    navigate("/user/update-user", {
      state: {
        user: location.state.data,
        checkedDepartments: checkedDepartments,
      },
    });
  };

  const step = 1;

  return (
    <div style={{ padding: "10px" }}>
      <Hamburger />
      <FramerScroll />
      <div
        style={{
          margin: "80px auto",
          padding: "2rem",
          backgroundColor: "#f9f8f9",
          borderRadius: "20px",
          width: "90%",
        }}
      >
        <Box sx={{ marginBottom: "20px" }}>
          <EditUserMultiStep step={step} />
        </Box>
        <h4 className="frame_heading">Edit Department and Operational Units</h4>
        <TableContainer sx={{ maxHeight: "440px", marginTop: "20px" }}>
          <Table style={{ backgroundColor: "white" }}>
            <TableBody>
              <TableRow
                sx={{ position: "sticky", top: "0px", zIndex: 1 }}
                style={{ backgroundColor: "#fff", borderRadius: "20px" }}
              >
                <TableCell
                  style={{
                    color: "#3079bd",
                    fontWeight: "600",
                    fontSize: "1.2rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100px",
                  }}
                >
                  Dept/OU
                </TableCell>
                {allPlants.map((plant, index) => (
                  <TableCell
                    style={{
                      color: "#3079bd",
                      fontWeight: "600",
                      fontSize: "1.2rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100px",
                    }}
                    key={index}
                  >
                    {plant}
                  </TableCell>
                ))}
              </TableRow>

              {allDepartments.map((department, index) => (
                <TableRow key={index}>
                  <TableCell>{department}</TableCell>
                  {allPlants.map((plant, index) => {
                    // const isChecked =
                    //   !!checkedDepartments[plant]?.includes(department);
                    const isChecked =
                      checkedDepartments[plant]?.includes(department) || false;

                    return (
                      <TableCell key={index}>
                        {tableData.find((unit) =>
                          unit[plant]?.includes(department)
                        ) ? (
                          <Checkbox
                            type="checkbox"
                            checked={isChecked}
                            onChange={() => toggleCheckbox(plant, department)}
                          />
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button onClick={handleBack} style={backBtnfixed}>
          Back
        </Button>
        <Button style={buttoncontaindedfixed} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default DepartmentChange;
