import {Box, Card, CardContent, Typography} from "@mui/material";
import OperationalUnitsTable from "../Tables/OperationalUnitsTable";
import React from "react";
import {ENVINT_BLUE} from "../../library/constants";

const OperationalUnitsTableCard = ({operationalUnits, isSmallScreen}) => {
  if (!Array.isArray(operationalUnits)) {
    return <Typography variant="body2" color="text.secondary">{operationalUnits}</Typography>;
  }
  const CardStyle = {
    textAlign: "left",
    padding: isSmallScreen ? "1.5rem 1.5rem" : "1.5rem 3.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: isSmallScreen ? "flex-start" : "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "1rem",
    transition: "max-width 0.3s ease, border-radius 0.3s ease",
  };

  const headingStyle = {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: ENVINT_BLUE,
    marginBottom: "1rem",
    textAlign: "center",
  };

  return (
    <Card sx={{borderRadius: "1rem", marginBottom: "20px"}} elevation={3}>
      <CardContent sx={CardStyle}>
        <Box>
          <Typography variant="h5" component="div" gutterBottom sx={headingStyle}>
            Departments and Operational Units Association Matrix
          </Typography>
        </Box>
        <OperationalUnitsTable operationalUnits={operationalUnits}/>
      </CardContent>
    </Card>
  );
};

export default OperationalUnitsTableCard;