import { Tooltip } from "@mui/material"
// Define a color palette
const colors = [
    "#F44336", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5",
    "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50",
    "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800",
    "#FF5722", "#795548", "#9E9E9E", "#607D8B"
  ];
  
  function stringToHash(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
  }

  function getColorForInitials(initials) {
      const hash = stringToHash(initials);
      const index = Math.abs(hash) % colors.length;
      return colors[index];
  }

export const Profile=({name,email})=>{
    const initials = name.split(" ").map(n => n[0]).join("");
    const backgroundColor = getColorForInitials(initials);
    return(
        <>
        <Tooltip title={email}>
         <div
          style={{
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              background: backgroundColor,
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.2rem",
              textTransform: "uppercase",
              cursor:"pointer"
            }}
          >
            {name[0]}
          </div>
        </div>
        </Tooltip>
        </>
    )
}