import React, {useEffect, useState} from "react";
import axios from "axios";
import {Box, Button,} from "@mui/material";

import "./framework.css";
import "./Home.css";
import "./Standards.css";
import {useLocation, useNavigate} from "react-router-dom";
import Hamburger from "../Navbars/Hamburger.js";
import {baseUrl} from "../../baseurl.js";
import {backBtnfixed, buttoncontaindedfixed} from "../../styles.js";
import FrameWorkCard from "../AssignmentManager/FrameWorkCard.jsx";
import PillarScoreCard from "../AssignmentManager/PillarScoreCard.jsx";
import encrypt, {decryptData} from "../../services/crypto.js";
import {getDynamicSelection} from "../../services/FormManagerApi.js";
import MultiStepAssignManager from "../../pages/MultiStepAssignManager/MultiStepAssignManager.jsx";
import {Loader} from "../Loader/Loader.jsx";

const FrameWorks = () => {
  const location = useLocation();
  const requestData = location.state?.prioritiesJson?.priority;
  // // console.log("re", location.state);
  const selectedOption = location.state?.prioritiesJson?.priority;
  const org = location.state.org;
  const data = location.state?.org?.name;
  // // console.log("org", org);
  const [loading, setLoading] = useState(false);
  const [frameworkData, setFrameworkData] = useState({});
  const [scorecard, setScorecard] = useState({});
  // // console.log("scoreCard", scorecard);
  const [selectedCategories, setSelectedCategories] = useState(
    location.state?.selectedCategories || []
  );
  const [selectedData, setSelectedData] = useState(
    location.state?.selectedData || {}
  );
  const [selectedSubcategories, setSelectedSubcategories] = useState(
    location.state?.selectedSubcategories || {}
  );
  const [submittedData, setSubmittedData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // const token = window.localStorage.getItem("token");
      // const headers = {
      //   Authorization: `${token}`,
      // };
      const json = {
        priorities: requestData,
      };

      const encryptData = encrypt(json);

      try {
        const response = await getDynamicSelection(encryptData);

        // axios.post(
        //   `${baseUrl}/getDynamicSelection`,
        //   { encryptedData: encryptData },
        //   { headers }
        // );

        const decryptedData = decryptData(response.data.encryptedData);

        const parsedData = JSON.parse(decryptedData);
        // // console.log("parsedData", parsedData.frameworks);

        setFrameworkData(parsedData.frameworks);

        // Update selectedCategories and selectedData based on fetched data
        const categories = Object.keys(parsedData.frameworks);
        const data = {};
        categories.forEach((category) => {
          data[category] = {};
        });

        setSelectedCategories((prev) => (prev.length > 0 ? prev : categories));
        setSelectedData((prev) => (Object.keys(prev).length > 0 ? prev : data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCategoryCheckboxChange = (category) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(category)
        ? prevSelected.filter((selected) => selected !== category)
        : [...prevSelected, category]
    );

    setSelectedData((prevSelectedData) => {
      const updatedData = { ...prevSelectedData };

      // If the category is unchecked, remove all its associated themes and topics
      if (!prevSelectedData[category]) {
        return updatedData;
      }

      // Remove the selected category data if it exists
      delete updatedData[category];

      return updatedData;
    });

    setSelectedSubcategories((prevSelectedSubcategories) => {
      const updatedSubcategories = { ...prevSelectedSubcategories };

      // If the category is unchecked, remove all its associated subcategories
      if (!prevSelectedSubcategories[category]) {
        return updatedSubcategories;
      }

      // Remove the selected category subcategories if they exist
      delete updatedSubcategories[category];

      return updatedSubcategories;
    });
  };

  const handleSubcategoryCheckboxChange = (category, subcategory) => {
    setSelectedSubcategories((prevSelected) => ({
      ...prevSelected,
      [category]: prevSelected[category]
        ? prevSelected[category].includes(subcategory)
          ? prevSelected[category].filter(
              (selected) => selected !== subcategory
            )
          : [...prevSelected[category], subcategory]
        : [subcategory],
    }));

    setSelectedData((prevSelectedData) => {
      const updatedData = { ...prevSelectedData };
      if (!updatedData[category]) {
        updatedData[category] = {};
      }

      // If subcategory is checked, check all its elements
      if (!prevSelectedData[category]?.[subcategory]) {
        updatedData[category][subcategory] = {};
        frameworkData[category][subcategory].forEach((element) => {
          updatedData[category][subcategory][element] = true;
        });
      } else {
        // If subcategory is unchecked, uncheck all its elements
        delete updatedData[category][subcategory];
      }
      return updatedData;
    });
  };
  const handleCheckboxChange = (category, subcategory, element) => {
    setSelectedData((prevSelectedData) => {
      const updatedData = { ...prevSelectedData };

      // Toggle the state of the checkbox for the element
      updatedData[category][subcategory][element] =
        !prevSelectedData[category][subcategory][element];

      // If all elements are checked, check the subcategory checkbox
      const allElementsChecked = Object.values(
        updatedData[category][subcategory]
      ).every((val) => val);
      if (allElementsChecked) {
        setSelectedSubcategories((prevSelected) => ({
          ...prevSelected,
          [category]: prevSelected[category]
            ? [...prevSelected[category], subcategory]
            : [subcategory],
        }));
      } else if (
        !allElementsChecked &&
        !prevSelectedData[category][subcategory][element]
      ) {
        // If any element is unchecked, but the subcategory is not unchecked, keep it checked
        setSelectedSubcategories((prevSelected) => ({
          ...prevSelected,
          [category]: prevSelected[category],
        }));
      }
      updateScorecard();
      return updatedData;
    });
    updateScorecard();
  };

  const handleClearSelection = () => {
    setSelectedCategories([]);
    setSelectedSubcategories({});
    setSelectedData({});
  };

  useEffect(() => {
    updateScorecard();
  }, [selectedCategories, selectedSubcategories, selectedData, submittedData]);

  const updateScorecard = () => {
    const newScorecard = {};

    Object.keys(frameworkData).forEach((category) => {
      if (selectedCategories.includes(category)) {
        let themesCount = 0;
        let topicsCount = 0;

        Object.entries(selectedData[category] || {}).forEach(
          ([subcategory, elements]) => {
            if (selectedSubcategories[category]?.includes(subcategory)) {
              themesCount++;
              topicsCount += Object.values(elements).filter(Boolean).length;
            }
          }
        );

        newScorecard[category] = { themes: themesCount, topics: topicsCount };
      }
    });

    setScorecard(newScorecard);
  };
  const handleSubmit = async () => {
    // Define formattedSelectedData before using it
    const formattedSelectedData = {
      organizationEmail: location.state.org?.organizationEmail,
      pillar: selectedCategories.join(","),
      theme: Object.values(selectedSubcategories).flat().join(","),
      topic: getCheckedTopics(selectedData).join(","),
      priorities: requestData,
    };
    // console.log("formated", formattedSelectedData);

    // const token = window.localStorage.getItem("token");
    //
    // const headers = {
    //   Authorization: `${token}`,
    // };
    // Send the formattedSelectedData to the server
    try {
      setLoading(true);

      const encryptData = encrypt({ formattedSelectedData });

      const response = await axios.post(
        `${baseUrl}/getKPIList`,
        { encryptedData: encryptData },
        { withCredentials: true }
      );

      // // console.log("response", response);
      const decryptedData = decryptData(response.data.encryptedData);
      // // console.log("decryptedData", decryptedData);

      const parsedData = JSON.parse(decryptedData);
      // // console.log("parsedData", parsedData.extractedItemList);

      // Assuming the response contains the data you want to store
      // Navigate to the new route with the data
      navigate("/assignment/select-kpis", {
        state: {
          responseData:
            parsedData.extractedItemList || location.state?.responseData,
          selectedValues: formattedSelectedData,
          org: org,
          selectedCategories: selectedCategories,
          selectedData: selectedData,
          selectedSubcategories: selectedSubcategories,
          selectedOption: selectedOption,
          scorecard: scorecard,
          changedFrequencies: location.state?.changedFrequencies,
          checkedRows: location.state?.checkedRows,
          selectedFrequencies: location.state?.selectedFrequencies,
          checkedAll: location.state?.checkedAll,
          frameworkFilter: location.state?.frameworkFilter,
          selectedCount: location.state?.selectedCount,
          selectedUsers: location.state?.selectedUsers,
        },
      });
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setLoading(false);
    }
  };
  const getCheckedTopics = (selectedData) => {
    const checkedTopics = [];
    for (const category in selectedData) {
      for (const subcategory in selectedData[category]) {
        for (const element in selectedData[category][subcategory]) {
          if (selectedData[category][subcategory][element]) {
            checkedTopics.push(element);
          }
        }
      }
    }
    return checkedTopics;
  };
  useEffect(() => {
    if (location.state?.scorecard) {
      setScorecard(location.state.scorecard);
    }
  }, []);

  const step = 2;

  const handleBack = () => {
    navigate("/assignment/select-priority", {
      state: {
        org: org,
        selectedOption: requestData,
        selectedData: selectedData,
        selectedCategories: selectedCategories,
        selectedSubcategories: selectedSubcategories,
        responseData: location.state?.responseData,
        scorecard: scorecard,
        changedFrequencies: location.state?.changedFrequencies,
        checkedRows: location.state?.checkedRows,
        selectedFrequencies: location.state?.selectedFrequencies,
        checkedAll: location.state?.checkedAll,
        frameworkFilter: location.state?.frameworkFilter,
        selectedCount: location.state?.selectedCount,
        selectedUsers: location.state?.selectedUsers,
      },
    });
  };

  return (
    <>
      <Hamburger />
      <div className="standards-container">
        <MultiStepAssignManager step={step} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <h3 className="frame_heading">Select Pillar Theme and Topic</h3>
          {/* <div className="header-box" style={{ margin: "0px 0px" }}>
            <ul className="ul">
              <li>
                <div className="color-box pink"></div> Governance
              </li>
              <li>
                <div className="color-box env-bg"></div> Environment
              </li>
              <li>
                <div className="color-box blue1"></div> Social
              </li>
            </ul>
          </div> */}
        </Box>

        <div
          style={{
            margin: "20px 0",
            padding: "1rem",
            width: "95%",
            background: "white",
          }}
        >
          <FrameWorkCard
            frameworkData={frameworkData}
            selectedCategories={selectedCategories}
            handleCategoryCheckboxChange={handleCategoryCheckboxChange}
            handleSubcategoryCheckboxChange={handleSubcategoryCheckboxChange}
            selectedSubcategories={selectedSubcategories}
            handleCheckboxChange={handleCheckboxChange}
            selectedData={selectedData}
          />
          <PillarScoreCard scorecard={scorecard} />
          <Button
            sx={{ textTransform: "none" }}
            style={{
              color: "gray",
              padding: "5px 15px",
              margin: "5px 0",
              borderRadius: "20px",
              border: "1px solid gray",
              margin: "0 10px",
            }}
            onClick={handleClearSelection}
          >
            Clear Selection
          </Button>
          <Button onClick={handleBack} style={backBtnfixed}>
            Back
          </Button>
          <Button style={buttoncontaindedfixed} onClick={handleSubmit}>
            Continue
          </Button>
        </div>
        <Loader loading={loading} />
      </div>
    </>
  );
};
export default FrameWorks;
