import React from "react";
import axios from "axios";
import {Button, TableCell, TableRow, Tooltip} from "@mui/material";
import {
  userdeletebutton,
  userdeletebuttonDisabled,
  usereditbutton,
  usereditbuttonDisabled,
  userseebutton,
} from "../../styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import {useNavigate} from "react-router-dom";
import {baseUrl} from "../../baseurl";
import {FaArrowsAltH} from "react-icons/fa";
import {toast} from "react-toastify";
import {decryptData} from "../../services/crypto";
import {getOrg, getUserRole} from "../../services/loggedIn";

export const UserRow = ({ user, id, onView, onDelete, onUpdate }) => {
  let dname;
  const navigate = useNavigate();
  function handelSubmit() {
    // const token = window.localStorage.getItem("token");
    // const headers = {
    //   Authorization: `${token}`,
    // };
    axios
      .get(`${baseUrl}/getAllOrganizations`, {
          // headers
          withCredentials: true
      })
      .then((response) => {
        const decryptedData = decryptData(response.data);

        const parsedData = JSON.parse(decryptedData);
        // console.log("parsedData", parsedData.unmarshalledData);
        dname = parsedData.unmarshalledData;
        view(dname);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function view(dname) {
    if (user.operationalUnits !== "N/A") {
      navigate("/user/view-dept-ou", { state: { user: user, data: dname } });
    } else {
      toast.error("No operational Units found");
    }
  }

  const userRole = getUserRole();
  const currentOrg = getOrg();
  const renderActions = (user) => {
    // console.log("user", user.adminRights === "Consultant");
    if (user.organization === currentOrg && userRole === "Manager") {
      return (
        <>
          <TableCell>
            <Tooltip title="Edit user">
              <Button
                className="button editOrg"
                id="view0"
                disabled={user.adminRights !== "Consultant"}
                onClick={() => onUpdate(user)}
                style={
                  user.adminRights === "Consultant"
                    ? usereditbutton
                    : userdeletebuttonDisabled
                }
              >
                <EditNoteIcon />
              </Button>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Delete user">
              <Button
                className="button deleteOrg"
                id="delete0"
                disabled={user.adminRights !== "Consultant"}
                onClick={() => onDelete(user)}
                style={
                  user.adminRights === "Consultant"
                    ? userdeletebutton
                    : userdeletebuttonDisabled
                }
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </TableCell>
        </>
      );
    } else if (userRole === "Consultant") {
      console.log("user", user.organization);
      return (
        <>
          <TableCell>
            <Tooltip title="Edit user">
              <Button
                className="button editOrg"
                id="view0"
                disabled={user.organization === currentOrg}
                onClick={() => onUpdate(user)}
                style={
                  user.organization === currentOrg
                    ? usereditbuttonDisabled
                    : usereditbutton
                }
              >
                <EditNoteIcon />
              </Button>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Delete user">
              <Button
                className="button deleteOrg"
                id="delete0"
                disabled={user.organization === currentOrg}
                onClick={() => onDelete(user)}
                style={
                  user.organization === currentOrg
                    ? userdeletebuttonDisabled
                    : userdeletebutton
                }
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </TableCell>
        </>
      );
    } else {
      return (
        <>
          <TableCell>
            <Tooltip title="Edit user">
              <Button
                className="button editOrg"
                id="view0"
                onClick={() => onUpdate(user)}
                style={usereditbutton}
              >
                <EditNoteIcon />
              </Button>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Delete user">
              <Button
                className="button deleteOrg"
                id="delete0"
                onClick={() => onDelete(user)}
                style={userdeletebutton}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </TableCell>
        </>
      );
    }
  };
  return (
    <TableRow className="table-row" style={{ zIndex: "-1" }}>
      <TableCell>{id}</TableCell>
      <TableCell>{user.userName}</TableCell>
      <TableCell>
        <Tooltip title="View Table">
          <Button
            className="button"
            id="view0"
            onClick={handelSubmit}
            style={usereditbutton}
          >
            <FaArrowsAltH />
          </Button>
        </Tooltip>
      </TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.mobile}</TableCell>
      <TableCell>{user.userStatus === true ? "Active" : "Inactive"}</TableCell>
      <TableCell>{user.lastLogin}</TableCell>
      <TableCell>
        <Tooltip title="View Details">
          <Button
            className="button"
            onClick={() => onView(user)}
            style={userseebutton}
          >
            <VisibilityIcon />
          </Button>
        </Tooltip>
      </TableCell>
      {renderActions(user)}

      {/* <TableCell>
          <Tooltip title="Edit user">
            <Button
              className="button"
              id="view0"
              onClick={() => onUpdate(user)}
              style={usereditbutton}
            >
              <EditNoteIcon />
            </Button>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title="Delete User">
            <Button
              className="button"
              id="delete0"
              onClick={() => onDelete(user)}
              style={userdeletebutton}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </TableCell> */}
    </TableRow>
  );
};
