import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Button,
  Divider,
  Backdrop,
  CircularProgress,
  TableContainer,
  Box,
  TableHead,
  Tooltip,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import "./AddDept.css";
import { backBtnfixed, buttoncontaindedfixed } from "../../../styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../baseurl";
import { toast } from "react-toastify";
import Hamburger from "../../Navbars/Hamburger";
import encrypt, { decryptData } from "../../../services/crypto";
import { addOrganization } from "../../../services/OrganizationManagemnetApi";
import MultiStep from "../../../pages/MultiStepAddOrg/MultiStep";
import { Loader } from "../../Loader/Loader";

export const AddDeptMatrix = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    skills: initialSkills,
    operationalUnits: initialOperationalUnits,
    orgData: initialOrganizationData,
    skillsWithDepartments: initialskillsWithDepartments,
  } = location.state;
  // // console.log("state", location.state);

  // const organizationEmail=location.state.organizationEmail;
  // const image=location.state.image;
  // // // console.log(">>>>",organizationEmail,"<<<<",image);

  const [selectedItems, setSelectedItems] = useState(
    location.state?.selectedItems || []
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleCheckboxChange = (skill, unit) => {
    const updatedItems = [...selectedItems];
    const existingItemIndex = updatedItems.findIndex((item) => item[unit]);

    if (existingItemIndex !== -1) {
      // Check if the skill is already present for the unit
      const skillsArray = updatedItems[existingItemIndex][unit].split(", ");
      const skillIndex = skillsArray.indexOf(skill);

      if (skillIndex !== -1) {
        // If skill is present, remove it
        skillsArray.splice(skillIndex, 1);

        // Update the item in the array
        updatedItems[existingItemIndex][unit] = skillsArray.join(", ");
      } else {
        // If skill is not present, add it
        updatedItems[existingItemIndex][unit] += `, ${skill}`;
      }
    } else {
      // If the unit doesn't exist in selectedItems, add a new item
      updatedItems.push({ [unit]: skill });
    }

    setSelectedItems(updatedItems);
  };

  const handleSelectAll = () => {
    const updatedItems = initialOperationalUnits.map((unit) => {
      const newItem = {};
      newItem[unit] = initialSkills.join(", ");
      return newItem;
    });

    setSelectedItems(updatedItems);
  };

  const operationalUnits = selectedItems;

  // Step 2-4: Extract and concatenate unique values for each department
  const uniqueValuesSet = new Set();

  operationalUnits.forEach((unitObject) => {
    const unitValues = Object.values(unitObject)[0].split(","); // Extract values and split into an array
    unitValues.forEach((value) => uniqueValuesSet.add(value.trim())); // Ensure uniqueness
  });

  const handleClearAll = () => {
    setSelectedItems([]);
  };

  const isItemSelected = (skill, unit) =>
    selectedItems.some((item) => item[unit] && item[unit].includes(skill));

  const handleBack = () => {
    navigate("/organization/add-dept", {
      state: {
        orgData: initialOrganizationData,
        skills: initialSkills,
        operationalUnits: initialOperationalUnits,
        skillsWithDepartments: initialskillsWithDepartments,
        selectedItems: selectedItems,
        selectedManagers: location.state.selectedManagers,
      },
    });
  };

  // const formData = new FormData();
  // formData.append("organizationEmail", organizationEmail);
  // formData.append("image", image);

  // // // console.log("selectedItems", selectedItems);
  const finalJSON = {
    organizationEmail: initialOrganizationData.organizationEmail,
    name: initialOrganizationData.name,
    type: initialOrganizationData.type,
    address: initialOrganizationData.address,
    mobile: initialOrganizationData.mobile,
    departments: initialskillsWithDepartments,
    spocName: initialOrganizationData.spocName,
    spocEmail: initialOrganizationData.spocEmail,
    spocMobile: initialOrganizationData.spocMobile,
    operationalUnits: JSON.stringify(selectedItems),
    countryCode: initialOrganizationData.code,
    country: initialOrganizationData.country,
    selectedManagers: location.state.selectedManagers,
  };

  console.log("final", finalJSON);

  // Function to get a pre-signed URL from the backend
  const getPresignedUrl = async (fileName, fileType) => {
    // const token = window.localStorage.getItem("token");

    try {
      const response = await axios.get(`${baseUrl}/getPresignedUrl`, {
        // headers: {
        //   Authorization: `${token}`,
        //   "Content-Type": "application/json",
        // },
        params: {
          fileName,
          fileType,
        },
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error("Error getting presigned URL:", error);
      throw error; // Re-throw the error to handle it where this function is called
    }
  };
  // Function to handle the image upload
  const uploadImageToS3 = async (file) => {
    if (!file) {
      return "NO_IMAGE";
    }
    const { url, key } = await getPresignedUrl(file.name, file.type);

    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };

    await axios.put(url, file, options);

    // Construct the full URL
    const fullUrl = `https://image-of-organizations.s3.ap-south-1.amazonaws.com/${key}`;

    return fullUrl;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      // Upload the image to S3
      const imageFile = initialOrganizationData.image;
      console.log("imageFile", imageFile);

      const image = await uploadImageToS3(imageFile);
      // // // console.log("Step 3 <<<<<<<",image,">>>>",organizationEmail)
      // const token = window.localStorage.getItem("token");

      // // // console.log("FORM DATA>>>>>>>>>",formData);

      const finalJSON = {
        organizationEmail: initialOrganizationData.organizationEmail,
        name: initialOrganizationData.name,
        type: initialOrganizationData.type,
        address: initialOrganizationData.address,
        mobile: initialOrganizationData.mobile,
        departments: initialskillsWithDepartments,
        spocName: initialOrganizationData.spocName,
        spocEmail: initialOrganizationData.spocEmail,
        spocMobile: initialOrganizationData.spocMobile,
        operationalUnits: JSON.stringify(selectedItems),
        countryCode: initialOrganizationData.code,
        country: initialOrganizationData.country,
        selectedManagers: location.state.selectedManagers,
        image,
      };

      console.log("final2", finalJSON);

      const encryptedOrgData = encrypt({ finalJSON });
      // // // console.log("Encrypted Data: ", encryptedOrgData);

      // const token = window.localStorage.getItem("token");
      // const headers = {
      //   Authorization: `${token}`,
      //   "Content-Type": "application/json",
      // };

      const response = await addOrganization(encryptedOrgData);

      if (response.data.success === true) {
        toast.success("Organization added successfully");
        navigate("/organization/management");
      }
      //  axios.post(
      //   `${baseUrl}/addorg`,
      //   { orgData: encryptedOrgData },
      //   {
      //     headers,
      //   }
      // );
      // const headers = {
      //   Authorization: `${token}`,
      //   "Content-Type": "multipart/form-data",
      // };

      //   const uploadResponse = await axios.post(
      //     `${baseUrl}/uploadimg/${organizationEmail}`, // Make sure to include organizationEmail in the URL
      //     formData,
      //     {
      //       headers,
      //     }
      //   );

      // // // console.log("data response",response);
      // // // console.log("image response",uploadResponse)
      // navigate(
      //   `/organization_management`
      // );
      // navigate(
      //   `/AddLogo/${encodeURIComponent(response.data.organizationEmail)}`
      // );
    } catch (error) {
      console.error("Error adding organization:", error);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const step = 2;

  return (
    <>
      <Hamburger />

      <div className="add-dept-table-body">
        <div className="add-dept-table-container">
          <Box>
            <MultiStep step={step} />
          </Box>
          <br />
          <h3 className="frame_heading">
            Mark departments for each operational unit
          </h3>
          <br />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{ textTransform: "none" }}
              style={{
                color: "gray",
                padding: "5px 15px",
                borderRadius: "20px",
                border: "1px solid gray",
                margin: "0 10px",
              }}
              onClick={handleClearAll}
            >
              Clear All
            </Button>
            <Button
              sx={{ textTransform: "none" }}
              style={{
                color: "gray",
                padding: "5px 15px",
                borderRadius: "20px",
                border: "1px solid gray",
              }}
              onClick={handleSelectAll}
            >
              Select All
            </Button>
          </div>
          <br />
          <TableContainer sx={{ maxHeight: "440px", marginTop: "20px" }}>
            <Table className="add-dept-table">
              <TableBody>
                <TableRow
                  sx={{ position: "sticky", top: "0px", zIndex: 1 }}
                  style={{ background: "#fff" }}
                >
                  <TableCell
                    className="head"
                    style={{
                      color: "#3079bd",
                      fontWeight: "600",
                      fontSize: "1.2rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100px",
                    }}
                  >
                    Dept/OU
                  </TableCell>
                  {initialOperationalUnits.map((unit, index) => (
                    <Tooltip title={unit}>
                      <TableCell
                        key={index}
                        className="head"
                        style={{
                          color: "#3079bd",
                          fontWeight: "600",
                          fontSize: "1.2rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                        }}
                      >
                        {unit}
                      </TableCell>
                    </Tooltip>
                  ))}
                </TableRow>
                {initialskillsWithDepartments.map((skill, skillIndex) => {
                  const deptNames = Object.keys(skill)[0];
                  return (
                    <TableRow key={skillIndex}>
                      <TableCell>{deptNames}</TableCell>
                      {initialOperationalUnits.map((unit, unitIndex) => (
                        <TableCell key={unitIndex}>
                          <Checkbox
                            checked={isItemSelected(deptNames, unit)}
                            onChange={() =>
                              handleCheckboxChange(deptNames, unit)
                            }
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Button onClick={handleBack} style={backBtnfixed}>
            Back
          </Button>
          <Button
            className="add-dept-clrbtn"
            style={buttoncontaindedfixed}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Loader loading={loading} />
        </div>
      </div>
    </>
  );
};
