// encryption.js
import CryptoJS from "crypto-js";

// Replace this with your own secure encryption key
const secretKey = process.env.REACT_APP_ENCRYTION_KEY;
const algorithm = "aes-256-cbc";

const encrypt = (data) => {
  // // console.log("Encrypted Data: ", encryptedData);
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
};

export function decryptData(encryptedData) {
  if (!encryptedData) return "";
  return CryptoJS.AES.decrypt(encryptedData, secretKey).toString(CryptoJS.enc.Utf8); // "vnfkjn"
}

export function isValidJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default encrypt;
