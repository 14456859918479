import React from 'react';
import './HorizontalProgressBar.css'; // Import CSS for styling (see below for content)

const HorizontalProgressBar = ({ percentage,color }) => {


  // Determine color based on percentage range
  let barColor;
   if (percentage >= 95 && percentage <=100) {
    barColor = '#45b653'; // Dark green
  }
  else if (percentage>=0 && percentage<=20){
    barColor='red';
  }
  
  else{
    barColor='#ff9c00';
  }
  return (
    <div
    className='horizontal-progress-container'
  >
    <div
      style={{
        width: `${percentage}%`,        
        backgroundColor:barColor
      }}
      className='progress'
    >
      <div
        style={ {
          color: percentage===0 ? 'red': barColor }}
          className='progress-percentage-text'
      >
        {percentage}%
      </div>
    </div>
  </div>
  );
};

export default HorizontalProgressBar;
