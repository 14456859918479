import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    IconButton,
    Divider,
  } from "@mui/material";
  import React from "react";
  import ErrorIcon from '@mui/icons-material/Error';
  import { backBtn, cancelBtn, okBtn } from "../../styles";
  
  const ErrorModal = ({ closePopup, openPopup, handleBack }) => {
    return (
      <Dialog
        open={true}
        keepMounted
        onClose={handleBack}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{color:'red'}}>
       <h3>Error</h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{display:'flex',flexDirection:'column',alignItems:"center",justifyContent:'center'}}>
          {/* <ErrorIcon style={{color:'gray',fontSize:'3rem',margin:'20px 0'}}/> */}
          There is no mapped data found for the departments and operational units
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{margin:'10px 0'}}>
          <Button onClick={handleBack} style={backBtn}>Back</Button>
          {/* <Button onClick={closePopup}>Agree</Button> */}
        </DialogActions>
      </Dialog>
    );
  };
  
  export default ErrorModal;
  