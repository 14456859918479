import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {FaWpforms} from "react-icons/fa6";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {MdCloudDownload} from "react-icons/md";
import {Loader} from "../../../components/Loader/Loader";
import ClientLayout from "../../../components/ClientLayout/ClientLayout";

const ClientLandingPage = () => {
  const [loading,setLoading]=useState(true)

  useEffect(()=>{
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); 

    return () => clearTimeout(timer);
  },[])

  if (loading) {
    return <Loader loading={loading}/>
  }

  return (
    <div>
      {/* <DashHamburger /> */}
      <ClientLayout>
        <div className="landing-page-container">
          <div className="link-box-container">
            <Link
              to="/reporting/dashboard"
              className="link-box blue"
            >
              <div className="circle">
                <DashboardIcon
                  sx={{ fontSize: "5rem", color: "white" }}
                  className="circle-icon"
                />

                {/* <h3>Organization Management</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                Reporting  <br /> Dashboard
              </h4>
            </Link>

            <Link to="/download_report" className="link-box green2">
              <div className="circle">
                <MdCloudDownload
                  sx={{ fontSize: "5rem", color: "white" }}
                  className="circle-icon"
                />

                {/* <h3>Organization Management</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                Download & Preview
                <br /> Report
              </h4>
            </Link>
            <Link to="/reporting/history/overview" className="link-box green">
              <div className="circle">
                <FaWpforms className="circle-icon" />

                {/* <h3>User Management</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                History 
              </h4>
            </Link>
          </div>
        </div>
        </ClientLayout>
    </div>
  );
};

export default ClientLandingPage;
