import React, {useEffect, useState} from "react";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import {backBtnfixed, buttoncontaindedfixed} from "../../styles.js";
import {baseUrl} from "../../baseurl.js";
import {Backdrop, Box, Button, CircularProgress} from "@mui/material";
import Hamburger from "../Navbars/Hamburger.js";
import {FramerScroll} from "../FramerScroll/FramerScroll.jsx";
import {toast} from "react-toastify";
import ReviewUserTable from "../AssignmentManager/ReviewUserTable.jsx";
import encrypt, {decryptData} from "../../services/crypto.js";
import {assignKpisToUsers} from "../../services/FormManagerApi.js";
import {BsInfoCircle} from "react-icons/bs";
import ThankyouModal from "../PopUpDialog/ThankyouModal.jsx";
import MultiStepAssignManager from "../../pages/MultiStepAssignManager/MultiStepAssignManager.jsx";
import ConfirmModal from "../PopUpDialog/ConfirmModal.jsx";
import ErrorModal from "../PopUpDialog/ErrorModal.jsx";
import {Loader} from "../Loader/Loader.jsx";

const TableReview = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(
    location.state?.selectedUsers || {}
  );
  // // console.log("selectedUser", selectedUsers);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const ous = [...new Set(data.map((item) => item.OU))];
  const tableData = data.reduce((acc, item) => {
    const { Department, OU, Users } = item;
    acc[Department] = { ...acc[Department], [OU]: Users };
    return acc;
  }, {});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleUserChange = (department, ou, event) => {
    const newSelectedUsers = {
      ...selectedUsers,
      [`${department}-${ou}`]: event.target.value,
    };
    setSelectedUsers(newSelectedUsers);
  };
  // // console.log("state", location.state);
  const data0 = location.state.data0;
  const org_email = location.state.data;

  const handleSubmit = async () => {
    if (!Object.keys(selectedUsers).length) {
      return toast.error("Select at least one user");
    }
    try {
      setLoading(true);

      // Create an array to store the modified data
      const modifiedData0 = data.map((item) => {
        const { OU, Department, Users, Data, priorityTag } = item;
        const ouKey = `${Department}-${OU}`;

        // Get the selected user for the current OU and Department combination
        const selectedUser = selectedUsers[ouKey];
        if (Array.isArray(Users) && Users.length !== 0) {
          // Determine the user to include based on selection or use the original Users array
          const updatedUsers = selectedUser
            ? [
                {
                  // Include all properties from the original Users array for the selected user
                  ...Users.find((user) => user.userName === selectedUser),
                  // Add additional properties from the selected user if needed
                },
              ]
            : [];

          return {
            OU,
            Department,
            Users: updatedUsers,
            Data,
            priorityTag,
          };
        } else {
          return null;
        }
      });
      const modifiedData = modifiedData0.filter((item) => item !== null);
      const encryptedAssignData = encrypt({ modifiedData });
      await assignKpisToUsers(encryptedAssignData)
        .then((response) => {
          setLoading(true);
          toast.success(response.data.message);
          setShowModal(true);
          setLoading(false);
        })
        .catch((error) => {
          // alert(error.message);
          toast.error(error.message);
        });

      setLoading(false);
      setSelectedUsers({});
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchOrganizations = async () => {
      setLoading(true);
      // const token = window.localStorage.getItem("token");
      // const headers = {
      //   Authorization: `${token}`,
      //   "Content-Type": "application/json",
      // };
      const json = {
        KPICodes: data0,
        organizationEmail: org_email,
      };

      const encryptData = encrypt({ json });

      axios
        .post(
          `${baseUrl}/getMappedTable`,
          { encryptedData: encryptData },
          { withCredentials: true }
        )
        .then((response) => {
          setLoading(false);

          // // console.log("response", response);
          const decryptedData = decryptData(response.data);

          const parsedData = JSON.parse(decryptedData);
          // console.log("parsedData", parsedData);

          setData(parsedData.finalMappedTable);
          console.log("-----------",parsedData.finalMappedTable)
          const allUsersEmpty = parsedData.finalMappedTable.every(
            (item) => item.Users.length === 0
        );
          console.log(">>>>>>>",allUsersEmpty)

          if (parsedData.finalMappedTable.length === 0 || allUsersEmpty ) {
            setShowErrorModal(true);
          }
        })
        .catch((error) => {
          console.error(error);
          // setShowErrorModal(true); 
        });
    };

    fetchOrganizations();
  }, []);

  // useEffect(() => {
  //   console.log(data.length)
  //   if (!loading && data.length === 0) {
  //     // const allUsersEmpty = data.every((item) => item.Users?.length === 0);
  //     // console.log(allUsersEmpty)
  //     if (data.length===0) {
  //       setShowErrorModal(true);
  //     }
  //   }
  // }, [loading, data]);

  const handleErrorModalClose = () => {
    setShowErrorModal(false);
  };

  const closePopup = () => {
    setShowModal(false);
    setShowConfirmModal(false);
  };

  const openShowConfirmModal = () => {
    if (!Object.keys(selectedUsers).length) {
      return toast.error("Select at least one user");
    }
    setShowConfirmModal(true);
  };

  const openPopup = () => {
    setShowModal(true);
  };

  const handleNavigate = () => {
    navigate("/home");
  };

  const step = 4;

  const handleBack = () => {
    navigate("/assignment/select-kpis", {
      state: {
        org: location.state?.org,
        prioritiesJson: location.state?.prioritiesJson,
        selectedData: location.state?.selectedData,
        selectedCategories: location.state?.selectedCategories,
        selectedSubcategories: location.state?.selectedSubcategories,
        selectedOption: location.state?.selectedOption,
        responseData: location.state?.responseData,
        scorecard: location.state?.scorecard,
        changedFrequencies: location.state?.changedFrequencies,
        checkedRows: location.state?.checkedRows,
        selectedFrequencies: location.state?.selectedFrequencies,
        checkedAll: location.state?.checkedAll,
        frameworkFilter: location.state?.frameworkFilter,
        selectedCount: location.state?.selectedCount,
        selectedUsers: selectedUsers,
      },
    });
  };

  return (
    <div
      style={{
        padding: "16px",
      }}
    >
      <Hamburger />
      <FramerScroll />
      {showErrorModal  ?(
        <ErrorModal
          openPopup={showErrorModal}
          closePopup={handleErrorModalClose}
          handleBack={handleBack}
        />
      ): (
        <div
          style={{
            marginTop: "5%",
            padding: "2rem",
            borderRadius: "20px",
            backgroundColor: "#f9f8f9",
          }}
        >
          {/* <h4 className="frame_heading">
          Review User Assocation with OU-Department Matrix
        </h4> */}
          <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
            <MultiStepAssignManager step={step} />
          </Box>
          <h4 className="frame_heading">Select User To Assign KPI's</h4>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsInfoCircle />
            &nbsp; Select User based on particular department and operation unit
          </p>
          <ReviewUserTable
            ous={ous}
            tableData={tableData}
            selectedUsers={selectedUsers}
            handleUserChange={handleUserChange}
          />

          <form>
            <Button
              disabled={loading}
              onClick={openShowConfirmModal}
              style={buttoncontaindedfixed}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </form>
          <Button onClick={handleBack} style={backBtnfixed}>
            Back
          </Button>
        </div>
      ) }

      <Loader loading={loading} />

      {showConfirmModal && (
        <ConfirmModal
          open={showConfirmModal}
          title={"Confirm Submitting"}
          onClose={closePopup}
          message={"Are you sure you want to continue submitting assignment?"}
          confirmText={"continue"}
          cancelText={"cancel"}
          onConfirm={handleSubmit}
        />
      )}

      {showModal && (
        <Backdrop>
          <ThankyouModal
            openPopup={openPopup}
            closePopup={closePopup}
            handleNavigate={handleNavigate}
            message={"Thank You for the Assignment!"}
            title={"Assignment Completed"}
            confirmText={"OK"}
          />
        </Backdrop>
      )}
    </div>
  );
};
export default TableReview;
