import React from "react";
import UserAdoption from "./UserAdoption";
import DashboardLayout from "../../components/DashboradLayout/DashboardLayout";

const UserAdp = () => {
  return (
    <>
      {/* <DashHamburger /> */}
      <DashboardLayout>
        <div>
          <UserAdoption />
        </div>
      </DashboardLayout>
    </>
  );
};
export default UserAdp;
